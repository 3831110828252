var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { name: "slide-panel" },
      on: {
        "before-enter": _vm.beforeEnter,
        enter: _vm.enter,
        "after-enter": _vm.afterEnter,
        "before-leave": _vm.beforeLeave,
        leave: _vm.leave,
        "after-leave": _vm.afterLeave
      }
    },
    [
      _c(
        "el-aside",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show,
              expression: "show"
            }
          ],
          staticClass: "info-panel",
          attrs: { width: _vm.width }
        },
        [
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showCloseButton,
                  expression: "showCloseButton"
                }
              ],
              staticClass: "info-panel-close",
              on: { click: _vm.close }
            },
            [_c("i", { staticClass: "fas fa-times" })]
          ),
          _vm._t("default")
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }