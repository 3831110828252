export default {
  namespaced: true,
  state: {
    refresh_token: {
      // value: '',
      value: localStorage.getItem('refreshToken'),
    },
    refresh_token_temp: {
      // value: '',
      value: localStorage.getItem('refreshTokenTemp'),
    },
    // auto_login_flag: {
    //   // value: '',
    //   value: localStorage.getItem('autoLoginFlag'),
    // },
    access_token: {
      value: '',
    },
    activeUser: {
      value: {},
    },
    activeUserAuthInfo: {
      value: {},
    },
  },
  getters: {
    getAccessToken: (state) => {
      return state.access_token.value;
    },
    // getRefreshToken: (state) => {
    //   return state.refresh_token.value;
    // },
    getRefreshToken: (state) => {
      let autoLoginFlag = false;
      if (localStorage.getItem('autoLoginFlag') === 'true') {
        autoLoginFlag = true;
      }

      console.log(`autoLoginFlag is ${autoLoginFlag}`);
      if (autoLoginFlag) {
        return state.refresh_token.value;
      } else {
        return state.refresh_token_temp.value;
      }
    },
    getActiveUser: (state) => {
      return state.activeUser.value;
    },
    getActiveUserAuthInfo: (state) => {
      return state.activeUserAuthInfo.value;
    },
    getAutoLoginFlag: (state) => {
      if (localStorage.getItem('autoLoginFlag') === 'true') {
        return true;
      } else {
        return false;
      }
    },
  },
  mutations: {
    // setRefreshToken(state, payload) {
    //   state.refresh_token.value = payload;
    //   localStorage.setItem('refreshToken', payload);
    // },
    setRefreshToken(state, payload) {
      const { token, autoFlag = false } = payload;
      localStorage.setItem('autoLoginFlag', autoFlag + '');

      if (autoFlag) {
        state.refresh_token.value = token;
        state.refresh_token_temp.value = '';

        localStorage.setItem('refreshToken', token);
        localStorage.removeItem('refreshTokenTemp');
      } else {
        state.refresh_token.value = '';
        state.refresh_token_temp.value = token;

        localStorage.setItem('refreshTokenTemp', token);
        localStorage.removeItem('refreshToken');
      }
    },
    removeRefreshToken(state) {
      state.refresh_token.value = '';
      state.refresh_token_temp.value = '';
      localStorage.removeItem('refreshTokenTemp');
      localStorage.removeItem('refreshToken');
      state.activeUser.value = false;
    },
    setAccessToken(state, payload) {
      state.access_token.value = payload;
    },
    setActiveUser(state, payload) {
      state.activeUser.value = payload;
    },
    setActiveUserAuthInfo(state, payload) {
      state.activeUserAuthInfo.value = payload;
    },
  },
  actions: {
    // actRefreshToken({ state, commit, rootState }, payload) {
    //   commit('setRefreshToken', payload.refresh_payload);
    // },
    actRefreshToken({ state, commit, rootState }, payload) {
      commit('setRefreshToken', {
        token: payload.refresh_payload,
        autoFlag: payload.auto_flag,
      });
    },
    actRemoveRefreshToken({ state, commit, rootState }, payload) {
      commit('removeRefreshToken');
    },
    actAccessToken({ state, commit, rootState }, payload) {
      commit('setAccessToken', payload.access_payload);
    },
    actActiveUser({ state, commit, rootState }, payload) {
      commit('setActiveUser', payload.activeUser);
    },
    actActiveUserAuthInfo({ state, commit, rootState }, payload) {
      commit('setActiveUserAuthInfo', payload.actActiveUserAuthInfo);
    },
  },
};
