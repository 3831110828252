<template>
  <div class="page-subject-wrap">
    <div class="page-subject">
      <div class="page-subject-content">
        <h3>{{ this.$route.name }}</h3>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item v-for="(route, index) in $route.matched" :key="index">
            <a
              @click="clickNaviMenu(route)"
              v-if="route.name.toLowerCase() == 'home'"
              v-bind:class="[$route.name == route.name ? 'current-page' : '']"
            >
              <i class="fas fa-home"></i>
            </a>
            <a @click="clickNaviMenu(route)" v-else v-bind:class="[$route.name == route.name ? 'current-page' : '']">{{ route.name }}</a>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="header-addon">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
