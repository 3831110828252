<template>
  <div>
    <page-traffic-gis v-if="this.activeUser.group == 'utis' || this.activeUser.group == 'police'"> </page-traffic-gis>
    <page-led-display-manage v-else-if="this.activeUser.group == 'ledoper'"></page-led-display-manage>
    <!-- <page-fclt-gis v-else-if="!this.isMobile"></page-fclt-gis> -->
    <page-fclt-status v-else-if="!this.isMobile"></page-fclt-status>
    <page-mobile-fclt-dashboard v-else> </page-mobile-fclt-dashboard>
    <form-user-password ref="formUserPassword"></form-user-password>
  </div>
</template>

<script>
// import pageFcltDashboard from './pageFcltDashboard.vue'
import pageMobileFcltDashboard from './pageMobileDashboard.vue';
// import pageFcltGis from '@/core/pageFcltGis.vue';
import pageFcltStatus from '@/core/pageFcltStatus.vue';
import pageTrafficGis from '@/traffic/pageGimpoGis.vue';
import pageLedDisplayManage from '@/led/pageLedDisplayManage.vue';
import formUserPassword from '@/core/forms/formUserPassword.vue';

export default {
  components: {
    'page-mobile-fclt-dashboard': pageMobileFcltDashboard,
    'page-led-display-manage': pageLedDisplayManage,
    'form-user-password': formUserPassword,
    'page-traffic-gis': pageTrafficGis,
    // 'page-fclt-gis': pageFcltGis,
    'page-fclt-status': pageFcltStatus,
  },
  created() {
    this.activeUser = this.$dkRouter.getUserInfo();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.activeUser.userid === 'admin' && this.activeUser.firstLogin) {
        this.$refs.formUserPassword.showDialog(this.activeUser);
      }
    });
  },
  data() {
    return {
      activeUser: null,
      isMobile: this.$isMobile,
    };
  },
  methods: {},
};
</script>

<style scoped></style>
