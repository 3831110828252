var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      attrs: {
        "label-width": _vm.labelWidth,
        "label-position": _vm.$isMobile ? "top" : "right",
        size: "medium",
        model: _vm.formData
      }
    },
    _vm._l(_vm.formData, function(input) {
      return _c(
        "div",
        { key: input.key },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: input.label,
                prop: input.key,
                rules: _vm.numberRules
              }
            },
            [
              input.formType === "string"
                ? _c("el-input", {
                    attrs: { placeholder: input.label },
                    model: {
                      value: input.value,
                      callback: function($$v) {
                        _vm.$set(input, "value", $$v)
                      },
                      expression: "input.value"
                    }
                  })
                : _vm._e(),
              input.formType === "number"
                ? _c("el-input", {
                    attrs: { placeholder: input.label },
                    model: {
                      value: input.value,
                      callback: function($$v) {
                        _vm.$set(input, "value", $$v)
                      },
                      expression: "input.value"
                    }
                  })
                : _vm._e(),
              input.formType === "combobox"
                ? _c(
                    "el-select",
                    {
                      attrs: { placeholder: input.label },
                      model: {
                        value: input.value,
                        callback: function($$v) {
                          _vm.$set(input, "value", $$v)
                        },
                        expression: "input.value"
                      }
                    },
                    _vm._l(input.options, function(option) {
                      return _c("el-option", {
                        key: option.value,
                        attrs: { label: option.label, value: option.value }
                      })
                    }),
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }