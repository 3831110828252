<template>
  <el-dialog
    :title="formTitle"
    :visible.sync="formDialog"
    @close="hideDialog"
    :close-on-click-modal="false"
    width="600px"
  >
    <div>
      <div
        v-if="formData.userid == 'admin' && formData.firstLogin"
        class="admin-notice"
      >
        <p>
          현재 기본값으로 설정된 관리자 비밀번호를 사용하고 계십니다.
          <br />보안을 위해 변경하시길 바랍니다.
        </p>
      </div>
      <el-form
        :model="formData"
        label-width="140px"
        size="medium"
        :rules="rules"
        ref="form0"
      >
        <el-row>
          <el-col :span="22" style="height: 68px;">
            <el-form-item label="비밀번호" prop="password">
              <el-input
                type="password"
                v-model="formData.password"
                placeholder="비밀번호"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="22" style="height: 63px;">
            <el-form-item label="비밀번호 확인" prop="checkPassword">
              <el-input
                type="password"
                v-model="formData.checkPassword"
                placeholder="비밀번호 확인"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="form-bottom">
        <el-button @click="hideDialog" style="float: right;">취소</el-button>
        <el-button
          type="primary"
          @click="save"
          style="float: right; margin-right: 5px;"
          >저장</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import CryptoJS from 'crypto-js';
export default {
  data() {
    var validatePass = async (rule, value, callback) => {
      var passwordRules = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,16}$/;
      if (!passwordRules.test(value)) {
        callback(
          new Error(
            '비밀번호는 8자 이상의 숫자와 영문자, 특수문자(!@#$%^&*=-) 의 조합으로 만들어야 합니다.'
          )
        );
      } else {
        // if (this.formData.checkPassword !== '') {
        //   this.$refs.form0.validateField('checkPassword');
        // }
        let userData = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/auth/getUserSalt`, {
          params: {
            userid: this.formData.userid,
          },
        });
        let password = CryptoJS.SHA256(
          userData.data.salt + this.formData.password
        ).toString();
        if (this.passwordCompare === password) {
          callback(new Error('기존 비밀번호와 동일합니다.'));
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('비밀번호를 확인하여 주십시오'));
      } else if (value !== this.formData.password) {
        callback(new Error('비밀번호가 일치하지 않습니다'));
      } else {
        callback();
      }
    };
    return {
      formDialog: false,
      formTitle: '',
      passwordCompare: '',
      formData: {
        name: '',
        userid: '',
        phone: '',
        email: '',
        group: '',
        authority: '',
        password: '',
        checkPassword: '',
        images: [],
        salt: '',
        firstLogin: false,
      },
      rules: {
        password: [
          {
            required: true,
            validator: validatePass,
            trigger: ['blur', 'change'],
          },
        ],
        checkPassword: [
          {
            required: true,
            validator: validatePass2,
            trigger: ['blur', 'change'],
          },
        ],
      },
    };
  },
  created() {},
  methods: {
    getPasswordCompare() {
      let param = {
        userid: this.formData.userid,
      };
      this.$http
        .post(`${this.$config.getServerConfig().core.api}/core/auth/getPasswordCompare`, { data: param })
        .then((response) => {
          this.passwordCompare = response.data.message;
        });
    },
    save() {
      let me = this;
      try {
        this.$refs.form0.validate(async (valid) => {
          if (valid) {
            if (this.formData.userid == 'admin' && this.formData.firstLogin) {
              this.formData.firstLogin = false;
            }

            let response = await this.$http.post(
              `${this.$config.getServerConfig().core.api}/core/api/user/update`,
              {
                data: this.formData,
              }
            );
            me.$parent.$emit('procDataComplete', this.formData);
            me.resetData();
            me.hideDialog();
            me.$message({
              type: 'success',
              message: `사용자 정보 등록 완료`,
            });
          } else {
            return false;
          }
        });
      } catch (error) {
        this.resetData();
        this.hideDialog();
        this.$message.error(`사용자 정보 등록 중 에러 발생`);
      }
    },
    showDialog(row) {
      this.updateFlag = true;
      this.formData = {
        firstLogin: row.firstLogin,
        userid: row.userid,
      };
      this.getPasswordCompare();
      if (row.userid == 'admin' && row.firstLogin) {
        this.formTitle = '관리자 비밀번호 수정';
      } else {
        this.formTitle = '사용자 비밀번호 수정';
      }
      this.formDialog = true;
      this.$forceUpdate();
    },
    hideDialog() {
      this.resetData();
      this.formDialog = false;
    },
    resetData() {
      this.formData = {
        name: '',
        userid: '',
        phone: '',
        email: '',
        group: '',
        authority: '',
        password: '',
        checkPassword: '',
        images: [],
      };
      this.$refs.form0.resetFields();
    },
  },
};
</script>

<style scoped>
.admin-notice {
  margin-bottom: 30px;
  text-align: center;
}
</style>
