import axios from 'axios';
import CryptoJS from 'crypto-js';

export default {
  namespaced: true,
  actions: {
    // return new Promise((resolve, reject) => {
    //     let password = CryptoJS.SHA256(payload.data.password)
    //     let param = {
    //       data : {
    //         userid: payload.data.userid,
    //         password: password.toString(),
    //         autoLogin: payload.data.autoLogin
    //       }
    //     }
    //     axios.post(`${this.state.dkConfig.getServerConfig().core.api}/core/auth/getAll`, {...param}).then(response => {
    //       resolve(response.data);
    //     }).catch(err => {
    //       return reject(err);
    //     })
    //   }).then(response => {
    //     dispatch('auth/actAccessToken', {access_payload: response.result.accessToken}, {root: true});
    //     dispatch('auth/actRefreshToken', {refresh_payload: response.result.refreshToken}, {root: true});
    //   })

    async actLogin({ state, commit, rootState, dispatch }, payload) {
      // return new Promise(async (resolve, reject) => {
      try {
        let saltResponse = await axios.get(`${this.state.dkConfig.getServerConfig().core.api}/core/auth/getUserSalt`, {
          params: {
            userid: payload.data.userid,
          },
        });
        let password = CryptoJS.SHA256(saltResponse.data.salt + payload.data.password);
        let param = {
          data: {
            userid: payload.data.userid,
            password: password.toString(),
            autoLogin: payload.data.autoLogin,
          },
        };
        if (payload.data.appId) param.data.appId = payload.data.appId;
        if (payload.data.appToken) param.data.appToken = payload.data.appToken;

        try {
          let result = await axios.post(`${this.state.dkConfig.getServerConfig().core.api}/core/auth/getAll`, { ...param });
          if (this.getters['configure/getConfigure'].isLoginApprovedEquip) {
            if (result.data.result.approveEquipStatus && result.data.result.approveEquipStatus === 'success') {
              dispatch('auth/actAccessToken', { access_payload: result.data.result.accessToken }, { root: true });
              // dispatch('auth/actRefreshToken', { refresh_payload: result.data.result.refreshToken }, { root: true });

              dispatch(
                'auth/actRefreshToken',
                {
                  refresh_payload: result.data.result.refreshToken,
                  auto_flag: payload.data.autoLogin,
                },
                { root: true }
              );
            }
          } else {
            dispatch('auth/actAccessToken', { access_payload: result.data.result.accessToken }, { root: true });

            // dispatch('auth/actRefreshToken', { refresh_payload: result.data.result.refreshToken }, { root: true });
            dispatch(
              'auth/actRefreshToken',
              {
                refresh_payload: result.data.result.refreshToken,
                auto_flag: payload.data.autoLogin,
              },
              { root: true }
            );
          }
          return result.data;
        } catch (err2) {
          console.error('');
          throw err2;
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
      // })
      // .then(response => {

      // })
    },
  },
};
