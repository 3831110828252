import axios from 'axios';

export default {
  namespaced: true,
  state: {
    fcltStatus: {
      value: null,
    },
  },
  getters: {
    getFcltStatus: (state) => {
      return state.fcltStatus.value;
    },
    // getPagination: (state) => {
    //     return state.pagination
    // }
  },
  mutations: {
    setFcltStatus(state, payload) {
      state.fcltStatus.value = payload;
      //localStorage.setItem('gisApi', payload)
    },
    // setPagination(state, payload) {
    //     state.pagination = payload
    // }
  },
  actions: {
    getFcltStatus({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${this.state.dkConfig.getServerConfig().core.api}/core/api/fcltStatus`, { params: payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      })
        .then((response) => {
          commit('setFcltStatus', response.data);
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getFcltStatusChart({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${this.state.dkConfig.getServerConfig().core.api}/core/api/fcltStatusChart`, { params: payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      })
        .then((response) => {
          commit('setFcltStatus', response.data);
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addFcltStatus({ commit }, payload) {
      return axios.post(`${this.state.dkConfig.getServerConfig().core.api}/core/api/fcltStatus/add`, payload);
    },
    updateFcltStatus({ commit }, payload) {
      return axios.post(`${this.state.dkConfig.getServerConfig().core.api}/core/api/fcltStatus/update`, payload);
    },
    deleteFcltStatus({ commit }, payload) {
      return axios.post(`${this.state.dkConfig.getServerConfig().core.api}/core/api/fcltStatus/delete`, payload);
    },
    // restoreFcltStatusState({commit}, payload) {
    //     return axios.post(`${this.state.dkConfig.getServerConfig().core.api}/core/api/fcltStatus/restore`, payload)
    // },
    // swapFcltStatusState({commit}, payload) {
    //     return axios.post(`${this.state.dkConfig.getServerConfig().core.api}/core/api/fcltStatus/swap`, payload)
    // }
  },
};
