var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "formNoticePopup" } },
    [
      _c(
        "el-dialog",
        {
          staticClass: "el-dialog-notice",
          attrs: {
            visible: _vm.isNoticePopupDialog,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            top: "5vh",
            "show-close": false,
            "before-close": _vm.hideDialog,
            "lock-scroll": true
          },
          on: {
            "update:visible": function($event) {
              _vm.isNoticePopupDialog = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticStyle: { "max-height": "80vh", "min-height": "70vh" } },
            [
              _c("div", [
                _c("div", [
                  _c("h3", [_vm._v(_vm._s(_vm.notice.title))]),
                  _c("div", {
                    staticClass: "notice-content",
                    domProps: { innerHTML: _vm._s(_vm.getNoticeText()) }
                  })
                ]),
                _c("div", [
                  _c(
                    "div",
                    {
                      class: [
                        !_vm.notice.images || _vm.notice.images.length === 0
                          ? "notice-file d-none"
                          : "notice-file"
                      ]
                    },
                    [
                      _c("div", { staticStyle: { display: "table" } }, [
                        _c("h4", [
                          _vm._v("\n                첨부파일\n              ")
                        ])
                      ]),
                      _c(
                        "ul",
                        _vm._l(_vm.notice.images, function(file, index) {
                          return _c(
                            "li",
                            { key: index, staticClass: "a-tag-files" },
                            [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      _vm.$config.getServerConfig().image.url +
                                      file.url,
                                    download: file.name
                                  }
                                },
                                [
                                  _c("i", { staticClass: "el-icon-document" }),
                                  _vm._v(" " + _vm._s(file.name))
                                ]
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ]
                  )
                ])
              ])
            ]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { staticClass: "button", on: { click: _vm.setCookie } },
                [_vm._v("오늘그만보기")]
              ),
              _c(
                "el-button",
                { staticClass: "button", on: { click: _vm.hideDialog } },
                [_vm._v("확인")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }