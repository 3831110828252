import pageBasic from './pageBasic.vue';
import pageDownload from './pageDownload.vue';

const routeList = [
  // { component: pageMain, name: 'Home', path: '/' },
  { component: pageBasic, name: 'basic', path: '/' },
  { component: pageDownload, name: 'download', path: '/download' },
  { component: pageBasic, name: 'basic', path: '/:path' },
];

export default class {
  constructor({ router }) {
    routeList.forEach((pageInfo) => {
      const { path, name, component } = pageInfo;
      const route = {
        path,
        name,
        component,
        children: [],
        meta: {
          requireAccessToken: true,
        },
        //   icon: 'fas fa-home',
      };

      router.addRoutes([route]);
    });
  }
}
