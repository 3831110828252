class DKLedUtils {
  static getLedTypeText(fclt) {
    if (!fclt) {
      return '';
    }
    if (fclt.fcltTypeData && fclt.fcltTypeData.ledType && fclt.fcltTypeData.ledType.value) {
      if (fclt.fcltTypeData.ledType.value === '3color') {
        return '3컬러';
      } else if (fclt.fcltTypeData.ledType.value === 'ctype') {
        return '풀컬러';
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  static getLedSize(fclt) {
    if (!fclt) {
      return '';
    }
    if (
      fclt.fcltTypeData &&
      fclt.fcltTypeData.ledWidth &&
      fclt.fcltTypeData.ledWidth.value &&
      fclt.fcltTypeData.ledHeight &&
      fclt.fcltTypeData.ledHeight.value
    ) {
      return `${fclt.fcltTypeData.ledHeight.value}x${fclt.fcltTypeData.ledWidth.value}`;
    } else {
      return '';
    }
  }

  static getLedSizeKorean(fclt) {
    if (!fclt) {
      return '';
    }
    if (
      fclt.fcltTypeData &&
      fclt.fcltTypeData.ledWidth &&
      fclt.fcltTypeData.ledWidth.value &&
      fclt.fcltTypeData.ledHeight &&
      fclt.fcltTypeData.ledHeight.value
    ) {
      return `${fclt.fcltTypeData.ledHeight.value}단 ${fclt.fcltTypeData.ledWidth.value}열`;
    } else {
      return '';
    }
  }

  static getLedMessageSizeKorean(val) {
    if (val) {
      let s = val.split('x');
      return `${s[0]}단${s[1]}열`;
    } else {
      return '';
    }
  }
}

export default DKLedUtils;
