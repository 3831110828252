<template>
  <transition
    name="slide-panel"
    v-on:before-enter="beforeEnter"
    v-on:enter="enter"
    v-on:after-enter="afterEnter"
    v-on:before-leave="beforeLeave"
    v-on:leave="leave"
    v-on:after-leave="afterLeave"
  >
    <el-aside v-show="show" class="info-panel" :width="width">
      <span class="info-panel-close" @click="close" v-show="showCloseButton">
        <i class="fas fa-times"></i>
      </span>
      <slot> </slot>
    </el-aside>
  </transition>
</template>

<script>
export default {
  created() {
    if (this.initShow) {
      this.show = true;
    }
  },
  data() {
    return {
      show: false,
    };
  },
  props: {
    beforeEnter: {
      type: Function,
      default: function (el) {},
    },
    enter: {
      type: Function,
      default: function (el) {},
    },
    afterEnter: {
      type: Function,
      default: function (el) {},
    },
    beforeLeave: {
      type: Function,
      default: function (el) {},
    },
    leave: {
      type: Function,
      default: function (el) {},
    },
    afterLeave: {
      type: Function,
      default: function (el) {},
    },
    width: {
      type: String,
      default: '300px',
    },
    initShow: {
      type: Boolean,
      default: false,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    toggle() {
      this.show = !this.show;
      return this.show;
    },
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
    isOpened() {
      return this.show;
    },
  },
};
</script>

<style scoped>
.slide-panel-enter-active {
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.slide-panel-leave-active {
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.slide-panel-enter,
.slide-panel-leave-to {
  width: 0 !important;
}

.info-panel-close {
  color: #999;
  cursor: pointer;
  margin: 12px;
  position: absolute;
  right: 0;
  top: -7px;
  z-index: 200;
}

.info-panel-close:hover {
  color: #bbb;
}

.info-panel-close > i {
  font-size: 18px;
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .info-panel {
    bottom: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1000;
  }
}

@media screen and (max-width: 768px) {
  .info-panel {
    max-width: 100%;
    min-height: 100%;
    position: absolute;
    right: 0;

    /* bottom: 0px; */
    top: 0;
    z-index: 1000;
  }
}
</style>
