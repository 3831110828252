var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      this.activeUser.group == "utis" || this.activeUser.group == "police"
        ? _c("page-traffic-gis")
        : this.activeUser.group == "ledoper"
        ? _c("page-led-display-manage")
        : !this.isMobile
        ? _c("page-fclt-status")
        : _c("page-mobile-fclt-dashboard"),
      _c("form-user-password", { ref: "formUserPassword" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }