<template>
  <router-view />
</template>

<script>
export default {
  data() {
    return {
      activeUser: null,
    };
  },
  created() {
    if (this.$initRoutePath) {
      this.$router.push(this.$initRoutePath);
    }
    // else {
    //   this.$router.replace('/')
    // }
    this.activeUser = this.$dkRouter.getUserInfo();
    // window.addEventListener('beforeunload', this.handler);
  },
  methods: {
    // handler(event) {
    // }
  },
};
</script>
