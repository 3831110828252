<template>
  <el-dialog :title="formTitle" :visible.sync="formDialog" @close="hideDialog" :close-on-click-modal="false" id="formLedFclt" top="5vh">
    <div class="wrap">
      <div class="led-search-list" v-if="isSearch">
        <el-table
          v-show="isSearchInit"
          :data="ipSearchedLedList"
          empty-text="검색된 LED 장비가 없습니다."
          size="mini"
          stripe
          @row-click="selectIpSearchedListList"
        >
          <el-table-column label="장비ID" header-align="center" prop="boardId" align="center"></el-table-column>
          <el-table-column label="IP" header-align="center" prop="ip" align="center"></el-table-column>
          <!-- <el-table-column label="Gateway" header-align="center" prop="gateway" align="center"></el-table-column>
          <el-table-column label="MAC Address" header-align="center" prop="macaddress" width="180" align="center"></el-table-column> -->
          <el-table-column label="등록상태" header-align="center" align="center">
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.isRegistered">등록 완료</el-tag>
              <el-tag type="warning" v-else>미등록 장비</el-tag>
            </template>
          </el-table-column>
        </el-table>
        <div class="ip-search-loading" v-show="!isSearchInit">
          <div class="loading-image">
            <img src="../../assets/img/loading.gif" />
          </div>
          <div>
            LED 장비 검색 중
          </div>
        </div>
      </div>
      <div :class="['led-form-wrap', isSearch ? 'with-ip-search' : '']">
        <el-form class="led-form-data" :model="fcltDataDoc" label-width="120px" size="mini" :rules="rules" ref="form0">
          <el-form-item label="LED 장비 ID" prop="fcltId">
            <el-col :span="14">
              <el-input v-model="fcltDataDoc.fcltId" placeholder="LED 장비 ID"></el-input>
            </el-col>
          </el-form-item>

          <el-form-item label="LED 명칭" prop="fcltName">
            <el-col :span="14">
              <el-input v-model="fcltDataDoc.fcltName" placeholder="LED 명칭"></el-input>
            </el-col>
          </el-form-item>

          <el-form-item label="LED 유형" prop="fcltTypeId">
            <el-col :span="14">
              <el-select v-model="fcltDataDoc.fcltTypeId" @change="setLedFcltType">
                <el-option v-for="(item, seq) in ledFcltTypeData" :key="seq" :value="item.fcltTypeId" :label="item.fcltTypeName"></el-option>
              </el-select>
              <!-- <el-input v-model="fcltDataDoc.fcltName" placeholder="LED 명칭"></el-input> -->
            </el-col>
          </el-form-item>

          <el-form-item label="주소" prop="setNumberAddr">
            <el-col :span="14">
              <el-input v-model="fcltDataDoc.setNumberAddr" placeholder="주소"></el-input>
            </el-col>
          </el-form-item>

          <el-form-item label="위도" prop="gisLat">
            <el-col :span="14">
              <el-input v-model="fcltDataDoc.gisLat" placeholder="위도"></el-input>
            </el-col>
          </el-form-item>

          <el-form-item label="경도" prop="gisLng">
            <el-col :span="14">
              <el-input v-model="fcltDataDoc.gisLng" placeholder="경도"></el-input>
            </el-col>
          </el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item label="IP" prop="ledIP">
                <el-input v-model="fcltDataDoc.ip" placeholder="IP"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Gateway" prop="ledGateway">
                <el-input v-model="fcltDataDoc.gateway" placeholder="Gateway"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="Netmask" prop="netmask">
                <el-input v-model="fcltDataDoc.netmask" placeholder="Netmask"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Port" prop="port">
                <el-input v-model="fcltDataDoc.port" placeholder="Port"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="LED 단" prop="ledHeight">
                <el-input-number v-model="fcltDataDoc.height" :min="1" :max="30"></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="LED 열" prop="ledWidth">
                <el-input-number v-model="fcltDataDoc.width" :min="1" :max="30"></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-form-item label="Mac Address" prop="ledMacAddress">
            <el-input v-model="fcltDataDoc.macaddress" placeholder="Mac Address"></el-input>
          </el-form-item> -->
        </el-form>
        <dk-gis
          ref="fcltManageDialogGis"
          :markerData="markerData"
          :gisApiInfo="gisApiInfo"
          :apiLoadComplete="fcltManageDialogGisInit"
          :markerDragEnd="markerDragEnd"
          :markerInfoWindow="false"
        ></dk-gis>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="save">저장</el-button>
      <el-button @click="hideDialog">취소</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dkgismain from '@/components/dkGis/dkGisMain.vue';
import DKLedUtils from '../ledUtils';
import _ from 'lodash';

export default {
  components: {
    'dk-gis': dkgismain,
  },
  updated() {
    if (this.formDialog && this.$refs.fcltManageDialogGis) {
      let api = this.gisApiInfo.find((apiInfo) => {
        return apiInfo.initGis;
      });
      this.$refs.fcltManageDialogGis.gisModuleLoad(api.gisName);
      this.gisInit = true;
      //if (this.geometry) {
      //this.setCenter(this.geometry);
      //}
    }
  },
  props: {
    gisApiInfo: {
      type: Array,
      // gis 기본 설정값을 띄우는 요부분은 나중에 vuex에서 받아서 처리할것
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      gisInit: false,
      formDialog: false,
      formTitle: 'LED 지점 추가',
      ipSearchedLedList: [],
      ledFcltList: [],
      ledFcltTypeData: [],
      isSearch: false,
      isSearchInit: false,
      geometry: null,
      markerData: [
        {
          seq: 0,
          draggable: true,
          geometry: {
            // lng: 129.30534838238188,
            // lat: 35.572932085143
            lng: 127.10056150323506,
            lat: 37.489008855218195,
          },
          properties: {},
        },
      ],
      fcltDataDoc: {
        fcltId: '',
        fcltName: '',
        fcltTypeId: '',
        setNumberAddr: '',
        gisLat: '',
        gisLng: '',
        ip: '',
        gateway: '',
        port: '',
        width: 6,
        height: 1,
        isRegistered: false,
      },
      rules: {
        fcltName: [
          {
            required: true,
            message: 'LED 명칭을 입력해 주세요',
            trigger: ['change', 'blur'],
          },
          {
            min: 3,
            message: '3자 이상 입력해주세요',
            trigger: ['change', 'blur'],
          },
        ],
        fcltTypeId: [
          {
            required: true,
            message: 'LED 유형을 선택해 주세요',
            trigger: ['change', 'blur'],
          },
        ],
        setNumberAddr: [
          {
            required: true,
            message: 'LED 설치 주소가 입력되야 합니다.',
            trigger: ['change', 'blur'],
          },
        ],
        gisLat: [
          {
            required: true,
            message: 'LED 설치 좌표가 유효하지 않습니다.',
            trigger: ['change', 'blur'],
          },
        ],
        gisLng: [
          {
            required: true,
            message: 'LED 설치 좌표가 유효하지 않습니다.',
            trigger: ['change', 'blur'],
          },
        ],
      },
    };
  },
  methods: {
    async getLedFclt() {
      const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/led/api/ledFclt`);
      this.ledFcltList = response.data.docs;
    },
    async getLedFcltType() {
      const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/led/api/ledFcltType`);
      this.ledFcltTypeData = response.data.docs;
    },
    hideDialog() {
      this.resetData();
      this.formDialog = false;
    },
    async showDialog(isSearch, args) {
      this.formDialog = true;
      this.getLedFcltType();
      (this.isSearch = isSearch || false), (this.geometry = args.geometry || null);
      if (this.isSearch) {
        this.getLedFclt();
        const response = await this.$http.post(`${this.$config.getServerConfig().core.api}/led/api/ledFclt/ipSearch`);
        // const ipSearchList = response.data.result;
        this.ipSearchedLedList = response.data.result;
        // console.log(this.ipSearchedLedList);
        this.isSearchInit = true;
        this.ipSearchedLedList.forEach((item) => {
          const isRegistered = _.find(this.ledFcltList, (ledFclt) => {
            return ledFclt.fcltId === item.boardId;
          });
          if (isRegistered) {
            item.isRegistered = true;
          } else {
            item.isRegistered = false;
          }
        });
      } else {
        this.formInit = true;
      }
      this.fcltManageDialogGisInit();
    },
    fcltManageDialogGisInit() {
      let me = this;
      if (this.$refs.fcltManageDialogGis) {
        if (this.geometry) {
          this.$refs.fcltManageDialogGis.setCenter(this.geometry);
          this.markerData[0].geometry = this.geometry;
        } else {
          let latlng = this.$refs.fcltManageDialogGis.getCenter();
          this.markerData[0].geometry = latlng;
        }

        this.$refs.fcltManageDialogGis.getAddrFromCenterCoord().then((result) => {
          me.fcltDataDoc.setNumberAddr = result.addr && result.addr.num_addr ? result.addr.num_addr : me.fcltDataDoc.setNumberAddr;
          me.fcltDataDoc.setRoadAddr = result.addr && result.addr.road_addr ? result.addr.road_addr : me.fcltDataDoc.setRoadAddr;
          me.fcltDataDoc.gisLat = result.geometry.lat ? result.geometry.lat : me.fcltDataDoc.gisLat;
          me.fcltDataDoc.gisLng = result.geometry.lng ? result.geometry.lng : me.fcltDataDoc.gisLng;
        });
        setTimeout(() => {
          this.$refs.fcltManageDialogGis.refresh();
        }, 100);
      }

      setTimeout(() => {
        this.$refs.fcltManageDialogGis.refresh();
      }, 100);
    },
    setCenter(geometry) {
      this.$refs.fcltManageDialogGis.setCenter(geometry);
      this.markerData[0].geometry = geometry;
    },
    getLedSize(val) {
      return DKLedUtils.getLedMessageSizeKorean(val);
    },
    markerDragEnd(result) {
      let me = this;
      me.fcltDataDoc.setNumberAddr = result.addr.num_addr || '정보없음';
      me.fcltDataDoc.setRoadAddr = result.addr.road_addr || '정보없음';
      me.fcltDataDoc.gisLat = result.geometry.lat;
      me.fcltDataDoc.gisLng = result.geometry.lng;
    },
    async selectIpSearchedListList(row) {
      const item = _.find(this.ledFcltList, (a) => {
        return a.fcltId === row.boardId;
      });
      if (!row.boardId) {
        this.$message({
          type: 'danger',
          message: `제어보드 명칭을 확인할 수 없습니다`,
        });
        return false;
      }
      if (!row.ip) {
        this.$message({
          type: 'danger',
          message: `제어보드 IP를 확인할 수 없습니다`,
        });
        return false;
      }
      const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/led/api/ledFclt/getNetworkConfig`, {
        params: {
          boardId: row.boardId,
        },
      });
      const networkInfo = response.data.result;

      this.fcltDataDoc.fcltId = row.boardId;
      this.fcltDataDoc.ip = networkInfo.ip;
      this.fcltDataDoc.gateway = networkInfo.gateway || '';
      this.fcltDataDoc.netmask = networkInfo.netmask;
      this.fcltDataDoc.mac = networkInfo.mac;
      this.fcltDataDoc.fcltTypeId = item.fcltTypeId;
      // this.fcltDataDoc.macaddress = item.macaddress || '';
      this.fcltDataDoc.fcltName = item.fcltName || '';
      this.fcltDataDoc.setNumberAddr = item.setNumberAddr || '';
      this.fcltDataDoc.gisLat = item.gisLat || '';
      this.fcltDataDoc.gisLng = item.gisLng || '';
      this.fcltDataDoc.isRegistered = row.isRegistered || false;
      if (this.fcltDataDoc.gisLat && this.fcltDataDoc.gisLng) {
        const geometry = {
          lat: parseFloat(item.gisLat),
          lng: parseFloat(item.gisLng),
        };
        this.markerData[0].geometry = geometry;
        this.$refs.fcltManageDialogGis.setCenter(geometry);
      }
    },
    resetData() {
      this.isSearch = false;
      this.isSearchInit = false;
      this.ipSearchedLedList = [];
      this.ledFcltList = [];
      this.ledFcltTypeData = [];
      this.fcltDataDoc = {
        fcltId: '',
        fcltName: '',
        fcltTypeId: '',
        setNumberAddr: '',
        gisLat: '',
        gisLng: '',
        ip: '',
        gateway: '',
        width: 6,
        height: 1,
        isRegistered: false,
      };
    },
    save() {
      this.$refs.form0.validate(async (valid) => {
        if (valid) {
          let paramData = {};
          const fcltTypeData = _.find(this.ledFcltTypeData, (ft) => {
            return ft.fcltTypeId === this.fcltDataDoc.fcltTypeId;
          });
          let saveFunc = `${this.$config.getServerConfig().core.api}/led/api/ledFclt/add`;
          if (this.fcltDataDoc.isRegistered) {
            saveFunc = `${this.$config.getServerConfig().core.api}/led/api/ledFclt/update`;
          }

          paramData.fcltId = this.fcltDataDoc.fcltId;
          paramData.fcltName = this.fcltDataDoc.fcltName;
          paramData.gisLat = this.fcltDataDoc.gisLat;
          paramData.gisLng = this.fcltDataDoc.gisLng;
          paramData.setNumberAddr = this.fcltDataDoc.setNumberAddr;
          paramData.fcltTypeId = this.fcltDataDoc.fcltTypeId;
          paramData.fcltTypeData = _.cloneDeep(fcltTypeData.fcltTypeData);
          paramData.fcltTypeData.ledIp.value = this.fcltDataDoc.ip;
          // paramData.fcltTypeData.ledGateway = {};
          paramData.fcltTypeData.ledGateway.value = this.fcltDataDoc.gateway;
          paramData.fcltTypeData.ledNetmask.value = this.fcltDataDoc.netmask;
          paramData.fcltTypeData.ledMac.value = this.fcltDataDoc.mac;
          paramData.fcltTypeData.ledWidth.value = this.fcltDataDoc.width;
          paramData.fcltTypeData.ledHeight.value = this.fcltDataDoc.height;
          paramData.fcltTypeData.ledPort.value = this.fcltDataDoc.port;
          // paramData.fcltTypeData.ledPort.value = this.fcltDataDoc.ip;
          try {
            await this.$http.post(saveFunc, {
              data: paramData,
            });
            this.$emit('completeLedFcltRegisterd', paramData);
            this.resetData();
            this.formDialog = false;
            this.$message({
              type: 'success',
              message: `LED 장비 등록 완료`,
            });
          } catch (err) {
            this.resetData();
            this.formDialog = false;
            this.$message.error(`LED 장비 등록 중 에러 발생`);
          }
        }
      });
    },
    setLedFcltType(val) {
      if (val === 'FCLTTYPE000021') {
        this.fcltDataDoc.port = 9527;
      } else if (val === 'FCLTTYPE000022') {
        this.fcltDataDoc.port = 5000;
      }
    },
  },
};
</script>
