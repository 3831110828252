<template>
    <el-form :label-width="labelWidth" :label-position="$isMobile?'top':'right'" size="medium" :model="formData">
        <div
            v-for="input in formData"
            :key="input.key"
        >
            <el-form-item :label="input.label" :prop="input.key" :rules="numberRules">
                <el-input 
                    v-if="input.formType==='string'"
                    v-model="input.value" 
                    :placeholder="input.label"
                ></el-input>
                <!-- <el-input-number 
                    v-if="input.formType==='number'"
                    v-model="input.value" 
                    :placeholder="input.label"
                    controls-position="right"
                ></el-input-number> -->
                <el-input
                    v-if="input.formType==='number'"
                    v-model="input.value" 
                    :placeholder="input.label"                    
                ></el-input>
                <el-select
                    v-if="input.formType==='combobox'"
                    v-model="input.value" 
                    :placeholder="input.label"
                >
                    <el-option
                        v-for="option in input.options"
                        :key="option.value"
                        :label="option.label"
                        :value="option.value"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
        </div>
    </el-form>
</template>

<script>

export default {
    created() {        
    },    

    props: {
        "labelWidth" : {
            type: String,
            default: "80px"
        },
        "formData" : {
            type: Object
        }
    },
    data() {
      var charFilter = (str) => {
          str = str.replace(/[^0-9]/g, '');
          return str
      }

      let validate = (rule, input, callback) => {                  
        if (input && !!input.formType && input.formType === 'number') {
            var regex= /[^0-9]/g            
            if (input.value) {
                var _val = input.value.trim();          
                input.value = charFilter(_val)
            }        
        }                
      }
        return {
            dataModel: {},
            numberRules: [
              { validator: validate, trigger:['blur', 'change']}
            ]
        }
    },
/*    computed: {
        rules() {
            let retRules = {};
            for (var key in this.formData) {
                let data = this.formData[key];
                console.log(data)
                if (data.required) {
                    if (data.formType==='string' || data.formType==='number') {
                        retRules[data.key] = [
                            {
                                required:true,
                                message: "필수 입력 항목입니다",
                                trigger: 'blur' 
                            }
                        ]
                    }
                    else if (data.formType==='combobox') {
                        retRules[data.key] = [
                            {
                                required: true,
                                message: "필수 선택 항목입니다",
                                trigger: 'change' 
                            }
                        ]                        
                    }
                }                
            }
            console.log(retRules)
            return retRules;
        }
    }*/
}
</script>
