<template>
  <div id="wrap" :class="[isHamberger, 'dk-menu']">
    <vue-headful :title="$config.getSiteConfig().title"></vue-headful>

    <div v-bind:class="['main-menu']" @mouseleave="sideMouseout">
      <dk-el-menu
        ref="dkElMenu"
        :navData="menuItem"
        :logoData="logoData"
        :selectItem="selectMenuItem"
        :selectLogout="selectLogout"
        :updateUserInfo="updateUserInfo"
        @onHamberger="onHamberger"
      ></dk-el-menu>
    </div>

    <div class="main-container">
      <div @click="isMenuOpen = false" :class="[isMenuOpen ? 'show' : '', 'menu-bubbling-mask']"></div>
      <router-view />
    </div>

    <!-- 요기까지 -->
    <help-modal ref="helpDialog"></help-modal>

    <el-dialog title="사용자 정보 조회" :visible.sync="userInfoDialog">
      <div>
        <el-row>
          <el-col :xs="12" :sm="8" :md="8" style="text-align: center;">
            <span
              class="user-thumb-img"
              v-if="activeUser.images && activeUser.images.length > 0"
              :style="'background-image:url(' + activeUser.images[0].serverFilePath + ')'"
            ></span>
            <span v-else class="user-thumb-img" style="background-image: url(static/images/unknown.png);"></span>
          </el-col>
          <el-col :xs="12" :sm="16" :md="16" style="margin: 0 10px;">
            <h2>{{ activeUser.name }}</h2>
            <p>{{ activeUser.userid }}</p>
          </el-col>
          <el-col :span="18" :offset="3">
            <table class="el-table el-table--mini user-table">
              <tr>
                <th>사용자 ID</th>
                <td>{{ activeUser.userid }}</td>
              </tr>
              <tr>
                <th>그룹</th>
                <td>{{ activeUser.groupName }}</td>
              </tr>
              <tr>
                <th>이메일</th>
                <td>{{ activeUser.email }}</td>
              </tr>
              <tr>
                <th>연락처</th>
                <td>{{ activeUser.phone }}</td>
              </tr>
            </table>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updatePassword" size="small">비밀번호 수정</el-button>
        <el-button @click="updateUserInfo" size="small">사용자 정보 수정</el-button>
        <el-button @click="userInfoDialog = false" size="small">닫기</el-button>
      </span>
    </el-dialog>
    <form-user-info ref="formDialog"></form-user-info>
    <form-user-password ref="formUserPassword"></form-user-password>
    <form-notice-popup
      :notice="notices[nowPopup]"
      :isNoticePopupDialog="isNoticePopupDialog"
      @hideNoticePopupDialog="hideNoticePopupDialog"
    ></form-notice-popup>
  </div>
</template>

<script>
import dkelmenu from '@/components/dkElMenu.vue';
import vueHeadful from 'vue-headful';
import helpDialog from '@/core/widget/helpDialog.vue';
import formUserInfo from '@/core/forms/formUserInfo';
import formUserPassword from '@/core/forms/formUserPassword.vue';
import formNoticePopup from '@/core/forms/formNoticePopup.vue';

export default {
  components: {
    'form-user-info': formUserInfo,
    'form-user-password': formUserPassword,
    'dk-el-menu': dkelmenu,
    'vue-headful': vueHeadful,
    'help-modal': helpDialog,
    'form-notice-popup': formNoticePopup,
  },
  props: {
    source: String,
  },
  computed: {
    menuChange() {
      if (this.defaultCheck) {
        return 'active';
      } else {
        return '';
      }
    },
  },

  data: () => ({
    defaultCheck: false,
    drawer: true,
    menuItem: [],
    mini: true,
    check: false,
    isMenuOpen: false,
    activeUser: null,
    logoData: {
      // name: "dk_ci_dark.png",
      // serverFileName: "",
      // serverFilePath: "static/images/dk_ci_dark.png",
      // size: 0,
      // url: ""
    },

    currentLicenseImage: null,
    userList: [],
    userInfoDialog: false,
    isHamberger: '',
    isNoticePopupDialog: false,
    notices: [],
    nowPopup: 0,
  }),
  created() {
    if (this.$config.getSiteConfig().logo.length > 0) {
      this.logoData = this.$config.getSiteConfig().logo[0];
    }
    this.menuItem = this.$dkRouter.getMenuData();

    this.activeUser = this.$dkRouter.getUserInfo();

    this.getUserList();

    this.getNotices();

    this.$store.dispatch('license/initLicense');
  },
  mounted() {},
  methods: {
    async getNotices() {
      try {
        let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/notice`, {
          search: { isPopup: true, endDate: { $gte: new Date() } },
          sort: { writeDate: -1 },
        });
        this.notices = response.data;
      } catch {
        console.log('공지사항 팝업 post요청 에러');
      }
      this.openNoticePopupDialog();
    },
    hideNoticePopupDialog() {
      this.isNoticePopupDialog = false;
      this.nowPopup++;
      this.openNoticePopupDialog();
    },
    openNoticePopupDialog() {
      while (this.nowPopup < this.notices.length) {
        if (this.$cookies.isKey(this.notices[this.nowPopup]._id)) {
          this.nowPopup++;
        } else {
          this.isNoticePopupDialog = true;
          break;
        }
      }
    },
    menuClick() {
      this.defaultCheck = !this.defaultCheck;
    },
    goHome() {
      // this.$router.push(this.$config.getServerConfig().baseUrl);
      this.$router.push('/');
      this.isMenuOpen = false;
    },
    selectMenuItem(item, event) {
      // 여기서 선택한 메뉴상태를 vuex로 커밋할것
      if (item.url) {
        this.$router.push(item.url);
        this.isMenuOpen = false;
      } else {
        this.isMenuOpen = true;
      }
      event.preventDefault();
      event.stopPropagation();
    },
    selectLogout() {
      this.$confirm(`시스템에서 로그아웃 합니다.`, '로그아웃', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'info',
      }).then(() => {
        // this.$store.dispatch('auth/actRefreshToken', { refresh_payload: '' });
        this.$store.dispatch('auth/actRemoveRefreshToken');
        this.$store.dispatch('auth/actAccessToken', { access_payload: '' });
        window.location.href = this.$config.getServerConfig().baseUrl;
      });
    },
    showMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    showHelpDialog() {
      this.$refs.helpDialog.showHelpDialog(this.$route.path);
    },

    async getUserList() {
      let tmpUserList = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/user`, { param: {} });
      this.userList = tmpUserList.data.docs;
    },
    userPhoneLink(item) {
      return `tel:${item.phone}`;
    },
    centerPhoneLink() {
      return `tel:${this.$config.getSiteConfig().phone}`;
    },
    updatePassword() {
      this.$refs.formUserPassword.showDialog(this.activeUser);
    },
    updateUserInfo() {
      this.$refs.formDialog.showDialog(this.activeUser);
    },
    sideMouseout() {
      this.$refs.dkElMenu.userModalOff();
    },
    onHamberger(value) {
      this.isHamberger = value ? 'on' : '';
    },
  },
};
</script>
<style scoped>
.slide-panel-enter-active {
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.slide-panel-leave-active {
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.slide-panel-enter,
.slide-panel-leave-to {
  width: 0 !important;
}
</style>
