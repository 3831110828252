import axios from '../components/axios';

class DkFileUtil {
  static downloadFromUrl(fileUrl, fileName) {
    if (!fileUrl || !fileName) {
      return false;
    }
    axios({
      url: fileUrl,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  }
  static getHumanFileSize(bytes, si) {
    var thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
    var units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    var u = -1;
    do {
      bytes /= thresh;
      ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
  }

  static isImage(fileName) {
    if (fileName.match(/.(bmp|jpg|jpeg|png|gif)$/i)) {
      return true;
    } else {
      return false;
    }
  }
}

export default DkFileUtil;
