<template>
  <GChart
    ref="gChart"
    type="ColumnChart"
    :settings="{
      packages: ['corechart'],
      language: 'ko',
      callback: () => {
        this.drawChart();
      },
    }"
    :data="chartData"
    :options="options"
    @ready="onChartReady"
  />
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
      default: 300,
    },
    dateExpression: {
      type: String,
      default: 'day',
    },
    cols: {
      type: Array,
      require: true,
    },
    data: {
      type: Array,
      // default: new Array(),
    },
    maxCount: {
      typeof: Number,
    },

    colors: {
      type: Array,
      default() {
        return ['#f56c6c', '#409eff', '#52b1fb', '#c6d9f1'];
      },
    },
  },
  data() {
    return {
      chartsLib: undefined,
      options: {
        // column차트 공통부분
        annotations: {
          alwaysOutside: true,
        },
        fontSize: 12,
        width: '100%',
        backgroundColor: 'transparent',
        color: '#333',
        legend: {
          textStyle: { color: '#333' },
          position: 'none', // 차트마다 렌더링이 제대로 안되어서 none처리하고 html에 직접 박아둠
          alignment: 'end',
        },
        chartArea: {
          backgroundColor: 'transparent',
          width: '92%',
          height: '75%',
        },
        hAxis: {
          textStyle: { fontSize: 10 },
        },
        vAxis: {
          minValue: 1,
          maxValue: 0,
          viewWindow: { min: 0 },
        },
      },
      rows: [],
    };
  },
  created() {
    this.options.height = this.height;
    this.options.colors = this.colors;
    this.setRows(this.data);
  },
  mounted() {},
  methods: {
    onChartReady(chart, google) {
      this.chartsLib = google;
    },
    drawChart() {
      this.$emit('drawChart');
    },
    setRows(data) {
      let rows = [];
      if (data) {
        rows = data.map((elm) => {
          const { xaxisLabel, value } = elm;
          const result = [{ v: xaxisLabel }];
          value.forEach((v) => {
            result.push({ v });
          });
          return {
            c: result,
          };
        });
      }
      this.rows = rows;
    },
  },

  computed: {
    colsLength() {
      return this.cols.length;
    },
    chartData() {
      if (!this.chartsLib) return {};
      const chartObjectPassData = {
        cols: this.cols,
        rows: this.rows,
      };

      const data = new this.chartsLib.visualization.DataTable(chartObjectPassData);

      // annotaion 추가하는 곳. annotaion 필요없으면 on/off 처리 추가작업해야함
      var view = new this.chartsLib.visualization.DataView(data);
      let columns = [];
      for (var i = 0; i <= this.colsLength - 1; i++) {
        if (i > 0) {
          columns.push(i);
          columns.push({
            calc: 'stringify',
            sourceColumn: i,
            type: 'string',
            role: 'annotation',
          });
        } else {
          columns.push(i);
        }
      }
      view.setColumns(columns);
      return view;
    },
  },

  watch: {
    data(n) {
      this.setRows(n);
    },
    // column차트 vAaix가 가장 큰 데이터에 딱 맞게 나와서 annotation이 표출이 가려지기에 maxValue를 최대갯수의 10% 늘려놓음
    maxCount: {
      immediate: true,
      handler(n) {
        this.options.vAxis.maxValue = n + Math.ceil(n * (10 / 100));
      },
    },
  },
};
</script>
