export default {
  state: {
    todo: ''
  },
  getters: {
    getTodo: (state) => {
      return state.todo
    }
  },
  mutations: {
    setTodo(state, payload) {
      state.todo = payload
    }
  },
  actions: {
    actTodo({commit}, payload) {
      commit('setTodo', payload)
    }
  }
}
