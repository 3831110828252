var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chart-container" },
    [
      _c("GChart", {
        attrs: {
          type: "PieChart",
          settings: {
            packages: ["corechart"],
            language: "ko",
            callback: function() {
              this$1.drawChart()
            }
          },
          data: _vm.chartData,
          options: _vm.options
        },
        on: { ready: _vm.onChartReady }
      }),
      _vm.centerText
        ? _c("span", { staticClass: "center-text" }, [
            _vm._v(_vm._s(_vm.centerText))
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }