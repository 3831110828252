<template>
  <el-upload
    :action="uploadPath"
    name="uploadFile"
    :list-type="listType"
    :data="this.uploadParam"
    :before-upload="checkUpload"
    :on-change="procUploadFile"
    :on-remove="procRemoveFile"
    :file-list="fileList"
    :drag="drag"
    :multiple="multiple"
    :limit="limit"
    :on-preview="onPreview"
    :disabled="disabled"
  >
    <slot></slot>
  </el-upload>
</template>

<script>
import DkFileUtil from '@/components/dkFileUtil.js';
export default {
  props: {
    fileList: {
      type: Array,
      default() {
        return [];
      },
    },
    path: {
      type: String,
      require: true,
    },
    uploaderType: {
      type: String,
      default: 'file',
    },
    listType: {
      type: String,
      default: 'text',
    },
    drag: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    checkUploadFile: {
      type: Function,
      default() {},
    },
    limit: {
      type: Number,
      default: 10,
    },
    onPreview: {
      type: Function,
      default() {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uploadPath: `${this.$config.getServerConfig().core.api}/core/api/file/uploadFile`,
      tempUploadFiles: [],
      uploadParam: {},
      checkUpload() {},
    };
  },
  created() {
    this.uploadParam.uploadPath = this.path;
    if (this.uploaderType == 'file') {
      this.checkUpload = function () {};
    } else if (this.uploaderType == 'image') {
      this.checkUpload = this.checkUploadImage;
    }
  },
  mounted() {
    this.tempUploadFiles = this.fileList;
  },
  destroyed() {
    this.resetData();
  },
  methods: {
    resetData() {
      this.tempUploadFiles = [];
      this.uploadParam = {};
    },
    procUploadFile(file, fileList) {
      this.tempUploadFiles = [];
      fileList.forEach((fl) => {
        if (fl.status === 'success') {
          if (fl.response) {
            this.tempUploadFiles.push({
              name: fl.name,
              serverFileName: fl.response.result.serverFileName,
              serverFilePath: fl.response.result.serverFilePath,
              size: fl.size,
              url: `${this.$config.getServerConfig().image.url}${fl.response.result.serverFilePath}`,
            });
          } else {
            this.tempUploadFiles.push(fl);
          }
        }
      });
    },
    procRemoveFile(file, fileList) {
      this.tempUploadFiles = [];
      fileList.forEach((fl) => {
        this.tempUploadFiles.push(fl);
      });
    },
    checkUploadImage(file) {
      if (this.checkUploadFile) {
        this.checkUploadFile(file);
      }
      if (!DkFileUtil.isImage(file.name)) {
        this.$message.error(`이미지 파일만 업로드 할 수 있습니다.`);
        return false;
      }
    },
    getFileData() {
      return this.tempUploadFiles;
    },
    setFileData(fileData) {
      this.tempUploadFiles = [];
      this.tempUploadFiles = fileData;
    },
  },
};
</script>

<style scoped></style>
