import { render, staticRenderFns } from "./formSetting.vue?vue&type=template&id=cad9e390&scoped=true&"
import script from "./formSetting.vue?vue&type=script&lang=js&"
export * from "./formSetting.vue?vue&type=script&lang=js&"
import style0 from "./formSetting.vue?vue&type=style&index=0&id=cad9e390&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cad9e390",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/dk-platform-frontend/dk-platform-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('cad9e390')) {
      api.createRecord('cad9e390', component.options)
    } else {
      api.reload('cad9e390', component.options)
    }
    module.hot.accept("./formSetting.vue?vue&type=template&id=cad9e390&scoped=true&", function () {
      api.rerender('cad9e390', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/mobile/forms/formSetting.vue"
export default component.exports