<template>
  <div class="mobile-download-page">
    <div class="download-btn">
      <button @click="onAppDownload">APK 다운로드</button>
    </div>
    <span class="down-title">사용설명서</span>
    <!-- <div class="download-tabs">
      <template v-for="(m, idx) in manuals">
        <div :class="manualFlag === idx ? 'btn-click' : ''" @click="tabChange(idx)">{{ m.name }}</div>
      </template>
    </div> -->
    <div class="img-place">
      <img src="static/images/manual/mobile/samsung-0.jpg" />
      <p>1. 모바일에서 인터넷 브라우저로 서버에 접속해서 "모바일 다운로드" 버튼을 눌러 다운로드 페이지로 이동합니다.</p>
      <!-- <img v-for="(img, idx) in images" :key="idx" :src="img" /> -->
      <img src="static/images/manual/mobile/samsung-1.jpg" />
      <p>2. 상단의 "APK 다운로드" 버튼을 누릅니다.</p>
      <img src="static/images/manual/mobile/samsung-2.jpg" />
      <p>3. 확인버튼을 선택해 설치파일을 다운로드 합니다.</p>
      <img src="static/images/manual/mobile/samsung-3.jpg" />
      <p>4. 하단의 "열기"를 누르거나 다운받은 설치파일을 실행하여 설치를 시작합니다.</p>
      <img src="static/images/manual/mobile/samsung-4.jpg" />
      <p>
        5. "설치"를 선택하여 설치를 진행합니다.
        <span class="important">※ App 이름은 설치되는 기관에 따라서 차이가 있을 수 있습니다.</span>
      </p>
      <img src="static/images/manual/mobile/samsung-5.jpg" />
      <p>6. 설치 진행중인 화면입니다.</p>
      <img src="static/images/manual/mobile/samsung-6.jpg" />
      <p>7. 설치 진행 중에 다음과 같은 화면이 뜨면 "무시하고 설치"를 선택하여 설치를 계속 진행합니다.</p>
      <p>8. 설치가 완료되었습니다</p>
      <p><b>+ 설치가 완료된 후 설치된 어플을 실행하면 아래와 같이 나오는 팝업에 전부 "허용" 을 선택합니다.</b></p>
      <div class="img-place-small">
        <img src="static/images/manual/mobile/samsung-7.jpg" />
        <img src="static/images/manual/mobile/samsung-8.jpg" />
        <img src="static/images/manual/mobile/samsung-9.jpg" />
        <img src="static/images/manual/mobile/samsung-10.jpg" />
      </div>
    </div>
  </div>
</template>

<script>
import FileDownload from 'js-file-download';
export default {
  components: {},
  data: () => ({
    manualFlag: 0,
    fileUrl: 'https://test.dkant.net/images/app-asan.apk',
    fileName: 'app-test.apk',
    testFileUrl: 'http://localhost:8080/images/app-asan.apk',
    imagebaseUrl: 'static/images/manual/mobile/',
    extension: 'jpg',

    images: [],
  }),
  created() {
    // this.setImage();
    const configure = this.$store.getters['configure/getConfigure'];
    const { install = {} } = this.$config.getSiteConfig();
    if (Array.isArray(install.mobile) && install.mobile.length > 0) {
      if (install.mobile[0].url) {
        this.fileUrl = install.mobile[0].url;
        this.fileName = install.mobile[0].name;
      }
    }
    // if (configure && configure.mobile && configure.mobile.apkDownloadUrl) {
    //   this.fileUrl = `http://${configure.mobile.apkDownloadUrl}`;
    // }
  },
  mounted() {},
  computed: {},
  methods: {
    onAppDownload() {
      let url = this.fileUrl;
      var ret = window.open(url);
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },

    tabChange(idx) {
      this.manualFlag = idx;
      this.setImage();
    },

    onClickDownload() {
      this.downloadWithAxios();
    },

    forceFileDownload(response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', this.fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
    },

    async downloadWithAxios() {
      const me = this;
      try {
        const response = await this.$http.get(me.fileUrl, {
          headers: { responseType: 'arraybuffer' },
        });
        // .then((response) => {
        //   FileDownload(response.data, me.fileName);
        // });
        me.forceFileDownload(response);
      } catch (err) {
        console.error(err);
        me.message('다운로드 실패');
      }
    },

    setImage() {
      const me = this;
      try {
        const images = [];

        const manualInfo = me.manuals[me.manualFlag];

        if (!manualInfo || !manualInfo.key) {
          throw new Error('manual data error');
        }

        const { imageName, imageLength } = manualInfo;

        for (let i = 0; i < imageLength; i++) {
          const imgPath = `${me.imagebaseUrl}${imageName}${i}.${me.extension}`;
          images.push(imgPath);
        }

        me.images = images;
      } catch (err) {}
    },
  },
};
</script>
