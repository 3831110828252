<template>
  <el-dialog
    :title="formTitle"
    :visible.sync="formDialog"
    @close="hideDialog"
    :close-on-click-modal="false"
    id="formLedBrightnessControl"
    width="420px"
  >
    <div class="wrap">
      <table class="el-table el-table--mini">
        <colgroup>
          <col style="width: 130px;" />
          <col />
        </colgroup>
        <tr>
          <th>LED 시설물 명칭</th>
          <td>
            <span v-if="ledFclt">{{ ledFclt.fcltName }}</span>
          </td>
        </tr>
        <tr>
          <th>설치 주소</th>
          <td>
            <span v-if="ledFclt">{{ ledFclt.setNumberAddr }}</span>
          </td>
        </tr>
        <tr>
          <th>LED 유형</th>
          <td>
            <span v-if="ledFclt">{{ getLedTypeText(ledFclt) }} / {{ getLedSize(ledFclt) }}</span>
          </td>
        </tr>
      </table>
      <div class="brightness-config">
        <el-form label-position="top">
          <el-form-item label="밝기 값" class="brightness-form">
            <el-slider :min="0" :max="10" v-model="brightnessValue" show-stops show-input> </el-slider>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="setBrightness">밝기 설정</el-button>
      <el-button @click="hideDialog">취소</el-button>
    </span>
  </el-dialog>
</template>

<script>
import DKLedUtils from '../ledUtils';
export default {
  data() {
    return {
      formDialog: false,
      formTitle: 'LED 밝기 조절',
      ledMessageSize: null,
      brightnessValue: 5,
      ledFclt: null,
    };
  },
  methods: {
    setBrightness() {
      let param = {
        ledFclt: this.ledFclt,
        value: this.brightnessValue,
      };
      this.$confirm(`LED 밝기 설정값을 전송합니다.`, '확인', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'info',
      })
        .then(async () => {
          try {
            await this.$http.post(`${this.$config.getServerConfig().core.api}/led/api/ledFclt/setBrightness`, {
              data: param,
            });
            this.$message({
              type: 'success',
              message: `전송 완료`,
            });
            this.hideDialog();
          } catch (err) {
            this.$message({
              type: 'info',
              message: `전송 실패`,
            });
            this.hideDialog();
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `전송 취소`,
          });
        });
    },
    hideDialog() {
      this.ledFclt = null;
      this.brightnessValue = 1;
      this.formDialog = false;
    },
    showDialog(ledFclt) {
      this.ledFclt = ledFclt;
      this.formDialog = true;
    },
    getLedTypeText(ledFclt) {
      return DKLedUtils.getLedTypeText(ledFclt);
    },
    getLedSize(ledFclt) {
      return DKLedUtils.getLedSizeKorean(ledFclt);
    },
  },
};
</script>
