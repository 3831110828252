var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("div", { class: ["sidebar-box", _vm.isOpen] }, [
        _c("div", { staticClass: "logo-box" }, [
          _c("div", { staticClass: "img-box" }, [
            _c("img", {
              attrs: {
                alt: "logo image",
                src:
                  this.$config.getServerConfig().image.url +
                  _vm.logoData.serverFilePath
              },
              on: { click: _vm.goHome }
            })
          ]),
          _c(
            "div",
            { staticClass: "hamberger-box", on: { click: _vm.menuClick } },
            [
              _c("div", { class: ["hamberger", _vm.menuChange] }, [
                _c("span"),
                _c("span"),
                _c("span")
              ])
            ]
          )
        ]),
        _c("div", { staticClass: "sidebar-content" }, [
          _c("div", { staticClass: "user-info-box" }, [
            this.activeUser.images && this.activeUser.images.length > 0
              ? _c("div", {
                  staticClass: "user-img",
                  style:
                    "background-image:url(" +
                    _vm.$config.getServerConfig().image.url +
                    this.activeUser.images[0].serverFilePath +
                    ")"
                })
              : _c("div", {
                  staticClass: "user-img",
                  style:
                    "background-image: url(" + "static/images/unknown.png);"
                }),
            _c(
              "div",
              { staticClass: "user-info", on: { click: _vm.userClick } },
              [
                _c("div", [
                  _c("span", [_vm._v(_vm._s(this.activeUser.name))]),
                  _c("i", { staticClass: "icon-arrow" })
                ]),
                _c("span", [
                  _vm._v(
                    _vm._s(this.activeUser.userid) +
                      " (" +
                      _vm._s(this.activeUser.groupName) +
                      ")"
                  )
                ])
              ]
            ),
            _c("ul", { class: ["user-modal", _vm.dpOn] }, [
              _c("li", { on: { click: _vm.showLicenseModal } }, [
                _vm._v("라이선스 정보")
              ]),
              _c("li", { on: { click: _vm.updateUserInfo } }, [
                _vm._v("로그인 정보")
              ]),
              _c("li", { on: { click: _vm.selectLogout } }, [
                _vm._v("로그아웃")
              ])
            ])
          ]),
          _c(
            "ul",
            { staticClass: "nav-list-box" },
            _vm._l(_vm.navData, function(depth1) {
              return _c(
                "li",
                {
                  key: depth1.featureId,
                  attrs: { id: depth1.featureId },
                  on: {
                    click: function($event) {
                      return _vm.depth1Click($event, depth1)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      class: [
                        _vm.isDepth1selected(depth1.featureId),
                        "depth1-div "
                      ]
                    },
                    [
                      _c("span", [
                        _c("i", {
                          class: [depth1.icon, "list-icon list-align"]
                        })
                      ]),
                      _c("span", { staticClass: "list-title" }, [
                        _vm._v(_vm._s(depth1.label))
                      ]),
                      _c("i", { staticClass: "icon-arrow list-icon" })
                    ]
                  ),
                  _c(
                    "ul",
                    _vm._l(depth1.children, function(child, key) {
                      return _c(
                        "li",
                        {
                          key: key,
                          on: {
                            click: function($event) {
                              return _vm.selectItem(child, $event)
                            }
                          }
                        },
                        [_vm._v(_vm._s(child.label))]
                      )
                    }),
                    0
                  )
                ]
              )
            }),
            0
          )
        ])
      ]),
      _vm.licenseInfo.licenseFullName
        ? _c(
            "el-dialog",
            {
              staticClass: "license-dialog",
              attrs: {
                visible: _vm.licenseDialog,
                title: "라이선스 정보",
                width: "940px"
              },
              on: {
                "update:visible": function($event) {
                  _vm.licenseDialog = $event
                },
                open: _vm.openedLicenseDialog,
                close: _vm.closeLicenseDialog
              }
            },
            [
              _c("hr"),
              _c(
                "div",
                { staticClass: "license-dialog-wrap" },
                [
                  _c("h3", { staticClass: "license-title" }, [
                    _c(
                      "span",
                      { class: _vm.licenseInfo.licenseCode.toLowerCase() },
                      [
                        _vm._v(
                          _vm._s(_vm.licenseInfo.licenseFullName.toUpperCase())
                        )
                      ]
                    ),
                    _vm._v("\n        을 사용 중입니다.\n      ")
                  ]),
                  _c("p", { staticClass: "license-fclt-count" }, [
                    _vm._v("\n        현재 등록된 시설물 개소 :\n        "),
                    _c(
                      "span",
                      {
                        class:
                          _vm.licenseInfo.currentFcltCount >=
                          _vm.licenseInfo.limitFcltCount
                            ? "excess"
                            : "normal"
                      },
                      [_vm._v(_vm._s(_vm.licenseInfo.currentFcltCount))]
                    ),
                    _vm._v(
                      "\n        / " +
                        _vm._s(_vm.licenseInfo.limitFcltCount) +
                        "\n      "
                    )
                  ]),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-card",
                            {
                              staticStyle: {
                                "border-top": "2px solid #4abb14"
                              },
                              attrs: {
                                "body-style": { padding: "0px" },
                                shadow: "never"
                              }
                            },
                            [
                              _c("div", [
                                _c("img", {
                                  staticClass: "image",
                                  attrs: { src: _vm.licenseImage["ce"] }
                                })
                              ]),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    padding: "10px",
                                    "text-align": "center"
                                  }
                                },
                                [_vm._v("시설물 등록대수 최대 1000개소")]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-card",
                            {
                              staticStyle: {
                                "border-top": "2px solid #27a5dc"
                              },
                              attrs: {
                                "body-style": { padding: "0px" },
                                shadow: "never"
                              }
                            },
                            [
                              _c("div", [
                                _c("img", {
                                  staticClass: "image",
                                  attrs: { src: _vm.licenseImage["se"] }
                                })
                              ]),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    padding: "10px",
                                    "text-align": "center"
                                  }
                                },
                                [_vm._v("시설물 등록대수 최대 1500개소")]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-card",
                            {
                              staticStyle: {
                                "border-top": "2px solid #b94b5b"
                              },
                              attrs: {
                                "body-style": { padding: "0px" },
                                shadow: "never"
                              }
                            },
                            [
                              _c("div", [
                                _c("img", {
                                  staticClass: "image",
                                  attrs: { src: _vm.licenseImage["pe"] }
                                })
                              ]),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    padding: "10px",
                                    "text-align": "center"
                                  }
                                },
                                [_vm._v("시설물 등록대수 최대 3000개소")]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-card",
                            {
                              staticStyle: {
                                "border-top": "2px solid #6d5abe"
                              },
                              attrs: {
                                "body-style": { padding: "0px" },
                                shadow: "never"
                              }
                            },
                            [
                              _c("div", [
                                _c("img", {
                                  staticClass: "image",
                                  attrs: { src: _vm.licenseImage["ee"] }
                                })
                              ]),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    padding: "10px",
                                    "text-align": "center"
                                  }
                                },
                                [_vm._v("시설물 등록대수 최대 5000개소")]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }