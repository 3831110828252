var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "canvasWrap",
      staticClass: "led-canvas-wrap",
      style: _vm.previewSize
    },
    [
      _c(
        "svg",
        {
          ref: "ledMessageSvg",
          class: [
            "editor-canvas",
            _vm.selectable ? "led-canvas-selectable" : ""
          ],
          style: _vm.canvasStyle,
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            "xmlns:xlink": "http://www.w3.org/1999/xlink",
            stroke: "none",
            fill: "none",
            overflow: "hidden",
            preserveAspectRatio: "none",
            width: _vm.canvasWidth,
            height: _vm.canvasHeight,
            viewBox: _vm.canvasViewBox
          }
        },
        [
          _vm._l(_vm.ledMessageData.imageData, function(item) {
            return _c("image-object", {
              key: item.objectId,
              ref: "imageObject",
              refInFor: true,
              attrs: {
                objectId: item.objectId,
                objectData: item,
                ratio: _vm.ratio
              }
            })
          }),
          _vm._l(_vm.ledMessageData.textData, function(item) {
            return _c("text-object", {
              key: item.objectId,
              ref: "textObject",
              refInFor: true,
              attrs: {
                objectId: item.objectId,
                objectData: item,
                ratio: _vm.ratio
              }
            })
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }