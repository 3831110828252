import axios from 'axios';
import io from 'socket.io-client';

export default {
  namespaced: true,
  state: {
    info: {
      currentFcltCount: 0,
      licenseCode: '',
    },
    dialog: false,
    socket: null,
  },
  getters: {
    getLicense: (state) => {
      return state.info;
    },
    getDialog: (state) => {
      return state.dialog;
    },
  },
  mutations: {
    setLicense(state, payload) {
      state.info = payload;
    },
    setDialog(state, payload) {
      state.dialog = payload;
    },
    setCurrentFcltCount(state, payload) {
      state.info.currentFcltCount = payload;
    },
  },
  actions: {
    initLicense({ commit }) {
      this.state.socket = io({
        path: `${this.state.dkConfig.getServerConfig().license.api}/dk-socket/license`,
        transport: ['websocket'],
      });
      this.state.socket.emit('requestLicense', {});
      this.state.socket.on('responseLicense', (response) => {
        commit('setLicense', response);
      });
    },
    // getLicense({ commit }, payload) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get(`${this.state.dkConfig.getServerConfig().core.api}/core/api/license`, { params: payload })
    //       .then((response) => {
    //         resolve(response);
    //       })
    //       .catch((error) => {
    //         reject(error);
    //       });
    //   })
    //     .then((response) => {
    //       commit('setLicense', response.data);
    //       return response.data;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    setDialog({ commit }, payload) {
      commit('setDialog', payload);
    },
    setCurrentFcltCount({ commit }, payload) {
      this.state.socket.emit('requestLicense', {});
      commit('setCurrentFcltCount', payload);
    },
  },
};
