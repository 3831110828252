<template>
  <el-container class="fill-width-height" ref="gisContainer" id="gimpoGis">
    <div class="gis-toolbar">
      <el-menu
        class="gis-toolbar-menu"
        background-color="#edf1f2"
        text-color="#808389"
        active-text-color="#3266b4"
        style="margin-bottom: 6px;"
        v-if="gisMenuItems1.length > 0"
      >
        <el-menu-item v-for="(item, index) in gisMenuItems1" :key="index" :index="item.index" @click="gisMenuSelect(item.clickEvent)">
          <i :class="item.icon"></i>
          {{ item.label }}
        </el-menu-item>
      </el-menu>
      <el-menu class="gis-toolbar-menu" background-color="#edf1f2" text-color="#808389" active-text-color="#3266b4">
        <el-menu-item v-for="(item, index) in gisMenuItems2" :key="index" :index="item.index" @click="gisMenuSelect(item.clickEvent)">
          <i :class="item.icon"></i>
          {{ item.label }}
        </el-menu-item>
        <!-- <el-menu-item
          :key="0"
          :index="gisMenuItems2[0].index"
          @click="gisMenuSelect(gisMenuItems2[0].clickEvent)"
        >
          <i :class="gisMenuItems2[0].icon"></i>{{gisMenuItems2[0].label}}
        </el-menu-item>
        <el-submenu
          style="padding:0px; height:36px; min-width:140px;"
          :key="1"
          :index="gisMenuItems2[1].index"
        >
          <template slot="title">
            <span>
            <i :class="gisMenuItems2[1].icon"></i>{{gisMenuItems2[1].label}}
            </span>
          </template>
          <el-menu-item index="3-1" min-width="140px;" @click="gisMenuSelect(gisMenuItems2[1].clickEvent)">
            <span class="horizon-item">
            On/Off
            </span>
          </el-menu-item>
          <el-menu-item index="3-2" min-width="140px;" @click="setTrafficSelectMode()">
            <span class="horizon-item">
            단일/다중
            </span>
          </el-menu-item> 
        </el-submenu>-->
      </el-menu>
    </div>
    <el-container class="gis-wrap">
      <el-container>
        <el-main>
          <div class="toggle-button hidden-xs-only" @click="toggleInfoPanel">
            <i v-if="!isShowInfoPanel" class="fas fa-angle-left"></i>
            <i v-else class="fas fa-angle-right"></i>
          </div>
          <dk-gis
            fill-height
            :gisApiInfo="gisApiInfo"
            :markerData="markerData"
            :clickMarker="clickFcltMarker"
            :fcltManagerOnGis="fcltManagerOnGis"
            :markerDragEnd="markerDragEnd"
            :apiLoadComplete="apiLoadComplete"
            :trafficLayerData="trafficLayerData"
            :selectTrafficLayer="selectTrafficLayer"
            :eventMarkerData="eventMarkerData"
            :clickEventMarker="clickEventMarker"
            ref="dkGis"
          ></dk-gis>
        </el-main>
      </el-container>
      <dk-info-panel
        ref="infoPanel"
        :afterLeave="changeInfoPanel"
        :afterEnter="changeInfoPanel"
        width="520px"
        :initShow="isShowInfoPanel"
        :class="[isShowFcltInfo ? 'is-show-fclt' : 'is-show-list', 'mobile-info-panel']"
        :showCloseButton="false"
      >
        <!-- 시설물 목록 -->
        <div class="info-panel-wrap" style="padding: 8px 10px;" v-show="!selectFcltMarkerData._id && selectTrafficLayerData.ifsc_id === ''">
          <el-row class="dashboard-fclt-status" :gutter="5">
            <el-col :span="8">
              <div class="chart" style="text-align: center;">
                <fclt-status-chart :height="105" fcltTypeId="FCLTTYPE10001"></fclt-status-chart>VMS
              </div>
            </el-col>
            <el-col :span="8">
              <div class="chart" style="text-align: center;">
                <fclt-status-chart :height="105" fcltTypeId="FCLTTYPE10002"></fclt-status-chart>CCTV
              </div>
            </el-col>
            <!-- <el-col :span="6">
              <div class="chart"><fclt-status-chart :height="105" fcltTypeId="FCLTTYPE10003"></fclt-status-chart>VDS</div>
            </el-col> -->
            <el-col :span="8">
              <div class="chart" style="text-align: center;"><fclt-status-chart :height="105" fcltTypeId="FCLTTYPE10004"></fclt-status-chart>RSE</div>
            </el-col>
          </el-row>
          <el-tabs v-model="activeTrafficTab">
            <el-tab-pane label="교통 시설물" name="trafficTab1">
              <div class="dk-card">
                <el-form label-width="100px" size="small" class="gis-search-form">
                  <el-row>
                    <el-col :span="20">
                      <el-form-item label="검색">
                        <el-input v-model="keyword" @keyup.enter.native="searchFclt" placeholder="시설물 검색어"></el-input>
                      </el-form-item>
                      <el-form-item label="시설물 유형" prop="fcltType">
                        <el-select v-model="searchFcltTypeId" placeholder="시설물 유형" :multiple="true" style="width: 100%;">
                          <el-option v-for="item in fcltType" :key="item.fcltTypeId" :label="item.fcltTypeName" :value="item.fcltTypeId"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="4" style="height: 74px;">
                      <el-button type="primary" size="small" @click="searchFclt" style="height: 68px; margin-left: 5px;">
                        <i class="fas fa-search"></i>&nbsp;검색
                      </el-button>
                    </el-col>
                  </el-row>
                </el-form>

                <el-table :data="markerData" size="mini" @row-click="selectFcltDataGrid" class="no-padding">
                  <el-table-column label="시설물 ID" width="127px">
                    <template slot-scope="scope">
                      <el-tag
                        size="mini"
                        v-if="!!scope.row.fcltStatus && typeof scope.row.fcltStatus == 'object'"
                        :type="scope.row.fcltStatus.statusFlag == true ? 'success' : 'danger'"
                        >{{ scope.row.properties.fcltId }}</el-tag
                      >
                      <el-tag size="mini" v-else :type="'danger'">{{ scope.row.properties.fcltId }}</el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column prop="properties.fcltName" label="시설물 명칭">
                    <template slot-scope="scope">
                      <div class="fclt-name-cell">
                        <p class="fclt-type-name">{{ scope.row.properties.fcltTypeName }}</p>
                        <p>{{ scope.row.properties.fcltName }}</p>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="전원" width="40px" header-align="center" align="center">
                    <template slot-scope="scope">
                      <el-tag size="mini" :type="makeElType(scope.row.fcltStatus, 'powerStatus', 'success', 'warning', 'danger')">
                        <i class="fas fa-power-off" style="font-size: 12px; width: 16px;"></i>
                      </el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column label="팬" width="40px" header-align="center" align="center">
                    <template slot-scope="scope">
                      <el-tag size="mini" :type="makeElType(scope.row.fcltStatus, 'fanStatus', 'success', 'warning', 'danger')">
                        <i class="fas fa-fan" style="font-size: 12px; width: 16px;"></i>
                      </el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column label="히터" width="40px" header-align="center" align="center">
                    <template slot-scope="scope">
                      <el-tag size="mini" :type="makeElType(scope.row.fcltStatus, 'heaterStatus', 'success', 'warning', 'danger')">
                        <i class="fas fa-fire" style="font-size: 12px; width: 16px;"></i>
                      </el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column label="문열림" width="40px" header-align="center" align="center">
                    <template slot-scope="scope">
                      <el-tag size="mini" :type="makeElType(scope.row.fcltStatus, 'doorStatus', 'success', 'warning', 'danger')">
                        <i class="fas fa-door-open" style="font-size: 12px; width: 16px;"></i>
                      </el-tag>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-tab-pane>
            <el-tab-pane label="사고/돌발" name="trafficTab2">
              <el-table :data="eventMarkerData" size="mini" @row-click="selectTrafficDataGrid" class="no-padding">
                <el-table-column label="내용">
                  <template slot-scope="scope">
                    {{ scope.row.properties.incidentInfo }}
                  </template>
                </el-table-column>

                <el-table-column label="돌발유형" width="60px" header-align="center" align="center">
                  <template slot-scope="scope">
                    {{ $refs.formTrafficIncident.getIncidentType(scope.row.properties.incidentType) }}
                  </template>
                </el-table-column>
                <el-table-column label="통제여부" width="60px" header-align="center" align="center">
                  <template slot-scope="scope">
                    {{ $refs.formTrafficIncident.getIncidentRoadCode(scope.row.properties.incidentRoadCode) }}
                  </template>
                </el-table-column>
                <el-table-column label="" header-align="right" align="right">
                  <template slot-scope="scope">
                    <div>
                      {{ `발생일시: ${getDateText(scope.row.properties.occurDateTime)}` }}
                    </div>
                    <div>
                      {{ `완료예정: ${getDateText(scope.row.properties.completeDateTime)}` }}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </div>

        <!-- 시설물 상세 페이지 -->
        <div v-show="selectFcltMarkerData.fcltId" class="d-flex" style="height: 100%;">
          <ul class="fclt-detail-tabs">
            <li @click="clearFclt" >
              <div class="tab-header">
                <i class="fas fa-list-ul"></i>
                <span>목록으로</span>
              </div>
            </li>
            <li v-if="isFcltPlugin" @click="buttonAction('tab0', true)" v-bind:class="[currentTab === 'tab0' ? 'tabClick' : '']">
              <div class="tab-header">
                <i class="fas fa-toolbox"></i>
                <span>{{fcltPluginLabel}}</span>
              </div>
            </li>
            <li @click="buttonAction('tab1', true)" v-bind:class="[currentTab === 'tab1' ? 'tabClick' : '']">
              <div class="tab-header">
                <i class="fas fa-wrench"></i>
                <span>시설물 정보</span>
              </div>
            </li>
            <li @click="buttonAction('tab2', true)" v-bind:class="[currentTab === 'tab2'? 'tabClick' : '']">
              <div class="tab-header">
                <i class="el-icon-s-order"></i>
                <span>관련 자료</span>
              </div>
            </li>
            <li @click="maintnce">
              <div class="tab-header">
                <i class="fas fa-wrench"></i>
                <span>유지 관리</span>
              </div>
            </li>
            <li @click="setRoadview" v-if="currentGisApi.gisName && currentGisApi.gisName == 'kakao'">
              <div class="tab-header">
                <i class="fas fa-map-marker-alt"></i>
                <span>거리뷰</span>
              </div>
            </li>
          </ul>
          <div class="gis-info-panel-wrap d-lfe" style=" height: 100%; width: 100%;">
            <div v-if="currentTab === 'tab0'">               
              <component :is="selectFcltPlugin" :fcltData="selectFcltMarkerData" ref="fcltPlugin"></component>              
            </div>
            <div v-if="currentTab === 'tab1'">
              <el-carousel height="150px" v-if="selectFcltMarkerData.images && selectFcltMarkerData.images.length > 0">
                <el-carousel-item v-for="(file, index) in selectFcltMarkerData.images" :key="index">
                    <div class="dk-carousel-item">
                      <img :src="$config.getServerConfig().image.url + file.serverFilePath" />
                    </div>
                </el-carousel-item>
              </el-carousel>
              <div v-if="this.activeUser.group === 'manager' || this.activeUser.group === 'operator'">
                  <!-- <el-dropdown trigger="click" @command="fcltInfoCommand">
                    <span class="el-dropdown-link">
                      <i class="fas fa-cogs"></i>시설물 정보 변경
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item icon="fas fa-wrench" command="update">시설물 정보 수정</el-dropdown-item>
                      <el-dropdown-item icon="fas fa-trash-alt" command="remove">시설물 정보 삭제</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown> -->
                  <div class="fclt-info-edit" v-if="editAbleFlag">
                    <el-button plain @click="updateFclt"><i class="fas fa-wrench"></i><span>시설물 정보 수정</span></el-button>
                    <el-button type="danger" plain @click="removeFclt"><i class="fas fa-trash-alt"></i><span>시설물 정보 삭제</span></el-button>
                  </div>
                </div>
                <div class="dk-card" style="padding-top: 0;">
                  <table class="el-table el-table--mini">
                    <tr>
                      <th>시설물 명칭</th>
                      <td>{{ selectFcltMarkerData.fcltName }}</td>
                    </tr>
                    <tr>
                      <th>시설물 유형</th>
                      <td>{{ selectFcltMarkerData.fcltTypeName }}</td>
                    </tr>
                    <tr>
                      <th>도로명 주소</th>
                      <td>{{ selectFcltMarkerData.setRoadAddr }}</td>
                    </tr>
                    <tr>
                      <th>지번 주소</th>
                      <td>{{ selectFcltMarkerData.setNumberAddr }}</td>
                    </tr>
                    <tr>
                      <th>경도</th>
                      <td>{{ selectFcltMarkerData.gisLng }}</td>
                    </tr>
                    <tr>
                      <th>위도</th>
                      <td>{{ selectFcltMarkerData.gisLat }}</td>
                    </tr>
                    <tr v-for="(ftData, index) in selectFcltMarkerData.fcltTypeData" :key="index">
                      <th>{{ ftData.label }}</th>
                      <td>{{ getFcltDataValue(ftData) }}</td>
                    </tr>
                  </table>
                </div>
            </div>
            <!-- <div v-if="currentTab === 'tab2'" >
                        <div class="dk-card">
                  <div class="dk-card-title">시설물 장비 정보</div>
                  <el-collapse v-if="selectFcltMarkerData.equip && selectFcltMarkerData.equip.length > 0" accordion>
             
                    <el-collapse-item v-for="(eq, index) in equipList" :key="index" :name="index">
                      <template slot="title">
                        <span style="font-size: 13px; font-weight: bold; margin-left: 5px;">{{ eq.materialName }}</span>
                        <span style="font-size: 12px; margin-left: 7px;">{{ eq.materialTypeName }}</span>
                      </template>
                      <table class="el-table el-table--mini dk-el-table-collapse">
                        <tr v-for="(eqd, index) in eq.equipData" :key="index">
                          <th style="width: 120px;">{{ eqd.label }}</th>
                          <td>{{ eqd.value }}</td>
                        </tr>
                        <tr v-for="(mtd, index) in eq.materialTypeData" :key="index">
                          <th style="width: 120px;">{{ mtd.label }}</th>
                          <td>{{ mtd.value }}</td>
                        </tr>
                      </table>
                    </el-collapse-item>
                  </el-collapse>
                  <h5 v-else style="text-align: center;">등록된 장비 정보가 없습니다.</h5>
                </div>
            </div> -->
            <div v-if="currentTab === 'tab2'">
                <div class="dk-card">
                  <el-table
                    :data="selectFcltMarkerData.files"
                    empty-text="등록된 시설물 관련 자료가 없습니다."
                    size="mini"
                    stripe
                    @row-click="downloadReference"
                  >
                    <el-table-column label="파일 명">
                      <template slot-scope="scope">
                        <span class="file-download-label">{{ scope.row.name }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="파일 크기" width="120px">
                      <template slot-scope="scope">{{ getHumanFileSize(scope.row.size) }}</template>
                    </el-table-column>
                  </el-table>
                </div>
            </div>
          </div>
        </div>
        
        <!-- <div v-show="selectFcltMarkerData._id">
          <div class="mobile-fclt-label hidden-sm-and-up">
            {{ selectFcltMarkerData.fcltName }}
            <span>{{ selectFcltMarkerData.fcltTypeName }}</span>
          </div>
          <el-menu mode="horizontal" class="fclt-detail-view-menu">
            <el-menu-item index="1" @click="clearFclt">
              <span class="horizon-item">
                <div>
                  <i class="fas fa-list-ul"></i>
                </div>
                목록으로
              </span>
            </el-menu-item>
            <el-menu-item index="2" @click="maintnce">
              <span class="horizon-item">
                <div>
                  <i class="fas fa-wrench"></i>
                </div>
                유지 관리
              </span>
            </el-menu-item>

            <el-submenu index="5" style="float: right; line-height: 22px; text-align: center;">
              <template slot="title">
                <span class="horizon-item" style="display: inline-block; height: 100%; line-height: 24px; margin: 0; padding: 4px 0;">
                  <div>
                    <i class="fas fa-cogs"></i>
                  </div>
                  시설물 정보
                </span>
              </template>
              <el-menu-item index="3-1" @click="updateFclt">시설물 정보 수정</el-menu-item>
              <el-menu-item index="3-2" @click="removeFclt">시설물 정보 삭제</el-menu-item>
            </el-submenu>
          </el-menu>

          <div>
            <el-tabs v-model="activeFcltTab" type="border-card">
              <el-tab-pane v-if="isFcltPlugin" :label="fcltPluginLabel" name="tab0">
                <component :is="selectFcltPlugin" :fcltData="selectFcltMarkerData" @updatePlugin="findFcltMarker" ref="fcltPlugin"></component>
              </el-tab-pane>
              <el-tab-pane label="시설물정보" name="tab1">
                <el-carousel height="150px" v-if="selectFcltMarkerData.images && selectFcltMarkerData.images.length > 0">
                  <el-carousel-item v-for="(file, index) in selectFcltMarkerData.images" :key="index">
                    <div class="dk-carousel-item">
                      <img :src="file.serverFilePath" />
                    </div>
                  </el-carousel-item>
                </el-carousel>
                <div class="dk-card">
                  <table class="el-table el-table--mini">
                    <tr>
                      <th>시설물 명칭</th>
                      <td>{{ selectFcltMarkerData.fcltName }}</td>
                    </tr>
                    <tr>
                      <th>시설물 유형</th>
                      <td>{{ selectFcltMarkerData.fcltTypeName }}</td>
                    </tr>
                    <tr>
                      <th>도로명 주소</th>
                      <td>{{ selectFcltMarkerData.setRoadAddr }}</td>
                    </tr>
                    <tr>
                      <th>지번 주소</th>
                      <td>{{ selectFcltMarkerData.setNumberAddr }}</td>
                    </tr>
                    <tr>
                      <th>경도</th>
                      <td>{{ selectFcltMarkerData.gisLng }}</td>
                    </tr>
                    <tr>
                      <th>위도</th>
                      <td>{{ selectFcltMarkerData.gisLat }}</td>
                    </tr>
                    <tr v-for="(ftData, index) in checkFcltData()" :key="index">
                      <th>{{ ftData.label }}</th>
                      <td>{{ getFcltDataValue(ftData) }}</td>
                    </tr>
                  </table>
                </div>
              </el-tab-pane>
              <el-tab-pane label="장비 정보" name="tab2">
                <div class="dk-card">
                  <div class="dk-card-title">시설물 장비 정보</div>
                  <el-collapse v-if="selectFcltMarkerData.equip && selectFcltMarkerData.equip.length > 0" accordion>
                    <el-collapse-item v-for="(eq, index) in selectFcltMarkerData.equip" :key="index" :name="index" v-if="eq && eq.materialName">
                      <template slot="title">
                        <span style="font-size: 13px; font-weight: bold; margin-left: 5px;">{{ eq.materialName }}</span>
                        <span style="font-size: 12px; margin-left: 7px;">{{ eq.materialTypeName }}</span>
                      </template>
                      <table class="el-table el-table--mini dk-el-table-collapse">
                        <tr v-for="(eqd, index) in eq.equipData" :key="index">
                          <th style="width: 120px;">{{ eqd.label }}</th>
                          <td>{{ eqd.value }}</td>
                        </tr>
                        <tr v-for="(mtd, index) in eq.materialTypeData" :key="index">
                          <th style="width: 120px;">{{ mtd.label }}</th>
                          <td>{{ mtd.value }}</td>
                        </tr>
                      </table>
                    </el-collapse-item>
                  </el-collapse>
                  <h5 v-else style="text-align: center;">등록된 장비 정보가 없습니다.</h5>
                </div>
              </el-tab-pane>
              <!-- <el-tab-pane label="유지 관리" name="tab3">
                <maintnce-log-widget
                  :selectFcltData="selectFcltMarkerData"
                  :selectRow="selectMaintnceLog"
                ></maintnce-log-widget>
              </el-tab-pane>-->
              <el-tab-pane label="관련 자료" name="tab4">
                <div class="dk-card">
                  <el-table
                    :data="selectFcltMarkerData.files"
                    empty-text="등록된 시설물 관련 자료가 없습니다."
                    size="mini"
                    stripe
                    @row-click="downloadReference"
                  >
                    <el-table-column label="파일 명">
                      <template slot-scope="scope">
                        <span class="file-download-label">{{ scope.row.name }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="파일 크기" width="120px">
                      <template slot-scope="scope">{{ getHumanFileSize(scope.row.size) }}</template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <div v-show="selectTrafficLayerData.ifsc_id">
          <el-menu mode="horizontal" class="fclt-detail-view-menu">
            <el-menu-item index="1" @click="clearTrafficLayerData">
              <span class="horizon-item">
                <i class="fas fa-list-ul"></i>
                <br />처음으로
              </span>
            </el-menu-item>
          </el-menu>
          <div class>
            <!-- 시간없어서 김포값에 맞춰놨음 ㅅㅂ-->
            <div class="dk-card">
              <div class="dk-card-title">
                {{ selectTrafficLayerData.ifsc_nm }}
                <span style="float: right; font-size: 0.9em;">({{ selectTrafficLayerData.strt_nm }} ~ {{ selectTrafficLayerData.end_nm }})</span>
              </div>
              <el-alert :title="getCmtrText" :type="getCmtrBarType" :center="true" :closable="false"></el-alert>
            </div>
            <div class="dk-card">
              <div v-for="(item, index) in selectTrafficLayerTrafData.linkRltnTraf" :key="index">
                <div class="link-traf">
                  <span class="link-traf-arrow" :style="linkCmtrColor1(item)">
                    <i class="fas fa-arrow-alt-circle-up" v-if="selectTrafficLayerData.drct_cd == '1'"></i>
                    <i class="fas fa-arrow-alt-circle-down" v-else></i>
                    {{ linkCmtrText(item) }}
                  </span>
                  <span class="link-traf-text">
                    {{ item.f_node_name }} ~ {{ item.t_node_name }}
                    <br />
                    평균속도{{ item.sped }} km/h / 통과시간{{ item.trvlhh }}초
                  </span>
                  <el-tag type="info" class="inccident-button" @click="inccidentOnLink(item, selectTrafficLayerData)">돌발/행사</el-tag>
                </div>
              </div>
            </div>
          </div>
        </div>
      </dk-info-panel>
    </el-container>
    <form-fclt-info ref="formFcltDialog" :gisApiInfo="gisApiInfo" :geometry="geometry"></form-fclt-info>
    <form-maintnce ref="formMaintnce"></form-maintnce>
    <form-traffic-incident
      ref="formTrafficIncident"
      :gisApiInfo="gisApiInfo"
      :geometry="geometry"
      @onSaveComplete="getEventMarkerData"
    ></form-traffic-incident>
  </el-container>
</template>

<script>
// DK components
import dkgismain from '@/components/dkGis/dkGisMain.vue';
import dkinfopanel from '@/components/dkInfoPanel.vue';
import formFcltInfo from '@/core/forms/formFcltInfo.vue';
import DkFileUtil from '@/components/dkFileUtil.js';
import formMaintnce from '@/core/forms/formMaintnce.vue';

import formTrafficIncident from '@/traffic/form/formTrafficIncident.vue';
import fcltStatusChart from '@/traffic/widget/fcltStatusChart.vue';
import dialogTrafficIncident from '@/traffic/dialogTrafficIncident.vue';
import moment from 'moment';
export default {
  components: {
    'dk-gis': dkgismain,
    'form-fclt-info': formFcltInfo,
    'form-maintnce': formMaintnce,
    'dk-info-panel': dkinfopanel,

    'form-traffic-incident': formTrafficIncident,
    'fclt-status-chart': fcltStatusChart,
    'dialog-traffic-incident': dialogTrafficIncident,
  },
  computed: {},
  data: () => ({
    pageInit: false,
    infoPanelOpen: true,
    gisApiInfo: [],
    currentGisApi: {},
    markerData: [],
    markerTempData: [],
    selectFcltMarkerData: {
      fcltName: '',
      fcltTypeName: '',
      fcltTypeId: '',
      regionId: '',
      regionName: '',
      setDate: '',
      setNumberAddr: '',
      setRoadAddr: '',
      gisLat: '',
      gisLng: '',
      equip: [],
      fcltTypeData: {},
      images: [],
      files: [],
    },
    isShowFcltInfo: false,
    fcltPluginLabel: '',
    // activeFcltTab: 'tab1',
    activeTrafficTab: 'trafficTab1',
    // fcltManageInfo: {
    //   gisApiInfo: []
    // },
    isOnTrafficLayer: true,
    trafficSelectMode: 'Single',
    isShowInfoPanel: true,
    geometry: {},
    fcltType: [],
    keyword: '',
    searchFcltTypeId: [],
    manageGroup: '',
    gisMenuItems1: [
      // {
      //   index:"0",
      //   label: "카카오",
      //   clickEvent: "changeKakao",
      //   icon: "fas fa-map"
      // },
      // {
      //   index:"1",
      //   label: "OSM",
      //   clickEvent: "changeOpenStreetMap",
      //   icon: "fas fa-map"
      // }
    ],
    gisMenuItems2: [
      {
        index: '2',
        label: '시설물추가',
        clickEvent: 'setFcltManageOnGis',
        icon: 'fas fa-map-marker-alt',
        type: 'global',
      },
      {
        index: '3',
        label: '교통정보',
        clickEvent: 'setTrafficLayer',
        icon: 'fas fa-traffic-light',
        type: 'global',
      },
    ],
    trafficLayerData: null,
    /**
     * 시간이 없어서 대충 해야되서 김포용 데이터가 들어가 있지만,
     * 추후 지속적인 확장성을 위해 일반화한 교통 데이터 모양으로 교체해야함
     */
    selectTrafficLayerData: {
      ifsc_id: '',
      ifsc_nm: '',
      strt_nm: '',
      end_nm: '',
      drct_cd: '1',
      cmtr_grad_cd: '0',
      link_data: [],
    },
    selectTrafficLayerTrafData: {
      ifsc_id: '',
      prcn_dt: '',
      tfvl: '',
      sped: 0,
      ocpyrate: 0,
      trvlhh: 0,
      cmtr_grad_cd: '0',
      linkRltnTraf: [],
    },
    trafficLayerRefreshId: null,
    activeUser: null,
    fcltStatusIntervalId: null,
    gridRender: 0,
    eventMarkerData: [],
    currentTab: 'tab1',
    isTab: true,
    systemLogInfo : {},
  }),
  created() {
    this.activeUser = this.$dkRouter.getUserInfo();
    this.trafficLayerData = {
      base: [
        {
          name: 'ifsc_traf',
          url: `${this.$config.getServerConfig().traffic.gisServer}/geoserver/wms`,
          params: {
            LAYERS: 'cite:gimpo_ifsc_offset',
            TILED: true,
            format: 'image/png',
          },
          //params: {'LAYERS': 'cite:gimpo_link_comp', 'TILED': true, 'STYLES': 'cite:gimpo_utis_deselect_style'}
          default: true,
          idKey: 'ifsc_id',
        },
      ],
      select: {
        source: {
          url: `${this.$config.getServerConfig().traffic.gisServer}/geoserver/wms`,
        },
        mode: 'Single',
      },
    };

    // GIS API 모듈 로딩정보
    let me = this;
    me.gisApiInfo = me.$store.getters['gis/getGisApi'];

    this.$store.dispatch('fcltType/getFcltType', {}).then(() => {
      me.fcltType = me.$store.getters['fcltType/getFcltType'];
      me.fcltType.forEach((val) => {
        if (val.initDisplay) {
          me.searchFcltTypeId.push(val.fcltTypeId);
        }
      });
    });
  },
  mounted() {
    // 도로 선형 갱신 인터벌
    this.trafficLayerRefreshId = setInterval(() => {
      this.$refs.dkGis.trafficLayerRefresh();
    }, 60 * 1000);

    // 시설물 상태 갱신 인터벌
    this.fcltStatusIntervalId = setInterval(() => {
      this.setRetrieveFcltStatus();
    }, 60 * 1000);

    this.$nextTick(() => {
      let api = this.gisApiInfo.find((apiInfo) => {
        return apiInfo.initGis;
      });
      if (api && api.gisName) {
        this.$refs.dkGis.gisModuleLoad(api.gisName);
      }
      this.getEventMarkerData();
    });
  },
  destroyed() {
    clearInterval(this.trafficLayerRefreshId);
    clearInterval(this.fcltStatusIntervalId);
    this.trafficLayerRefreshId = null;
  },
  computed: {
    isFcltPlugin() {
      if (this.selectFcltMarkerData && this.selectFcltMarkerData.fcltTypeId !== '') {
        let plugin = this.$dkPlugin.getPlugin(this.selectFcltMarkerData.fcltTypeId);
        console.log(plugin);

        if (plugin) {
          plugin.then((val) => {
            this.fcltPluginLabel = val.default.label;
          });
          this.currentTab = 'tab0';
          return true;
        } else {
          this.currentTab = 'tab1';
          return false;
        }
      }
    },
    selectFcltPlugin() {
      if (this.selectFcltMarkerData) {
        return () => {
          return this.$dkPlugin.getPlugin(this.selectFcltMarkerData.fcltTypeId);
        };
      }
    },
    getCmtrText() {
      let cmtrText = '정보없음';

      if (this.selectTrafficLayerTrafData.cmtr_grad_cd === '0') {
        cmtrText = '소통원활';
      } else if (this.selectTrafficLayerTrafData.cmtr_grad_cd === '1') {
        cmtrText = '소통지체';
      } else if (this.selectTrafficLayerTrafData.cmtr_grad_cd === '2') {
        cmtrText = '소통정체';
      }

      return `${cmtrText} [평균속도 : ${this.selectTrafficLayerTrafData.sped}km/h / 통과시간 : ${this.selectTrafficLayerTrafData.trvlhh}초]`;
    },
    getCmtrBarType() {
      let cmtrType = 'info';
      if (this.selectTrafficLayerTrafData.cmtr_grad_cd === '0') {
        cmtrType = 'success';
      } else if (this.selectTrafficLayerTrafData.cmtr_grad_cd === '1') {
        cmtrType = 'warning';
      } else if (this.selectTrafficLayerTrafData.cmtr_grad_cd === '2') {
        cmtrType = 'error';
      }
      return cmtrType;
    },
  },
  methods: {
    searchFclt() {
      let me = this;
      let param = {};
      param.keyword = me.keyword;
      param.fcltTypeId = me.searchFcltTypeId;
      me.markerData = [];

      me.getGisFcltMarker(param).then(() => {
        me.setRetrieveFcltStatus();
      });
    },
    async getGisFcltMarker(args) {
      // this.markerData = []
      this.searchPlaceData = [];
      this.activeUser = this.$dkRouter.getUserInfo();
      if (args) {
        args.bounds = this.$refs.dkGis.getBounds();
        args.zoomLevel = this.$refs.dkGis.getLevel();
        args.centerCoords = this.$refs.dkGis.getCenter();
      } else {
        args = {
          bounds: this.$refs.dkGis.getBounds(),
          zoomLevel: this.$refs.dkGis.getLevel(),
          centerCoords: this.$refs.dkGis.getCenter(),
        };
      }
      // 클러스터링 레벨설정 (교통지도는 없음)
      // 클러스터링을 지도 줌레벨을 실행할 최소 - 최대 기준을 설정한다
      // lv0 -> lv1 -> lv2의 최대, 미만 기준으로 실행하도록 한다.
      // 예 ) lv0 이 0이고 lv1이 3일때 줌 레벨이 4로 들어갈경우
      // if (zoom > lv0 && zoom < lv1)
      // 대충 이런식. 자세한건 백엔드 소스 참고
      // 클러스터를 안쓸거면 저렇게 나올수 없는 (대충 30보다 큰 수를 박으면 된다.)
      args.clusterLv0 = 0;
      args.clusterLv1 = 999;
      args.clusterLv2 = 999;

      let result = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/gis/getFcltMarker`, {
        params: args,
      });
      this.markerTempData = result.data.markerData;
    },

    selectFcltDataGrid(row, event, column) {
      // this.selectFclt(row);
      this.clickFcltMarker(row);
    },
    findFcltMarker(fcltData) {
      if (!fcltData && !fcltData.fcltId) {
        return;
      }
      let targetMarker = this.markerData.find((m) => {
        return m.properties.fcltId === fcltData.fcltId;
      });
    },
    clickFcltMarker(args) {
      if (args.$markerClickEvent) {
        args.$markerClickEvent();
        return;
      }
      this.$refs.dkGis.setLevel(3);
      if (this.$isMobile) {
        // document.getElementById('mainView').scrollTop = 0;
      }

      if (this.activeUser.group === 'utis') {
        this.clearTrafficLayerData();
      }

      this.isShowFcltInfo = true;
      if (!this.$refs.infoPanel.isOpened()) {
        this.$refs.infoPanel.open();
      }
      this.selectFcltMarkerData = args.properties;
      this.$refs.dkGis.setCenter({
        lat: this.selectFcltMarkerData.gisLat,
        lng: this.selectFcltMarkerData.gisLng,
      });
    },

    clearFclt() {
      if (this.$isMobile) {
        // document.getElementById('mainView').scrollTop = 0;
      }

      this.selectFcltMarkerData = {
        fcltName: '',
        fcltTypeName: '',
        fcltTypeId: '',
        regionId: '',
        regionName: '',
        setDate: '',
        setNumberAddr: '',
        setRoadAddr: '',
        gisLat: '',
        gisLng: '',
        equip: [],
        fcltTypeData: {},
        images: [],
        files: [],
      };
      this.isShowFcltInfo = false;
      if (this.$refs.dkGis) {
        this.hideRoadview();
      }
    },
    selectMaintnceLog(maintnceLog) {
      this.$refs.formMaintnce.showDialog(this.selectFcltMarkerData, maintnceLog);
    },
    maintnce() {
      this.$refs.formMaintnce.showDialog(this.selectFcltMarkerData);
    },
    markerDragEnd(args) {},
    updateFclt() {
      this.geometry = {
        lat: this.selectFcltMarkerData.gisLat,
        lng: this.selectFcltMarkerData.gisLng,
      };
      let updateFlag = true;
      this.$refs.formFcltDialog.showDialog(updateFlag);
      setTimeout(() => {
        this.$refs.formFcltDialog.setFcltDataDoc(this.selectFcltMarkerData);
      }, 100);
    },
    removeFclt() {
      this.$confirm(`${this.selectFcltMarkerData.fcltName} 시설물을 삭제 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(() => {
          this.$store
            .dispatch('fclt/deleteFclt', { data: this.selectFcltMarkerData })
            .then((response) => {
              this.clearFclt();
              this.searchFclt();
              this.$message({
                type: 'success',
                message: `${this.selectFcltMarkerData.fcltName} 시설물 삭제 완료`,
              });
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: `${this.selectFcltMarkerData.fcltName} 시설물 삭제 중 에러 발생`,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `${this.selectFcltMarkerData.fcltName} 시설물 삭제 취소`,
          });
        });
    },
    fcltManagerOnGis(args) {
      let updateFlag = false;
      let geometry = {};
      if (!args.geometry) {
        return;
      }
      this.geometry = this.$lodash.cloneDeep(args.geometry);
      this.$refs.formFcltDialog.showDialog(updateFlag);
    },
    checkDisplayFcltType(fcltTypeId) {
      let result = this.searchFcltTypeId.find((ft) => {
        return ft === fcltTypeId;
      });
      return result;
    },
    gisMenuSelect(eventName) {
      this[eventName]();
    },
    changeGoogle() {
      this.$refs.dkGis.gisModuleLoad('google');
    },

    changeKakao() {
      this.$refs.dkGis.gisModuleLoad('kakao');
    },

    changeOpenStreetMap() {
      this.$refs.dkGis.gisModuleLoad('openStreetMap');
    },

    setFcltManageOnGis() {
      this.$refs.dkGis.setFcltManageOnGis();
    },

    setTrafficLayer() {
      this.isOnTrafficLayer = this.$refs.dkGis.setTrafficLayer(!this.isOnTrafficLayer);
    },
    setTrafficSelectMode() {
      this.trafficSelectMode = this.$refs.dkGis.setTrafficSelectMode(this.trafficSelectMode);
    },
    // setIncidentOnGis() {
    //   this.$refs.dkGis.setIncidentOnGis();
    // },

    toggleInfoPanel() {
      this.$refs.infoPanel.toggle();
      this.isShowInfoPanel = this.$refs.infoPanel.isOpened();
    },

    changeInfoPanel(el) {
      this.$refs.dkGis.refresh();
    },

    getHumanFileSize(bytes) {
      return DkFileUtil.getHumanFileSize(bytes, true);
    },
    downloadReference(row, event, column) {
      DkFileUtil.downloadFromUrl(row.url, row.name);
    },
    getFcltDataValue(fcltData) {
      let val = fcltData.value;
      if (fcltData.formType == 'combobox') {
        if (fcltData.options) {
          let tempOption = fcltData.options.find((opt) => {
            return opt.value == fcltData.value;
          });
          if (tempOption) {
            val = tempOption.label;
          }
        }
      }
      return val;
    },
    setRoadview() {
      this.$refs.dkGis.setRoadview({
        lat: this.selectFcltMarkerData.gisLat,
        lng: this.selectFcltMarkerData.gisLng,
      });
    },
    hideRoadview() {
      this.$refs.dkGis.hideRoadview();
    },
    kakaoNavi() {
      if (Kakao) {
        Kakao.Navi.start({
          name: this.selectFcltMarkerData.fcltName,
          x: this.selectFcltMarkerData.gisLng,
          y: this.selectFcltMarkerData.gisLat,
          coordType: 'wgs84',
        });
      }
    },
    apiLoadComplete(currentGisApi) {
      this.currentGisApi = currentGisApi;
      // console.log(this.activeUser)
      this.searchFclt();
      if (this.activeUser.group === 'utis') {
        this.$refs.dkGis.setTrafficLayer(true);
      }
    },
    selectTrafficLayer(feature) {
      // if (this.activeUser.group === 'utis') {
      this.clearFclt();
      this.selectTrafficLayerData = feature;
      this.$http
        .get(`${this.$config.getServerConfig().core.api}/business/api/gimpoIfscTraf`, {
          params: this.selectTrafficLayerData,
        })
        .then((response) => {
          this.selectTrafficLayerTrafData = response.data;
        });
      // }
    },
    async setRetrieveFcltStatus(args) {
      // let markerData = args || this.markerData;
      let markerData = args || this.markerTempData;
      await this.$store.dispatch('fcltStatus/getFcltStatus', {});
      let fcltStatusList = this.$store.getters['fcltStatus/getFcltStatus'];
      let fcltStatusObj = this.$lodash.keyBy(fcltStatusList, (o) => {
        return o.fcltId;
      });

      markerData.forEach((marker) => {
        const fcltStatus = fcltStatusObj[marker.properties.fcltId];
        // marker.fcltStatus = fcltStatus;
        if (fcltStatus === undefined) {
          marker.status = 3;
        } else if (fcltStatus.statusFlag === true) {
          marker.status = 1;
          marker.fcltStatus = fcltStatus;
        } else {
          marker.status = 2;
          marker.fcltStatus = fcltStatus;
        }
      });
      this.markerData = markerData;
      this.gridRender = Math.random();
      // this.$forceUpdate();
    },
    clearTrafficLayerData() {
      this.selectTrafficLayerData = {
        ifsc_id: '',
        ifsc_nm: '',
        strt_nm: '',
        end_nm: '',
        drct_cd: '1',
        cmtr_grad_cd: '0',
        link_data: [],
      };
      this.selectTrafficLayerTrafData = {
        ifsc_id: '',
        prcn_dt: '',
        tfvl: '',
        sped: 0,
        ocpyrate: 0,
        trvlhh: 0,
        cmtr_grad_cd: '0',
        linkRltnTraf: [],
      };
    },
    linkCmtrColor1(linkTraf) {
      let style = {
        color: '#fff',
      };
      if (linkTraf.cmtr_grad_cd === '0') {
        style.backgroundColor = '#67c23a';
      } else if (linkTraf.cmtr_grad_cd === '1') {
        style.backgroundColor = '#e6a23c';
      } else if (linkTraf.cmtr_grad_cd === '2') {
        style.backgroundColor = '#f56c6c';
      } else {
        style.backgroundColor = '#909399';
      }
      return style;
    },
    linkCmtrText(linkTraf) {
      let text = '정보없음';
      if (linkTraf.cmtr_grad_cd === '0') {
        text = '소통원활';
      } else if (linkTraf.cmtr_grad_cd === '1') {
        text = '소통지체';
      } else if (linkTraf.cmtr_grad_cd === '2') {
        text = '소통정체';
      }

      return text;
    },
    inccidentOnLink(link) {
      this.$refs.formTrafficIncident.showDialog({ linkData: link });
      // console.log(ifsc)
      // console.log(link)
    },
    makeElType(fcltStatus, statusKey, ...typeList) {
      const typeSucc = typeList[0];
      const typeWarn = typeList[1];
      const typeFail = typeList[2] || typeList[1];

      if (typeof fcltStatus !== 'object') {
        return typeFail;
      } else if (fcltStatus.statusFlag !== true || typeof fcltStatus.status !== 'object') {
        return typeFail;
      } else if (fcltStatus.status[statusKey] !== true) {
        return typeWarn;
      } else {
        return typeSucc;
      }
    },
    checkFcltData() {
      if (this.selectFcltMarkerData.fcltTypeData) return this.selectFcltMarkerData.fcltTypeData;
      else [];
    },
    clickEventMarker(e) {
      this.$refs.formTrafficIncident.showDialog(e.properties);
    },
    selectTrafficDataGrid(row) {},
    async getEventMarkerData() {
      const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/trafficIncident`, {
        params: {
          incidentStatus: '1',
        },
      });

      this.eventMarkerData = [];

      response.data.docs.forEach((d) => {
        this.eventMarkerData.push({
          seq: d._id,
          draggable: false,
          geometry: {
            lat: d.gisLat,
            lng: d.gisLng,
          },
          properties: d,
        });
      });

      // this.eventMarkerData = tmpData;
      // // this.eventMarkerData = [
      //   {
      //     seq: '1351dfasd0',
      //     draggable: false,
      //     geometry: {
      //       lat: 37.62949925410082,
      //       lng: 126.7195958865944,
      //     },
      //     properties: {},
      //   },
      //   {
      //     seq: '1231232413',
      //     draggable: false,
      //     geometry: {
      //       lat: 37.63149925410082,
      //       lng: 126.7175958865944,
      //     },
      //     properties: {},
      //   },
      // ];
      // console.log(tmpData);
      this.$forceUpdate();
    },
    getDateText(date) {
      return moment(date).format('YYYY-MM-DD HH:mm');
    },
    buttonAction(tabId, isTab) {
      this.currentTab = tabId;
      this.isTab = isTab;
    },
  },
};
</script>

<style scoped></style>
