// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import Vuex from 'vuex';
import App from './App';
//import router from './router'
import axios from './components/axios';
import { store } from './store';
import 'babel-polyfill';
import lodash from 'lodash';

// ELEMENT UI
import ElementUI from 'element-ui';
import koLocale from 'element-ui/lib/locale/lang/ko';
import locale from 'element-ui/lib/locale';

// DK config
import DkConfig from './_config/DkConfig.js';

// DK 코드데이터
// import dkDataFormatter from '@/components/dk-data-formatter'
import DkEnfCodeFormatter from '@/components/dkEnfCodeFormatter';

// DK 라우팅
let router = null;
let dkRouter = null;
import DkRouter from './router.js';

// DK 플러그인
import DkPlugin from './plugin';

// 페이지 헤더
import DkPageHeader from '@/components/dkPageHeader.vue';

// 다국어 지원 (언젠가는 하겠지)
// import VueI18n from 'vue-i18n'

import { EventBus } from '@/components/module/EventBus';

// font awesome
import '../node_modules/@fortawesome/fontawesome-free/css/all.min.css';

//import 'element-ui/lib/theme-chalk/index.css';
//import 'element-ui/lib/theme-chalk/display.css';
if (process.env.VUE_APP_THEME === 'dark' || !process.env.VUE_APP_THEME) {
  require('element-theme-dark');
} else {
  require('element-ui/lib/theme-chalk/index.css');
  require('element-ui/lib/theme-chalk/display.css');
}
// DK 스타일 시트
import './assets/style/main.scss';

// 다크모드 모듈
// import 'element-theme-dark';

//쿠키(공지사항pop-up용)
import VueCookies from 'vue-cookies';

Vue.use(VueCookies);
Vue.$cookies.config('1d'); //쿠키 기본 만료일 1day
//vue 구글차트
import VueGoogleCharts from 'vue-google-charts';

locale.use(koLocale);

Vue.use(Vuex);
// Vue.use(VueI18n);
Vue.use(ElementUI);
Vue.use(VueGoogleCharts);
Vue.config.productionTip = false;
Vue.prototype.$http = axios;
Vue.prototype.$lodash = lodash;
Vue.prototype.$config = new DkConfig();

Vue.component('dk-page-header', DkPageHeader);

/*
console.log(Vue.prototype.$config.getServerConfig())

const _system_conf = require('./_config/_system_conf.json')
const _default_site_conf = require('./_config/_default_site_conf.json')
let _config.getServerConfig() = null
if (process.env.NODE_ENV === "development") {
  _config.getServerConfig() = require('./_config/_dev_config.getServerConfig().json')
}
else {
  _config.getServerConfig() = require('./_config/_build_config.getServerConfig().json')
}
Vue.prototype.$system_conf = _system_conf
Vue.prototype.$config.getServerConfig() = _config.getServerConfig()
*/

// const _axios_conf = require('../_config/_axios_conf.json')
// const _current_mode = require('../_config/_current_mode.json')
// Vue.prototype.$api = _axios_conf
// Vue.prototype.$current_mode = _current_mode

/*
var initPlugin = function(config) {
  return new Promise((resolve, reject) => {    
    Vue.prototype.$dkPlugin = new DkPlugin(config);
    Vue.prototype.$dkPlugin.initPlugin()
      .then(() => {
        return resolve(config);
      })
  }).catch((err) => {
      console.error("Failed to DK plugin initialize");
      return Promise.reject()
    })
}
*/

Vue.prototype.$isMobile = Vue.prototype.$config.getMobileCheck();
Vue.prototype.$isApp = Vue.prototype.$config.getAppCheck();

dkRouter = new DkRouter(Vue.prototype.$config.getServerConfig(), store, Vue.prototype.$isMobile);
router = dkRouter.getRouter();
new Promise((resolve, reject) => {
  return resolve();
})
  .then(() => {
    return new Promise(async (resolve, reject) => {
      try {
        /**
         * Configure 정보 읽어오기
         */
        await store.dispatch('configure/getConfigure', {});

        if (store.getters['configure/getConfigure']) {
          // console.log(store.getters['configure/getConfigure']);
          Vue.prototype.$config.setSiteConfig(store.getters['configure/getConfigure']);
        }
        return resolve();
      } catch (err) {
        return reject();
      }
    });
  })
  .then(() => {
    /**
     * 라우트 초기화
     */
    dkRouter.initRouter().then((rt) => {
      router = rt;
      return;
    });
  })
  .then(async () => {
    /**
     * 토큰
     */
    Vue.prototype.$dkPlugin = new DkPlugin(Vue.prototype.$config.getServerConfig());
    console.log(Vue.prototype.$dkPlugin);
    Vue.prototype.$dkRouter = dkRouter;
    //   document.cookie = "pc_macaddress=11:22:23:44:55:66;path=/";

    const refreshToken = store.getters['auth/getRefreshToken'];
    const autoLoginFlag = store.getters['auth/getAutoLoginFlag'];

    // if (refreshToken) {
    //   await store.dispatch('gis/actGisApi', {});
    // }
    if (!refreshToken) {
      // debugger;
      Vue.prototype.$initRoutePath = '/login';
      return;
    } else if (refreshToken && !autoLoginFlag) {
      store.dispatch('auth/actRemoveRefreshToken');
      const check = await Vue.prototype.$dkRouter.checkAccess();
      if (!check) {
        Vue.prototype.$initRoutePath = '/login';
        return;
      }
      store.dispatch('auth/actRefreshToken', {
        refresh_payload: refreshToken,
        auto_flag: autoLoginFlag,
      });
    }
    // else {
    try {
      // await new Promise(resolve=>{
      //   setTimeout(()=>{resolve()},10000);
      // })
      // await Vue.prototype.$dkRouter.getActiveUser(localStorage.getItem('refreshToken'));
      await Vue.prototype.$dkRouter.getActiveUser(refreshToken);
      Vue.prototype.$dkPlugin.initPlugin();
    } catch (err) {
      console.error('토큰 만료');
      // localStorage.removeItem('refreshToken');
      store.dispatch('auth/actRemoveRefreshToken');
      Vue.prototype.$initRoutePath = '/login';
      return;
    }
    // }
  })
  .catch((err) => {
    Vue.prototype.$initRoutePath = '/';
    console.error(err);
  })
  .finally(() => {
    new Vue({
      el: '#app',
      router,
      store,
      components: { App },
      template: '<App/>',
    });
  });
