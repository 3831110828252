import _ from 'lodash';
export default class DkTemplateUtil {
  static setFetcedTemplateValue(args) {
    let templateData, valueData;
    templateData = args.template;
    valueData = args.value;
    const result = _.map(templateData, (val) => {
      val.value = valueData[val.dataKey] && valueData[val.dataKey].value !== '' ? valueData[val.dataKey].value : '';
      if (val.type === 'select' || val.type === 'multiselect') {
        val.value = valueData[val.dataKey].selectValue;
      } else if (val.type === 'date') {
        if (val.value !== '') val.value = new Date(parseFloat(val.value));
      }
      return val;
    });
    return result;
  }
  static setTemplateValue(args) {
    let templateData, valueData;
    templateData = args.template;
    valueData = args.value;
    const result = _.map(templateData, (val) => {
      const templateValue = _.find(valueData, (vd) => {
        return val.dataKey === vd.dataKey;
      });
      if (templateValue && typeof templateValue === 'object') {
        val.value = templateValue.value !== '' ? templateValue.value : '';
        if (val.type === 'select' || val.type === 'multiselect') {
          val.value = templateValue.selectValue ? templateValue.selectValue : null;
        } else if (val.type === 'date') {
          if (val.value !== '') val.value = new Date(parseFloat(val.value));
        }
      } else {
        val.value = '';
      }

      return val;
    });
    return result;
  }
  static getTemplateValue(tempdateData, dataKey) {
    if (!tempdateData) {
      return null;
    }
    if (tempdateData[dataKey]) {
      return tempdateData[dataKey].value;
    } else {
      return null;
    }
  }
  static encodeKeyword(templateData) {}

  static decodeKeyword(templateData) {}
}
