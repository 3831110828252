<template>
  <div id="formNoticePopup">
    <el-dialog
      :visible.sync="isNoticePopupDialog"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      top="5vh"
      class="el-dialog-notice"
      :show-close="false"
      :before-close="hideDialog"
      :lock-scroll="true"
    >
      <div style="max-height: 80vh; min-height: 70vh;">
        <div>
          <div>
            <h3>{{ notice.title }}</h3>
            <div class="notice-content" v-html="getNoticeText()"></div>
          </div>
          <div>
            <div :class="[!notice.images || notice.images.length === 0 ? 'notice-file d-none' : 'notice-file']">
              <div style="display: table;">
                <h4>
                  첨부파일
                </h4>
              </div>
              <ul>
                <li class="a-tag-files" v-for="(file, index) in notice.images" :key="index">
                  <a :href="$config.getServerConfig().image.url + file.url" :download="file.name"><i class="el-icon-document"></i> {{ file.name }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="button" @click="setCookie">오늘그만보기</el-button>
        <el-button class="button" @click="hideDialog">확인</el-button>
      </span>
      <!-- <el-container :class="dialogClass">
        <el-row>
          <el-col :span="24" class="icon"> </el-col>
          <el-col :span="24">
            <div class="title"><i class="el-icon-info"></i>&nbsp;{{ notice.title }}</div>
            <hr class="line" size="1" />
            <div class="content"><span v-html="getNoticeText()"></span><br /></div>
          </el-col>
          <el-col :span="24">
            <el-carousel v-show="notice.images && notice.images.length > 0" class="image">
              <el-carousel-item v-for="(file, index) in notice.images" :key="index">
                <div class="dk-carousel-item">
                  <img :src="file.serverFilePath" />
                </div>
              </el-carousel-item>
            </el-carousel>
          </el-col>
        </el-row>
      </el-container> -->
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogWidth: '50%',
      dialogClass: 'big',
    };
  },
  props: {
    isNoticePopupDialog: {
      type: Boolean,
      default: false,
    },
    notice: {
      type: Object,
      default: () => ({
        title: '',
        text: '',
      }),
    },
  },
  watch: {
    // notice() {
    //   // 이미지 있을 시 & 없을 시
    //   if (this.notice.images) {
    //     (this.dialogWidth = '40%'), (this.dialogClass = 'big');
    //   } else {
    //     (this.dialogWidth = '30%'), (this.dialogClass = 'small');
    //   }
    // },
  },
  methods: {
    setCookie() {
      this.$cookies.set(this.notice._id, false, '1d'); // 실제로는 1d
      this.hideDialog();
    },
    hideDialog() {
      this.$emit('hideNoticePopupDialog');
    },
    getNoticeText() {
      console.log(this.notice);
      let result = this.notice.text;
      result = result.replace(/</g, '&lt;');
      result = result.replace(/>/g, '&gt;');
      result = result.replace(/\n/g, '<br>');
      return result;
    },
  },
};
</script>
