import moment from 'moment'
import axios from 'axios'

class DkEnfCodeFormatter {
    constructor(config) {
       /**
       *
       */
        this._config = config;
        this._codeDataInfo = {};
        this._cameraModelList = [];
    }

    // initCameraModel() {
    //     var _this = this
    //     return new Promise((resolve, reject) => {
    //         axios.post(`${_this._config.enf.api.detect}/ipes/apiServer/camera/db/getCameraModelInfo`,{
    //             "user" : {
    //                 "id" : "",
    //                 "token" : ""
    //             }
    //         })
    //         .then((response) => {
    //             if (response.data.result.code == 1) {
    //                 _this.cameraModelList = response.data.cameraModelInfo
    //             }
    //             else {
    //                 return reject(response.data.result.message)
    //             }
    //             return resolve()
    //         })
    //         .catch((err)=> {
    //             return reject(err)
    //         })
    //     })
    // }

    initCodeData() {
        var _this = this
        console.log(`${_this._config.enf.api.detect}/ipes/apiServer/code/getCodeData`)
        return new Promise((resolve, reject) => {
            axios.post(`${_this._config.enf.api.detect}/ipes/apiServer/code/getCodeData`,{})
            .then((response) => {
                console.log(response)
                if (response.data.result.code == 1) {
                    response.data.codeList.forEach((val, i)=>{
                        _this._codeDataInfo[val.engCodeName] = val
                    })
                    return resolve()
                }
                else {
                    return reject(response.data.result.message)
                }
            })
            .catch((err)=> {
                console.log(err)
                return reject(err)
            })
        })
    }

    getCodeValue(codeName, value) {
        let _this = this;
        if(!_this._codeDataInfo) {
            return false;
        }

        let data = _this._codeDataInfo[codeName];

        if(value) {
            for (var j=0; j<data.codeData.length; j++) {
                if (data.codeData[j].data == value) {
                    return data.codeData[j].dataName
                }
            }
        } else {
            return data.codeData;
        }
        return null;
    }

    getDateFormat(dateString, targetFormat) {
        return moment(dateString, "YYYYMMDDHHmmss").format(targetFormat)
    }

    // getCameraModel(value) {
    //     let ret = null
    //     if (!value) {
    //         return this.cameraModelList
    //     }
    //     for (var i=0; i<this.cameraModelList.length; i++) {
    //         let data = this.cameraModelList[i]
    //         if (data.cameraModelCd === value) {
    //             ret = data
    //             break
    //         }
    //     }
    //     return ret
    // }

    // getCameraModelInfo() {
    //     return this.cameraModelList
    // }
}
export default DkEnfCodeFormatter;
