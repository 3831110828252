<template>
  <el-dialog
    :title="formTitle"
    :visible.sync="formDialog"
    @close="hideDialog"
    :close-on-click-modal="false"
    top="5vh"
    class="el-dialog-aside"
    append-to-body
    fullscreen
    id="formMobileSetting"
  >
    <div class="form-gis-wrap" v-if="!$isMobile">
      <dk-gis ref="dkGis" style="height: 280px;" :gisApiInfo="gisApiInfo" :markerData="markerData" :level="level"></dk-gis>
    </div>
    <el-container class="dialog-container maintnce-container">
      <el-container>
        <el-form class="form-maintnce" ref="form1" size="medium" :model="formData" label-position="left">
          <el-row>
            <el-col :span="24">
              <el-form-item label="전체 알림 설정" prop="workType">
                <el-switch v-model="alamSet" @change="allToggle()" class="float-right" style="padding: 10px 0;"></el-switch>
              </el-form-item>
              <el-form-item prop="workType" :span="12">
                <ul style="margin: 0; padding: 0 3px;">
                  <li v-for="(item, idx) in pushList" :key="idx" class="flex-box">
                    <span class="fclt-type-name">{{ item.korValue }}</span>
                    <span class="auth-check">
                      <!-- <el-checkbox v-model="item.checked"></el-checkbox> -->
                      <el-switch v-model="item.checked" @change="toggleSelect()"></el-switch>
                    </span>
                  </li>
                </ul>
              </el-form-item>
              <el-row>
                <el-col :span="16" class="cache-clear-text">
                  ※ 최신의 내용으로 갱신하기 위해 앱의 캐시를 정리합니다.<br />자동 로그인 상태에서 로그아웃 될 수 있습니다.
                </el-col>
                <el-col :span="8">
                  <el-button @click="clearWebViewCache()">캐시 초기화</el-button>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-form>
      </el-container>
    </el-container>
    <span slot="footer" class="dialog-footer">
      <!-- <el-button v-if="updateFlag" icon="fas fa-trash-alt" @click="remove" style="float: left"></el-button> -->
      <el-button type="primary" @click="save">저장</el-button>
      <el-button @click="hideDialog">취소</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      formDialog: false,
      formTitle: '환경설정',
      formData: {
        managerId: '',
      },
      activeUser: {},
      pushList: [],
      alamSet: false,
    };
  },
  created() {
    this.activeUser = this.$dkRouter.getUserInfo();
    // this.getUserData();
  },
  mounted() {
    // this.activeUser = this.$dkRouter.getUserInfo();
    // this.getUserData();
    // this.getPushList();
  },
  computed: {},

  methods: {
    async save() {
      try {
        let pushSetting = {};
        pushSetting.pushSelect = {};
        for (let i = 0; i < this.pushList.length; i++) {
          if (this.pushList[i].checked) {
            this.pushList[i].checked = 1;
          } else {
            this.pushList[i].checked = 0;
          }
          pushSetting.pushSelect[this.pushList[i].value] = this.pushList[i].checked;
        }
        pushSetting.receivePush = this.alamSet;
        await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/user/update`, { data: { userid: this.activeUser.userid, pushSetting: pushSetting } });
        this.resetData();
        this.hideDialog();
        this.$message.success('환경설정 저장 완료');
      } catch (err) {
        this.$message.error('환경설정 저장 실패');
      }
    },
    async showDialogOnGis() {
      await this.getUserData();
      this.formTitle = '환경 설정';
      this.pushList = [];
      await this.getPushList();
      this.formDialog = true;
    },
    async getPushList() {
      try {
        let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/pushAlram/getPushList`, {});
        const pushResponse = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/pushAlram/getPushSelect`, { params: { group: this.activeUser.group } });
        let pushListProto = response.data;
        let pushAuth = pushResponse.data[0].pushSelect;
        this.pushList = [];
        for (let i = 0; i < pushListProto.length; i++) {
          Object.keys(pushAuth).forEach((element) => {
            if (pushListProto[i].value == element) {
              if (pushAuth[element] == 1) {
                this.pushList.push(pushListProto[i]);
              } else if (pushAuth[element] == 0) {
              }
            }
          });
        }

        if (this.activeUser.pushSetting) {
          this.alamSet = this.activeUser.pushSetting.receivePush;
          if (this.activeUser.pushSetting.pushSelect) {
            for (let i = 0; i < this.pushList.length; i++) {
              Object.keys(this.activeUser.pushSetting.pushSelect).forEach((push) => {
                if (this.pushList[i].value == push) {
                  let a = false;
                  if (this.activeUser.pushSetting.pushSelect[push] == 1) {
                    a = true;
                  } else if (this.activeUser.pushSetting.pushSelect[push] == 0) {
                    a = false;
                  }
                  this.pushList[i].checked = a;
                }
              });
            }
          }
        } else {
          this.alamSet = false;
        }
      } catch (err) {
        console.error(err);
        this.$message({
          type: 'error',
          message: '푸쉬정보 읽기 실패',
        });
      }
    },
    hideDialog() {
      this.resetData();
      this.formDialog = false;
    },
    toggleSelect(item) {
      // if(!this.alamSet){
      //   item.checked = false
      //   for(let i =0; i<this.pushList.length; i++){
      //     this.pushList[i].checked = false
      //   }
      // }
      this.$forceUpdate();
    },
    allToggle() {
      // for(let i =0; i<this.pushList.length; i++){
      //   this.pushList[i].checked = this.alamSet
      // }
      this.$forceUpdate();
    },
    isOpenDialog() {
      return this.formDialog;
    },
    async getUserData() {
      let userAuthList = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/userAuth?userid=${this.activeUser.userid}`);
      let a = { ...userAuthList.data.docs[0].userLoginAuth[0], ...userAuthList.data.docs[0] };
      this.activeUser = a;
      this.formDialog = false;
      return this.formDialog;
    },
    resetData() {
      this.currentTabId = 1;
      (this.formData = {
        managerId: '',
      }),
        (this.pushList = []);
      this.activeUser = this.$dkRouter.getUserInfo();
      console.log(this.activeUser);
      this.$forceUpdate();
    },
    clearWebViewCache() {
      try {
        this.$confirm(`캐시를 초기화합니다.`, '알림', {
          confirmButtonText: '확인',
          cancelButtonText: '취소',
          type: 'info',
        }).then(() => {
          if (window.JSBridge.clearWebViewCache()) {
            this.$message({
              type: 'success',
              message: `앱 캐시 초기화 완료`,
            });

            this.$store.dispatch('auth/actRefreshToken', { refresh_payload: '' });
            this.$store.dispatch('auth/actAccessToken', { access_payload: '' });
            window.location.href = this.$config.getServerConfig().baseUrl;
          } else {
            this.$message({
              type: 'danger',
              message: `앱 캐시 초기화 실패`,
            });
          }
        });
      } catch (err) {
        this.$message({
          type: 'danger',
          message: `앱 캐시 초기화 실패`,
        });
      }
    },

    // authCheckboxText(item) {
    //   if (item.checked) {
    //     return '사용 함';
    //   } else {
    //     return '사용 안함';
    //   }
    // },
  },
};
</script>

<style scoped>
.form-gis-wrap {
  position: relative;
}

@media only screen and (max-width: 768px) {
  .maintnce-container {
    flex-direction: column;
  }
  .maintnce-container aside {
    width: 100% !important;
  }
}

.form-maintnce {
  box-sizing: border-box;
  padding: 15px;
  width: 100%;
}

.maintnce-image {
  cursor: pointer;
  padding: 0 2px 4px 2px;
  text-align: center;
}

.maintnce-image:hover > img {
  opacity: 0.3;
}

.maintnce-image > img {
  height: auto;
  max-height: 120px;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  width: auto;
}

.form-maintnce-dialog {
  background-color: #edf1f2 !important;
  padding: 20px;
}
.el-table__header-wrapper {
  display: none;
}
</style>
