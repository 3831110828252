<template>
  <div class="page-set" style="height: 100vh;">
    <div v-if="compName !== 'Home'" class="top-menu-bar mb-both-pd">
      <div class="home-hamberger-box hamberger" @click="sideNavOn" style="position: absolute;">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <h4>
        <span @click="pageReload">{{ pageName }}</span>
      </h4>
    </div>
    <div v-else class="hamberger-box home-hamberger-box" @click="sideNavOn">
      <div class="hamberger mb-both-mg">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <component v-bind:is="currentPageComponent" :key="compName" :checkBackAction="checkBackAction" />
    <side-nav ref="sideNav"></side-nav>
  </div>
</template>

<script>
import FcltSideNav from './component/FcltSideNav.vue';
// import SideNav from './component/sideNav.vue';
export default {
  components: {
    // 'side-nav': FcltSideNav,
    'side-nav': FcltSideNav,
  },
  data: () => ({
    pageComponentList: [],
    currentPageComponent: undefined,
    pageName: '',
    compName: '',
  }),
  created() {
    const me = this;
    this.$on('sideNavOn', () => {
      me.sideNavOn();
    });

    document.title = this.$config.getSiteConfig().title;
  },
  async mounted() {
    // const pageList = await import('./pageList.js');
    const pageList = await import('./pageFcltList.js');
    this.pageComponentList = [...pageList.default];
    this.viewPage();
  },
  computed: {},
  methods: {
    checkBackAction() {
      if (this.$refs.sideNav.checkBackAction()) {
        return true;
      } else {
        return false;
      }
    },
    viewPage() {
      const { path, query, params } = this.$route;

      const pageComp = this.pageComponentList.find((pageComp) => path === pageComp.path);

      if (!pageComp) {
        return;
      }

      this.currentPageComponent = pageComp.component;
      this.compName = pageComp.key;
      this.pageName = pageComp.name;
    },

    sideNavOn() {
      this.$refs.sideNav.navOn();
    },
    pageReload() {
      window.location.reload();
    },
  },
  watch: {
    $route: function (n, o) {
      this.viewPage();
      this.$refs.sideNav.navOff();
    },
  },
};
</script>
<style>
.home-hamberger-box {
  position: absolute;
  top: 18px;
  z-index: 96;
}
</style>
