<template>
  <el-dialog :visible.sync="helpDialogShow" @close="hideDialog" class="el-dialog-notice">
    <el-carousel height="700px" :autoplay="false">
      <el-carousel-item v-for="(file, index) in selectImage" :key="index">
        <div class="dk-carousel-item">
          <img :src="file" />
        </div>
      </el-carousel-item>
    </el-carousel>
  </el-dialog>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      helpDialogShow: false,
      selectImage: [],
      helpImages: {
        '/': ['static/images/help/dashboard1.png', 'static/images/help/dashboard2.png', 'static/images/help/dashboard3.png'],
        '/userInfo': ['static/images/help/userInfo1.png', 'static/images/help/userInfo2.png'],
        '/fcltGis': [
          'static/images/help/fcltGis1.png',
          'static/images/help/fcltGis2.png',
          'static/images/help/fcltGis3.png',
          'static/images/help/fcltGis4.png',
          'static/images/help/fcltGis5.png',
        ],
        '/fcltInfo': ['static/images/help/fcltInfo1.png'],
        '/fcltTypeInfo': ['static/images/help/fcltTypeInfo1.png', 'static/images/help/fcltTypeInfo2.png'],
        '/maintnceLog': ['static/images/help/maintnceLog1.png'],
        '/material': ['static/images/help/material1.png', 'static/images/help/material2.png'],
        '/materialType': ['static/images/help/materialType1.png'],
        '/centerEquip': ['static/images/help/centerEquip1.png', 'static/images/help/centerEquip2.png'],
        '/serviceManage': ['static/images/help/serviceManage1.png', 'static/images/help/serviceManage2.png'],
        '/notice': ['static/images/help/notice1.png', 'static/images/help/notice2.png'],
        '/configure': ['static/images/help/configure1.png', 'static/images/help/configure2.png'],
        '/dataset': ['static/images/help/dataset1.png'],
      },
    };
  },
  methods: {
    showHelpDialog(path) {
      console.log(this.helpImages[path]);
      this.selectImage = this.helpImages[path];
      this.helpDialogShow = true;
    },
    hideDialog() {
      this.selectImage = [];
    },
  },
};
</script>

<style scoped></style>
