<template>
  <div class="dk-form">
    <dk-form-item
      v-for="(item, idx) in sortingTemplateData"
      ref="formItem"
      :key="idx"
      :idx="idx"
      :formData="item"
      :formType="formType"
      :dataKey="item.dataKey"
      @commitFormItem="commitFormItem"
      @swapToUp="swapToUp"
      @swapToDown="swapToDown"
      @removeItem="removeFormItem"
      :size="size"
    >
    </dk-form-item>
    <div class="dk-form-row bottom" v-if="formType === 'template'">
      <div class="dk-form-label">
        <span @click="addNewDkFormItem"> <i class="fas fa-plus"></i>속성 추가 </span>
      </div>
    </div>
  </div>
</template>

<script>
import uuid from 'uuid/v1';
import _ from 'lodash';

const dkTemplateFormItem = {
  template: `
    <div class="dk-form-row">
      <div class="dk-form-label">
        <span v-if="formType==='template'" class="swap-button-wrap">
          <i class="fas fa-caret-up up" @click="swapToUp"></i>
          <i class="fas fa-caret-down down" @click="swapToDown"></i>
        </span>
        <el-popover
          v-if="formType==='template'"
          placement="top-start"
          width="200"
          trigger="click"
          v-model="propVisible">
          <div class="dk-form-prop-setting">
            <el-input :size="size" v-model="label" @blur="exportData" @keyup.native.enter="exportData();focusForm();"></el-input>
            <h6>
              데이터 유형
            </h6>
            <ul>
              <li @click="setDataType('string')"><i class="fas fa-font"></i>문자</li>
              <li @click="setDataType('number')"><i class="fas fa-list-ol"></i>숫자</li>
              <li @click="setDataType('date')"><i class="fas fa-calendar-alt"></i>날짜</li>
              <li @click="setDataType('select')"><i class="fas fa-list-ul"></i>선택</li>
              <li @click="setDataType('multiselect')"><i class="fas fa-list-ul"></i>다중선택</li>
            </ul>
          </div>
          <span class="label" slot="reference" >{{getLabel}}</span>
        </el-popover>
        <span class="label" v-else>{{getLabel}}</span>
      </div>
      <div class="dk-form-item">
        <el-input v-if="getFormItemType===TEMPLATE_FORM_STRING || getFormItemType===TEMPLATE_FORM_PASSWORD" v-model="value" :size="size" placeholder="문자 입력" @focus="focusForm"></el-input>
        <el-input v-else-if="getFormItemType===TEMPLATE_FORM_NUMBER" v-model="value" :size="size" placeholder="숫자 입력" @focus="focusForm"></el-input>
        <el-date-picker
          v-else-if="getFormItemType===TEMPLATE_FORM_DATE"
          v-model="value"
          type="date"
          :size="size"
          placeholder="날짜 선택"
          style="width:100%"
          @focus="focusForm">
        </el-date-picker>
        <el-select
          v-else-if="getFormItemType===TEMPLATE_FORM_SELECT"          
          v-model="tempOptions"
          multiple
          filterable
          allow-create
          default-first-option
          :size="size"
          placeholder="옵션 추가"
          style="width:100%"
          @focus="focusForm">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-select        
          v-else-if="getFormItemType===INPUT_FORM_SELECT"
          :size="size"
          :placeholder="label"
          style="width:100%"
          v-model="value"
          @focus="focusForm">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
         <el-select
          v-else-if="getFormItemType===TEMPLATE_FORM_MULTISELECT"          
          v-model="tempOptions"
          multiple
          filterable
          allow-create
          default-first-option
          :size="size"
          placeholder="옵션 추가"
          style="width:100%"
          @focus="focusForm">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-select        
          v-else-if="getFormItemType===INPUT_FORM_MULTISELECT"
          :size="size"
          :placeholder="label"
          style="width:100%"
          v-model="value"
          multiple 
          @focus="focusForm">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-checkbox
        v-if="formType==='template'"
        class="dk-form-item-searchable" 
        :size="size"
        v-model="searchable"
        border
      >검색 여부
      </el-checkbox>
      <el-button 
        :size="size" 
        type="danger"
        class="dk-form-item-remove" 
        v-if="formType==='template'"
        @click="removeItem"
      >삭제</el-button>
    </div>
  `,
  data() {
    return {
      TEMPLATE_FORM_STRING: '01',
      TEMPLATE_FORM_PASSWORD: '02',
      TEMPLATE_FORM_NUMBER: '03',
      TEMPLATE_FORM_DATE: '04',
      TEMPLATE_FORM_SELECT: '05',
      TEMPLATE_FORM_MULTISELECT: '06',
      INPUT_FORM_STRING: '11',
      INPUT_FORM_PASSWORD: '12',
      INPUT_FORM_NUMBER: '13',
      INPUT_FORM_DATE: '14',
      INPUT_FORM_SELECT: '15',
      INPUT_FORM_MULTISELECT: '16',
      label: '',
      options: [],
      type: 'string',
      value: '',
      tempOptions: [],
      propVisible: false,
      searchable: false,
    };
  },
  mounted() {
    this.label = this.formData.label || '';
    this.type = this.formData.type || 'string';
    this.searchable = this.formData.searchable || false;
    if (this.formData.options && this.formData.options.length > 0) {
      // this.options = _.cloneDeep(this.formData.options);
      this.formData.options.forEach((opt) => {
        if (this.formType === 'template') {
          this.tempOptions.push(opt.label);
        } else {
          this.options.push(opt);
        }
      });
    }
    if (this.formType === 'input') {
      if (this.formData.value) {
        this.value = this.formData.value;
      }

      //   console.log(this.formData);
      //   this.importData();
    }
  },
  computed: {
    getLabel() {
      return this.label;
    },
    getFormItemType() {
      if (this.formType === 'template') {
        if (this.type === 'string') {
          return this.TEMPLATE_FORM_STRING;
        } else if (this.type === 'password') {
          return this.TEMPLATE_FORM_PASSWORD;
        } else if (this.type === 'number') {
          return this.TEMPLATE_FORM_NUMBER;
        } else if (this.type === 'date') {
          return this.TEMPLATE_FORM_DATE;
        } else if (this.type === 'select') {
          return this.TEMPLATE_FORM_SELECT;
        } else if (this.type === 'multiselect') {
          return this.TEMPLATE_FORM_MULTISELECT;
        }
      } else if (this.formType === 'input') {
        if (this.type === 'string') {
          return this.TEMPLATE_FORM_STRING;
          // return this.INPUT_FORM_STRING;
        } else if (this.type === 'password') {
          return this.TEMPLATE_FORM_PASSWORD;
          // return this.INPUT_FORM_PASSWORD;
        } else if (this.type === 'number') {
          return this.TEMPLATE_FORM_NUMBER;
          // return this.INPUT_FORM_NUMBER;
        } else if (this.type === 'date') {
          return this.TEMPLATE_FORM_DATE;
          // return this.INPUT_FORM_DATE;
        } else if (this.type === 'select') {
          return this.INPUT_FORM_SELECT;
        } else if (this.type === 'multiselect') {
          return this.INPUT_FORM_MULTISELECT;
        }
      }
    },
  },
  watch: {
    tempOptions(newVal) {
      this.options = [];
      newVal.forEach((a, idx) => {
        this.options.push({
          label: a,
          value: idx + 1,
        });
      });
      this.exportData();
    },
    // value() {
    //   this.$forceUpdate();
    // },
    // formData(newVal) {
    //   if (newVal.value) {
    //     this.value = newVal.value;
    //   }
    // },
  },
  props: {
    dataKey: {
      type: String,
    },
    formData: {
      type: Object,
      default: {
        label: '',
        type: 'string',
      },
    },
    formType: {
      type: String,
      default: 'input',
    },
    idx: {
      type: Number,
    },
    size: {
      type: String,
      default: 'mini',
    },
  },
  methods: {
    setDataType(type) {
      this.type = type;
      this.tempOptions = [];
      this.selectOptions = [];
      this.propVisible = false;
      this.exportData();
    },
    focusForm() {
      this.propVisible = false;
    },
    exportData() {
      let data = {
        dataKey: this.dataKey,
        type: this.type,
        searchable: this.searchable,
        // label: this.label,
      };
      if (this.formType === 'template') {
        if (this.label == '') {
          this.label = '속성';
        } else {
          data.label = this.label;
        }
        if (this.type === 'select' || this.type === 'multiselect') {
          data.options = this.options;
        }
      } else if (this.formType === 'input') {
        if (this.type === 'select') {
          data.selectValue = this.value;
          const selectOption = _.find(this.options, (opt) => {
            return opt.value === this.value;
          });
          if (selectOption) data.value = selectOption.label;
        } else if (this.type === 'multiselect') {
          let value = [];
          data.selectValue = this.value;
          if (typeof this.value === 'object' && this.value.length > 0) {
            this.value.forEach((v) => {
              if (v) {
                const selectOption = _.find(this.options, (opt) => {
                  return opt.value === v;
                });
                value.push(selectOption.label);
              }
            });
          }
          data.value = value;
        } else if (this.type === 'date') {
          data.value = this.value.getTime().toString();
        } else {
          data.value = this.value;
        }
      }
      this.$emit('commitFormItem', data);
    },
    getDataKey() {
      return this.dataKey;
    },
    setFormValue(data) {
      this.value = data.value;
      if (this.type === 'select' && this.type === 'multiselect') {
        this.value = data.selectValue;
      } else if (this.type === 'date') {
        if (this.value !== '') this.value = new Date(parseFloat(this.value));
      }
    },
    swapToUp() {
      console.log(this.idx);
      this.$emit('swapToUp', { idx: this.idx });
    },
    swapToDown() {
      this.$emit('swapToDown', { idx: this.idx });
    },
    removeItem() {
      this.$emit('removeItem', { dataKey: this.dataKey });
    },
  },
};

export default {
  components: {
    'dk-form-item': dkTemplateFormItem,
  },
  data() {
    return {
      templateData: [],
      formData: [],
      enabled: true,
      movingIndex: -1,
      futureIndex: -1,
      formRender: true,
    };
  },
  props: {
    formType: {
      type: String,
      default: 'input',
    },
    size: {
      type: String,
      default: 'mini',
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit('renderAfter', {
        formType: this.formType,
      });
    });
  },
  computed: {
    sortingTemplateData() {
      if (this.formRender) {
        return this.templateData;
      } else {
        return [];
      }
    },
  },
  methods: {
    addNewDkFormItem() {
      let newFormData = {
        dataKey: uuid(),
        label: '속성',
        type: 'string',
      };
      this.templateData.push(newFormData);
      this.$forceUpdate();
    },
    commitFormItem(formData) {
      let idx = _.findIndex(this.templateData, (td) => {
        return td.dataKey === formData.dataKey;
      });
      this.templateData[idx] = formData;
    },
    exportTemplateData() {
      if (this.$refs.formItem) {
        this.$refs.formItem.forEach((ft) => {
          ft.exportData();
        });
      }
      return this.templateData;
    },
    createTemplateForm(templateData) {
      this.templateData = _.cloneDeep(templateData);
    },
    importTemplateFormValue(formData) {
      _.map(formData, (val) => {
        let targetFormItem = _.find(this.$refs.formItem, (fi) => {
          return fi.getDataKey() === val.dataKey;
        });
        if (targetFormItem) {
          targetFormItem.setFormValue(val);
        }
      });
      this.$forceUpdate();
      // this.formData = formData;
    },
    resetForm() {
      this.templateData = [];
    },
    // checkMove: function (e) {
    //   console.log(this.templateData);
    //   // console.log('Future index: ' + e.draggedContext.futureIndex);
    // },
    // handleMove(e) {
    //   const { index, futureIndex } = e.draggedContext;
    //   this.movingIndex = index;
    //   this.futureIndex = futureIndex;
    //   // console.log(`${this.movingIndex}, ${this.futureIndex}`);
    //   return false; // disable sort
    // },
    handleDragEnd(movingIdx, targetIdx) {
      this.formRender = false;
      let templateData = _.cloneDeep(this.templateData);
      let futureItem = templateData[targetIdx];
      templateData[targetIdx] = templateData[movingIdx];
      templateData[movingIdx] = futureItem;
      this.templateData = templateData;
      this.$nextTick(() => {
        this.formRender = true;
      });
    },
    swapToUp(itemIdx) {
      if (itemIdx.idx <= 0) {
        return;
      }
      this.handleDragEnd(itemIdx.idx, itemIdx.idx - 1);
    },
    swapToDown(itemIdx) {
      if (itemIdx.idx + 1 >= this.templateData.length) {
        return;
      }
      this.handleDragEnd(itemIdx.idx, itemIdx.idx + 1);
    },
    removeFormItem(args) {
      this.formRender = false;
      const targetDataKey = args.dataKey;
      const removeIndex = _.findIndex(this.templateData, (val) => {
        return val.dataKey === targetDataKey;
      });
      let tempData = _.cloneDeep(this.templateData);
      if (removeIndex >= 0) {
        tempData.splice(removeIndex, 1);
      }
      this.templateData = tempData;
      this.$nextTick(() => {
        this.formRender = true;
      });
      this.$forceUpdate();
    },
  },
};
</script>
