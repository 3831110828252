<template>
  <div class="chart-container">
    <GChart
      type="PieChart"
      :settings="{
        packages: ['corechart'],
        language: 'ko',
        callback: () => {
          this.drawChart();
        },
      }"
      :data="chartData"
      :options="options"
      @ready="onChartReady"
    />
    <span v-if="centerText" class="center-text">{{ centerText }}</span>
  </div>
</template>

<script>
export default {
  created() {
    this.options.colors = this.colors;
    this.setRows(this.data);
  },
  props: {
    cols: {
      type: Array,
      require: true,
      /*[{
          label: '필드명',
          type: '타입',
      }]*/
    },
    data: {
      type: Array,
    },
    colors: {
      type: Array,
      default() {
        return ['#f56c6c', '#409eff'];
      },
    },
    centerText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      options: {
        pieHole: 0.6,
        pieSliceText: 'none',
        width: '100%',
        height: 300,
        backgroundColor: 'transparent',
        legend: {
          textStyle: { color: '#333' },
          position: 'top',
          alignment: 'end',
        },
        chartArea: { backgroundColor: 'transparent', width: '80%', height: '80%' },
      },
      rows: [],
    };
  },
  methods: {
    onChartReady(chart, google) {
      this.chartsLib = google;
    },
    drawChart() {
      this.$emit('drawChart');
    },
    setRows(data) {
      const rows = data.map((elm) => {
        const { xaxisLabel, value } = elm;
        const result = {
          c: [
            {
              v: xaxisLabel,
            },
            {
              v: value,
            },
          ],
        };
        return result;
      });
      this.rows = rows;
    },
  },

  computed: {
    chartData() {
      return {
        cols: this.cols,
        rows: this.rows,
      };
    },
  },

  watch: {
    data(n) {
      this.setRows(n);
    },
  },
};
</script>
<style scoped>
.chart-container {
  position: relative;
}
.center-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: 600;
  color: #409eff;
}
</style>
