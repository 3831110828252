var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("vue-headful", {
        attrs: { title: _vm.$config.getSiteConfig().title }
      }),
      _c("div", { class: [_vm.isMenuOpen ? "show" : "", "mobile-menu"] }, [
        _c("div", { staticClass: "mobile-menu-body" }, [
          _c("div", { staticClass: "mobile-menu-profile" }, [
            _vm.activeUser.images && _vm.activeUser.images.length > 0
              ? _c("div", {
                  staticClass: "mobile-user-thumb-img",
                  style:
                    "background-image:url(" +
                    _vm.activeUser.images[0].serverFilePath +
                    ")"
                })
              : _c("div", {
                  staticClass: "mobile-user-thumb-img",
                  staticStyle: {
                    "background-image": "url(static/images/unknown.png)"
                  }
                }),
            _c("div", { staticClass: "mobile-user-info" }, [
              _c("div", { staticClass: "name" }, [
                _vm._v(_vm._s(_vm.activeUser.name))
              ]),
              _c("div", { staticClass: "id" }, [
                _vm._v(
                  _vm._s(_vm.activeUser.userid) +
                    "(" +
                    _vm._s(_vm.activeUser.groupName) +
                    ")"
                )
              ])
            ])
          ]),
          _c("ul", { staticClass: "mobile-menu-list" }, [
            _c("li", [
              _c("a", { attrs: { href: _vm.centerPhoneLink() } }, [
                _vm._m(0),
                _c("span", [
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.$config.getSiteConfig().orgName) + " 전화연결"
                    )
                  ])
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "mobile-menu-bottom-bar" }, [
            _c("span", { on: { click: _vm.logout } }, [
              _c("i", {
                staticClass: "fas fa-sign-out-alt",
                staticStyle: { cursor: "pointer" }
              }),
              _vm._v("로그아웃 ")
            ])
          ])
        ])
      ]),
      _vm.$isMobile
        ? _c("div", { staticClass: "main-container mobile" }, [
            _c("div", { staticClass: "mobile-header" }, [
              _c("div"),
              _c("div", { staticClass: "mobile-main-ci-wrap" }, [
                _c("img", {
                  attrs: {
                    src:
                      _vm.$config.getServerConfig().core.webServer +
                      _vm.logoData.serverFilePath
                  },
                  on: { click: _vm.goHome }
                })
              ]),
              _c("div", [
                _c(
                  "span",
                  {
                    staticClass: "mobile-menu-toggle",
                    on: { click: _vm.showMenu }
                  },
                  [_c("i", { staticClass: "fas fa-bars" })]
                )
              ])
            ]),
            _c("div", {
              class: [_vm.isMenuOpen ? "show" : "", "menu-bubbling-mask"],
              on: {
                click: function($event) {
                  _vm.isMenuOpen = false
                }
              }
            }),
            _c(
              "div",
              { staticClass: "mobile-container" },
              [_c("router-view")],
              1
            )
          ])
        : _vm._e(),
      _c("form-user-info", { ref: "formDialog" }),
      _c("form-user-password", { ref: "formUserPassword" })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("img", {
        staticClass: "menu-icon",
        attrs: { src: "static/images/telephone-symbol-button.png" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }