//////////////////////////////////////////////////////////////////////////////////
/*
/* GIS API 로딩 모듈
/* 
/* GIS API 자체가 외부 함수이기 때문에, 별도의 스크립트 모듈 로딩 프로세스가 필요하다.
/* API별로 로딩하는 방식이 완전히 다르기 때문에, 동일한 결과를 리턴하기 위해서는 
/* 약간 지저분한 프로세스가 필요하다.
/* 
/* Kakao : API 모듈이 다음서버 측에서 인증이되면 window['kakao']객체가 추가된다.
/*        다만 이 객체가 비동기로 처리되기 때문에 타이머를 돌려서 추가 된 시점을 파악한다.
/*        window['kakao'] 객체가 선택 가능하면, kakao.maps.load 콜백 함수를 써서 
/*        api 로딩 promise를 resolve 처리한다.
/*
/* Google : API <script> 태그 URL에 콜백 함수 명칭을 명시한다.
/*        이 콜백 함수는 window['함수명']의 형태로 선언되어 있어야하며, google api가 로드될 때
/*        실행하여 프로세스를 처리한다. 그러므로 이 소스에서는 기본 api로딩 promise에
/*        새로운 promise를 선언하고, 그 resolve를 이 콜백함수에 등록하여 기존 프로세스에
/*        탑승할 수 있도록 하였다.          
/*
/* Vworld : 알아서해라
/*
////////////////////////////////////////////////////////////////////////////////*/
function kakaomapTileset() {
  kakao.maps.Tileset.add(
    'KAKAO_PROXY',
    new kakao.maps.Tileset({
      width: 256,
      height: 256,
      urlFunc: function (x, y, z) {
        // console.log(`x${x}, y${y}, z${z}`);
        return `https://map1.daumcdn.net/map_2d/2103dor/L${z}/${y}/${x}.png`;
        //프록시 타고 카카오 슝슝~
        // return `/map1.daumcdn.net/map_2d/2103dor/L${z}/${y}/${x}.png`;
      },
      minZoom: 1,
      maxZoom: 10,
    })
  );
}

export const load = (gisApiInfo) => {
  if (typeof document === 'undefined') {
    // Do nothing if run from server-side
    return;
  }
  // gis api가 초기화 되지 않은경우 외부 모듈을 불러옴
  if (!gisApiInfo.gisApiLoadInit) {
    var gisName = gisApiInfo.gisName || '';
    var apiKey = gisApiInfo.apiKey || '';
    var gisOption = gisApiInfo.gisOption || {};

    var mapScript = document.createElement('script');
    var url = '';
    if (gisName == 'kakao') {
      url = '//dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=';
      //if (gisApiInfo.froxKakao) {
      // debugger;
      //url = '/dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=';
      // }
      url += apiKey;
    } else if (gisName == 'google') {
      url = 'https://maps.googleapis.com/maps/api/js?key=';
      url += apiKey;
    }
    // VWorld의 경우 openlayers로 처리함
    else if (gisName == 'vworld') {
      url = '//';
      url += apiKey;
    } else if (gisName === 'openStreetMap') {
    } else {
      return false;
    }

    if (gisOption) {
      for (let k in gisOption) {
        url += '&' + k + '=' + gisOption[k];
      }
    }
    if (url) {
      mapScript.setAttribute('src', url);
      mapScript.setAttribute('async', '');
      mapScript.setAttribute('defer', '');
      document.head.appendChild(mapScript);
    }
  }
  return Promise.resolve()
    .then(() => {
      var apiLoadCallback = new Promise((resolve, reject) => {
        // Kakao API 체크
        if (gisApiInfo.gisName == 'kakao') {
          var checkLoading = function () {
            setTimeout(function () {
              if (!window.kakao) {
                checkLoading();
              } else {
                kakao.maps.load((a, b, c, d) => {
                  console.log(gisApiInfo.froxKakao);
                  if (gisApiInfo.froxKakao) {
                    kakaomapTileset();
                  }
                  resolve(gisApiInfo);
                });
              }
            }, 20);
          };
          if (!window.kakao) {
            checkLoading();
          } else {
            resolve(gisApiInfo);
          }
        }
        // Google API 체크
        else if (gisApiInfo.gisName == 'google') {
          new Promise((resolve2, reject2) => {
            window['dkGisInit'] = resolve2;
          })
            .then(() => {
              return resolve(gisApiInfo);
            })
            .catch((err) => {
              return resolve(false);
            });
        } else if (gisName == 'vworld') {
          return resolve(gisApiInfo);
        } else if (gisName == 'openStreetMap') {
          return resolve(gisApiInfo);
        }
      });
      return apiLoadCallback;
    })
    .then((successGisApiInfo) => {
      successGisApiInfo.gisApiLoadInit = true;
      return successGisApiInfo;
    })
    .catch((err) => {
      return false;
    });
};
