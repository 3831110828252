var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "dk-card shadow",
          staticStyle: { "min-height": "300px" }
        },
        [
          _c("div", { staticClass: "dk-card-title" }, [
            _vm._v("\n      유지보수 처리 현황\n    ")
          ]),
          _c(
            "div",
            [
              _c(
                "el-table",
                {
                  staticStyle: {
                    width: "100%",
                    "border-top": "1px solid #dedede"
                  },
                  attrs: {
                    data: _vm.maintnceLogs,
                    "empty-text": "표출할 유지관리 이력이 없습니다",
                    size: "mini",
                    stripe: ""
                  },
                  on: { "row-click": _vm.selectMaintnceLogRow }
                },
                [
                  _vm.showFcltInfo
                    ? _c("el-table-column", {
                        attrs: {
                          label: "시설물 유형",
                          "min-width": "70px",
                          "header-align": "center",
                          prop: "fcltTypeName"
                        }
                      })
                    : _vm._e(),
                  _vm.showFcltInfo
                    ? _c("el-table-column", {
                        attrs: {
                          label: "시설물 명칭",
                          "min-width": "70px",
                          "header-align": "center",
                          prop: "fcltName"
                        }
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      label: "시작일",
                      "min-width": "110px",
                      "header-align": "center",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.getFormatDate(scope.row.startDate)) +
                                "\n          "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "종료일",
                      "min-width": "110px",
                      "header-align": "center",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.endDate
                              ? _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.getFormatDate(scope.row.endDate)
                                      ) +
                                      "\n            "
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(
                                    "\n              정보없음\n            "
                                  )
                                ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "진행 상태",
                      "header-align": "center",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.status == "step1"
                              ? _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v("발생/접수")
                                ])
                              : scope.row.status == "step2"
                              ? _c("el-tag", { attrs: { type: "warning" } }, [
                                  _vm._v("처리 중")
                                ])
                              : scope.row.status == "step3"
                              ? _c("el-tag", [_vm._v("확인")])
                              : scope.row.status == "step4"
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v("완료")
                                ])
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c("dk-el-pagination", {
                ref: "pagination",
                attrs: {
                  totalDocs: _vm.totalDocs,
                  limit: _vm.limit,
                  pagingProc: _vm.pagingProc
                }
              })
            ],
            1
          )
        ]
      ),
      _c("form-maintnce", { ref: "formMaintnce" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }