<template>
  <el-dialog :title="formTitle" :visible.sync="formDialog" @close="hideDialog" :close-on-click-modal="false" id="formLedBatchTransfer">
    <div class="wrap">
      <div class="schedule-list">
        <el-table
          :data="ledSchedules"
          empty-text="등록된 LED 스케줄 정보가 없습니다."
          size="mini"
          stripe
          highlight-current-row
          @current-change="rowSelect"
        >
          <el-table-column type="expand">
            <template slot-scope="props">
              <!-- <p>{{ props.row.ledMessages }}</p> -->
              <led-preview-viewer
                v-for="(item, idx) in props.row.ledMessages"
                :key="idx"
                :ledMessageData="item"
                :ref="`preview-${idx}`"
              ></led-preview-viewer>
            </template>
          </el-table-column>
          <el-table-column label="스케줄 명칭" header-align="left" align="left" prop="ledScheduleName"></el-table-column>
          <el-table-column label="크기" header-align="left" align="left" width="100px">
            <template slot-scope="scope">{{ getLedMessageSize(scope.row.ledMessageSize) }}</template>
          </el-table-column>
        </el-table>
      </div>
      <div class="led-list">
        <el-table :data="ledList" empty-text="등록된 LED 스케줄 정보가 없습니다." size="mini" stripe>
          <el-table-column label="명칭" header-align="center" align="left" prop="fcltName"></el-table-column>
          <el-table-column label="주소" header-align="center" align="left" prop="setNumberAddr"></el-table-column>
          <el-table-column label="전송상태" header-align="center" align="center">
            <template slot-scope="scope">
              <el-tag v-if="!scope.row.isFinish" type="warning">준비 중</el-tag>
              <el-tag v-else type="success">전송 완료</el-tag>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="batchTransfer">발송</el-button>
      <el-button @click="hideDialog">취소</el-button>
    </span>
  </el-dialog>
</template>

<script>
import ledPreviewViewer from './ledPreviewViewer.vue';
import DKLedUtils from '../ledUtils';

export default {
  components: {
    'led-preview-viewer': ledPreviewViewer,
  },
  data() {
    return {
      formDialog: false,
      formTitle: 'LED 스케줄 일괄 표출',
      ledMessageSize: null,
      ledList: [],
      ledSchedules: [],
      seletedSchedule: null,
      ledType: null,
    };
  },
  methods: {
    async getLedSchedules() {
      this.ledSchedules = [];
      let param = {};
      if (this.ledMessageSize) {
        param.ledMessageSize = this.ledMessageSize;
      }
      if (this.ledType) {
        param.ledType = this.ledType;
      }

      const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/led/api/ledSchedule`, {
        params: param,
      });
      this.ledSchedules = response.data.docs;
    },
    rowSelect(row) {
      this.seletedSchedule = row;
    },
    batchTransfer() {
      let param = {
        scheduleInfo: this.seletedSchedule,
        ledFclt: this.ledList,
      };
      this.$confirm(`LED 스케줄을 일괄 전송합니다.`, '확인', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'info',
      })
        .then(async () => {
          try {
            await this.$http.post(`${this.$config.getServerConfig().core.api}/led/api/ledSchedule/scheduleBatchTransfer`, {
              data: param,
            });
            this.$message({
              type: 'success',
              message: `전송 완료`,
            });
            this.$emit('completeBatchTransfer', { ...param });
            this.hideDialog();
          } catch (err) {
            this.$message({
              type: 'info',
              message: `전송 실패`,
            });
            this.hideDialog();
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `전송 취소`,
          });
        });
    },
    hideDialog() {
      this.ledMessageSize = null;
      this.ledType = null;
      this.formDialog = false;
    },
    showDialog(ledList) {
      if (ledList.length <= 0) {
        return;
      }
      this.ledMessageSize = DKLedUtils.getLedSize(ledList[0]);
      this.ledType = ledList[0].fcltTypeData.ledType.value;
      this.formDialog = true;
      this.ledList = ledList;
      this.getLedSchedules();
    },
    getLedMessageSize(val) {
      return DKLedUtils.getLedMessageSizeKorean(val);
    },
  },
};
</script>
