<template>
  <div>
    <div class="dk-card shadow" style="min-height: 300px;">
      <div class="dk-card-title">
        유지보수 처리 현황
      </div>
      <div>
        <el-table
          :data="maintnceLogs"
          empty-text="표출할 유지관리 이력이 없습니다"
          size="mini"
          @row-click="selectMaintnceLogRow"
          stripe
          style="width: 100%; border-top: 1px solid #dedede;"
        >
          <el-table-column label="시설물 유형" min-width="70px" header-align="center" prop="fcltTypeName" v-if="showFcltInfo"> </el-table-column>
          <el-table-column label="시설물 명칭" min-width="70px" header-align="center" prop="fcltName" v-if="showFcltInfo"> </el-table-column>
          <el-table-column label="시작일" min-width="110px" header-align="center" align="center">
            <template slot-scope="scope">
              {{ getFormatDate(scope.row.startDate) }}
            </template>
          </el-table-column>
          <el-table-column label="종료일" min-width="110px" header-align="center" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.endDate">
                {{ getFormatDate(scope.row.endDate) }}
              </span>
              <span v-else>
                정보없음
              </span>
            </template>
          </el-table-column>
          <el-table-column label="진행 상태" header-align="center" align="center">
            <template slot-scope="scope">
              <el-tag type="danger" v-if="scope.row.status == 'step1'">발생/접수</el-tag>
              <el-tag type="warning" v-else-if="scope.row.status == 'step2'">처리 중</el-tag>
              <el-tag v-else-if="scope.row.status == 'step3'">확인</el-tag>
              <el-tag type="success" v-else-if="scope.row.status == 'step4'">완료</el-tag>
            </template>
          </el-table-column>
        </el-table>
        <dk-el-pagination :totalDocs="totalDocs" :limit="limit" :pagingProc="pagingProc" ref="pagination"> </dk-el-pagination>
      </div>
    </div>
    <form-maintnce ref="formMaintnce"> </form-maintnce>
  </div>
</template>
<script>
import moment from 'moment';
import dkelpagination from '@/components/dkElPagination.vue';
import formmaintnce from '@/core/forms/formMaintnce.vue';

export default {
  components: {
    'dk-el-pagination': dkelpagination,
    'form-maintnce': formmaintnce,
  },
  created() {
    this.getMaintnceLog(1);
    this.$on('procDataComplete', (fcltData) => {
      this.getMaintnceLog();
    });
  },
  data() {
    return {
      limit: 5,
      totalDocs: 0,
      fcltData: [],
      maintnceLogs: [],
      maintnceLogDialog: false,
      selectMaintnceLog: null,
    };
  },
  props: {
    selectFcltData: {
      type: Object,
      default: null,
    },
    selectRow: {
      type: Function,
      default: null,
    },
    isProgress: {
      type: Boolean,
      default: false,
    },
    showFcltInfo: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    selectFcltData(newVal) {
      this.getMaintnceLog();
    },
  },
  methods: {
    getFormatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
    getMaintnceLog(pageNum) {
      let me = this;
      let param = {
        page: pageNum || this.$refs.pagination.getPage(),
        limit: this.limit,
      };
      if (this.selectFcltData) {
        param.fcltId = this.selectFcltData.fcltId;
      }
      if (this.isProgress) {
        param.isProgress = true;
      }
      this.$store.dispatch('maintnce/getMaintnceLog', param).then(() => {
        me.maintnceLogs = me.$store.getters['maintnce/getMaintnceLog'];
        me.totalDocs = me.$store.getters['maintnce/getPagination'].totalDocs;
      });
    },
    pagingProc(pageNum) {
      this.getNotices();
    },
    getWriteDate(writeDate) {
      return moment(writeDate).format('YYYY년 MM월 DD일 HH:mm');
    },
    selectMaintnceLogRow(row) {
      if (this.selectRow) {
        this.selectRow(row);

        return;
      } else {
        this.maintnceLogDialog = true;
        this.selectMaintnceLog = row;
        this.$refs.formMaintnce.showDialog(this.selectFcltData, this.selectMaintnceLog);
      }
    },
  },
};
</script>

<style scoped></style>
