import { render, staticRenderFns } from "./pageMobileMain.vue?vue&type=template&id=2a1d0083&scoped=true&"
import script from "./pageMobileMain.vue?vue&type=script&lang=js&"
export * from "./pageMobileMain.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a1d0083",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/dk-platform-frontend/dk-platform-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2a1d0083')) {
      api.createRecord('2a1d0083', component.options)
    } else {
      api.reload('2a1d0083', component.options)
    }
    module.hot.accept("./pageMobileMain.vue?vue&type=template&id=2a1d0083&scoped=true&", function () {
      api.rerender('2a1d0083', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pageMobileMain.vue"
export default component.exports