var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { visible: _vm.isDisplay, title: _vm.formTitle },
      on: {
        "update:visible": function($event) {
          _vm.isDisplay = $event
        }
      }
    },
    [_vm._v("\n  " + _vm._s(_vm.trafficIncidentData) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }