<template>
  <div class="fill-width-height el-container" id="pageFcltStatus">
    <el-container class="page-body">
      <el-main>
        <el-row class="content-wrap">
          <el-col :span="24" class="page-content">
            <el-tabs v-model="activeTabName" @tab-click="tabClick" class="tab-container">
              <el-tab-pane label="유형별 시설물 현황" name="fcltTypeTab">
                <FcltStatusTabLayout :dataType="activeTabName" :statusData="statusData" :sumStatusData="sumStatusData" />
              </el-tab-pane>
              <el-tab-pane label="지역별 시설물 현황" name="fcltAreaTab">
                <FcltStatusTabLayout :dataType="activeTabName" :statusData="statusData" :sumStatusData="sumStatusData" />
              </el-tab-pane>
            </el-tabs>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import axios from 'axios';
import FcltStatusTabLayout from './forms/layout/fcltStatusTabLayout.vue';
export default {
  components: {
    FcltStatusTabLayout,
  },
  data() {
    return {
      activeTabName: 'fcltTypeTab',
      statusData: [],
      sumStatusData: {
        pole: 0,
        fclt: 0,
      },
      data: {},
    };
  },
  created() {},
  async mounted() {
    await this.setData();
    this.tabClick({ name: 'fcltTypeTab' });
  },
  methods: {
    tabClick(tab) {
      this.activeTabName = tab.name;
      this.statusData = this.data[tab.name];
    },
    async setData() {
      let loadingOut = setTimeout(() => {
        this.$message.error('통계 데이터를 불러오는데 실패했습니다.');
        this.loading.close();
      }, 10000);
      try {
        this.loading = this.$loading({
          lock: true,
          text: '통계 데이터를 받아오는 중입니다. 최대 10초 소요',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
        });

        let values = await Promise.all([
          axios.get(`${this.$config.getServerConfig().core.api}/asan/api/statisticsType`),
          axios.get(`${this.$config.getServerConfig().core.api}/asan/api/statisticsArea`),
        ]);

        this.data.fcltTypeTab = values[0].data;
        this.data.fcltAreaTab = values[1].data;

        // 지역별 시설물 기준으로 총 개수,대수 저장함: 유형별로 하면 한 개소에 두 개 이상 유형별 시설물이 들어갈 경우가 있어서 정확한 개소 측정이 안됨
        if (Array.isArray(this.data.fcltAreaTab) && this.data.fcltAreaTab.length > 0) {
          this.data.fcltAreaTab.forEach((item) => {
            this.sumStatusData.pole += item.allPoleCnt;
            this.sumStatusData.fclt += item.allFcltCnt;
          });
        }

        this.loading.close();
        clearTimeout(loadingOut);
      } catch (err) {
        this.loading.close();
        clearTimeout(loadingOut);
        this.$message.error('통계 데이터를 불러오는데 실패했습니다.');
        console.error(err);
      }
    },
  },
};
</script>

<style></style>
