var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-set", staticStyle: { height: "100vh" } },
    [
      _vm.compName !== "Home"
        ? _c("div", { staticClass: "top-menu-bar mb-both-pd" }, [
            _c(
              "div",
              {
                staticClass: "home-hamberger-box hamberger",
                staticStyle: { position: "absolute" },
                on: { click: _vm.sideNavOn }
              },
              [_c("span"), _c("span"), _c("span")]
            ),
            _c("h4", [
              _c("span", { on: { click: _vm.pageReload } }, [
                _vm._v(_vm._s(_vm.pageName))
              ])
            ])
          ])
        : _c(
            "div",
            {
              staticClass: "hamberger-box home-hamberger-box",
              on: { click: _vm.sideNavOn }
            },
            [_vm._m(0)]
          ),
      _c(_vm.currentPageComponent, {
        key: _vm.compName,
        tag: "component",
        attrs: { checkBackAction: _vm.checkBackAction }
      }),
      _c("side-nav", { ref: "sideNav" })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hamberger mb-both-mg" }, [
      _c("span"),
      _c("span"),
      _c("span")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }