var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "el-dialog-aside",
      attrs: {
        title: _vm.formTitle,
        visible: _vm.formDialog,
        "close-on-click-modal": false,
        top: "5vh",
        "append-to-body": "",
        fullscreen: "",
        id: "formMobileSetting"
      },
      on: {
        "update:visible": function($event) {
          _vm.formDialog = $event
        },
        close: _vm.hideDialog
      }
    },
    [
      !_vm.$isMobile
        ? _c(
            "div",
            { staticClass: "form-gis-wrap" },
            [
              _c("dk-gis", {
                ref: "dkGis",
                staticStyle: { height: "280px" },
                attrs: {
                  gisApiInfo: _vm.gisApiInfo,
                  markerData: _vm.markerData,
                  level: _vm.level
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-container",
        { staticClass: "dialog-container maintnce-container" },
        [
          _c(
            "el-container",
            [
              _c(
                "el-form",
                {
                  ref: "form1",
                  staticClass: "form-maintnce",
                  attrs: {
                    size: "medium",
                    model: _vm.formData,
                    "label-position": "left"
                  }
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "전체 알림 설정",
                                prop: "workType"
                              }
                            },
                            [
                              _c("el-switch", {
                                staticClass: "float-right",
                                staticStyle: { padding: "10px 0" },
                                on: {
                                  change: function($event) {
                                    return _vm.allToggle()
                                  }
                                },
                                model: {
                                  value: _vm.alamSet,
                                  callback: function($$v) {
                                    _vm.alamSet = $$v
                                  },
                                  expression: "alamSet"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "workType", span: 12 } },
                            [
                              _c(
                                "ul",
                                {
                                  staticStyle: { margin: "0", padding: "0 3px" }
                                },
                                _vm._l(_vm.pushList, function(item, idx) {
                                  return _c(
                                    "li",
                                    { key: idx, staticClass: "flex-box" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "fclt-type-name" },
                                        [_vm._v(_vm._s(item.korValue))]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "auth-check" },
                                        [
                                          _c("el-switch", {
                                            on: {
                                              change: function($event) {
                                                return _vm.toggleSelect()
                                              }
                                            },
                                            model: {
                                              value: item.checked,
                                              callback: function($$v) {
                                                _vm.$set(item, "checked", $$v)
                                              },
                                              expression: "item.checked"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            ]
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "cache-clear-text",
                                  attrs: { span: 16 }
                                },
                                [
                                  _vm._v(
                                    "\n                ※ 최신의 내용으로 갱신하기 위해 앱의 캐시를 정리합니다."
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "자동 로그인 상태에서 로그아웃 될 수 있습니다.\n              "
                                  )
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.clearWebViewCache()
                                        }
                                      }
                                    },
                                    [_vm._v("캐시 초기화")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("저장")]
          ),
          _c("el-button", { on: { click: _vm.hideDialog } }, [_vm._v("취소")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }