<template>
  <div class="wrap">
    <!-- <paper-tabs selected="0" scrollable class="straight-wrap">
            <paper-tab            
                v-for="(item,idx) in roadLinkData"
                :key="idx"
                class="straight-cell"
                @click="selectLinkData(item)"
            >
            <div>
                <div class="t-node-item">{{item.t_node_name}}</div>
                <div class="link-id">{{item.link_id}}</div>
            </div>
        
            </paper-tab>     
        </paper-tabs> -->
    <div v-if="roadLinkData.length > 0">
      {{ roadLinkData[0].road_name }}
    </div>
    <ul class="straight-wrap" v-if="roadLinkData.length > 0">
      <li
        v-for="(item, idx) in roadLinkData"
        :key="idx"
        :class="[item[dataKey] === currentLinkData[dataKey] ? 'active' : '', 'straight-cell']"
        @click="selectLinkData(item)"
      >
        <span class="dir-arrow">
          <img :src="arrowIcon" />
        </span>
        <a>
          <span class="f-node-item">{{ item.f_node_name }}</span>
          <span class="t-node-item" v-if="idx + 1 === roadLinkData.length">{{ item.t_node_name }}</span>

          <p class="link-id">{{ item[dataKey] }}</p>
        </a>
        <span class="active-button">
          <el-button
            @click="toggleActivateLinkData(item)"
            size="mini"
            :type="checkActivatedLinkData(item) ? 'success' : 'info'"
            icon="el-icon-check"
            circle
          >
          </el-button>
        </span>
      </li>
    </ul>
    <div v-else class="no-road-link-data">
      <h3>직선도 정보가 없습니다.</h3>
    </div>
  </div>
</template>

<script>
// import '@polymer/paper-tabs/paper-tabs.js';
// import '@polymer/paper-tabs/paper-tab.js';

import _ from 'lodash';

export default {
  props: {
    roadLinkData: {},
    selectLink: {
      type: Function,
      default: null,
    },
    dataKey: {
      type: String,
      default: 'link_id',
    },
  },
  data() {
    return {
      currentLinkData: {},
      // activatedLinkData: [],
      arrowIcon: require('@/assets/img/angle-arrow-down.png'),
    };
  },
  created() {
    // // sample code
    // if (this.roadLinkData.length >= 2) {
    //     this.currentLinkData = this.roadLinkData[1]
    //     console.log(this.currentLinkData)
    //     this.activatedLinkData.push(this.roadLinkData[0])
    // }
  },
  mounted() {
    this.$store.commit('dkStraightRoad/setDataKey', this.dataKey);
  },
  watch: {
    roadLinkData(newVal) {
      // console.log(newVal)
      this.$emit('onLoadRoadLinkData', newVal);
    },
    activatedLinkData(newVal) {
      console.log(newVal);
    },
  },
  computed: {
    activatedLinkData() {
      return this.$store.getters['dkStraightRoad/getActivatedLinkData'];
    },
  },
  methods: {
    checkActivatedLinkData(item) {
      let data = this.activatedLinkData.find((dt) => {
        return dt[this.dataKey].toString() === item[this.dataKey].toString();
      });

      if (data) {
        return true;
      } else {
        return false;
      }
    },
    selectLinkData(linkData) {
      if (this.selectLink) {
        this.selectLink(linkData);
      }
    },
    toggleActivateLinkData(linkData) {
      this.$store.commit('dkStraightRoad/setActivatedLinkData', linkData);
      // let tmpData = this.activatedLinkData.find((dt) => {
      //   return dt[this.dataKey] === linkData[this.dataKey];
      // });
      // let param = {};
      // param[this.dataKey] = linkData[this.dataKey];
      // if (tmpData) {
      //   _.remove(this.activatedLinkData, param);
      // } else {
      //   this.activatedLinkData.push(linkData);
      //   this.activatedLinkData = _.sortBy(this.activatedLinkData, 'seq');
      // }
      this.$forceUpdate();
      // console.log(this.$store.getters['dkStraightRoad/getActivatedLinkData']);
      this.$emit('updateActivateLinkData');
    },
    removeLinkData(linkData) {
      this.$store.commit('dkStraightRoad/setActivatedLinkData', linkData);
      this.$forceUpdate();
      this.$emit('updateActivateLinkData');
    },
    focusLinkData(linkData) {
      if (!linkData) {
        return;
      }
      let param = {};
      param[this.dataKey] = linkData[this.dataKey];
      let idx = _.findIndex(this.roadLinkData, param);

      var container = this.$parent.$el;
      setTimeout(() => {
        if (idx > 2) {
          container.scrollTop = (idx - 2) * 57.6;
        } else {
          container.scrollTop = 0;
        }
      }, 100);
      this.currentLinkData = linkData;
      this.$forceUpdate();
    },
    getRoadData() {
      return;
    },
    setActivatedLinkData(linkData) {
      // this.activatedLinkData = linkData;
      // this.focusLinkData(linkData);
      // this.$forceUpdate();
    },
    // getActivatedLinkData() {
    //   return this.activatedLinkData;
    // },
  },
};
</script>

<style scoped>
.wrap {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.dir-arrow {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  bottom: -1px;
  background-color: green;
  display: flex;
  border-bottom: 1px solid #fff;
  flex-direction: column-reverse;
}

.dir-arrow img {
  width: 60%;
  margin: 0 auto 2px auto;
}

.straight-wrap {
  width: 100%;
  border: 0;
  padding: 0px;
  background: #f5f5f5;
  list-style: none;
  overflow: hidden;
  margin: 0;
}

.straight-cell {
  box-sizing: border-box;
  position: relative;
  border-bottom: 1px solid #cfcfcf;
  cursor: pointer;
}
.straight-cell:hover {
  background-color: rgb(195, 232, 255);
}

.straight-cell.active {
  background-color: lightskyblue;
}

.straight-wrap > .straight-cell > a {
  color: #555;
  box-sizing: border-box;
  text-decoration: none;
  margin-left: 60px;
  padding: 14px 10px 14px 10px;
  position: relative;
  display: block;
}

.straight-cell .link-id {
  margin: 0;
  color: #aaa;
  font-size: 12px;
}

.active-button {
  position: absolute;
  right: 5px;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.no-road-link-data {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

/* .straight-wrap > .straight-cell > a:before {
  content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 30px solid #ddd;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    margin-left: 1px;
    left: 100%;
    z-index: 1;
  }

  .straight-wrap > .straight-cell > a:after {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 30px solid #f5f5f5;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    left: 100%;
    z-index: 2;
  }

  .straight-wrap > li:first-child > a {
    padding-left: 15px !important;
  }

  .straight-wrap > .straight-cell > a.selected {
    border-color: #b1dfbb !important;
    color: #fff !important;
    background: #b1dfbb !important;
  }

  .straight-wrap > .straight-cell > a.selected:after {
    border-left: 30px solid #b1dfbb !important;
  }

  .straight-wrap > .straight-cell > a.active {
    border-color: #5cb85c !important;
    color: #fff !important;
    background: #5cb85c !important;
  }

  .straight-wrap > .straight-cell > a.active:after {
    border-left: 30px solid #5cb85c !important;
  }   */

/* .f-node-item {
    position:absolute;
    left:-50px;
    width:100px;
    top:50px;
    text-align:center;
  }
  .t-node-item {
    position:absolute;
    right:-50px;
    width:100px;
    top:50px;
    text-align:center;
  } */
.t-node-item,
.link-id {
  margin: 0;
}

.t-node-item {
  font-weight: bold;
  font-size: 14px;
}
</style>
