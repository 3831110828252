import axios from 'axios';

// import _ from 'lodash';

export default {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    // 여기부턴 내가 맹금
    getHeader({ state }, payload) {
      return new Promise((resolve, reject) =>
        axios
          .get(`${this.state.dkConfig.getServerConfig().core.api}/core/api/fcltExcel/getHeader`, { params: payload })
          .then((result) => resolve(result.data))
          .catch((error) => reject(error))
      );
    },
    getData({ state }, payload) {
      return new Promise((resolve, reject) =>
        axios
          .get(`${this.state.dkConfig.getServerConfig().core.api}/core/api/fcltExcel/getData`, { params: payload })
          .then((result) => resolve(result.data))
          .catch((error) => reject(error))
      );
    },
    saveData({ state }, payload) {
      return new Promise((resolve, reject) =>
        axios
          .post(`${this.state.dkConfig.getServerConfig().core.api}/core/api/fcltExcel/saveData`, payload)
          .then((result) => resolve(result.data))
          .catch((error) => reject(error))
      );
    },
  },
  mutations: {},
};
