var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.formTitle,
        visible: _vm.formDialog,
        "close-on-click-modal": false,
        id: "formLedBrightnessControl",
        width: "420px"
      },
      on: {
        "update:visible": function($event) {
          _vm.formDialog = $event
        },
        close: _vm.hideDialog
      }
    },
    [
      _c("div", { staticClass: "wrap" }, [
        _c("table", { staticClass: "el-table el-table--mini" }, [
          _c("colgroup", [
            _c("col", { staticStyle: { width: "130px" } }),
            _c("col")
          ]),
          _c("tr", [
            _c("th", [_vm._v("LED 시설물 명칭")]),
            _c("td", [
              _vm.ledFclt
                ? _c("span", [_vm._v(_vm._s(_vm.ledFclt.fcltName))])
                : _vm._e()
            ])
          ]),
          _c("tr", [
            _c("th", [_vm._v("설치 주소")]),
            _c("td", [
              _vm.ledFclt
                ? _c("span", [_vm._v(_vm._s(_vm.ledFclt.setNumberAddr))])
                : _vm._e()
            ])
          ]),
          _c("tr", [
            _c("th", [_vm._v("LED 유형")]),
            _c("td", [
              _vm.ledFclt
                ? _c("span", [
                    _vm._v(
                      _vm._s(_vm.getLedTypeText(_vm.ledFclt)) +
                        " / " +
                        _vm._s(_vm.getLedSize(_vm.ledFclt))
                    )
                  ])
                : _vm._e()
            ])
          ])
        ]),
        _c(
          "div",
          { staticClass: "brightness-config" },
          [
            _c(
              "el-form",
              { attrs: { "label-position": "top" } },
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "brightness-form",
                    attrs: { label: "밝기 값" }
                  },
                  [
                    _c("el-slider", {
                      attrs: {
                        min: 0,
                        max: 10,
                        "show-stops": "",
                        "show-input": ""
                      },
                      model: {
                        value: _vm.brightnessValue,
                        callback: function($$v) {
                          _vm.brightnessValue = $$v
                        },
                        expression: "brightnessValue"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.setBrightness } },
            [_vm._v("밝기 설정")]
          ),
          _c("el-button", { on: { click: _vm.hideDialog } }, [_vm._v("취소")])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }