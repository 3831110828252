<template>
  <el-dialog
    :title="formTitle"
    :visible.sync="formDialog"
    @close="hideDialog"
    :close-on-click-modal="false"
    class="el-dialog-aside"
    append-to-body
    fullscreen
  >
    <el-container class="dialog-container maintnce-container">
      <el-container>
        <el-form class="form-maintnce" ref="form1" size="medium" :model="formData" label-position="top" v-if="formData">
          <el-row>
            <el-form-item label="사용자 ID" prop="userid">
              <!-- <el-input v-model="formData.userid" placeholder="사용자 Id"></el-input> -->
              <el-input v-model="formData.userid" placeholder="사용자 Id" @input="inputId"></el-input>
              <!-- <el-input v-model="formData.userid" placeholder="사용자 Id" :disabled="true"></el-input> -->
              <!-- <label> {{ formData.userid }} </label> -->
              <!-- <span>{{ formData.userid }}</span> -->
              <!-- {{ formData.userid }} -->
            </el-form-item>
            <el-col :span="24">
              <el-form-item label="이름" prop="name">
                <el-input v-model="formData.name" placeholder="사용자 이름"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="그룹" prop="groupName">
                <el-select v-model="formData.group" placeholder="사용자 그룹" style="width: 100%;" :disabled="true">
                  <el-option v-for="group in groupList" :key="group.groupId" :label="group.groupName" :value="group.groupId"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="전화번호" prop="phone">
                <el-input v-model="formData.phone" placeholder="사용자 전화번호" @input="inputphone" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="이메일" prop="email">
                <el-input v-model="formData.email" placeholder="사용자 이메일"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <div class="ttt" style="color: #606266; font-size: 14px; line-height: 40px;">프로필 사진</div>
              <dk-el-file-upload
                ref="uploadImage"
                uploaderType="image"
                listType="picture-card"
                path="user"
                :fileList="formData.images"
                :onPreview="handlePictureCardPreview"
                :limit="1"
              >
                <div>
                  <i class="el-icon-plus"></i>
                </div>
              </dk-el-file-upload>
            </el-col>
          </el-row>
        </el-form>
      </el-container>
    </el-container>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="save">저장</el-button>
      <el-button @click="hideDialog">취소</el-button>
    </span>
    <el-dialog append-to-body :visible.sync="imagePreviewDialog">
      <img width="100%" :src="imagePreviewUrl" alt />
    </el-dialog>
  </el-dialog>
</template>

<script>
import dkgismain from '@/components/dkGis/dkGisMain.vue';
// import DkFileUtil from '@/components/dkFileUtil.js'
import dkElFileUpload from '@/components/dkElFileUpload.vue';
// import moment from 'moment';

export default {
  components: {
    'dk-gis': dkgismain,
    'dk-el-file-upload': dkElFileUpload,
  },
  data() {
    return {
      updateFlag: false,
      formDialog: false,
      formTitle: '유저 정보 수정',
      groupList: [],
      userid: '',
      formData: {
        userid: '',
        name: '',
        group: '',
        phone: '',
        email: '',
        images: [],
      },

      imagePreviewDialog: false,
      imagePreviewUrl: '',
      userList: [],
      activeUser: this.$dkRouter.getUserInfo(),
      markerData: [],
      clusterData: [],
      searchData: [],
      timeout: null,
      fcltType: [],
      searchFcltTypeId: [],

      level: 5,
    };
  },
  created() {
    let me = this;
  },
  mounted() {
    this.resetData();
    // me.initData();
  },
  computed: {},
  methods: {
    inputId(a, b, c) {
      this.formData.userid = this.userid;
    },
    inputphone(input) {
      input = input.replace(/[^0-9]/g, '');
      let tmp = '';
      if (input.length < 4) {
        this.formData.phone = input;
        return;
      } else if (input.length < 7) {
        tmp += input.substr(0, 3);
        tmp += '-';
        tmp += input.substr(3);
        this.formData.phone = tmp;
        return;
      } else if (input.length < 11) {
        tmp += input.substr(0, 3);
        tmp += '-';
        tmp += input.substr(3, 3);
        tmp += '-';
        tmp += input.substr(6);
        this.formData.phone = tmp;
        return;
      } else {
        tmp += input.substr(0, 3);
        tmp += '-';
        tmp += input.substr(3, 4);
        tmp += '-';
        tmp += input.substr(7);
        this.formData.phone = tmp;
        return;
      }
    },
    async initData() {
      try {
        // const { userid, name, group, phone, email, images = [] } = this.$dkRouter.getUserInfo();
        const { userid, phone, email } = this.$dkRouter.getUserInfo();
        // const {userid} = this.$dkRouter.getUserInfo();
        this.userid = userid;
        const response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/user`, {
          search: { userid },
        });

        if (response.status !== 200) {
          throw new Error('');
        }

        const { name, group, images } = response.data.result[0] || {};

        // const formData = response.data.result[0];

        this.formData = {
          userid,
          name,
          group,
          images,
          phone,
          email,
        };

        return true;
      } catch (err) {
        // this.$message({
        //   type: 'error',
        //   message: 'initData fail',
        // });
        return false;
      }
    },

    async getGroupList() {
      try {
        const response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/menus`);
        if (response.status !== 200) {
          throw new Error('getGroupList faile');
        }
        this.groupList = response.data.map((elm) => {
          const { menuId, name } = elm;
          return {
            groupId: menuId,
            groupName: name,
          };
        });
      } catch (err) {
        // this.$message({
        //   type: 'error',
        //   message: 'getGroupList faile',
        // });
      }
    },

    save() {
      let me = this;
      this.$refs.form1.validate(async (valid) => {
        try {
          if (!valid) {
            return false;
          }
          const { userid, name, group, phone, email, images } = me.formData;
          const gInfo = me.groupList.find((elm) => elm.groupId === group) || {};
          const groupName = gInfo.groupName;
          me.formData.images = me.$refs.uploadImage.getFileData();

          await me.$http.post(`${this.$config.getServerConfig().core.api}/core/api/user/update`, {
            data: {
              ...me.formData,
              groupName,
            },
          });

          // let refreshToken = this.$store.getters['auth/getRefreshToken'];
          // let activeUser = this.$store.getters['auth/getAccessToken'];
          // this.$dkRouter.getMobileActiveUser()

          // 유저 정보 변경으로 인한 토근 변경에 대처하는 방법?
          me.$dkRouter.setUserInfo();
          me.hideDialog();
          me.$message.success('유저정보 수정 완료');
        } catch (error) {
          this.hideDialog();
          console.log(error);
          this.$message.error(`유저정보 수정 중 에러 발생`);
        }
      });
    },

    async showDialog() {
      let me = this;
      try {
        await this.getGroupList();

        await this.initData();

        this.formDialog = true;
      } catch (err) {}
    },

    hideDialog() {
      this.resetData();
      this.formDialog = false;
    },
    isOpenDialog() {
      return this.formDialog;
    },
    resetData() {
      this.updateFlag = false;

      if (this.$refs.form1) this.$refs.form1.resetFields();

      if (this.$refs.uploadImage) this.$refs.uploadImage.setFileData([]);

      this.searchData = [];
    },

    handlePictureCardPreview(file) {
      this.imagePreviewUrl = file.url;
      this.imagePreviewDialog = true;
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 768px) {
  .maintnce-container {
    flex-direction: column;
  }
  .maintnce-container aside {
    width: 100% !important;
  }
}

.form-maintnce {
  box-sizing: border-box;
  padding: 15px;
  width: 100%;
}

.maintnce-image {
  cursor: pointer;
  padding: 0 2px 4px 2px;
  text-align: center;
}

.maintnce-image:hover > img {
  opacity: 0.3;
}

.maintnce-image > img {
  height: auto;
  max-height: 120px;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  width: auto;
}

.form-maintnce-dialog {
  background-color: #edf1f2 !important;
  padding: 20px;
}
.el-table__header-wrapper {
  display: none;
}

.el-upload--picture-card {
  display: none !important;
}
</style>
