<template>
  <el-dialog
    :title="formTitle"
    :visible.sync="formDialog"
    @close="hideDialog"
    :close-on-click-modal="false"
    top="5vh"
    class="el-dialog-aside"
    append-to-body
    fullscreen
  >
    <!-- <div class="gis-wrap" v-if="!$isMobile"> -->
    <div class="gis-wrap">
      <!-- <dk-gis 
        ref="dkGis" 
        style="height: 280px" 
        :gisApiInfo="gisApiInfo" 
        :markerData="markerData" 
        :level="level">
      </dk-gis> -->
      <dk-gis v-show="formDialog" ref="dkGis" style="height: 280px;" :level="level" :gisApiInfo="gisApiInfo" :apiLoadComplete="apiLoadComplete" />
      <div class="mygis-location btn-style spread-location" @click="initMarkerPosition">
        <i class="icon-target"></i>
        <div>현재위치</div>
      </div>
    </div>

    <el-container class="dialog-container maintnce-container">
      <el-container>
        <el-form class="form-maintnce" :rules="rules" ref="form1" size="medium" :model="formData" label-position="top">
          <el-row>
            <el-col :span="24">
              <el-form-item label="유지관리 대상 시설물" prop="fcltName" v-if="!$isMobile">
                <el-input v-model="formData.fcltName"> </el-input>
                <!-- <el-autocomplete
                  v-model="formData.fcltName"
                  :fetch-suggestions="searchFcltData"
                  @select="applySearchData"
                  placeholder="Search"
                  value-key="fcltName"
                  ref="searchFclt"
                ></el-autocomplete>                 -->
              </el-form-item>
              <!-- <h3 v-else>{{ formData.fcltName }}</h3> -->
            </el-col>
            <el-col :span="12" v-show="false">
              <el-form-item label="작업 유형" prop="workType">
                <el-select v-model="formData.workType" placeholder="작업 유형" style="width: 90%;">
                  <el-option v-for="(work, index) in workTypes" :key="index" :label="work.label" :value="work.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-show="false">
              <el-form-item label="중요도" prop="importance">
                <el-select v-model="formData.importance" placeholder="중요도" style="width: 100%;">
                  <el-option v-for="(imp, index) in importances" :key="index" :label="imp.label" :value="imp.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="요약" prop="maintnceSummary">
                <el-input v-model="formData.maintnceSummary" placeholder="상황전파 내용 요약"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="설명" prop="maintnceDesc">
                <el-input v-model="formData.maintnceDesc" placeholder="내용 설명" type="textarea" :rows="5"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <div style="color: #606266; font-size: 14px; line-height: 40px;">상황전파 관련 사진</div>
              <dk-el-file-upload
                ref="uploadImage"
                uploaderType="image"
                listType="picture-card"
                path="maintnce"
                :fileList="formData.files"
                :onPreview="handlePictureCardPreview"
              >
                <div>
                  <i class="el-icon-plus"></i>
                </div>
              </dk-el-file-upload>
            </el-col>
          </el-row>
        </el-form>
      </el-container>
      <el-aside width="280px">
        <div class="info-panel">
          <el-form class="form-maintnce" ref="form2" size="small" :model="formData" label-position="top">
            <el-row>
              <el-col :span="24">
                <el-form-item label="진행상황" prop="status" v-show="false">
                  <el-select v-model="formData.status" placeholder="작업 진행상황">
                    <el-option v-for="(status, index) in maintnceSteps" :key="index" :label="status.label" :value="status.status"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="담당자" prop="managerId">
                  <el-select v-model="formData.managerId" placeholder="담당자를 선택하세요">
                    <el-option v-for="(user, index) in userList" :key="index" :label="user.name" :value="user.userid"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="작업자" prop="workerId" v-show="false">
                  <el-select v-model="formData.workerId" placeholder="작업자를 지정할 경우 선택">
                    <el-option v-for="(user, index) in userList" :key="index" :label="user.name" :value="user.userid"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="작업완료 예정일" prop="completePlanDate" v-show="false">
                  <el-date-picker type="date" v-model="formData.completePlanDate" placeholder="완료일을 지정 할 경우 선택"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="종료일" prop="endDate" v-show="false">
                  <el-date-picker type="date" v-model="formData.endDate" placeholder="종료일을 수정 할 경우 선택"></el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-aside>
    </el-container>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="save">저장</el-button>
      <el-button @click="hideDialog">취소</el-button>
    </span>
    <el-dialog append-to-body :visible.sync="imagePreviewDialog">
      <img width="100%" :src="imagePreviewUrl" alt />
    </el-dialog>

    <!-- <el-dialog 
            title="유지관리 처리 사진"
            :visible.sync="maintnceImageDialog"
            append-to-body
        >
            <el-carousel 
                v-if="maintnceImage && maintnceImage.length > 0"                
                height="400px"
                ref="maintnceImageCarousel"
                :initial-index="activeMaintnceImageIndex"
                >
                <el-carousel-item
                    v-for="(file, index) in maintnceImage"
                    :key="index"                    
                >
                    <div class="dk-carousel-item">
                        <img :src="file.image.serverFilePath">
                        <div class="dk-carousel-item-label">
                            {{file.msg}}
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>         
    </el-dialog>-->
  </el-dialog>
</template>

<script>
import dkgismain from '@/components/dkGis/dkGisMain.vue';
// import DkFileUtil from '@/components/dkFileUtil.js'
import dkElFileUpload from '@/components/dkElFileUpload.vue';
import moment from 'moment';
import _ from 'lodash';

export default {
  components: {
    'dk-gis': dkgismain,
    'dk-el-file-upload': dkElFileUpload,
  },
  data() {
    return {
      formDialog: false,
      createMapFlag: false,
      formTitle: '유지관리 등록',
      formData: {
        id: '',
        fcltId: '',
        fcltName: '',
        fcltTypeId: '',
        fcltTypeName: '',
        maintnceSummary: '',
        maintnceDesc: '',
        status: 'step1',
        startDate: new Date(),
        endDate: null,
        completePlanDate: null,
        managerId: '',
        managerName: '',
        workerId: '',
        workerName: '',
        workComment: [],
        files: [],
      },
      maintnceImageDialog: false,
      activeMaintnceImageIndex: 0,
      rules: {
        fcltName: [
          {
            required: true,
            message: '유지관리 대상 시설물은 선택되어야 합니다.',
            trigger: ['change', 'blur'],
          },
        ],
        maintnceSummary: [
          {
            required: true,
            message: '유지관리 요약한 내용을 입력해주세요',
            trigger: ['change', 'blur'],
          },
        ],
        workType: [
          {
            required: true,
            message: '작업 유형을 선택해주세요',
            trigger: ['change', 'blur'],
          },
        ],
        importance: [
          {
            required: true,
            message: '유지관리 작업 중요도를 선택해주세요',
            trigger: ['change', 'blur'],
          },
        ],
      },
      rules2: {
        status: [{ required: true, message: '', trigger: 'blur' }],
      },
      // maintnceSteps: this.$config.getSiteConfig().maintnceStatus,
      maintnceSteps: {},
      workTypes: [
        { value: '1', label: '점검' },
        { value: '2', label: '수리' },
        { value: '3', label: '교체' },
        { value: '4', label: '기타', default: true },
      ],
      importances: [
        { value: '1', label: '매우높음' },
        { value: '2', label: '높음' },
        { value: '3', label: '보통', default: true },
        { value: '4', label: '낮음' },
        { value: '5', label: '매우낮음' },
      ],
      imagePreviewDialog: false,
      imagePreviewUrl: '',
      userList: [],
      activeUser: this.$dkRouter.getUserInfo(),
      markerData: [],
      clusterData: [],
      gisApiInfo: null,
      searchData: [],
      timeout: null,
      fcltType: [],
      searchFcltTypeId: [],

      level: 5,

      lat: 35.53889,
      lng: 129.31667,
    };
  },
  created() {
    let me = this;

    (async () => {
      me.gisApiInfo = me.$store.getters['gis/getGisApi'];
      if (!me.gisApiInfo.length || me.gisApiInfo.length.length === 0) {
        await me.$store.dispatch('gis/actGisApi', {});
        me.gisApiInfo = me.$store.getters['gis/getGisApi'];
      }
      me.gisApiInfo[0].apiKey = me.$config.getSiteConfig().gis.apiKey;
    })();

    let ll = this.$config.getSiteConfig().maintnceStatus;
    this.maintnceSteps = _.filter(ll, (item) => {
      return item.status !== 'finish';
    });
    // this.getFcltTypes();

    (async () => {
      try {
        const coods = await me.getGeolocation();
        me.lat = coods.lat;
        me.lng = coods.lng;
      } catch (err) {
        me.$message({
          type: 'warnning',
          message: 'GPS 기능을 지원하지 않는 상태 입니다.',
        });
      }
      me.gisApiInfo[0].geometry.lat = me.lat;
      me.gisApiInfo[0].geometry.lng = me.lng;
    })();
  },
  mounted() {
    this.resetData();
    this.getUserList();
    this.activeUser = this.$dkRouter.getUserInfo();

    this.showDialog();
  },
  computed: {},
  methods: {
    sleep(time) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, time);
      });
    },
    async gisInit() {
      while (true) {
        try {
          if (!this.createMapFlag) throw '';
          this.$refs.dkGis.getLevel();
          break;
        } catch (err) {
          await this.sleep(50);
          continue;
        }
      }
      this.$refs.dkGis.setLevel(this.level);
      this.createMarkerLayer();
      this.initMarkerPosition();
    },
    async apiLoadComplete(args) {
      // this.gisInit();
      this.createMapFlag = true;
      args;
    },
    async getUserList() {
      let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/user`, {});
      this.userList = response.data.docs;
    },
    getUserName(userid) {
      let aa = this.userList.find((user) => {
        return user.userid === userid;
      });
      return aa.name;
    },

    getGeolocation() {
      let me = this;

      return new Promise((resolve, reject) => {
        // if ('geolocation' in navigator) {
        if (navigator.geolocation) {
          /* 지오로케이션 사용 가능 */
          navigator.geolocation.getCurrentPosition(
            (position) => {
              return resolve({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              });
            },
            (err) => {
              // alert('GPS 기능을 지원하지 않는 상태 입니다.');
              return reject();
            }
          );
        } else {
          // const centerCoords = me.$refs.dkGis.getCenter();
          // return resolve({
          //   lat: centerCoords.lat,
          //   lng: centerCoords.lng,
          // });
          // alert('GPS 기능을 지원하지 않습니다.');
          return reject();
        }
      });
    },

    async initMarkerPosition() {
      const position = await this.getGeolocation();
      const { lat, lng } = position;
      this.$refs.dkGis.setCenter({ lat, lng });
      this.marker.setLatLng({ lat, lng });
    },

    save() {
      let me = this;
      this.$refs.form1.validate(async (valid) => {
        try {
          this.formData.startDate = new Date();
          this.formData.files = this.$refs.uploadImage.getFileData();
          if (this.formData.managerId) {
            this.formData.managerName = this.getUserName(this.formData.managerId);
          }
          if (this.formData.workerId) {
            this.formData.workerName = this.getUserName(this.formData.workerId);
          }
          const { lat, lng } = this.marker.getLatLng();

          if (valid) {
            let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/ulsanBukgu/api/spreadSituation/add`, {
              data: {
                ...me.formData,
                gisLat: lat,
                gisLng: lng,
              },
            });

            if (response.status !== 200) {
              throw new Error('유지관리 등록 중 에러 발생');
            }

            this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/pushAlram/send`, {
              content: this.formData.maintnceSummary,
              channelId: 'maintnce',
            });

            me.$emit('procDataComplete', this.formData);
            me.$message({
              type: 'success',
              message: `유지관리 등록 완료`,
            });
            me.hideDialog();
          } else {
            return false;
          }
        } catch (error) {
          this.hideDialog();
          console.log(error);
          this.$message.error(`유지관리 등록 중 에러 발생`);
        }
      });
    },
    remove() {
      let me = this;
      this.$confirm('현재 유지관리 작업을 삭제합니까? 삭제한 작업은 복구할 수 없습니다.', '경고', {
        confirmButtonText: '네',
        cancelButtonText: '아니오',
        type: 'danger',
      })
        .then(async () => {
          try {
            let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/ulsanBukgu/api/spreadSituation/delete`, { data: this.formData });
            me.$emit('procDataComplete', this.formData);
            me.hideDialog();
            me.$message({
              type: 'success',
              message: `유지관리 작업 삭제 완료`,
            });
          } catch (error) {
            this.hideDialog();
            this.$message.error(`유지관리 작업 삭제 중 에러 발생`);
          }
        })
        .catch(() => {
          me.$message.info(`취소합니다`);
        });
    },
    showDialog() {
      // this.hideDialog();
      this.formTitle = '상황 전파';
      this.formData.status = 'step1';
      this.formData.managerId = this.activeUser.userid;
      this.formDialog = true;
      this.$forceUpdate();

      this.gisInit();
    },
    hideDialog() {
      this.resetData();
      this.formDialog = false;
      this.$parent.$emit('closeFormSpreadSituation');
    },
    isOpenDialog() {
      return this.formDialog;
    },
    resetData() {
      this.formData = {
        id: '',
        fcltId: '',
        fcltName: '',
        fcltTypeId: '',
        fcltTypeName: '',
        maintnceSummary: '',
        maintnceDesc: '',
        status: 'step1',
        workType: '4',
        importance: '3',
        startDate: new Date(),
        endDate: null,
        completePlanDate: null,
        managerId: '',
        workerId: '',
        workComment: [],
        files: [],
      };
      this.formTitle = '유지관리 등록';
      if (this.$refs.form1) this.$refs.form1.resetFields();

      if (this.$refs.form2) this.$refs.form2.resetFields();

      if (this.$refs.uploadImage) this.$refs.uploadImage.setFileData([]);

      this.searchData = [];
      try {
        this.markerLayer.removeAllMarker();
      } catch (err) {}
      this.markerLayer = undefined;
      this.marker = undefined;
    },

    handlePictureCardPreview(file) {
      this.imagePreviewUrl = file.url;
      this.imagePreviewDialog = true;
    },
    checkConfirmUser() {
      if (this.activeUser.userid == this.formData.managerId) {
        return true;
      } else if (this.activeUser.group == 'master') {
        return true;
      } else {
        return false;
      }
    },

    createMarkerLayer() {
      const { lat, lng } = this;
      const key = new Date().getTime();

      const mouseEvent = {};
      // mouseEvent.click
      this.markerLayer = this.$refs.dkGis.createMarkerLayer();

      this.markerLayer.addMarker({
        key,
        lat,
        lng,
        draggable: true,
        // event: mouseEvent,
        // properties: {},
      });

      const { marker } = this.markerLayer.findMarker(key);
      this.marker = marker;
    },
  },
};
</script>

<style scoped>
.gis-wrap {
  position: relative;
}

@media only screen and (max-width: 768px) {
  .maintnce-container {
    flex-direction: column;
  }
  .maintnce-container aside {
    width: 100% !important;
  }
}

.form-maintnce {
  box-sizing: border-box;
  padding: 15px;
  width: 100%;
}

.maintnce-image {
  cursor: pointer;
  padding: 0 2px 4px 2px;
  text-align: center;
}

.maintnce-image:hover > img {
  opacity: 0.3;
}

.maintnce-image > img {
  height: auto;
  max-height: 120px;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  width: auto;
}

.form-maintnce-dialog {
  background-color: #edf1f2 !important;
  padding: 20px;
}

.spread-location {
}
</style>
