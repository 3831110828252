var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dk-form" },
    [
      _vm._l(_vm.sortingTemplateData, function(item, idx) {
        return _c("dk-form-item", {
          key: idx,
          ref: "formItem",
          refInFor: true,
          attrs: {
            idx: idx,
            formData: item,
            formType: _vm.formType,
            dataKey: item.dataKey,
            size: _vm.size
          },
          on: {
            commitFormItem: _vm.commitFormItem,
            swapToUp: _vm.swapToUp,
            swapToDown: _vm.swapToDown,
            removeItem: _vm.removeFormItem
          }
        })
      }),
      _vm.formType === "template"
        ? _c("div", { staticClass: "dk-form-row bottom" }, [
            _c("div", { staticClass: "dk-form-label" }, [
              _c("span", { on: { click: _vm.addNewDkFormItem } }, [
                _c("i", { staticClass: "fas fa-plus" }),
                _vm._v("속성 추가 ")
              ])
            ])
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }