import axios from '../components/axios';

export default {
  namespaced: true,
  state: {
    gisApi: {
      value: [],
    },
    fcltMarker: {
      value: null,
    },
    clusterData: {
      value: null,
    },
    isOpenInforWindow: false,
  },
  getters: {
    getGisApi: (state) => {
      return state.gisApi.value;
    },
    getFcltMarker: (state) => {
      return state.fcltMarker.value;
    },
    getClusterData: (state) => {
      return state.clusterData.value;
    },
    getIsOpenInfoWindow: (state) => {
      return state.isOpenInforWindow;
    },
  },
  mutations: {
    setGisApi(state, payload) {
      state.gisApi.value = payload;
      //localStorage.setItem('gisApi', payload)
    },
    setFcltMarker(state, payload) {
      state.fcltMarker.value = payload;
    },
    setClusterData(state, payload) {
      state.clusterData.value = payload;
    },
    setIsOpenInfoWindow(state, payload) {
      state.isOpenInforWindow = payload;
    },
  },
  actions: {
    actGisApi({ commit }, payload) {
      let tmp = [];
      return axios.get(`${this.state.dkConfig.getServerConfig().core.api}/core/api/gis/getApiInfo`, payload).then((response) => {
        response.data.forEach((gisInfo) => {
          // GIS API 유형별 옵션 설정
          if (gisInfo.gisName == 'kakao') {
            gisInfo.gisOption = {
              libraries: 'services,drawing,clusterer',
            };
          } else if (gisInfo.gisName == 'google') {
            gisInfo.gisOption = {
              callback: 'dkGisInit',
            };
          }
          tmp.push(gisInfo);
        });
        commit('setGisApi', tmp);
      });
    },
    actGetFcltMarker({ commit }, payload) {
      if (!payload) payload = {};
      let activeUser = this.getters['auth/getActiveUser'];
      if (activeUser && activeUser.userid) {
        payload.userid = activeUser.userid;
        payload.group = activeUser.group;
      }
      return axios.get(`${this.state.dkConfig.getServerConfig().core.api}/core/api/gis/getFcltMarker`, { params: payload }).then((response) => {
        // let arr = [];
        // response.data.forEach(data => {
        //   if (!data.draggable) {
        //     data.draggable = false;
        //   }
        //   arr.push(data);
        // })

        if (response.data.markerData) {
          commit('setFcltMarker', response.data.markerData);
          commit('setClusterData', []);
        }
        if (response.data.clusterData) {
          commit('setFcltMarker', []);
          commit('setClusterData', response.data.clusterData);
        }

        return response;
      });
    },
    actIsOpenInfoWindow({ commit }, payload) {
      commit('setIsOpenInfoWindow', payload.value);
    },
  },
};
