<template>
  <div ref="canvasWrap" class="led-canvas-wrap" @mousemove="mousemove">
    <canvas class="dummy-canvas" ref="dummyCanvas" :width="canvasWidth" :height="canvasHeight" :viewBox="canvasViewBox"></canvas>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      stroke="none"
      fill="none"
      overflow="hidden"
      preserveAspectRatio="none"
      class="editor-canvas"
      :style="canvasStyle"
      :width="canvasWidth"
      :height="canvasHeight"
      :viewBox="canvasViewBox"
      ref="ledMessageSvg"
    >
      <image-object
        v-for="item in ledMessageData.imageData"
        :key="item.objectId"
        :objectId="item.objectId"
        :objectData="item"
        :ratio="ratio"
        ref="imageObject"
      />
      <text-object
        v-for="item in ledMessageData.textData"
        :key="item.objectId"
        :objectId="item.objectId"
        :objectData="item"
        :ratio="ratio"
        :ledType="ledType"
        ref="textObject"
      />
    </svg>
  </div>
</template>

<script>
export const NONE = 0;
export const ADD_TEXT_OBJECT = 1;
export const ADD_IMAGE_OBJECT = 2;

export const actionsEnum = {
  None: 0,
  LeftResize: 1,
  TopResize: 2,
  RightResize: 3,
  BottomResize: 4,
  TopLeftResize: 5,
  BottomLeftResize: 6,
  TopRightResize: 7,
  BottomRightResize: 8,
  Move: 9,
};

const textObject = {
  template: `
        <g :id="objectId">
            <g :transform="getMatrix">
                <g transform="translate(0 0)">
                  <foreignObject :width="width" :height="height" x="0" y="0">
                    <div xmlns="http://www.w3.org/1999/xhtml" :style="[inputTextStyle]" ref="textInput" v-html="text"   @mousedown="mousedown(actionsEnum.Move, $event)"></div>
                  </foreignObject>                  
                </g>
                <g transform="translate(0 0)" v-show="isSelected">
                    <line x1="0" y1="0" :x2="width" y2="0" stroke="#90A4AE" stroke-width="1" stroke-dasharray="5,5" class="topDrawing" @mousedown="mousedown(actionsEnum.TopResize, $event)"/>
                    <line x1="0" :y1="height" :x2="width" :y2="height" stroke="#90A4AE" stroke-width="1" stroke-dasharray="5,5" class="bottomDrawing" @mousedown="mousedown(actionsEnum.BottomResize, $event)"/>
                    <line x1="0" y1="0" x2="0" :y2="height" stroke="#90A4AE" stroke-width="1" stroke-dasharray="5,5" class="leftDrawing" @mousedown="mousedown(actionsEnum.LeftResize, $event)"/>
                    <line :x1="width" y1="0" :x2="width" :y2="height" stroke="#90A4AE" stroke-width="1" stroke-dasharray="5,5" class="rightDrawing" @mousedown="mousedown(actionsEnum.RightResize, $event)"/>
                    <circle cx="0" cy="0" r="5" stroke="#E0E0E0" stroke-width="1" fill="#90A4AE" class="topLeftDrawing" @mousedown="mousedown(actionsEnum.TopLeftResize, $event)" />
                    <circle :cx="width" cy="0" r="5" stroke="#E0E0E0" stroke-width="1" fill="#90A4AE" class="topRightDrawing" @mousedown="mousedown(actionsEnum.TopRightResize, $event)"/>
                    <circle cx="0" :cy="height" r="5" stroke="#E0E0E0" stroke-width="1" fill="#90A4AE" class="bottomLeftDrawing"  @mousedown="mousedown(actionsEnum.BottomLeftResize, $event)"/>
                    <circle :cx="width" :cy="height" r="5" stroke="#E0E0E0" stroke-width="1" fill="#90A4AE" class="bottomRightDrawing" @mousedown="mousedown(actionsEnum.BottomRightResize, $event)"/>                
                </g>
            </g>
        </g>`,
  data() {
    return {
      objectType: 'text',
      x: 0,
      y: 0,
      width: 128,
      height: 64,
      text: 'TEXT',
      offsetX: 0,
      offsetY: 0,
      moveOffsetX: 0,
      moveOffsetY: 0,
      isSelected: false,
      actionsEnum: actionsEnum,
      currentAction: 0,
      fontFamily: 'NanumGothic',
      fontWeight: 'normal',
      fontColor: '#00ff00',
      fontSize: 0,
      textAlign: 'left',
      dispEffectCode: 24,
      dispEffectTime: 5,
    };
  },
  props: {
    ratio: {
      type: Number,
      default: 5,
    },
    objectId: {
      type: String,
      default: 'textObject-0',
    },
    objectData: {
      type: Object,
    },
    bmpColor: {
      type: String,
      default: '24bit',
    },
    ledType: {
      type: String,
      default: 'ctype',
    },
  },
  mounted() {
    this.setSize(32 * this.ratio, 16 * this.ratio);
    this.setFontSize(14 * this.ratio);
    this.$nextTick(() => {
      this.importData(this.objectData);
    });
  },
  watch: {
    objectData(newVal) {
      this.importData(newVal);
    },
  },
  computed: {
    getMatrix() {
      return `matrix(1, 0, 0, 1, ${this.x}, ${this.y})`;
    },
    inputTextStyle() {
      const style = {
        width: '100%',
        fontSize: this.fontSize + 'px',
        backgroundColor: 'transparent',
        border: 'none',
        fontWeight: this.fontWeight,
        color: this.fontColor,
        fontFamily: this.fontFamily ? this.fontFamily : 'NanumGothic',
        lineHeight: '110%',
        textAlign: this.textAlign,
        overflow: 'hidden',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
      };
      return style;
    },
  },
  methods: {
    selectObject() {
      this.isSelected = true;
      this.isEdit = true;
      this.$parent.$emit('selectObject', { obj: this, eventType: 'onMouse' });
    },
    selectObjectKey() {
      this.isSelected = true;
      this.isEdit = true;
      this.$parent.$emit('selectObject', { obj: this, eventType: 'onKey' });
    },
    mousedown(flag, event) {
      this.currentAction = flag;
      this.offsetX = this.x;
      this.offsetY = this.y;
      this.moveOffsetX = event.offsetX - this.x;
      this.moveOffsetY = event.offsetY - this.y;
      // this.offsetX = this.x;
      // this.offsetY = this.y;
      this.selectObject();
      event.stopPropagation();
      event.preventDefault();
    },
    deselectObject() {
      this.isSelected = false;
    },
    //contentEditable에 텍스트 태그를 치환하는 형태로 씀
    setTextDom(textDom) {
      this.text = textDom;
    },
    htmlSerialParsing() {
      let result = [];
      let htmlText = this.getEditDom().toString();
      let idx = 0;
      let loop = true;
      while (loop) {
        let chk = htmlText.substring(idx, htmlText.length);
        if (chk.charAt(0) === '<') {
          // 태그가 아닌건 짤라서 배열에 넣음
          if (idx > 0) {
            result.push({
              text: htmlText.substring(0, idx),
            });
          }

          // 태그는 dom object만들어서 pasing하고 삭제
          const div = document.createElement('div');
          div.innerHTML = chk;
          //console.log(div.children[0].innerText)
          if (div.children[0] && div.children[0].innerText && div.children[0].attributes[0]) {
            result.push({
              text: div.children[0].innerText,
              color: div.children[0].attributes[0].nodeValue,
            });
          }
          div.removeChild(div.children[0]);
          idx = -1;
          htmlText = div.innerHTML.toString();
        }
        idx++;
        if (idx >= htmlText.length) loop = false;
      }
      if (htmlText) {
        result.push({
          text: htmlText,
        });
      }
      return result;
    },
    exportData() {
      let result = {
        x: this.x / this.ratio,
        y: this.y / this.ratio,
        width: this.width / this.ratio,
        height: this.height / this.ratio,
        text: this.getEditDom(),
        fontSize: this.fontSize / this.ratio,
        fontFamily: this.fontFamily,
        fontWeight: this.fontWeight,
        fontColor: this.fontColor,
        objectId: this.objectId,
        textAlign: this.textAlign,
        imageWidth: this.$refs.textInput.innerText.length * (this.fontSize / this.ratio) + this.$refs.textInput.innerText.length * 1,
        dispEffectCode: this.dispEffectCode,
        dispEffectTime: this.dispEffectTime,
      };
      if (this.ledType === '3color') {
        result.serialData = this.htmlSerialParsing();
      }
      return result;
    },

    importData(data) {
      this.x = data.x * this.ratio || 0;
      this.y = data.y * this.ratio || 0;
      this.width = data.width * this.ratio || 32 * this.ratio;
      this.height = data.height * this.ratio || 16 * this.ratio;
      this.text = data.text || 'TEXT';
      this.fontSize = data.fontSize * this.ratio || 14 * this.ratio;
      this.fontFamily = data.fontFamily || 'NanumGothic';
      this.fontWeight = data.fontWeight || 'normal';
      this.fontColor = data.fontColor || '#00ff00';
      this.textAlign = data.textAlign || 'left';
      this.dispEffectCode = data.dispEffectCode || 26;
      this.dispEffectTime = data.dispEffectTime || 5;
    },
    getObjectId() {
      return this.objectId;
    },
    setPosition(x, y) {
      this.x = x;
      this.y = y;
    },
    setSize(width, height) {
      this.width = width;
      this.height = height;
    },
    getSize() {
      return {
        width: this.width,
        height: this.height,
      };
    },
    getWidth() {
      return this.width;
    },
    getHeight() {
      return this.height;
    },
    getObjectType() {
      return 'text';
    },
    getX() {
      return this.x;
    },
    getY() {
      return this.y;
    },
    getEditDom() {
      return this.$refs.textInput.innerHTML;
    },
    setEditDom(dom) {
      this.$refs.textInput.innerHTML = dom;
    },
    setTextAlign(align) {
      this.textAlign = align;
    },
    setFontFamily(fontFamily) {
      this.fontFamily = fontFamily;
    },
    getFontFamily() {
      return this.fontFamily;
    },
    setFontSize(fontSize) {
      this.fontSize = fontSize;
    },
    getFontSize() {
      return this.fontSize;
    },
    setFontColor(fontColor) {
      this.fontColor = fontColor;
    },
    getFontColor() {
      return this.fontColor;
    },
    setFontWeight(fontWeight) {
      this.fontWeight = fontWeight;
    },
    getFontWeight() {
      return this.fontWeight;
    },
    setDispEffectCode(code) {
      this.dispEffectCode = code;
    },
    setDispEffectTime(time) {
      this.dispEffectTime = time;
    },
    getDispEffectCode() {
      return this.dispEffectCode;
    },
    getDispEffectTime() {
      return this.dispEffectTime;
    },
  },
};

const imageObject = {
  // template: `
  //       <g :id="objectId">
  //           <g :transform="getMatrix">
  //               <g transform="translate(0 0)">
  //                   <image x="0" y="0" :width="width" :height="height" @mousedown="mousedown(actionsEnum.Move, $event)" :xlink:href="imageBynary"></image>
  //               </g>
  //               <g transform="translate(0 0)" v-show="isSelected">
  //                   <line x1="0" y1="0" :x2="width" y2="0" stroke="#90A4AE" stroke-width="1" stroke-dasharray="5,5" class="topDrawing" @mousedown="mousedown(actionsEnum.TopResize, $event)"/>
  //                   <line x1="0" :y1="height" :x2="width" :y2="height" stroke="#90A4AE" stroke-width="1" stroke-dasharray="5,5" class="bottomDrawing" @mousedown="mousedown(actionsEnum.BottomResize, $event)"/>
  //                   <line x1="0" y1="0" x2="0" :y2="height" stroke="#90A4AE" stroke-width="1" stroke-dasharray="5,5" class="leftDrawing" @mousedown="mousedown(actionsEnum.LeftResize, $event)"/>
  //                   <line :x1="width" y1="0" :x2="width" :y2="height" stroke="#90A4AE" stroke-width="1" stroke-dasharray="5,5" class="rightDrawing" @mousedown="mousedown(actionsEnum.RightResize, $event)"/>
  //                   <circle cx="0" cy="0" r="5" stroke="#E0E0E0" stroke-width="1" fill="#90A4AE" class="topLeftDrawing" @mousedown="mousedown(actionsEnum.TopLeftResize, $event)" />
  //                   <circle :cx="width" cy="0" r="5" stroke="#E0E0E0" stroke-width="1" fill="#90A4AE" class="topRightDrawing" @mousedown="mousedown(actionsEnum.TopRightResize, $event)"/>
  //                   <circle cx="0" :cy="height" r="5" stroke="#E0E0E0" stroke-width="1" fill="#90A4AE" class="bottomLeftDrawing"  @mousedown="mousedown(actionsEnum.BottomLeftResize, $event)"/>
  //                   <circle :cx="width" :cy="height" r="5" stroke="#E0E0E0" stroke-width="1" fill="#90A4AE" class="bottomRightDrawing" @mousedown="mousedown(actionsEnum.BottomRightResize, $event)"/>
  //               </g>
  //           </g>
  //       </g>`,
  template: `
        <g :id="objectId">
            <g :transform="getMatrix">
                <g transform="translate(0 0)">
                  <foreignObject :width="width" :height="height" x="0" y="0">
                    <div xmlns="http://www.w3.org/1999/xhtml" :style="[inputImageStyle]" ref="imageInput"  @mousedown="mousedown(actionsEnum.Move, $event)">
                      <img :src="inputImageUrl" style="width:100%;height:100%;display:inline-block;" @dragstart="imageDragCancle"/>
                    </div>
                  </foreignObject>
                </g>
                <g transform="translate(0 0)" v-show="isSelected">
                    <line x1="0" y1="0" :x2="width" y2="0" stroke="#90A4AE" stroke-width="1" stroke-dasharray="5,5" class="topDrawing" @mousedown="mousedown(actionsEnum.TopResize, $event)"/>
                    <line x1="0" :y1="height" :x2="width" :y2="height" stroke="#90A4AE" stroke-width="1" stroke-dasharray="5,5" class="bottomDrawing" @mousedown="mousedown(actionsEnum.BottomResize, $event)"/>
                    <line x1="0" y1="0" x2="0" :y2="height" stroke="#90A4AE" stroke-width="1" stroke-dasharray="5,5" class="leftDrawing" @mousedown="mousedown(actionsEnum.LeftResize, $event)"/>
                    <line :x1="width" y1="0" :x2="width" :y2="height" stroke="#90A4AE" stroke-width="1" stroke-dasharray="5,5" class="rightDrawing" @mousedown="mousedown(actionsEnum.RightResize, $event)"/>
                    <circle cx="0" cy="0" r="5" stroke="#E0E0E0" stroke-width="1" fill="#90A4AE" class="topLeftDrawing" @mousedown="mousedown(actionsEnum.TopLeftResize, $event)" />
                    <circle :cx="width" cy="0" r="5" stroke="#E0E0E0" stroke-width="1" fill="#90A4AE" class="topRightDrawing" @mousedown="mousedown(actionsEnum.TopRightResize, $event)"/>
                    <circle cx="0" :cy="height" r="5" stroke="#E0E0E0" stroke-width="1" fill="#90A4AE" class="bottomLeftDrawing"  @mousedown="mousedown(actionsEnum.BottomLeftResize, $event)"/>
                    <circle :cx="width" :cy="height" r="5" stroke="#E0E0E0" stroke-width="1" fill="#90A4AE" class="bottomRightDrawing" @mousedown="mousedown(actionsEnum.BottomRightResize, $event)"/>
                </g>
            </g>
        </g>`,
  mounted() {
    this.setSize(32 * this.ratio, 16 * this.ratio);
    this.$nextTick(() => {
      this.importData(this.objectData);
    });
  },
  watch: {
    objectData(newVal) {
      this.importData(newVal);
    },
  },
  computed: {
    getMatrix() {
      return `matrix(1, 0, 0, 1, ${this.x}, ${this.y})`;
    },
    inputImageStyle() {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`,
        //background: `url(${this.imageUrl}) no-repeat 50% 50% /contain #000`,
      };
    },
    inputImageUrl() {
      return this.imageUrl;
    },
  },
  data() {
    return {
      objectType: 'image',
      x: 0,
      y: 0,
      width: 128,
      height: 64,
      offsetX: 0,
      offsetY: 0,
      moveOffsetX: 0,
      moveOffsetY: 0,
      isSelected: false,
      imageBynary: '',
      actionsEnum: actionsEnum,
      currentAction: 0,
      imageUrl: '',
      dispEffectCode: 0,
      dispEffectTime: 5,
    };
  },
  props: {
    ratio: {
      type: Number,
      default: 5,
    },
    objectId: {
      type: String,
      default: 'imageObject-0',
    },
    objectData: {
      type: Object,
    },
    bmpColor: {
      type: String,
      default: '24bit',
    },
  },
  methods: {
    getImageBynary(imageUrl) {
      let me = this;
      this.toDataUrl(imageUrl, function (dataUrl) {
        me.imageBynary = dataUrl;
      });
    },
    toDataUrl(src, callback, outputFormat) {
      var img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = function () {
        var canvas = document.createElement('CANVAS');
        var ctx = canvas.getContext('2d');
        var dataURL;
        canvas.height = this.naturalHeight;
        canvas.width = this.naturalWidth;
        ctx.imageSmoothingEnabled = false;
        ctx.drawImage(this, 0, 0);
        dataURL = canvas.toDataURL(outputFormat);
        callback(dataURL);
      };
      img.src = src;
      if (img.complete || img.complete === undefined) {
        img.src = 'data:image/png;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
        img.src = src;
      }
    },
    mousedown(flag, event) {
      this.currentAction = flag;
      this.offsetX = this.x;
      this.offsetY = this.y;
      this.moveOffsetX = event.offsetX - this.x;
      this.moveOffsetY = event.offsetY - this.y;

      this.selectObject();
    },
    deselectObject() {
      this.isSelected = false;
    },
    setPosition(x, y) {
      this.x = x;
      this.y = y;
    },
    setSize(width, height) {
      this.width = width;
      this.height = height;
    },
    getSize() {
      return {
        width: this.width,
        height: this.height,
      };
    },
    getObjectId() {
      return this.objectId;
    },
    selectObject() {
      this.isSelected = true;
      this.isEdit = true;
      this.$parent.$emit('selectObject', { obj: this, eventType: 'onMouse' });
    },
    selectObjectKey() {
      this.isSelected = true;
      this.isEdit = true;
      this.$parent.$emit('selectObject', { obj: this, eventType: 'onKey' });
    },
    exportData() {
      return {
        x: this.x / this.ratio,
        y: this.y / this.ratio,
        width: this.width / this.ratio,
        height: this.height / this.ratio,
        imageUrl: this.imageUrl,
        objectId: this.objectId,
        dispEffectCode: this.dispEffectCode,
        dispEffectTime: this.dispEffectTime,
      };
    },
    importData(data) {
      this.x = data.x * this.ratio || 0;
      this.y = data.y * this.ratio || 0;
      this.width = data.width * this.ratio || 32 * this.ratio;
      this.height = data.height * this.ratio || 16 * this.ratio;
      if (data.imageUrl) {
        // this.getImageBynary(data.imageUrl);
        this.imageUrl = data.imageUrl;
      }
      this.imageUrl = data.imageUrl || '';
      this.dispEffectCode = data.dispEffectCode || 0;
      this.dispEffectTime = data.dispEffectTime || 5;
    },
    getWidth() {
      return this.width;
    },
    getHeight() {
      return this.height;
    },
    getObjectType() {
      return 'image';
    },
    getX() {
      return this.x;
    },
    getY() {
      return this.y;
    },
    setImageUrl(imageUrl) {
      this.imageUrl = imageUrl;
      this.getImageBynary(imageUrl);
    },
    imageDragCancle(e) {
      e.preventDefault();
    },
    setDispEffectCode(code) {
      this.dispEffectCode = code;
    },
    setDispEffectTime(time) {
      this.dispEffectTime = time;
    },
    getDispEffectCode() {
      return this.dispEffectCode;
    },
    getDispEffectTime() {
      return this.dispEffectTime;
    },
  },
};

export default {
  components: {
    'text-object': textObject,
    'image-object': imageObject,
  },
  props: {
    ratio: {
      type: Number,
      default: 5,
    },
    ledType: {
      type: String,
      default: 'ctype',
    },
  },
  // watch: {
  //   ledType(newVal) {
  //     //console.log(newVal);
  //   },
  // },
  data() {
    return {
      NONE,
      ADD_TEXT_OBJECT,
      ADD_IMAGE_OBJECT,
      mode: 0,
      ledMessageData: {
        textData: [],
        imageData: [],
      },
      cols: 6,
      rows: 1,

      moduleCount: 16,
      editorWidth: 384,
      editorHeight: 64,
      canvasWidth: `384px`,
      canvasHeight: '64px',
      canvasViewBox: '0 0 384 64',
      isDragObject: false,
      tempImageSize: {},
      offset: {},
    };
  },
  computed: {
    canvasStyle() {
      return {
        marginLeft: `-${this.editorWidth / 2}px`,
      };
    },
    gridCanvasStyle() {
      return {
        width: `${this.editorWidth}px`,
        height: `${this.editorHeight}px`,
        marginLeft: `-${this.editorWidth / 2}px`,
      };
    },
  },
  created() {
    const _this = this;
    this.$on('selectObject', (arg) => {
      const selectObject = arg.obj;
      const eventType = arg.eventType;
      if (this.selectObject && this.selectObject.getObjectId()) {
        if (this.selectObject.getObjectId() !== selectObject.getObjectId()) {
          this.selectObject.deselectObject();
        }
      }
      this.selectObject = selectObject;
      this.tempImageSize = this.selectObject.getSize();
      if (eventType == 'onMouse') this.isDragObject = true;

      this.$forceUpdate();
      this.$emit('selectObjectPosInfo', this.selectObject);
    });

    this.$on('selectLedMessage', (arg) => {
      if (this.ledType === '3color') {
        //console.log(_this.$refs.textObject);
        setTimeout(() => {
          if (_this.$refs.textObject && _this.$refs.textObject.length > 0) {
            _this.$refs.textObject[0].selectObject();
            //console.log(_this.$refs.textObject);
          }
        }, 100);
      }
    });

    window.addEventListener('keyup', this.keyUpEvent);
  },
  mounted() {
    this.setEditorSize({
      cols: 6,
      rows: 1,
      moduleCount: 16,
    });
    window.addEventListener('mouseup', this.mouseup, false);
  },
  destroyed() {
    window.removeEventListener('keyup', this.keyUpEvent);
    window.removeEventListener('mouseup', this.mouseup);
  },
  methods: {
    setEditorSize(size) {
      this.cols = size.cols || 6;
      this.rows = size.rows || 1;
      this.moduleCount = size.moduleCount || 16;
      const width = this.cols * this.moduleCount * this.ratio;
      const height = this.rows * this.moduleCount * this.ratio;
      this.editorWidth = width;
      this.editorHeight = height;
      this.canvasWidth = `${width}px`;
      this.canvasHeight = `${height}px`;
      this.canvasViewBox = `0 0 ${width} ${height}`;
      this.setOffset();
    },
    setOffset() {
      // console.log(this.$refs['canvasWrap'].getBoundingClientRect());
      const wrapOffset = this.$refs['canvasWrap'].getBoundingClientRect();
      this.offset = {
        left: wrapOffset.left + wrapOffset.width / 2 - this.editorWidth / 2,
        top: wrapOffset.top + wrapOffset.height / 2 - this.editorHeight / 2,
      };
      // this.offset = this.$refs['ledMessageSvg'].getBoundingClientRect();
    },
    mouseup() {
      if (this.isDragObject && this.selectObject && this.selectObject.objectType === 'text') {
        this.isLineDraw = false;
        this.isDragObject = false;
        this.selectObject.setPosition(this.selectObject.x, this.selectObject.y);
      }
      if (this.isDragObject && this.selectObject && this.selectObject.objectType === 'image') {
        this.isDragObject = false;
        this.isLineDraw = false;
        if (this.selectObject.currentAction === actionsEnum.Move) {
          this.selectObject.setPosition(this.selectObject.x, this.selectObject.y);
        }
      }
      this.$emit('selectObjectPosInfo', this.selectObject);
    },
    mousemove(event) {
      if (this.isDragObject && this.selectObject) {
        this.objectMouseMoveEvent(event);
        this.$emit('selectObjectPosInfo', this.selectObject);
      }
    },
    objectMouseMoveEvent(event) {
      // console.log(event);
      let x = event.clientX;
      let y = event.clientY;
      let deltaX = x - this.offset.left - this.selectObject.offsetX;
      let deltaY = y - this.offset.top - this.selectObject.offsetY;
      // console.log(event);
      // console.log(`${event.clientX}, ${event.offsetX}, ${event.x}`);
      // console.log(`${event.clientX}, ${this.offset.left}, ${this.selectObject.offsetX} : ${deltaX}, ${this.selectObject.offsetX}`);
      let deltaWidth = this.tempImageSize.width;
      let deltaHeight = this.tempImageSize.height;
      let deltaLeft = this.selectObject.offsetX;
      let deltaTop = this.selectObject.offsetY;

      if (
        this.selectObject.currentAction == actionsEnum.RightResize ||
        this.selectObject.currentAction == actionsEnum.TopRightResize ||
        this.selectObject.currentAction == actionsEnum.BottomRightResize
      ) {
        deltaWidth = deltaX;
      }
      if (
        this.selectObject.currentAction == actionsEnum.LeftResize ||
        this.selectObject.currentAction == actionsEnum.TopLeftResize ||
        this.selectObject.currentAction == actionsEnum.BottomLeftResize
      ) {
        deltaWidth -= deltaX;
        deltaLeft = deltaX + this.selectObject.offsetX;
        // deltaLeft = deltaX;
      }
      if (
        this.selectObject.currentAction == actionsEnum.BottomResize ||
        this.selectObject.currentAction == actionsEnum.BottomLeftResize ||
        this.selectObject.currentAction == actionsEnum.BottomRightResize
      ) {
        deltaHeight = deltaY;
      }
      if (
        this.selectObject.currentAction == actionsEnum.TopResize ||
        this.selectObject.currentAction == actionsEnum.TopLeftResize ||
        this.selectObject.currentAction == actionsEnum.TopRightResize
      ) {
        deltaHeight -= deltaY;
        deltaTop = deltaY + this.selectObject.offsetY;
        // deltaTop = deltaY;
      }

      if (this.selectObject.currentAction == actionsEnum.Move) {
        deltaX -= this.selectObject.moveOffsetX;
        deltaY -= this.selectObject.moveOffsetY;
        let allowdX = 0 <= deltaX && this.editorWidth >= deltaX + this.selectObject.getWidth();
        let allowdY = 0 <= deltaY && this.editorHeight >= deltaY + this.selectObject.getHeight();

        if (!allowdX) {
          if (0 > deltaX) {
            deltaX = 0;
          } else if (this.editorWidth < deltaX + this.selectObject.getWidth()) {
            deltaX = this.editorWidth - this.selectObject.getWidth();
          }
        }

        if (!allowdY) {
          if (0 > deltaY) {
            deltaY = 0;
          } else if (this.editorHeight < deltaY + this.selectObject.getHeight()) {
            deltaY = this.editorHeight - this.selectObject.getHeight();
          }
        }
        const moveX = Math.round(deltaX / this.ratio) * this.ratio;
        const moveY = Math.round(deltaY / this.ratio) * this.ratio;
        this.selectObject.setPosition(moveX, moveY);
      } else if (this.selectObject.currentAction !== actionsEnum.None) {
        let applyWidth = 0;
        let applyHeight = 0;
        if (deltaWidth + deltaLeft > this.editorWidth) {
          applyWidth = deltaWidth - Math.abs(this.editorWidth - deltaWidth - deltaLeft);
        } else if (deltaLeft < 0) {
          applyWidth = deltaWidth + deltaLeft;
          deltaLeft = 0;
        } else {
          applyWidth = deltaWidth;
        }
        if (deltaHeight + deltaTop > this.editorHeight) {
          applyHeight = deltaHeight - Math.abs(this.editorHeight - deltaHeight - deltaTop);
        } else if (deltaTop < 0) {
          applyHeight = deltaHeight + deltaTop;
          deltaTop = 0;
        } else {
          applyHeight = deltaHeight;
        }
        const moveX = Math.round(deltaLeft / this.ratio) * this.ratio;
        const moveY = Math.round(deltaTop / this.ratio) * this.ratio;
        const targetWidth = Math.round(applyWidth / this.ratio) * this.ratio;
        const targetHeight = Math.round(applyHeight / this.ratio) * this.ratio;
        this.selectObject.setSize(targetWidth, targetHeight);
        this.selectObject.setPosition(moveX, moveY);
      }
    },
    addTextObject(args) {
      let idx = new Date().getTime();
      let textData = {
        objectId: `textObject-${idx}`,
        x: 0,
        y: 0,
        width: 48,
        height: 16,
      };
      if (args && args.x) {
        textData.x = args.x;
      }
      if (args && args.y) {
        textData.y = args.y;
      }
      if (args && args.width) {
        if (args.width.indexOf('%') > -1) {
          let percent = parseInt(args.width.substring(0, args.width.indexOf('%'))) / 100;
          textData.width = (this.editorWidth * percent) / this.ratio;
        } else {
          textData.width = parseInt(args.width);
        }
      }
      if (args && args.height) {
        if (args.height.indexOf('%') > -1) {
          let percent = parseInt(args.height.substring(0, args.height.indexOf('%'))) / 100;
          textData.height = (this.editorHeight * percent) / this.ratio;
        } else {
          textData.height = parseInt(args.height);
        }
      }
      this.ledMessageData.textData.push(textData);
      this.$forceUpdate();
      this.$nextTick(() => {
        const tempObj = this.$refs.textObject.find((obj) => {
          return textData.objectId == obj.getObjectId();
        });
        if (tempObj.selectObject) tempObj.selectObject();
        this.$emit('updateObject', {});
      });
      return textData.obejctId;
    },
    addImageObject(imageUrl) {
      let idx = new Date().getTime();
      let imageData = {
        objectId: `imageObject-${idx}`,
        x: 0,
        y: 0,
        width: 16,
        height: 16,
      };
      if (imageUrl) {
        imageData.imageUrl = imageUrl;
      }
      this.ledMessageData.imageData.push(imageData);
      this.$forceUpdate();
      this.$nextTick(() => {
        const tempObj = this.$refs.imageObject.find((obj) => {
          return imageData.objectId == obj.getObjectId();
        });
        if (tempObj.selectObject) tempObj.selectObject();
        this.$emit('updateObject', {});
      });
      return imageData.obejctId;
    },
    keyUpEvent(e) {
      if (e.target.isContentEditable) {
        this.$emit('updateObject', {});
        return;
      }
      if (this.selectObject && this.selectObject.objectId) {
        switch (e.keyCode) {
          case 46:
            if (this.ledType === 'ctype') this.removeObject();

            break;
        }
      }
    },
    removeObject() {
      let delIdx = 0;
      if (this.selectObject.objectType === 'text') {
        delIdx = this.ledMessageData.textData.findIndex((ele) => {
          return ele.objectId === this.selectObject.getObjectId();
        });
        this.ledMessageData.textData.splice(delIdx, 1);
      } else if (this.selectObject.objectType === 'image') {
        delIdx = this.ledMessageData.imageData.findIndex((ele) => {
          return ele.objectId === this.selectObject.getObjectId();
        });
        this.ledMessageData.imageData.splice(delIdx, 1);
      }
      this.$emit('updateObject', {});
    },
    getObjectList() {
      let list = [];
      if (typeof this.$refs.textObject === 'object' && this.$refs.textObject.length > 0) {
        this.$refs.textObject.forEach((item) => {
          list.push(item);
        });
      }
      if (typeof this.$refs.imageObject === 'object' && this.$refs.imageObject.length > 0) {
        this.$refs.imageObject.forEach((item) => {
          list.push(item);
        });
      }
      return list;
    },
    exportData() {
      let result = {
        textData: [],
        imageData: [],
        cols: this.cols,
        rows: this.rows,
        ledType: this.ledType,
        moduleCount: this.moduleCount,
      };
      if (typeof this.$refs.textObject === 'object' && this.$refs.textObject.length > 0) {
        this.$refs.textObject.forEach((item) => {
          result.textData.push(item.exportData());
        });
      }
      if (typeof this.$refs.imageObject === 'object' && this.$refs.imageObject.length > 0) {
        this.$refs.imageObject.forEach((item) => {
          result.imageData.push(item.exportData());
        });
      }
      return result;
    },
    importData(data) {
      this.setEditorSize({
        cols: data.cols,
        rows: data.rows,
        moduleCount: data.moduleCount,
      });
      data.textData.forEach((item) => {
        this.ledMessageData.textData.push(item);
      });
      data.imageData.forEach((item) => {
        this.ledMessageData.imageData.push(item);
      });
      this.$forceUpdate();
      this.$nextTick(() => {
        this.$emit('importedData', {});
      });
    },
    resetEditor() {
      this.ledMessageData.textData = [];
      this.ledMessageData.imageData = [];
      this.$forceUpdate();
    },
    setRatio(num) {
      this.ratio = num;
    },
  },
};
</script>

<style scoped>
.led-canvas-wrap {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  position: relative;
}

.dummy-canvas {
  position: absolute;
  z-index: -1;
}

.editor-canvas {
  background-color: #000;
  background-image:
    linear-gradient(to right, rgba(255, 255, 255, 0.2) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 1px, transparent 1px);
  background-size: 4px 4px;
  left: 50%;
  position: absolute;
}

.grid-canvas {
  background-image:
    linear-gradient(to right, rgba(255, 255, 255, 0.2) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 1px, transparent 1px);
  background-size: 4px 4px;
  left: 50%;
  position: absolute;
  z-index: 1;
}
</style>
