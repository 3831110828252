<template>
  <el-dialog :visible.sync="isDisplay" :title="formTitle">
    {{ trafficIncidentData }}
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      isDisplay: false,
      formTitle: '',
      trafficIncidentData: {},
    };
  },
  methods: {
    showDialog(data) {
      this.trafficIncidentData = data;
      this.isDisplay = true;
    },
  },
};
</script>

<style></style>
