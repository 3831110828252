import axios from '../components/axios';

class DkPlugin {
  constructor(config) {
    this._config = config;
    this._plugins = {};
    this._gisMarker = {};
  }
  initPlugin() {
    let me = this;
    this._plugins = {};
    return axios.get(`${this._config.core.api}/core/api/fcltType`, {}).then((response) => {
      response.data.docs.forEach((data) => {
        this._gisMarker[data.fcltTypeId] = {
          gisMarkerWidth: data.gisMarkerWidth || 0,
          gisMarkerHeight: data.gisMarkerHeight || 0,
          gisMarkerOffsetX: data.gisMarkerOffsetX || 0,
          gisMarkerOffsetY: data.gisMarkerOffsetY || 0,
          gisMarkerImage: data.gisMarkerImage || {},
        };
        if (data.isPlugin) {
          try {
            if (data.plugin && data.plugin.noPluginAddon) {
              throw new Error('no addon');
            }
            me._plugins[data.fcltTypeId] = import(`./${data.fcltTypeId}.vue`);
          } catch (err) {
            console.log(err);

            me._plugins[data.fcltTypeId] = null;
            delete me._plugins[data.fcltTypeId];
          }
        }
      });
    });
  }
  getPlugin(fcltTypeId) {
    if (this._plugins[fcltTypeId]) return this._plugins[fcltTypeId];
    else return false;
  }
  getGisMarker(fcltTypeId) {
    if (this._gisMarker[fcltTypeId]) {
      return this._gisMarker[fcltTypeId];
    }
  }
}

export default DkPlugin;
