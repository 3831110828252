<template>
    <div class="dk-pagination-wrap">
         <el-pagination
            background
            layout="prev, pager, next"
            :total="totalDocs"
            :page-size="limit"
            @current-change="pagingProc"
            :current-page.sync="page"
        >
        </el-pagination>
    </div>    
</template>

<script>
export default {
    props : {
        limit: {
            type: Number,
            default: 10
        },
        totalDocs: {
            type: Number            
        },
        pagingProc: {
            type: Function,
            default(pageNum) {                
            }
        }        
    },
    data() {
        return {
            page: 1
        }
    },
    mounted() {
       
    },
    methods: {
        getPage() {
            return this.page;
        },
        setPage(pageNum) {
            this.page = pageNum;
        }
    }
}
</script>

<style scoped>
.dk-pagination-wrap {
    padding-top: 10px;
	-webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
	display: flex;
	display: -webkit-box;
    display: -ms-flexbox;
	position: relative;
    box-sizing: border-box;
}
</style>