import Vue from 'vue';
import Router from 'vue-router';
import axios from 'axios';
import main from '@/pageMain.vue';
import mobileMain from '@/pageMobileMain.vue';
import login from '@/core/pageLogin';
import page404 from '@/core/page404';
import dashboard from '@/core/pageDashboard';
// import dashboard from '@/ai/pageAiDashboard';
import fcltMobileGis from '@/core/pageFcltMobileGis';
import download from '@/mobile/pageDownload.vue';

import mobilePages from '@/mobile/index.js';

class DkRouter {
  constructor(config, store, isMobile) {
    this._config = config;
    this._store = store;
    this._router = null;
    this._menu = null;
    this._user = null;
    this._isMobile = isMobile;
    this.defaultRouter();
  }

  defaultRouter() {
    Vue.use(Router);
    this._router = new Router({
      routes: [
        {
          path: '/',
          name: 'error',
          component: page404,
        },
      ],
    });
  }

  // async initRouter() {
  //   const me = this;
  //   let routes = [
  //     // {
  //     //   path: '/',
  //     //   name: 'Home',
  //     //   component: main,
  //     //   children: [],
  //     //   meta: {
  //     //     requireAccessToken: true
  //     //   },
  //     //   icoapp.use('/core/', require('./routes/menu'));n: "fas fa-home"
  //     // },
  //     {
  //       path: '/login',
  //       name: 'login',
  //       component: login,
  //       beforeEnter: (to, from, next) => {
  //         // if (!this._store.getters.getAccessToken && !this._store.getters.getRefreshToken) {
  //         //     next();
  //         //   } else {
  //         //     if (from.path) {
  //         //       next(from.path);
  //         //     } else {
  //         //       next('/');
  //         //     }
  //         //   }

  //         // if (!me._store['auth/getAccessToken'] && !me._store.getters['auth/getRefreshToken']) {

  //         // if(!me._store['auth/getAccessToken']) {
  //         //   if(!me._store['auth/getRefreshToken']) {
  //         //     return next();
  //         //   } else {

  //         //   }
  //         // }

  //         // if (from.path) {
  //         //   next(from.path);
  //         // } else {
  //         //   next('/');
  //         // }
  //         debugger

  //         // if (!me._store['auth/getAccessToken']) {
  //         //   next();
  //         // } else {
  //         //   if (from.path) {
  //         //     next(from.path);
  //         //   } else {
  //         //     next('/');
  //         //   }
  //         // }
  //       },
  //     },
  //     {
  //       path: '/404',
  //       component: page404,
  //     },
  //   ];
  //   Vue.use(Router);
  //   //여기 파라미터에 사용자 아이디 넣을것

  //   return new Promise((resolve, reject) => {
  //     //.then(() => {
  //     this._router = new Router({ routes: routes });
  //     this._router.beforeEach((to, from, next) => {
  //       if (!to.matched.length) {
  //         next('/404');
  //       }
  //       //console.log(`${to.path}  , router`);
  //       if (!this._store.getters.getAccessToken) {
  //         axios
  //           .post(`${this._config.core.api}/core/auth/getAccess`)
  //           .then((response) => {
  //             this._store.dispatch('auth/actAccessToken', { access_payload: response.data.result.accessToken }, { root: true });
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //           });
  //       }
  //       if (to.meta.requireAccessToken) {
  //         return new Promise((resolve, reject) => {
  //           axios
  //             .post(`${this._config.core.api}/core/auth/validation`)
  //             .then((response) => {
  //               debugger
  //               if (response.data.err) {
  //                 reject();
  //               } else {
  //                 resolve();
  //               }
  //             })
  //             .catch((err) => {
  //               console.log('error');
  //               reject();
  //             });
  //         })
  //           .then(() => {
  //             next();
  //           })
  //           .catch(() => {
  //             this._store.dispatch('actTodo', to.path);
  //             next('/login');
  //           });
  //       }
  //       next();
  //     });
  //     return resolve(this._router);
  //   });
  // }

  async initRouter() {
    const me = this;
    let routes = [
      {
        path: '/login',
        name: 'login',
        component: login,
        // beforeEnter: (to, from, next) => {
        //   // next(from.path);
        //   if (from.path) {
        //     debugger;
        //   }
        //   next();
        // },
      },
      {
        path: '/download',
        name: 'download',
        component: download,
        // beforeEnter: (to, from, next) => {
        //   // next(from.path);
        //   if (from.path) {
        //     debugger;
        //   }
        //   next();
        // },
      },
      {
        path: '/404',
        component: page404,
      },
    ];
    Vue.use(Router);
    //여기 파라미터에 사용자 아이디 넣을것
    this._router = new Router({ routes: routes });
    this._router.beforeEach(this.beforeRouter.bind(this));
    return this._router;
  }

  async checkAccess() {
    try {
      const response = await axios.post(`${this._config.core.api}/core/auth/getAccess`);
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  }

  async beforeRouter(to, from, next) {
    try {
      if (to.path === '/download') {
        return next();
        // return next('/download');
      }
      if (to.path === '/login') {
        return next();
      }
      //       if (!to.matched.length) {
      //         next('/404');
      //       }
      // const getAccessResponse = await axios.post(`${this._config.core.api}/core/auth/getAccess`);
      // if (getAccessResponse.status !== 200) throw '';
      if (!this.checkAccess()) throw '';
      // try {
      //   await this._store.dispatch('auth/actAccessToken', { access_payload: response.data.result.accessToken }, { root: true });4
      // } catch(err) {
      //   console.log(err);
      // }
      if (to.meta.requireAccessToken) {
        const response = await axios.post(`${this._config.core.api}/core/auth/validation`);
        if (response.data.err) {
          throw response.data.err;
        }
        return next();
      }
    } catch (err) {
      this._store.dispatch('actTodo', to.path);
      return next('/login');
    }
  }

  getRouter() {
    return this._router;
  }
  async getActiveUser(refreshToken) {
    if (this._isMobile) {
      return this.getMobileActiveUser(refreshToken);
    }
    // 사용자 인증 후에 GIS API 정보를 vuex에 설정함
    await this._store.dispatch('gis/actGisApi', {});
    return axios
      .get(`${this._config.core.api}/core/auth/activeUser`, {
        params: { refreshToken: refreshToken },
      })
      .then(async (response) => {
        let homeRoute;
        this._menu = response.data.menu;
        this._user = response.data.user;
        this._store.dispatch('auth/actActiveUser', { activeUser: response.data.user }, { root: true });
        if (this._isMobile) {
          homeRoute = {
            path: '/',
            name: 'Home',
            component: mobileMain,
            children: [],
            meta: {
              requireAccessToken: true,
            },
            icon: 'fas fa-home',
          };

          homeRoute.children.push({
            name: 'fcltMobileGis',
            path: '',
            component: fcltMobileGis,
            meta: {
              requireAccessToken: true,
            },
          });
        } else {
          homeRoute = {
            path: '/',
            name: 'Home',
            component: main,
            children: [],
            meta: {
              requireAccessToken: true,
            },
            icon: 'fas fa-home',
          };

          response.data.menu.forEach((data) => {
            //console.log(data.items)
            let firstPage = dashboard;

            const configure = this._store.state.configure.configure.value;

            if (configure.firstPage) {
              firstPage = function (resolve) {
                return require([`.${configure.firstPage}`], resolve);
              };
            }
            homeRoute.children.push({
              name: 'dashboard',
              path: '',
              component: firstPage,
              meta: {
                requireAccessToken: true,
              },
            });
            data.items.forEach((item) => {
              if (item.url) {
                homeRoute.children.push({
                  name: item.label || 'name',
                  path: item.url,
                  icon: item.icon || 'fas fa-box',
                  props: {
                    featureId: item.featureId || '',
                  },
                  meta: {
                    requireAccessToken: item.requireAccessToken || true,
                  },
                  component(resolve) {
                    return require([`${item.componentPath}`], resolve);
                  },
                });
              }
              if (item.children) {
                item.children.forEach((childrenItem) => {
                  if (childrenItem.url) {
                    homeRoute.children.push({
                      name: childrenItem.label || 'name',
                      path: childrenItem.url,
                      icon: childrenItem.icon || 'fas fa-box',
                      meta: {
                        requireAccessToken: childrenItem.requireAccessToken || true,
                        featureId: childrenItem.featureId || '',
                      },
                      component(resolve) {
                        return require([`${childrenItem.componentPath}`], resolve);
                      },
                    });
                  }
                });
              }
            });
          });
        }
        this._router.addRoutes([homeRoute]);
        return;
      });
  }
  async getMobileActiveUser(refreshToken) {
    try {
      const response = await axios.get(`${this._config.core.api}/core/auth/activeUser`, {
        params: { refreshToken: refreshToken },
      });
      this._menu = response.data.menu;
      this._user = response.data.user;

      new mobilePages({
        router: this._router,
      });
    } catch (err) {
      throw err;
    }
  }
  getMenuData() {
    if (this._isMobile) {
      return [];
    } else {
      return this._menu[0].items;
    }
  }
  getUserInfo() {
    return this._user;
  }

  async setUserInfo() {
    const refreshToken = this._store.getters['auth/getRefreshToken'];
    const response = await axios.get(`${this._config.core.api}/core/auth/activeUser`, {
      params: { refreshToken: refreshToken },
    });
    this._user = response.data.user;
  }
}

export default DkRouter;
