<template>
  <div>
    <div class="fclt-status-wrap">
      <doughnut-chart
        ref="chart"
        :chart-data="statusData"
        :height="height - 20"
        :options="{
            responsive: true, 
            maintainAspectRatio: false,
            legend: {
              display:false,
              position:'bottom',
              align: 'center',
              labels: {
                padding: 10,
                boxWidth: 15
              }
            }
          }"
      ></doughnut-chart>
      <div class="center-label">
        <span style="color:#FB6A7B">{{fcltFailureCount}}</span>
        /{{fcltCount}}
      </div>
    </div>
    <div class="fclt-status-label">{{label}}</div>
  </div>
</template>

<script>
import doughnutChart from "@/components/chart/doughnutChart.js";

export default {
  components: {
    doughnutChart
  },
  props: {
    height: {
      type: Number,
      default: 120
    },
    fcltTypeId: {
      type: String,
      required: true
    },
    label: {
      type: String
    }
  },
  data() {
    return {
      intervalId: null,
      fcltSuccessCount: 0,
      fcltFailureCount: 0,
      fcltCount: 0,
      statusData: {
        datasets: [
          {
            data: [],
            backgroundColor: []
          }
        ],
        labels: [],
        total: 0
      },
      
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getFcltStatus();
      this.intervalId = setInterval(() => {
        this.getFcltStatus();
      }, 60000);
    });
  },
  destroyed() {
    clearInterval(this.intervalId);
  },
  methods: {
    async getFcltStatus() {
      let tmpFcltStatusData = await this.$store.dispatch(
        "fcltStatus/getFcltStatusChart",
        { fcltTypeId: this.fcltTypeId }
      );
      this.fcltCount = tmpFcltStatusData.fcltCount;
      this.fcltSuccessCount = tmpFcltStatusData.fcltSuccessCount;
      this.fcltFailureCount = tmpFcltStatusData.fcltFailureCount;
      this.statusData.datasets[0].data[0] = tmpFcltStatusData.fcltSuccessCount;
      this.statusData.datasets[0].data[1] =
        tmpFcltStatusData.fcltCount - tmpFcltStatusData.fcltSuccessCount;
      this.statusData.datasets[0].backgroundColor[0] = "#5899D1";
      this.statusData.datasets[0].backgroundColor[1] = "#FB6A7B";  
      this.statusData.labels[0] = "정상가동";
      this.statusData.labels[1] = "통신이상";
      this.statusData.total = tmpFcltStatusData.fcltCount;
      this.$refs.chart.update();

      // for (var i = 0; i < 2; i++) {
      //   this.fcltInstPnstData.datasets[0].data[i] = data[i].count;
      //   this.fcltInstPnstData.datasets[0].backgroundColor[i] = this.chartColors[i];
      //   this.fcltInstPnstData.labels[i] = data[i]._id
      //   this.fcltInstPnstData.total += data[i].count;
      // }
    }
  }
};
</script>

<style>
.fclt-status-wrap {
  position: relative;
}

.fclt-status-label {
  text-align: center;
  font-weight: bold;
  font-size: 13px;
  color: #222222;
}

.center-label {
  position: absolute;
  top: calc(50% - 5px);
  left: 50%;
  width: 100px;
  margin-left: -50px;
  text-align: center;
  font-weight: bold;
  font-size: 13px;
  color: #4a4a4a;
}
</style>