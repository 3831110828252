<template>
  <div id="wrap">
    <vue-headful :title="$config.getSiteConfig().title"></vue-headful>

    <!-- mobile menu -->
    <div v-bind:class="[isMenuOpen ? 'show' : '', 'mobile-menu']">
      <div class="mobile-menu-body">
        <div class="mobile-menu-profile">
          <div
            class="mobile-user-thumb-img"
            v-if="activeUser.images && activeUser.images.length > 0"
            :style="'background-image:url(' + activeUser.images[0].serverFilePath + ')'"
          ></div>
          <div v-else class="mobile-user-thumb-img" style="background-image: url(static/images/unknown.png);"></div>
          <div class="mobile-user-info">
            <div class="name">{{ activeUser.name }}</div>
            <div class="id">{{ activeUser.userid }}({{ activeUser.groupName }})</div>
          </div>
        </div>

        <ul class="mobile-menu-list">
          <li>
            <a :href="centerPhoneLink()">
              <span>
                <img src="static/images/telephone-symbol-button.png" class="menu-icon" />
              </span>
              <span>
                <div>{{ $config.getSiteConfig().orgName }} 전화연결</div>
              </span>
            </a>
          </li>
        </ul>

        <!-- <div class="mobile-menu-user-list">
              <ul>                    
                  <li v-for="(item, idx) in userList"
                      :key="idx"
                  >
                      <a :href="userPhoneLink(item)">
                      <span
                          class="mobile-phone-thumb"
                          v-if="item.images && item.images.length > 0"                        
                          :style="'background-image:url('+$config.getServerConfig().core.webServer+item.images[0].serverFilePath+')'">
                      </span>
                      <span
                          v-else 
                          class="mobile-phone-thumb"
                          style="background-image:url(static/images/unknown.png)">
                      </span>
                      <span>{{item.name}}</span>                        
                      </a>
                  </li>
                  
              </ul>      
        </div>-->
        <div class="mobile-menu-bottom-bar">
          <span @click="logout"> <i class="fas fa-sign-out-alt" style="cursor: pointer;"></i>로그아웃 </span>
        </div>
      </div>
    </div>
    <!-- main menu -->

    <!-- mobile only -->
    <div class="main-container mobile" v-if="$isMobile">
      <div class="mobile-header">
        <div></div>
        <div class="mobile-main-ci-wrap">
          <img :src="$config.getServerConfig().core.webServer + logoData.serverFilePath" @click="goHome" />
        </div>
        <div>
          <span class="mobile-menu-toggle" @click="showMenu">
            <i class="fas fa-bars"></i>
          </span>
        </div>
      </div>

      <div @click="isMenuOpen = false" :class="[isMenuOpen ? 'show' : '', 'menu-bubbling-mask']"></div>
      <div class="mobile-container">
        <router-view />
      </div>
    </div>
    <!-- mobile only -->

    <form-user-info ref="formDialog"></form-user-info>
    <form-user-password ref="formUserPassword"></form-user-password>
  </div>
</template>

<script>
window.mobileInit = function (token, deviceId) {
  window.mobileToken = token;
  window.modbileDeviceId = deviceId;
};
import vueHeadful from 'vue-headful';
import formUserInfo from '@/core/forms/formUserInfo';
import formUserPassword from '@/core/forms/formUserPassword.vue';

export default {
  components: {
    'form-user-info': formUserInfo,
    'form-user-password': formUserPassword,

    'vue-headful': vueHeadful,
  },
  props: {
    source: String,
  },
  computed: {},

  data: () => ({
    menuItem: [],
    isMenuOpen: false,
    activeUser: null,
    logoData: {
      // name: "dk_ci_dark.png",
      // serverFileName: "",
      // serverFilePath: "static/images/dk_ci_dark.png",
      // size: 0,
      // url: ""
    },

    userList: [],
    userInfoDialog: false,
  }),
  created() {
    if (this.$config.getSiteConfig().logo.length > 0) {
      this.logoData = this.$config.getSiteConfig().logo[0];
    }

    let menus = [
      {
        icon: 'fa-user',
        label: '연락처',
        children: [],
      },
    ];

    this.activeUser = this.$dkRouter.getUserInfo();

    this.getUserList();
  },
  methods: {
    goHome() {
      this.$router.push('/');
      this.isMenuOpen = false;
    },
    // selectMenuItem(item, event) {
    //   // 여기서 선택한 메뉴상태를 vuex로 커밋할것
    //   if (item.url) {
    //     this.$router.push(item.url);
    //     this.isMenuOpen = false;
    //   } else {
    //     this.isMenuOpen = true;
    //   }
    //   event.preventDefault();
    //   event.stopPropagation();
    // },
    logout() {
      let check = confirm('시스템에서 로그아웃 합니다');
      if (check) {
        this.$store.dispatch('auth/actRefreshToken', { refresh_payload: '' });
        this.$store.dispatch('auth/actAccessToken', { access_payload: '' });
        window.location.href = this.$config.getServerConfig().baseUrl;
      }
    },
    showMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    showHelpDialog() {
      this.$refs.helpDialog.showHelpDialog(this.$route.path);
    },

    async getUserList() {
      let tmpUserList = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/user`, { param: {} });
      this.userList = tmpUserList.data.docs;
    },
    userPhoneLink(item) {
      return `tel:${item.phone}`;
    },
    centerPhoneLink() {
      return `tel:${this.$config.getSiteConfig().phone}`;
    },
    updatePassword() {
      this.$refs.formUserPassword.showDialog(this.activeUser);
    },
    updateUserInfo() {
      this.$refs.formDialog.showDialog(this.activeUser);
    },
  },
};
</script>
<style scoped></style>
