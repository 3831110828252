import axios from 'axios';

export default {
  namespaced: true,
  state: {
    fcltType: {
      value: null,
    },
    pagination: {
      totalDocs: 0,
      limit: 10,
      page: 1,
    },
  },
  getters: {
    getFcltType: (state) => {
      return state.fcltType.value;
    },
    getPagination: (state) => {
      return state.pagination;
    },
  },
  mutations: {
    setFcltType(state, payload) {
      state.fcltType.value = payload;
      //localStorage.setItem('gisApi', payload)
    },
    setPagination(state, payload) {
      state.pagination = payload;
    },
  },
  actions: {
    getFcltType({ commit }, payload) {
      let activeUser = this.getters['auth/getActiveUser'];
      if (activeUser && activeUser.userid) {
        payload.userid = activeUser.userid;
        payload.group = activeUser.group;
      }
      return new Promise((resolve, reject) => {
        axios
          .get(`${this.state.dkConfig.getServerConfig().core.api}/core/api/fcltType`, { params: payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      })
        .then((response) => {
          commit('setFcltType', response.data.docs);
          if (response.data.totalDocs) {
            commit('setPagination', {
              totalDocs: response.data.totalDocs,
              limit: response.data.limit,
              page: response.data.page,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addFcltType({ commit }, payload) {
      console.log(payload);
      return axios.post(`${this.state.dkConfig.getServerConfig().core.api}/core/api/fcltType/add`, payload);
    },
    updateFcltType({ commit }, payload) {
      return axios.post(`${this.state.dkConfig.getServerConfig().core.api}/core/api/fcltType/update`, payload);
    },
    deleteFcltType({ commit }, payload) {
      return axios.post(`${this.state.dkConfig.getServerConfig().core.api}/core/api/fcltType/delete`, payload);
    },
    restoreFcltType({ commit }, payload) {
      return axios.post(`${this.state.dkConfig.getServerConfig().core.api}/core/api/fcltType/restore`, payload);
    },
    swapFcltType({ commit }, payload) {
      return axios.post(`${this.state.dkConfig.getServerConfig().core.api}/core/api/fcltType/swap`, payload);
    },
    checkRegisterFclt({ commit }, payload) {
      return axios.post(`${this.state.dkConfig.getServerConfig().core.api}/core/api/fcltType/registerFclt`, payload);
    },
  },
};
