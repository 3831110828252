<template>
  <div class="ovh" id="page404">
    <div class="img404">
      <svg
        version="1.0"
        id="레이어_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="574.667px"
        height="366px"
        viewBox="0 0 574.667 366"
        enable-background="new 0 0 574.667 366"
        xml:space="preserve"
      >
        <g>
          <g id="XMLID_2_">
            <g>
              <path
                fill="#383838"
                d="M472.853,312.754c1.17,2.58,0.46,4.11,0.21,4.51l-9.98,6.08l-72.53-41.87l11.44-8.23v-2.99
              C431.232,286.824,471.042,310.004,472.853,312.754z"
              />
              <path
                d="M474.672,311.924c1.91,4.19-0.06,6.62-0.14,6.73l-0.11,0.12l-11.31,6.9l-74.34-42.92l0.88-0.64l73.439,42.4l10.67-6.5
              c0,0,1.671-2,0-5.67c-1.42-3.12-55.539-34.03-71.77-43.25v-1.16C420.362,278.354,473.103,308.464,474.672,311.924z"
              />
              <path
                d="M473.762,312.344c1.671,3.67,0,5.67,0,5.67l-10.67,6.5l-73.439-42.4l0.899-0.64l72.53,41.87l9.98-6.08
              c0.25-0.4,0.96-1.93-0.21-4.51c-1.811-2.75-41.62-25.93-70.86-42.5v-1.16C418.223,278.314,472.342,309.224,473.762,312.344z"
              />
              <path d="M401.992,269.094v1.16c-0.67-0.39-1.34-0.77-2-1.14v-1.15C400.572,268.294,401.242,268.674,401.992,269.094z" />
              <path d="M399.992,266.794c0.61,0.35,1.28,0.73,2,1.14v1.16c-0.75-0.42-1.42-0.8-2-1.13V266.794z" />
              <path fill="#383838" d="M401.992,273.244l-11.44,8.23l-1.899-1.1l11.34-8.16v-3.1c0.66,0.37,1.33,0.75,2,1.14V273.244z" />
              <polygon points="399.992,260.524 399.353,259.934 400.452,259.244 401.522,260.934 399.992,261.894 			" />
              <path
                fill="#383838"
                d="M399.992,269.114v3.1l-11.34,8.16l-1.07-0.62l-0.16-5.92l10.03-6.16
              C398.292,268.144,399.132,268.624,399.992,269.114z"
              />
              <polygon fill="#383838" points="399.353,259.934 382.302,270.684 377.342,268.074 395.952,256.804 			" />
              <polygon points="395.563,254.704 396.592,256.414 395.952,256.804 394.422,255.394 			" />
              <polygon points="394.422,255.394 395.952,256.804 377.342,268.074 375.262,266.984 			" />
              <polygon fill="#16AEB8" points="394.422,255.394 375.262,266.984 373.802,266.224 393.272,254.334 			" />
              <polygon points="392.872,252.234 393.912,253.944 393.272,254.334 391.742,252.924 			" />
              <polygon points="393.272,254.334 373.802,266.224 371.742,265.134 391.742,252.924 			" />
              <polygon fill="#383838" points="391.742,252.924 371.742,265.134 367.622,262.974 388.893,250.304 			" />
              <polygon points="388.652,280.374 390.552,281.474 389.652,282.114 387.752,281.014 			" />
              <polygon points="387.752,281.014 389.652,282.114 388.772,282.754 386.872,281.654 			" />
              <polygon points="388.512,248.204 389.532,249.924 388.893,250.304 387.362,248.894 			" />
              <polygon points="387.362,248.894 388.893,250.304 367.622,262.974 365.542,261.884 			" />
              <path
                d="M387.313,247.264l-1.011-1.72l0.171-0.19l15.52,14.29v8.29c-0.72-0.41-1.39-0.79-2-1.14v-4.9l1.53-0.96l-1.07-1.69
              l-1.1,0.69l-3.4-3.13l0.64-0.39l-1.029-1.71l-1.141,0.69l-1.149-1.06l0.64-0.39l-1.04-1.71l-1.13,0.69l-2.85-2.62l0.64-0.38
              l-1.021-1.72l-1.149,0.69l-1.101-1.02L387.313,247.264z"
              />
              <path
                d="M386.592,280.344l-0.189-7.06l11.03-6.77c0,0,0.92,0.52,2.56,1.45v1.15c-0.86-0.49-1.7-0.97-2.54-1.44l-10.03,6.16
              l0.16,5.92l1.07,0.62l-0.9,0.64L386.592,280.344z"
              />
              <polygon fill="#16AEB8" points="386.262,247.874 387.362,248.894 365.542,261.884 363.832,260.984 			" />
              <polygon points="386.302,245.544 387.313,247.264 386.262,247.874 384.723,246.464 			" />
              <path
                d="M385.393,272.734l12.02-7.38l0.51,0.28c0.58,0.33,1.28,0.72,2.07,1.16v1.17c-1.64-0.93-2.56-1.45-2.56-1.45l-11.03,6.77
              l0.189,7.06l1.16,0.67l-0.88,0.64l-1.26-0.73L385.393,272.734z"
              />
              <polygon points="386.262,247.874 363.832,260.984 361.742,259.884 384.723,246.464 			" />
              <path
                fill="#383838"
                d="M397.412,265.354l-12.02,7.38l0.22,8.19l1.26,0.73l-3.52,2.53v-11.79l16.64-10.5v4.9
              c-0.79-0.44-1.49-0.83-2.07-1.16L397.412,265.354z"
              />
              <polygon fill="#383838" points="381.563,243.554 384.723,246.464 361.742,259.884 357.103,257.444 			" />
              <polygon points="383.353,284.184 383.353,286.134 381.353,286.134 381.353,284.724 382.192,285.014 			" />
              <polygon
                points="381.893,272.724 382.822,270.954 382.302,270.684 399.353,259.934 399.992,260.524 399.992,261.894 
              383.353,272.394 383.353,284.184 382.192,285.014 381.353,284.724 381.353,272.444 			"
              />
              <polygon points="382.822,270.954 381.893,272.724 381.353,272.444 381.353,271.284 382.302,270.684 			" />
              <polygon points="377.342,268.074 382.302,270.684 381.353,271.284 381.353,272.444 376.393,269.834 376.393,268.644 			" />
              <polygon points="380.962,241.604 381.952,243.334 381.563,243.554 380.022,242.134 			" />
              <polygon points="380.022,242.134 381.563,243.554 357.103,257.444 354.992,256.334 			" />
              <polygon fill="#16AEB8" points="378.842,241.044 380.022,242.134 354.992,256.334 353.442,255.524 			" />
              <polygon points="378.492,238.954 379.482,240.684 378.842,241.044 377.302,239.634 			" />
              <polygon points="378.842,241.044 353.442,255.524 351.342,254.424 377.302,239.634 			" />
              <polygon fill="#383838" points="375.172,237.664 377.302,239.634 351.342,254.424 348.723,253.044 			" />
              <polygon points="376.393,268.134 375.302,268.134 374.782,267.274 375.262,266.984 377.342,268.074 376.393,268.644 			" />
              <polygon points="376.393,268.134 376.393,268.644 375.822,268.994 375.302,268.134 			" />
              <polygon points="376.322,282.964 376.302,283.694 374.302,283.644 374.332,282.274 			" />
              <polygon points="375.822,268.994 376.393,268.644 376.393,269.834 374.393,268.784 374.393,268.134 375.302,268.134 			" />
              <polygon points="374.792,235.564 375.802,237.294 375.172,237.664 373.622,236.244 			" />
              <polygon
                points="372.782,267.934 372.782,266.844 373.802,266.224 375.262,266.984 374.782,267.274 375.302,268.134 
              374.393,268.134 374.393,268.784 			"
              />
              <polygon points="375.172,237.664 348.723,253.044 346.632,251.954 373.622,236.244 			" />
              <polygon fill="#16AEB8" points="374.393,268.784 374.393,272.444 372.782,271.924 372.782,267.934 			" />
              <polygon fill="#16AEB8" points="374.372,280.524 374.332,282.274 372.782,281.734 372.782,280.234 			" />
              <polygon fill="#16AEB8" points="372.422,235.124 373.622,236.244 346.632,251.954 344.832,251.004 			" />
              <polygon
                points="374.372,280.524 372.782,280.234 372.782,281.734 370.782,281.034 370.782,277.824 376.412,278.864 
              376.322,282.964 374.332,282.274 			"
              />
              <polygon points="372.052,233.034 373.052,234.764 372.422,235.124 370.882,233.714 			" />
              <polygon points="372.782,281.734 372.782,282.444 370.782,282.444 370.782,281.034 			" />
              <polygon
                points="370.782,273.384 370.782,266.884 372.782,267.934 372.782,271.924 374.393,272.444 374.393,268.784 
              376.393,269.834 376.393,275.184 			"
              />
              <polygon points="370.782,266.884 370.782,265.724 371.742,265.134 373.802,266.224 372.782,266.844 372.782,267.934 			" />
              <polygon points="372.422,235.124 344.832,251.004 342.732,249.904 370.882,233.714 			" />
              <polygon points="371.742,265.134 370.782,265.724 370.782,266.884 366.582,264.684 366.582,263.594 367.622,262.974 			" />
              <polygon
                fill="#383838"
                points="370.882,233.714 342.732,249.904 335.272,245.984 311.393,231.844 341.302,214.034 
              363.032,226.484 			"
              />
              <polygon points="366.912,279.684 366.912,280.394 364.912,280.394 364.912,278.984 			" />
              <polygon points="366.582,263.594 366.582,264.684 364.582,263.634 364.582,262.454 365.542,261.884 367.622,262.974 			" />
              <polygon points="363.832,260.984 365.542,261.884 364.582,262.454 364.582,263.634 362.772,262.684 362.772,261.604 			" />
              <polygon fill="#16AEB8" points="364.912,277.374 364.912,278.984 363.092,278.354 363.092,276.514 			" />
              <polygon fill="#16AEB8" points="364.582,263.634 364.582,268.874 362.772,267.824 362.772,262.684 			" />
              <polygon
                points="366.912,276.104 366.912,279.684 364.912,278.984 364.912,277.374 363.092,276.514 363.092,278.354 
              361.092,277.654 361.092,273.374 			"
              />
              <polygon points="363.092,278.354 363.092,279.054 361.092,279.054 361.092,277.654 			" />
              <polygon
                points="360.772,268.974 360.772,261.634 362.772,262.684 362.772,267.824 364.582,268.874 364.582,263.634 
              366.582,264.684 366.582,272.344 			"
              />
              <polygon points="362.772,261.604 362.772,262.684 360.772,261.634 360.772,260.454 361.742,259.884 363.832,260.984 			" />
              <polygon points="361.742,259.884 360.772,260.454 360.772,261.634 356.012,259.124 356.012,258.064 357.103,257.444 			" />
              <polygon points="356.012,258.064 356.012,259.124 354.012,258.074 354.012,256.894 354.992,256.334 357.103,257.444 			" />
              <polygon points="355.982,275.864 355.982,276.904 353.982,276.904 353.982,275.174 			" />
              <polygon
                points="382.522,287.244 353.862,277.234 353.982,276.904 355.982,276.904 355.982,275.864 361.092,277.654 
              361.092,279.054 363.092,279.054 363.092,278.354 364.912,278.984 364.912,280.394 366.912,280.394 366.912,279.684 
              370.782,281.034 370.782,282.444 372.782,282.444 372.782,281.734 374.332,282.274 374.302,283.644 376.302,283.694 
              376.322,282.964 381.353,284.724 381.353,286.134 383.353,286.134 383.353,284.184 386.872,281.654 388.772,282.754 			"
              />
              <polygon points="354.992,256.334 354.012,256.894 354.012,258.074 352.362,257.214 352.362,256.144 353.442,255.524 			" />
              <polygon
                points="353.982,272.164 352.042,271.244 352.042,274.494 350.042,273.794 350.042,268.074 355.982,270.904 
              355.982,275.864 353.982,275.174 			"
              />
              <polygon fill="#16AEB8" points="354.012,258.074 354.012,263.474 352.362,262.464 352.362,257.214 			" />
              <polygon fill="#16AEB8" points="353.982,272.164 353.982,275.174 352.042,274.494 352.042,271.244 			" />
              <polygon
                points="356.012,267.034 350.362,263.584 350.362,256.164 352.362,257.214 352.362,262.464 354.012,263.474 
              354.012,258.074 356.012,259.124 			"
              />
              <polygon points="350.362,256.164 350.362,254.984 351.342,254.424 353.442,255.524 352.362,256.144 352.362,257.214 			" />
              <polygon points="352.042,274.494 352.042,275.204 350.042,275.204 350.042,273.794 			" />
              <polygon points="351.342,254.424 350.362,254.984 350.362,256.164 347.662,254.744 347.662,253.664 348.723,253.044 			" />
              <polygon
                fill="#383838"
                points="350.042,268.074 350.042,273.794 347.692,272.974 347.692,267.444 341.762,264.614 
              341.762,270.904 338.372,269.724 310.433,254.254 310.433,233.594 334.302,247.724 341.762,251.644 341.762,259.114 
              347.662,262.414 347.662,254.744 350.362,256.164 350.362,263.584 356.012,267.034 356.012,259.124 360.772,261.634 
              360.772,268.974 366.582,272.344 366.582,264.684 370.782,266.884 370.782,273.384 376.393,275.184 376.393,269.834 
              381.353,272.444 381.353,284.724 376.322,282.964 376.412,278.864 370.782,277.824 370.782,281.034 366.912,279.684 
              366.912,276.104 361.092,273.374 361.092,277.654 355.982,275.864 355.982,270.904 			"
              />
              <polygon points="347.692,272.974 347.692,273.684 345.692,273.684 345.692,272.274 			" />
              <polygon points="347.662,253.664 347.662,254.744 345.662,253.694 345.662,252.514 346.632,251.954 348.723,253.044 			" />
              <polygon points="344.832,251.004 346.632,251.954 345.662,252.514 345.662,253.694 343.762,252.694 343.762,251.624 			" />
              <polygon fill="#16AEB8" points="345.692,268.704 345.692,272.274 343.762,271.604 343.762,267.784 			" />
              <polygon fill="#16AEB8" points="345.662,253.694 345.662,259.004 343.762,257.944 343.762,252.694 			" />
              <polygon
                points="345.692,268.704 343.762,267.784 343.762,271.604 341.762,270.904 341.762,264.614 347.692,267.444 
              347.692,272.974 345.692,272.274 			"
              />
              <polygon points="343.762,271.604 343.762,272.314 341.762,272.314 341.762,270.904 			" />
              <polygon
                points="341.762,259.114 341.762,251.644 343.762,252.694 343.762,257.944 345.662,259.004 345.662,253.694 
              347.662,254.744 347.662,262.414 			"
              />
              <polygon points="341.762,250.464 342.732,249.904 344.832,251.004 343.762,251.624 343.762,252.694 341.762,251.644 			" />
              <polygon
                points="353.862,277.234 337.482,271.514 310.433,256.534 310.433,254.254 338.372,269.724 341.762,270.904 
              341.762,272.314 343.762,272.314 343.762,271.604 345.692,272.274 345.692,273.684 347.692,273.684 347.692,272.974 
              350.042,273.794 350.042,275.204 352.042,275.204 352.042,274.494 353.982,275.174 353.982,276.904 			"
              />
              <polygon
                points="335.272,245.984 342.732,249.904 341.762,250.464 341.762,251.644 334.302,247.724 310.433,233.594 
              310.433,232.414 311.393,231.844 			"
              />
              <polygon points="314.442,206.784 313.482,208.534 311.853,207.644 313.973,206.524 			" />
              <polygon points="313.973,206.524 311.853,207.644 298.092,200.154 299.762,199.284 299.762,198.784 			" />
              <polygon points="311.393,231.844 310.433,232.414 310.433,231.274 			" />
              <polygon points="311.092,208.854 310.612,209.734 309.272,209.004 310.342,208.444 			" />
              <polygon points="310.433,256.534 310.433,256.574 309.092,255.794 			" />
              <polygon points="310.433,254.254 310.433,256.534 309.092,255.794 308.942,255.714 309.912,253.964 			" />
              <polygon points="310.433,232.414 310.433,233.594 308.922,232.704 309.433,231.844 309.942,232.704 			" />
              <polygon points="310.433,231.274 310.433,232.414 309.942,232.704 309.433,231.844 309.942,230.984 			" />
              <polygon points="310.433,231.244 310.433,231.274 309.942,230.984 			" />
              <polygon fill="#8C8C8C" points="310.342,208.444 309.272,209.004 295.552,201.474 296.622,200.914 			" />
              <polygon points="308.912,230.984 309.412,230.684 309.942,230.984 309.433,231.844 			" />
              <polygon points="308.042,210.474 307.563,211.344 306.223,210.614 307.272,210.054 			" />
              <polygon fill="#8C8C8C" points="293.542,202.514 307.272,210.054 306.223,210.614 292.473,203.074 			" />
              <polygon points="304.992,212.084 304.502,212.954 303.162,212.224 304.223,211.664 			" />
              <polygon fill="#8C8C8C" points="290.462,204.114 304.223,211.664 303.162,212.224 289.393,204.674 			" />
              <polygon points="301.933,213.694 301.452,214.574 300.103,213.834 301.162,213.274 			" />
              <polygon fill="#8C8C8C" points="301.162,213.274 300.103,213.834 286.312,206.274 287.382,205.714 			" />
              <polygon
                points="378.492,238.954 377.302,239.634 375.172,237.664 375.802,237.294 374.792,235.564 373.622,236.244 
              372.422,235.124 373.052,234.764 372.052,233.034 370.882,233.714 363.032,226.484 341.302,214.034 311.393,231.844 
              310.433,231.274 310.433,231.244 309.942,230.984 309.412,230.684 337.412,213.884 301.723,194.624 301.012,194.934 
              320.242,205.474 313.692,208.934 313.482,208.534 314.442,206.784 313.973,206.524 316.022,205.444 299.762,196.524 
              299.762,194.844 297.762,194.844 297.762,195.434 296.512,194.744 301.802,192.394 339.532,212.764 341.282,211.724 
              364.313,224.944 386.473,245.354 386.302,245.544 384.723,246.464 381.563,243.554 381.952,243.334 380.962,241.604 
              380.022,242.134 378.842,241.044 379.482,240.684 			"
              />
              <polygon points="299.762,198.784 299.762,199.284 298.092,200.154 297.882,200.034 298.842,198.284 			" />
              <polygon points="299.762,194.844 299.762,196.524 297.762,195.434 297.762,194.844 			" />
              <polygon points="299.002,215.374 298.522,216.254 297.052,215.444 298.112,214.884 			" />
              <polygon fill="#8C8C8C" points="298.112,214.884 297.052,215.444 283.242,207.864 284.312,207.314 			" />
              <polygon points="296.622,200.914 295.552,201.474 295.002,201.174 295.482,200.294 			" />
              <polygon points="295.822,216.914 295.342,217.794 293.992,217.054 295.063,216.494 			" />
              <polygon fill="#8C8C8C" points="295.063,216.494 293.992,217.054 280.162,209.464 281.232,208.914 			" />
              <polygon points="293.542,202.514 292.473,203.074 291.882,202.744 292.362,201.874 			" />
              <polygon points="290.462,204.114 289.393,204.674 288.762,204.324 289.242,203.444 			" />
              <polygon points="287.382,205.714 286.312,206.274 285.642,205.904 286.122,205.024 			" />
              <polygon points="283.012,206.594 284.312,207.314 283.242,207.864 282.522,207.474 			" />
              <polygon points="279.892,208.174 281.232,208.914 280.162,209.464 279.412,209.054 			" />
              <polygon
                points="279.412,209.054 280.162,209.464 278.732,210.214 276.602,209.074 297.762,198.064 297.762,195.434 
              299.762,196.524 299.762,198.784 298.842,198.284 297.882,200.034 298.092,200.154 296.622,200.914 295.482,200.294 
              295.002,201.174 295.552,201.474 293.542,202.514 292.362,201.874 291.882,202.744 292.473,203.074 290.462,204.114 
              289.242,203.444 288.762,204.324 289.393,204.674 287.382,205.714 286.122,205.024 285.642,205.904 286.312,206.274 
              284.312,207.314 283.012,206.594 282.522,207.474 283.242,207.864 281.232,208.914 279.892,208.174 			"
              />
              <polygon points="276.602,209.074 278.732,210.214 277.052,211.084 276.132,209.314 			" />
              <polygon
                points="277.052,211.084 278.732,210.214 292.762,217.704 293.992,217.054 295.342,217.794 295.822,216.914 
              295.063,216.494 297.052,215.444 298.522,216.254 299.002,215.374 298.112,214.884 300.103,213.834 301.452,214.574 
              301.933,213.694 301.162,213.274 303.162,212.224 304.502,212.954 304.992,212.084 304.223,211.664 306.223,210.614 
              307.563,211.344 308.042,210.474 307.272,210.054 309.272,209.004 310.612,209.734 311.092,208.854 310.342,208.444 
              311.853,207.644 313.482,208.534 313.692,208.934 292.762,219.974 273.392,209.624 272.002,210.254 269.812,209.054 
              273.462,207.394 276.602,209.074 276.132,209.314 			"
              />
              <polygon points="272.002,210.254 270.372,211.004 270.182,211.084 269.352,209.264 269.812,209.054 			" />
              <polygon
                points="270.372,211.004 272.002,210.254 309.412,230.684 308.912,230.984 309.433,231.844 308.922,232.704 
              308.433,232.704 308.433,232.434 270.762,211.864 			"
              />
              <polygon
                points="268.762,232.414 268.762,208.484 269.812,209.054 269.352,209.264 270.182,211.084 270.372,211.004 
              270.762,211.864 270.762,231.264 308.433,253.104 308.433,232.704 308.922,232.704 310.433,233.594 310.433,254.254 
              309.912,253.964 308.942,255.714 			"
              />
              <path d="M214.862,162.574l-90.44,52.95v-100.5l90.44-52.95V162.574z M127.422,210.294l84.44-49.44v-93.55l-84.44,49.44V210.294z" />
              <polygon points="184.822,114.474 184.822,115.044 183.452,115.864 183.452,115.714 182.822,115.714 182.822,114.474 			" />
              <polygon points="182.822,116.244 182.822,115.714 183.452,115.714 183.452,115.864 			" />
              <polygon points="183.452,121.044 182.822,121.404 182.822,116.244 183.452,115.864 			" />
              <polygon
                fill="#222222"
                points="182.822,121.404 182.822,136.564 174.862,140.694 174.862,146.654 171.602,148.234 
              171.602,152.644 163.502,157.474 161.082,156.064 161.082,154.564 156.932,151.264 151.812,153.964 151.812,139.374 			"
              />
              <polygon points="182.822,115.714 182.822,116.244 181.452,117.054 181.452,115.714 			" />
              <polygon points="180.922,120.204 180.922,121.594 179.922,121.594 179.922,120.784 			" />
              <polygon fill="#8C8C8C" points="180.922,117.374 180.922,120.204 179.922,120.784 179.922,117.964 			" />
              <polygon points="179.922,117.964 179.922,116.914 180.922,116.914 180.922,117.374 			" />
              <polygon points="178.482,121.614 178.482,123.014 177.482,123.014 177.482,122.194 			" />
              <polygon fill="#8C8C8C" points="178.482,118.824 178.482,121.614 177.482,122.194 177.482,119.424 			" />
              <polygon points="178.482,118.334 178.482,118.824 177.482,119.424 177.482,118.334 			" />
              <polygon points="175.042,124.434 175.042,123.604 176.042,123.024 176.042,124.434 			" />
              <polygon fill="#8C8C8C" points="176.042,120.284 176.042,123.024 175.042,123.604 175.042,120.884 			" />
              <polygon points="176.042,119.754 176.042,120.284 175.042,120.884 175.042,119.754 			" />
              <polygon points="172.602,125.844 172.602,125.024 173.602,124.444 173.602,125.844 			" />
              <polygon fill="#8C8C8C" points="173.602,121.734 173.602,124.444 172.602,125.024 172.602,122.334 			" />
              <polygon points="172.602,122.334 172.602,121.164 173.602,121.164 173.602,121.734 			" />
              <polygon points="170.172,127.264 170.172,126.424 171.172,125.854 171.172,127.264 			" />
              <polygon fill="#8C8C8C" points="171.172,123.184 171.172,125.854 170.172,126.424 170.172,123.784 			" />
              <polygon points="171.172,122.584 171.172,123.184 170.172,123.784 170.172,122.584 			" />
              <polygon points="167.732,128.684 167.732,127.844 168.732,127.264 168.732,128.684 			" />
              <polygon fill="#8C8C8C" points="168.732,124.644 168.732,127.264 167.732,127.844 167.732,125.244 			" />
              <polygon points="167.732,125.244 167.732,124.004 168.732,124.004 168.732,124.644 			" />
              <polygon points="165.292,130.094 165.292,129.254 166.292,128.684 166.292,130.094 			" />
              <polygon fill="#8C8C8C" points="166.292,126.094 166.292,128.674 166.292,128.684 165.292,129.254 165.292,126.694 			" />
              <polygon points="165.292,126.694 165.292,125.414 166.292,125.414 166.292,126.094 			" />
              <polygon points="163.852,130.094 163.852,131.514 162.852,131.514 162.852,130.674 			" />
              <polygon fill="#8C8C8C" points="163.852,127.554 163.852,130.094 162.852,130.674 162.852,128.154 			" />
              <polygon points="163.852,126.834 163.852,127.554 162.852,128.154 162.852,126.834 			" />
              <polygon points="161.902,158.854 164.172,157.384 164.172,159.404 163.512,159.794 			" />
              <polygon
                points="173.602,153.774 164.172,159.404 164.172,157.384 161.902,158.854 161.082,158.374 161.082,156.064 
              163.502,157.474 171.602,152.644 171.602,148.234 174.862,146.654 174.862,140.694 182.822,136.564 182.822,121.404 
              183.452,121.044 183.452,115.864 184.822,115.044 184.822,137.784 176.862,141.904 176.862,147.904 173.602,149.494 			"
              />
              <polygon
                points="164.172,162.524 176.112,155.874 176.112,151.074 179.452,149.484 179.452,143.334 186.982,139.404 
              187.442,113.484 184.822,115.044 184.822,114.474 182.822,114.474 182.572,114.054 189.502,109.924 188.962,140.624 
              181.452,144.544 181.452,150.754 178.112,152.334 178.112,157.054 162.172,165.934 162.172,161.054 161.322,161.604 
              161.082,161.224 161.082,159.374 161.902,158.854 163.512,159.794 164.172,159.404 			"
              />
              <polygon points="160.412,132.934 160.412,132.084 161.412,131.504 161.412,132.934 			" />
              <polygon fill="#8C8C8C" points="161.412,129.004 161.412,131.504 160.412,132.084 160.412,129.604 			" />
              <polygon points="160.412,129.604 160.412,128.254 161.412,128.254 161.412,129.004 			" />
              <polygon points="161.082,158.374 161.082,159.374 159.912,160.134 159.912,157.694 			" />
              <polygon points="161.082,156.064 161.082,158.374 159.912,157.694 159.912,156.914 160.582,155.774 			" />
              <polygon points="159.912,156.914 159.912,157.694 159.572,157.494 			" />
              <polygon
                points="159.082,155.524 158.872,155.354 159.912,154.804 159.912,156.914 159.572,157.494 159.912,157.694 
              159.912,160.134 161.082,159.374 161.082,161.224 159.082,161.224 			"
              />
              <polygon points="157.972,134.354 157.972,133.494 158.972,132.914 158.972,134.354 			" />
              <polygon fill="#8C8C8C" points="158.972,130.464 158.972,132.914 157.972,133.494 157.972,131.064 			" />
              <polygon points="157.972,131.064 157.972,129.674 158.972,129.674 158.972,130.464 			" />
              <polygon
                points="156.712,153.644 151.812,156.234 151.812,153.964 156.932,151.264 161.082,154.564 161.082,156.064 
              160.582,155.774 159.912,156.914 159.912,154.804 158.872,155.354 			"
              />
              <polygon points="155.532,135.764 155.532,134.914 156.532,134.334 156.532,135.764 			" />
              <polygon fill="#8C8C8C" points="156.532,131.914 156.532,134.334 155.532,134.914 155.532,132.514 			" />
              <polygon points="155.532,132.514 155.532,131.084 156.532,131.084 156.532,131.914 			" />
              <polygon points="153.102,137.184 153.102,136.314 154.102,135.734 154.102,137.184 			" />
              <polygon fill="#8C8C8C" points="154.102,133.364 154.102,135.734 153.102,136.314 153.102,133.964 			" />
              <polygon points="154.102,132.504 154.102,133.364 153.102,133.964 153.102,132.504 			" />
              <polygon
                points="182.822,121.404 151.812,139.374 151.812,137.064 153.102,136.314 153.102,137.184 154.102,137.184 
              154.102,135.734 155.532,134.914 155.532,135.764 156.532,135.764 156.532,134.334 157.972,133.494 157.972,134.354 
              158.972,134.354 158.972,132.914 160.412,132.084 160.412,132.934 161.412,132.934 161.412,131.504 162.852,130.674 
              162.852,131.514 163.852,131.514 163.852,130.094 165.292,129.254 165.292,130.094 166.292,130.094 166.292,128.684 
              166.292,128.674 167.732,127.844 167.732,128.684 168.732,128.684 168.732,127.264 170.172,126.424 170.172,127.264 
              171.172,127.264 171.172,125.854 172.602,125.024 172.602,125.844 173.602,125.844 173.602,124.444 175.042,123.604 
              175.042,124.434 176.042,124.434 176.042,123.024 177.482,122.194 177.482,123.014 178.482,123.014 178.482,121.614 
              179.922,120.784 179.922,121.594 180.922,121.594 180.922,120.204 181.452,119.894 181.452,117.054 182.822,116.244 			"
              />
              <polygon
                points="150.102,133.424 182.572,114.054 182.822,114.474 182.822,115.714 181.452,115.714 181.452,117.054 
              180.922,117.374 180.922,116.914 179.922,116.914 179.922,117.964 178.482,118.824 178.482,118.334 177.482,118.334 
              177.482,119.424 176.042,120.284 176.042,119.754 175.042,119.754 175.042,120.884 173.602,121.734 173.602,121.164 
              172.602,121.164 172.602,122.334 171.172,123.184 171.172,122.584 170.172,122.584 170.172,123.784 168.732,124.644 
              168.732,124.004 167.732,124.004 167.732,125.244 166.292,126.094 166.292,125.414 165.292,125.414 165.292,126.694 
              163.852,127.554 163.852,126.834 162.852,126.834 162.852,128.154 161.412,129.004 161.412,128.254 160.412,128.254 
              160.412,129.604 158.972,130.464 158.972,129.674 157.972,129.674 157.972,131.064 156.532,131.914 156.532,131.084 
              155.532,131.084 155.532,132.514 154.102,133.364 154.102,132.504 153.102,132.504 153.102,133.964 151.812,134.734 
              151.812,137.064 150.312,137.934 149.812,137.934 149.812,133.594 			"
              />
              <polygon points="151.812,153.964 151.812,156.234 151.272,156.514 150.342,154.744 			" />
              <polygon points="151.812,137.064 151.812,139.374 151.312,139.664 150.312,137.934 			" />
              <polygon
                points="149.812,154.744 149.812,137.934 150.312,137.934 151.312,139.664 151.812,139.374 151.812,153.964 
              150.342,154.744 			"
              />
              <polygon
                points="149.812,162.374 149.812,154.744 150.342,154.744 151.272,156.514 151.812,156.234 151.812,159.064 
              158.872,155.354 159.082,155.524 159.082,161.224 161.082,161.224 161.322,161.604 157.912,163.814 157.912,158.124 			"
              />
              <path
                d="M149.792,132.904c-0.87,0.58-1.3,1.06-1.3,1.86v36.81c0,1.11,0.77,2.02,1.77,2.15l28.21-16.04l9.15-5.21
              c0.35-0.2,0.68-0.38,0.96-0.54c1.51-0.84,1.54-0.88,1.54-1.75l0.75-39.18c0-1.08-0.77-1.99-1.75-2.12l-38.31,23.39
              C150.422,132.504,150.082,132.714,149.792,132.904z M146.492,134.764c0-2.24,1.68-3.24,3.3-4.21l0.19-0.12l38.61-23.57h0.28
              c2.2,0,4,1.86,4,4.16l-0.75,39.18c0,2.04-0.97,2.58-2.56,3.47c-0.28,0.16-0.59,0.33-0.94,0.53l-37.86,21.53h-0.27
              c-2.2,0-4-1.86-4-4.16V134.764z"
              />
              <path d="M122.932,217.924l93.42-54.7V59.674l-93.42,54.7V217.924z M218.352,164.374l-97.42,57.03v-108.18l97.42-57.03V164.374z" />
            </g>
            <g></g>
          </g>
        </g>
      </svg>
    </div>
    <div class="text404">
      <h3>페이지를 <span>찾을 수 없습니다.</span></h3>
      <h4>(404 Not Found)</h4>
      <el-button @click="click">전 페이지로</el-button>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    click: function () {
      return this.$router.go(-2);
    },
  },
};
</script>
<style scoped></style>
