<template>
  <el-dialog :visible.sync="formDialog" :title="formTitle" width="80%" top="5vh" @close="hideDialog" @open="openDialog" :close-on-click-modal="false">
    <!-- 시설물 관리 dialog -->
    <div>
      <el-steps :active="step" finish-status="success" align-center style="margin: 5px 0 10px 0;" ref="fcltStep">
        <el-step v-for="(s, index) in stepData" :key="index" :title="s.title" @click="alert('qwer')"></el-step>
      </el-steps>
      <div v-show="step === 0" class="fclt-form-step">
        <el-form :model="fcltDataDoc" label-width="120px" size="medium" :rules="rules" ref="form0">
          <el-row>
            <el-col :xs="20" :sm="20" :md="18">
              <el-form-item label="시설물 ID" prop="fcltId">
                <el-input v-model="fcltDataDoc.fcltId" placeholder="시설물 ID" :disabled="updateFlag || autoMakeFcltId"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="4" :sm="4" :md="6">
              <el-checkbox v-show="!updateFlag" v-model="autoMakeFcltId" @change="makeFcltId">자동 ID 생성</el-checkbox>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12">
              <el-form-item label="시설물 명칭" prop="fcltName">
                <el-input v-model="fcltDataDoc.fcltName" placeholder="시설물 명칭"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12">
              <el-form-item label="시설물 유형" prop="fcltTypeId">
                <el-select v-model="fcltDataDoc.fcltTypeId" placeholder="시설물 유형" @change="selectFcltType">
                  <el-option v-for="item in fcltType" :key="item.fcltTypeId" :label="item.fcltTypeName" :value="item.fcltTypeId"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24">
              <el-form-item label="설치 일자" prop="setDate">
                <el-date-picker type="date" v-model="fcltDataDoc.setDate" placeholder="설치 일자"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12">
              <el-form-item label="지번 주소" prop="setNumberAddr">
                <el-input v-model="fcltDataDoc.setNumberAddr" placeholder="지번 주소"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12">
              <el-form-item label="도로명 주소" prop="setRoadAddr">
                <el-input v-model="fcltDataDoc.setRoadAddr" placeholder="도로명 주소"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12">
              <el-form-item label="위도" prop="gisLat">
                <el-input v-model="fcltDataDoc.gisLat" placeholder="위도"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12">
              <el-form-item label="경도" prop="gisLng">
                <el-input v-model="fcltDataDoc.gisLng" placeholder="경도"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <dk-gis
          ref="fcltManageDialogGis"
          style="height: 350px;"
          :markerData="markerData"
          :gisApiInfo="gisApiInfo"
          :apiLoadComplete="fcltManageDialogGisInit"
          :markerDragEnd="markerDragEnd"
          :markerInfoWindow="false"
        ></dk-gis>
      </div>

      <!-- <div v-show="step === 1" class="fclt-form-step">
        <el-dialog width="30%" title="장비 목록" :visible.sync="materialListDialog" @close="hideMaterialDialog" append-to-body>
          <el-table :data="materialList" style="width: 100%;">
            <el-table-column label width="100px">
              <template slot-scope="scope">
                <img
                  v-if="scope.row.images && scope.row.images.length > 0"
                  :src="scope.row.images[0].serverFilePath"
                  style="height: 60px; width: auto;"
                />
              </template>
            </el-table-column>
            <el-table-column label="장비 유형" width="120px" prop="materialTypeName"></el-table-column>
            <el-table-column label="장비 명칭" prop="materialName"></el-table-column>
          </el-table>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="hideMaterialDialog">추가</el-button>
          </div>
        </el-dialog>
        <el-row>
          <el-col :md="14" :sm="20" :xs="24">
            <el-form label-width="120px" size="medium" :label-position="$isMobile ? 'top' : 'right'">
              <el-form-item label="장비 검색" prop="equipSearch">
                <el-select
                  v-model="tempMaterialId"
                  filterable
                  remote
                  reserve-keyword
                  placeholder="장비 모델명을 입력하세요"
                  :remote-method="searchMaterialData"
                  :loading="loading"
                  style="width: calc(100% - 120px);"
                >
                  <el-option v-for="material in materialList" :key="material.materialId" :label="material.materialName" :value="material.materialId">
                    <span style="float: left; text-align: center;" v-if="material.images && material.images.length > 0">
                      <img :src="material.images[0].serverFilePath" style="height: 34px; width: auto;" />
                    </span>
                    <span style="float: left; margin-left: 10px;">{{ material.materialName }}</span>
                  </el-option>
                </el-select>
                <el-button @click="addSelectEquipList">추가</el-button>
              </el-form-item>
            </el-form>
          </el-col>

              <el-col :span="8" style="padding:0 15px">
                  
                   <el-button @click="showMaterialDialog">
                        장비 목록
                    </el-button>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12">
            <el-table
              v-if="checkEmptyEquip"
              :data="fcltDataDoc.equip"
              style="width: 100%;"
              highlight-current-row
              @current-change="showSelectEquipForm"
            >
              <el-table-column label width="100px" style="text-align: center;">
                <template slot-scope="scope">
                  <img
                    v-if="scope.row.images && scope.row.images.length > 0"
                    :src="scope.row.images[0].serverFilePath"
                    style="height: 60px; width: auto;"
                  />
                </template>
              </el-table-column>
              <el-table-column label="장비 유형" width="150px" prop="materialTypeName"></el-table-column>
              <el-table-column label="장비 명칭" prop="materialName"></el-table-column>
              <el-table-column label width="100px">
                <template slot-scope="scope">
                  <el-button type="danger" size="mini" @click.stop="deleteEquipList(scope.$index, fcltDataDoc.equip)">삭제</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12">
            <dk-dynamic-form labelWidth="120px" :formData="selectEquipFormData"></dk-dynamic-form>
          </el-col>
        </el-row>
      </div> -->

      <div v-show="step === 1" class="fclt-form-step">
        <el-row>
          <el-col :span="18">
            <dk-dynamic-form labelWidth="200px" :formData="fcltDataDoc.fcltTypeData"></dk-dynamic-form>
          </el-col>
        </el-row>
      </div>

      <div v-show="step === 2" class="fclt-form-step">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12">
            <h3>시설물 관련 사진</h3>
            <dk-el-file-upload ref="uploadImage" uploaderType="image" listType="picture-card" path="fclt" :fileList="fcltDataDoc.images">
              <div>
                <i class="el-icon-plus"></i>
              </div>
            </dk-el-file-upload>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12">
            <h3>시설물 관련 자료</h3>
            <dk-el-file-upload ref="uploadFile" path="fcltDoc" :fileList="fcltDataDoc.files" :drag="true" :multiple="true">
              <div>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  이곳에 업로드할 파일을 드롭하시거나
                  <br />
                  <em>클릭해서 업로드하세요</em>
                </div>
              </div>
            </dk-el-file-upload>
          </el-col>
        </el-row>
      </div>
      <div class="form-bottom">
        <el-button @click="prev" style="float: left;" v-show="step > 0">
          <i class="fas fa-angle-left" style="margin-right: 10px;"></i>이전
        </el-button>
        <el-button @click="next" style="float: right;" v-show="step < stepData.length - 1">
          다음
          <i class="fas fa-angle-right" style="margin-left: 10px;"></i>
        </el-button>
        <el-button @click="save" style="float: right;" v-show="step === stepData.length - 1">
          저장
          <i class="fas fa-angle-right" style="margin-left: 10px;"></i>
        </el-button>
        <el-button @click="save" style="float: right;" v-show="updateFlag && step !== stepData.length - 1">저장</el-button>
      </div>
    </div>
    <!-- 시설물 관리 dialog 끝 -->
  </el-dialog>
</template>

<script>
import dkgismain from '@/components/dkGis/dkGisMain.vue';
import dkdynamicform from '@/components/dkDynamicForm.vue';
import uuid from 'uuid/v1';
import dkElFileUpload from '@/components/dkElFileUpload.vue';

export default {
  components: {
    'dk-gis': dkgismain,
    'dk-dynamic-form': dkdynamicform,
    'dk-el-file-upload': dkElFileUpload,
  },
  created() {
    let me = this;
    this.getFcltType();
  },
  data() {
    var validateFcltId = async (rule, value, callback) => {
      if (!this.autoMakeFcltId) {
        if (value === '') {
          callback(new Error('시설물 ID를 입력해 주세요'));
        }
      } else {
        callback();
      }
    };
    return {
      formDialog: false,
      formTitle: '시설물 추가',
      updateFlag: false,
      fcltType: [],
      initStepClick: false,
      step: 0,
      stepData: [
        { idx: 0, title: '시설물 기본 정보' },
        // { idx: 1, title: '장비 정보' },
        { idx: 1, title: '시설물 유형 정보' },
        { idx: 2, title: '관련 파일' },
      ],
      systemLogInfo: {},
      gisInit: false,
      autoMakeFcltId: false,
      fcltDataDoc: {
        fcltName: '',
        fcltTypeName: '',
        fcltTypeId: '',
        regionId: '',
        regionName: '',
        setDate: '',
        setNumberAddr: '',
        setRoadAddr: '',
        gisLat: '',
        gisLng: '',
        equip: [],
        fcltTypeData: {},
        files: [],
        images: [],
        keywords: [],
        fcltId: '',
      },
      tempFcltTypeData: {},
      rules: {
        fcltId: [
          {
            required: true,
            validator: validateFcltId,
            trigger: ['change', 'blur'],
          },
        ],
        fcltName: [
          {
            required: true,
            message: '시설물 명칭을 입력해 주세요',
            trigger: ['change', 'blur'],
          },
          {
            min: 3,
            message: '3자 이상 입력해주세요',
            trigger: ['change', 'blur'],
          },
        ],
        fcltTypeId: [
          {
            required: true,
            message: '시설물 유형을 선택해 주세요',
            trigger: 'change',
          },
        ],
        setDate: [
          {
            required: true,
            type: 'date',
            message: '설치 일자를 선택해 주세요',
            trigger: 'change',
          },
        ],
        setNumberAddr: [
          {
            required: true,
            message: '시설물 설치 주소가 입력되야 합니다.',
            trigger: ['change', 'blur'],
          },
        ],
        // setRoadAddr: [
        //   {
        //     required: true,
        //     message: '시설물 설치 주소가 입력되야 합니다.',
        //     trigger: ['change', 'blur'],
        //   },
        // ],
        gisLat: [
          {
            required: true,
            message: '시설물 설치 좌표가 유효하지 않습니다.',
            trigger: ['change', 'blur'],
          },
        ],
        gisLng: [
          {
            required: true,
            message: '시설물 설치 좌표가 유효하지 않습니다.',
            trigger: ['change', 'blur'],
          },
        ],
      },
      markerData: [
        {
          seq: 0,
          draggable: true,
          geometry: {
            // lng: 129.30534838238188,
            // lat: 35.572932085143
            lng: 127.10056150323506,
            lat: 37.489008855218195,
          },
          properties: {},
        },
      ],
      materialListDialog: false,
      loading: false,
      materialList: [],
      tempMaterialId: null,
      selectEquipFormData: {},
      templateData: null,
    };
  },
  computed: {
    checkEmptyEquip() {
      if (!this.fcltDataDoc.equip) {
        return false;
      }
      if (this.fcltDataDoc.equip && this.fcltDataDoc.equip.length > 0) {
        if (this.fcltDataDoc.equip[0]) {
          return true;
        }
        this.fcltDataDoc.equip.splice(0, 1);
      }
      return false;
    },
  },
  props: {
    gisApiInfo: {
      type: Array,
      // gis 기본 설정값을 띄우는 요부분은 나중에 vuex에서 받아서 처리할것
      default() {
        return [];
      },
    },
    geometry: {
      type: Object,
      default: null,
    },
  },
  updated() {
    this.$nextTick(() => {
      if (this.$refs.fcltStep && !this.initStepClick) {
        this.initStepClick = true;
        this.$refs.fcltStep.$children.forEach((val, key) => {
          val.$el.onclick = () => {
            if (this.updateFlag) {
              this.step = key;
            }
          };
        });
      }
      if (this.gisInit) {
        return;
      }
      if (this.formDialog && this.$refs.fcltManageDialogGis) {
        let api = this.gisApiInfo.find((apiInfo) => {
          return apiInfo.initGis;
        });
        this.$refs.fcltManageDialogGis.gisModuleLoad(api.gisName);
        this.gisInit = true;
        //if (this.geometry) {
        //this.setCenter(this.geometry);
        //}
      }
    });
  },
  methods: {
    async getFcltType() {
      try {
        let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/fcltType`, {});
        this.fcltType = response.data.docs;
      } catch (error) {
        this.$message({
          type: 'info',
          message: `시설물유형 로드 에러`,
        });
      }
    },
    next() {
      if (this.$refs[`form${this.step}`]) {
        this.$refs[`form${this.step}`].validate((valid) => {
          if (valid) {
            this.step++;
          } else {
            return false;
          }
        });
      } else {
        this.step++;
      }

      if (this.step == 1) {
      } else if (this.step == 2) {
        let options = [];
        this.fcltDataDoc.equip.forEach((equip) => {
          options.push({
            label: equip.materialName,
            value: equip.equipId,
          });
        });
        for (var key in this.fcltDataDoc.fcltTypeData) {
          if (this.fcltDataDoc.fcltTypeData[key].valueRef && this.fcltDataDoc.fcltTypeData[key].valueRef === 'equip') {
            this.fcltDataDoc.fcltTypeData[key].options = this.$lodash.cloneDeep(options);
          }
        }
      }
    },
    prev() {
      if (this.step == 1) {
      }
      this.step--;
    },
    async save() {
      try {
        let saveFunc = 'fclt/add';
        if (this.updateFlag) {
          saveFunc = 'fclt/update';
        }
        this.systemLogInfo.page = `${this.$route.name} ${this.fcltDataDoc.fcltName}`;
        this.fcltDataDoc.files = this.$refs.uploadFile.getFileData();
        this.fcltDataDoc.images = this.$refs.uploadImage.getFileData();
        let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/${saveFunc}`, {
          data: this.fcltDataDoc,
        });
        this.$parent.$emit('procDataComplete', this.fcltDataDoc);
        this.hideDialog();
        this.$store.dispatch('license/setCurrentFcltCount', response.data.currentFcltCount);
        this.$message({
          type: 'success',
          message: `${this.fcltDataDoc.fcltName} 시설물 저장 완료`,
        });
        this.systemLogInfo.saveType = saveFunc;
        await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/systemLog/add`, { data: this.systemLogInfo });
      } catch (err) {
        this.hideDialog();
        if (err.response.data && err.response.data.limitFcltCount) {
          const licenseInfo = this.$store.getters['license/getLicense'];
          if (licenseInfo.currentFcltCount >= licenseInfo.limitFcltCount) {
            this.$alert(
              `등록된 ${licenseInfo.currentFcltCount}건의 시설물 정보가 있고, 현재 라이선스는 총 ${licenseInfo.limitFcltCount}건의 시설물이 등록 가능하므로, 유효한 시설물 등록 수를 초과합니다.`,
              '라이선스 등록 초과',
              {
                confirmButtonText: '확인',
                type: 'warning',
              }
            );
            this.$store.dispatch('license/setDialog', true);
            return;
          }
        } else {
          this.$message.error(`${this.fcltDataDoc.fcltName} 시설물 저장 중 에러 발생`);
        }
      }
    },
    fcltManageDialogGisInit() {
      let me = this;
      if (this.$refs.fcltManageDialogGis) {
        if (this.geometry) {
          this.$refs.fcltManageDialogGis.setCenter(this.geometry);
          this.markerData[0].geometry = this.geometry;
        } else {
          let latlng = this.$refs.fcltManageDialogGis.getCenter();
          this.markerData[0].geometry = latlng;
        }

        this.$refs.fcltManageDialogGis.getAddrFromCenterCoord().then((result) => {
          me.fcltDataDoc.setNumberAddr = result.addr && result.addr.num_addr ? result.addr.num_addr : me.fcltDataDoc.setNumberAddr;
          me.fcltDataDoc.setRoadAddr = result.addr && result.addr.road_addr ? result.addr.road_addr : me.fcltDataDoc.setRoadAddr;
          me.fcltDataDoc.gisLat = result.geometry.lat ? result.geometry.lat : me.fcltDataDoc.gisLat;
          me.fcltDataDoc.gisLng = result.geometry.lng ? result.geometry.lng : me.fcltDataDoc.gisLng;
        });
        setTimeout(() => {
          this.$refs.fcltManageDialogGis.refresh();
        }, 100);
      } else {
        let latlng = this.$refs.fcltManageDialogGis.getCenter();
        this.markerData[0].geometry = latlng;
      }
      this.$refs.fcltManageDialogGis.getAddrFromCenterCoord().then((result) => {
        me.fcltDataDoc.setNumberAddr = result.addr && result.addr.num_addr ? result.addr.num_addr : me.fcltDataDoc.setNumberAddr;
        me.fcltDataDoc.setRoadAddr = result.addr && result.addr.road_addr ? result.addr.road_addr : me.fcltDataDoc.setRoadAddr;
        me.fcltDataDoc.gisLat = result.geometry.lat ? result.geometry.lat : me.fcltDataDoc.gisLat;
        me.fcltDataDoc.gisLng = result.geometry.lng ? result.geometry.lng : me.fcltDataDoc.gisLng;
      });
      setTimeout(() => {
        this.$refs.fcltManageDialogGis.refresh();
      }, 100);
    },
    setCenter(geometry) {
      this.$refs.fcltManageDialogGis.setCenter(geometry);
      this.markerData[0].geometry = geometry;
    },
    setFcltDataDoc(data) {
      let me = this;
      if (!data.equip || typeof data.equip !== 'object') {
        data.equip = [];
      }
      this.fcltDataDoc = this.$lodash.cloneDeep(data);
      this.fcltDataDoc.setDate = new Date(this.fcltDataDoc.setDate);
      if (this.fcltDataDoc.files && typeof this.fcltDataDoc.files === 'object') {
        this.fcltDataDoc.files.forEach((file) => {
          file.url = `${file.serverFilePath}`;
        });
      }
      if (this.fcltDataDoc.images && typeof this.fcltDataDoc.images === 'object') {
        this.fcltDataDoc.images.forEach((file) => {
          file.url = `${file.serverFilePath}`;
        });
      }
      this.tempFcltTypeData = data.fcltTypeData;
      if (!this.fcltDataDoc.fcltTypeData) {
        this.selectFcltType(this.fcltDataDoc.fcltTypeId);
      }
      this.$nextTick(() => {
        this.$refs.uploadFile.setFileData(this.fcltDataDoc.files);
        this.$refs.uploadImage.setFileData(this.fcltDataDoc.images);
      });
    },
    gisModuleLoad(gisName) {
      this.$refs.fcltManageDialogGis.gisModuleLoad(gisName);
    },
    resetData() {
      this.step = 0;
      this.fcltDataDoc = {
        fcltName: '',
        fcltTypeName: '',
        fcltTypeId: '',
        regionId: '',
        regionName: '',
        setDate: '',
        setNumberAddr: '',
        setRoadAddr: '',
        gisLat: '',
        gisLng: '',
        equip: [],
        fcltTypeData: {},
        files: [],
        fcltId: '',
      };
      this.selectEquipFormData = {};
      this.tempUploadFiles = [];
      this.tempUploadImages = [];
      this.autoMakeFcltId = false;
      if (this.$refs.uploadFile) this.$refs.uploadFile.setFileData(null);

      if (this.$refs.uploadImage) this.$refs.uploadImage.setFileData(null);
    },
    markerDragEnd(result) {
      let me = this;
      me.fcltDataDoc.setNumberAddr = result.addr.num_addr || '정보없음';
      me.fcltDataDoc.setRoadAddr = result.addr.road_addr || '정보없음';
      me.fcltDataDoc.gisLat = result.geometry.lat;
      me.fcltDataDoc.gisLng = result.geometry.lng;
    },
    showDialog(updateFlag) {
      this.updateFlag = updateFlag;
      if (updateFlag) {
        this.formTitle = '시설물 수정';
      } else {
        this.formTitle = '시설물 추가';
        this.autoMakeFcltId = true;
        this.makeFcltId();
      }
      this.formDialog = true;
      this.$forceUpdate();

      setTimeout(() => {
        this.$refs.fcltManageDialogGis.gisModuleLoad('openStreetMap');
      }, 1000);
    },
    hideDialog() {
      this.resetData();
      this.formDialog = false;
    },
    openDialog() {
      this.fcltManageDialogGisInit();
    },
    selectFcltType(val) {
      let me = this;
      /**
       * 나중에 요기서 시설물 유형바꾸면 시스템에 영향줄 수 있다는 부분을 집어넣어야할듯
       * 시설물 정보 수정시 시설물 유형을 변경하면, 기존에 설정한 시설물 유형정보가 사라진다.
       */

      let tempFcltTypeList = this.fcltType.filter((item) => {
        return item.fcltTypeId.toLowerCase().indexOf(val.toLowerCase()) > -1;
      });
      if (tempFcltTypeList.length > 0) {
        this.fcltDataDoc.fcltTypeName = tempFcltTypeList[0].fcltTypeName;
        this.fcltDataDoc.fcltTypeData = this.$lodash.cloneDeep(tempFcltTypeList[0].fcltTypeData);
      }
      this.$lodash.map(this.tempFcltTypeData, (d) => {
        if (this.fcltDataDoc.fcltTypeData[d.key]) {
          this.fcltDataDoc.fcltTypeData[d.key].value = d.value;
        }
      });
    },
    addSelectEquipList() {
      let me = this;
      let tempEquipList = this.materialList.filter((item) => {
        return item.materialId == me.tempMaterialId;
      });
      if (tempEquipList.length > 0) {
        let data = this.$lodash.cloneDeep(tempEquipList[0]);
        data.equipId = uuid();
        this.fcltDataDoc.equip.push(data);
      }
    },
    deleteEquipList(index, rows) {
      rows.splice(index, 1);
      this.selectEquipFormData = {};
    },
    searchMaterialData(query) {
      let me = this;
      let func = async function () {
        me.loading = true;
        let response = await me.$http.get(`${this.$config.getServerConfig().core.api}/core/api/material`, {
          params: {
            keyword: query,
          },
        });
        me.materialList = response.data.docs;
        me.loading = false;
      };
      if (query !== '') {
        //me.$lodash.debounce(func, 500);
        setTimeout(func, 300);
      } else {
        this.materialList = [];
      }
    },
    showSelectEquipForm(selectEquipData) {
      //console.log(selectEquipData);
      if (selectEquipData) {
        if (selectEquipData.equipData) {
          this.selectEquipFormData = selectEquipData.equipData;
        }
      }
    },
    // showMaterialDialog() {
    //     let me = this;
    //     this.materialListDialog = true;
    //     this.$store.dispatch("material/actMaterial", {})
    //     .then(() => {
    //         me.materialList = me.$store.getters['material/getMaterial'];
    //     })
    // },
    hideMaterialDialog() {
      this.materialListDialog = false;
      this.materialList = [];
    },
    makeFcltId() {
      if (this.autoMakeFcltId) {
        this.fcltDataDoc.fcltId = uuid();
        // this.$refs.form0.clearValidate()
      } else {
        this.fcltDataDoc.fcltId = '';
      }
    },
  },
};
</script>

<style scoped>
.el-form-item__content {
  margin-left: 120px;
}

.fclt-form-step {
  min-height: 400px;
}
</style>
