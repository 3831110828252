import axios from 'axios';

export default {
  namespaced: true,
  state: {
    configure: {
      value: null,
    },
  },
  getters: {
    getConfigure: (state) => {
      return state.configure.value;
    },
  },
  mutations: {
    setConfigure(state, payload) {
      state.configure.value = payload;
      //localStorage.setItem('gisApi', payload)
    },
  },
  actions: {
    getConfigure({ commit }, payload) {
      console.log(this.state.dkConfig);
      return axios.get(`${this.state.dkConfig.getServerConfig().core.api}/core/api/configure`, payload).then((response) => {
        if (response.data.length == 0) {
          commit('setConfigure', null);
        } else {
          commit('setConfigure', response.data[0]);
        }
      });
    },
    addConfigure({ commit }, payload) {
      return axios.post(`${this.state.dkConfig.getServerConfig().core.api}/core/api/configure/add`, payload);
    },
    updateConfigure({ commit }, payload) {
      return axios.post(`${this.state.dkConfig.getServerConfig().core.api}/core/api/configure/update`, payload);
    },
    deleteConfigure({ commit }, payload) {
      return axios.post(`${this.state.dkConfig.getServerConfig().core.api}/core/api/configure/delete`, payload);
    },
  },
};
