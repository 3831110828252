var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-subject-wrap" }, [
    _c("div", { staticClass: "page-subject" }, [
      _c(
        "div",
        { staticClass: "page-subject-content" },
        [
          _c("h3", [_vm._v(_vm._s(this.$route.name))]),
          _c(
            "el-breadcrumb",
            { attrs: { "separator-class": "el-icon-arrow-right" } },
            _vm._l(_vm.$route.matched, function(route, index) {
              return _c("el-breadcrumb-item", { key: index }, [
                route.name.toLowerCase() == "home"
                  ? _c(
                      "a",
                      {
                        class: [
                          _vm.$route.name == route.name ? "current-page" : ""
                        ],
                        on: {
                          click: function($event) {
                            return _vm.clickNaviMenu(route)
                          }
                        }
                      },
                      [_c("i", { staticClass: "fas fa-home" })]
                    )
                  : _c(
                      "a",
                      {
                        class: [
                          _vm.$route.name == route.name ? "current-page" : ""
                        ],
                        on: {
                          click: function($event) {
                            return _vm.clickNaviMenu(route)
                          }
                        }
                      },
                      [_vm._v(_vm._s(route.name))]
                    )
              ])
            }),
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "header-addon" }, [_vm._t("default")], 2)
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }