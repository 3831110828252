import { load } from './dkGisApiLoad.js';

const props = {
  gisApiInfo: {
    required: true,
    type: Array,
  },
  apiLoadComplete: {
    type: Function,
  },
  markerData: {
    type: Array,
  },
  clusterData: {
    type: Array,
  },
  eventMarkerData: {
    type: Array,
  },
  eventClusterData: {
    type: Array,
  },
  searchData: {
    type: Array,
  },
  clickMarker: {
    type: Function,
    default: null,
  },
  clickCluster: {
    type: Function,
    default: null,
  },
  clickEventMarker: {
    type: Function,
    default: null,
  },
  clickEventCluster: {
    type: Function,
    default: null,
  },
  clickPlace: {
    type: Function,
    default: null,
  },
  fcltManagerOnGis: {
    type: Function,
    default: null,
  },
  incidentOnGis: {
    type: Function,
    default: null,
  },
  markerDragEnd: {
    type: Function,
    default: null,
  },
  centerChanged: {
    type: Function,
    default: null,
  },
  changedBounds: {
    type: Function,
    default: null,
  },
  markerInfoWindow: {
    type: Boolean,
    default: true,
  },
  trafficLayerData: {
    type: Object,
    default: null,
  },
  selectTrafficLayer: {
    type: Function,
    default: null,
  },
  center: {
    type: Object,
    default: null,
  },
  level: {
    type: Number,
    default: null,
  },
  selectBoxData: {
    type: Array,
    default() {
      return [];
    },
  },
};

export default {
  data() {
    return {
      gisName: '',
      currentGisApiInfo: {},
      isFcltManageOnGis: false,
      isIncidentOnGis: false,
      froxKakao: false,
    };
  },
  props: props,

  /**
   * @description
   *   GIS 메인 객체 생성자
   *   콜백 이벤트를 미리 정의해놓는다.
   *   외부에서 주입하는 형태로 처리할 예정
   */
  created() {
    const { options = [] } = this.$config.getSiteConfig();
    const ck = options.findIndex((o) => o === 'frox-kakao');
    if (ck !== -1) {
      this.froxKakao = true;
    }

    /**
     * API로드 완료 시 콜백 이벤트
     */
    this.$on('apiLoadComplete', (value) => {
      // 시설물 마커 정보 로드
      this.$refs.dkGisMap.setMarkerData(this.markerData);
      this.$refs.dkGisMap.markerInfoWindow = this.markerInfoWindow;
      // this.$refs.dkGisMap.trafficLayerData = this.trafficLayerData
      this.$refs.dkGisMap.setEventMarkerData(this.eventMarkerData);
      if (this.apiLoadComplete) {
        return this.apiLoadComplete(this.currentGisApiInfo);
      }
      if (this.center) {
        this.$refs.dkGisMap.setCenter(this.center);
      }
      if (this.level) {
        this.$refs.dkGisMap.setLevel(this.level);
      }

      // this.$refs.dkGisMap.setMarkerData(this.markerData)
    });

    /**
     * 시설물 마커 선택 콜백 이벤트 리스너
     * 외부 UI에서 메소드를 등록하여 데이터를 전달한다.
     * 시설물 지리정보와, 기타 속성값을 전달한다.
     *
     * @param Object
     * {
     *   geometry : {
     *     lng, lat
     *   },
     *   properties: {
     *     이런저런 속성값
     *   }
     * }
     */
    this.$on('clickMarker', (ev) => {
      if (this.clickMarker) {
        return this.clickMarker(ev);
      }
    });

    this.$on('clickCluster', (ev) => {
      if (this.clickCluster) {
        return this.clickCluster(ev);
      }
    });

    this.$on('clickEventMarker', (ev) => {
      if (this.clickEventMarker) {
        return this.clickEventMarker(ev);
      }
    });

    this.$on('clickEventCluster', (ev) => {
      if (this.clickCluster) {
        return this.clickEventCluster(ev);
      }
    });

    this.$on('clickPlace', (ev) => {
      if (this.clickPlace) {
        return this.clickPlace(ev);
      }
    });
    /**
     * GIS상에서 시설물 관리하는 기능 콜백 이벤트 리스너
     * 외부 UI에서 메소드를 등록하여 데이터를 전달한다.
     *
     * @param Object
     * {
     *   geometry : {
     *     lng, lat
     *   },
     *   addr : { // 주소 (좌표=>주소 GIS API를 이용해 받는다 )
     *     road_addr, // 도로명
     *     num_addr   // 지번
     *   }
     * }
     */
    this.$on('fcltManagerOnGis', (ev) => {
      this.isFcltManageOnGis = false;
      if (this.fcltManagerOnGis) {
        ev.gisApiInfo = [this.currentGisApiInfo];
        return this.fcltManagerOnGis(ev);
      }
    });

    /**
     * GIS상에서 시설물 드래그시 좌표, 주소 얻어오는 코드
     * 시설물 추가, 수정 UI에서 시설물 마커를 드래그 했을 시 발생한다.
     *
     * @param Object
     * {
     *   geometry : {
     *     lng, lat
     *   },
     *   addr : { // 주소 (좌표=>주소 GIS API를 이용해 받는다 )
     *     road_addr, // 도로명
     *     num_addr   // 지번
     *   }
     * }
     */
    this.$on('markerDragEnd', (ev) => {
      if (this.markerDragEnd) {
        return this.markerDragEnd(ev);
      }
    });

    /**
     * GIS 중심 이동시 콜백 이벤트 처리
     *
     * @param Object
     * {
     *   geometry : {
     *     lng, lat
     *   },
     *   addr : { // 주소 (좌표=>주소 GIS API를 이용해 받는다 )
     *     road_addr, // 도로명
     *     num_addr   // 지번
     *   }
     * }
     */
    this.$on('centerChanged', (ev) => {
      if (this.centerChanged) {
        return this.centerChanged(ev);
      }
    });

    // this.$on('panMove', (ev) => {

    //   //this.$emit("panMove", ev)
    // })

    /**
     * GIS상에서 돌발 등록을 관리하는 기능 콜백 이벤트 리스너
     * 외부 UI에서 메소드를 등록하여 데이터를 전달한다.
     *
     * @param Object
     * {
     *   geometry : {
     *     lng, lat
     *   },
     *   addr : { // 주소 (좌표=>주소 GIS API를 이용해 받는다 )
     *     road_addr, // 도로명
     *     num_addr   // 지번
     *   }
     * }
     */
    this.$on('incidentOnGis', (ev) => {
      this.isIncidentOnGis = false;
      if (this.incidentOnGis) {
        ev.gisApiInfo = [this.currentGisApiInfo];
        return this.incidentOnGis(ev);
      }
    });

    /**
     * 지도 바운더리 변경 이벤트 리스너
     */
    this.$on('changedBounds', () => {
      if (this.changedBounds) {
        return this.changedBounds();
      }
    });

    /**
     * 시스템 초기화시 등록된 api가 있을경우, api를 로딩함
     */
    if (this.gisApiInfo.length > 0) {
      this.gisModuleLoad();
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit('dkgisMounted', {});
    });
  },
  watch: {
    // GIS API 정보가 변경될 때, 외부 GIS API를 불러옴
    gisApiInfo(newVal, oldVal) {
      if (newVal.length > 0) {
        this.gisModuleLoad();
      }
    },

    // GIS 시설물 마커 데이터가 변경될 때 시설물 마커 정보를 갱신함
    markerData(newVal, oldVal) {
      if (this.$refs.dkGisMap) {
        this.$refs.dkGisMap.setMarkerData(newVal);
      }
    },

    clusterData(newVal, oldVal) {
      if (this.$refs.dkGisMap) {
        this.$refs.dkGisMap.setClusterData(newVal);
      }
    },

    selectBoxData(newVal, oldVal) {
      if (this.$refs.dkGisMap && this.$refs.dkGisMap.setSelectBoxData) {
        this.$refs.dkGisMap.setSelectBoxData(newVal);
      }
    },

    // GIS 시설물 이벤트 마커 데이터가 변경될 때 시설물 마커 정보를 갱신함
    eventMarkerData(newVal, oldVal) {
      if (this.$refs.dkGisMap) {
        this.$refs.dkGisMap.setEventMarkerData(newVal);
      }
    },

    eventClusterData(newVal, oldVal) {
      if (this.$refs.dkGisMap) {
        this.$refs.dkGisMap.setEventClusterData(newVal);
      }
    },

    searchData(newVal, oldVal) {
      if (this.$refs.dkGisMap) {
        this.$refs.dkGisMap.setSearchData(newVal);
      }
    },
    center(newVal) {
      if (this.$refs.dkGisMap && newVal) {
        this.$refs.setCenter(newVal);
      }
    },
    level(newVal) {
      if (this.$refs.dkGisMap && newVal) {
        this.$refs.setLevel(newVal);
      }
    },
  },
  computed: {
    dkGisMap() {
      let me = this;
      let gisModule;
      switch (me.gisName) {
        case 'kakao':
          gisModule = import('./kakao/map.vue');
          break;
        case 'google':
          gisModule = import('./google/map.vue');
          break;
        case 'openStreetMap':
          gisModule = import('./openStreetMap/map.vue');
          break;
      }
      return () => {
        return gisModule;
      };
    },
  },
  methods: {
    gisModuleLoad(loadGisName) {
      var me = this;
      //var initFlag = (loadGisName)?false:true

      new Promise((resolve, reject) => {
        me.gisApiInfo[0] = {
          ...me.gisApiInfo[0],
          froxKakao: me.froxKakao,
        };
        me.gisApiInfo.forEach((apiInfo) => {
          if (apiInfo.gisName == loadGisName || me.gisApiInfo.length == 1) {
            if (apiInfo.gisApiLoadInit) {
              return resolve(apiInfo);
            }
            return resolve(load(apiInfo));
          }
        });

        me.gisApiInfo.forEach((apiInfo) => {
          if (apiInfo.initGis) {
            return resolve(load(apiInfo));
          }
        });
      })
        // GIS API가 정상적으로 불러와지면 GIS 정보 객체를 리턴해준다.
        // 객체가 비었거나 false경우는 로딩중 에러난것.
        .then((successGisApiInfo) => {
          if (successGisApiInfo) {
            me.currentGisApiInfo = successGisApiInfo;
            me.gisName = me.currentGisApiInfo.gisName;
            return true;
          }
        });
    },

    /**
     * setLevel 지도 줌 레벨 설정
     *  1이 최대 줌 레벨
     *  줌 아웃 할 경우 숫자가 증가함
     * input
     *   level (int)
     *
     * output
     *
     */
    setLevel(level) {
      this.$refs.dkGisMap.setLevel(level);
    },

    /**
     * getLevel 현재 지도 줌 레벨 얻기
     *
     * input
     *
     * output
     *   return
     *      level(int)
     *
     */
    getLevel() {
      return this.$refs.dkGisMap.getLevel();
    },

    /**
     * 현재 지도 중심 좌표 얻기
     *
     * input
     *
     * output
     *   return {
     *
     *   }
     */
    getCenter() {
      return this.$refs.dkGisMap.getCenter();
    },

    setCenter(coord) {
      if (this.$refs.dkGisMap) {
        return this.$refs.dkGisMap.setCenter(coord);
      }
    },

    /**
     * 교통 지도레이어를 표시한다
     *
     * @param {boolean} flag
     */
    setTrafficLayer(flag) {},

    /**
     * GIS좌표를 이용하여 주소정보를 얻어온다.
     *
     * @param {Object} opts
     *
     * @returns promise object
     * {
     *   geometry : {
     *     lng, lat
     *   },
     *   addr : { // 주소 (좌표=>주소 GIS API를 이용해 받는다 )
     *     road_addr, // 도로명
     *     num_addr   // 지번
     *   }
     * }
     */
    getAddrFromCoord(opts) {
      return this.$refs.dkGisMap.getAddrFromCoord(opts);
    },

    /**
     * 주소정보를 이용하여 GIS좌표를 얻어온다.
     *
     * @param {Object} opts
     *
     * @returns promise object
     * {
     *   geometry : {
     *     lng, lat
     *   },
     *   addr : { // 주소 (좌표=>주소 GIS API를 이용해 받는다 )
     *     road_addr, // 도로명
     *     num_addr   // 지번
     *   }
     * }
     */

    getCoordFromAddr(addr) {
      return this.$refs.dkGisMap.getCoordFromAddr(addr);
    },
    /**
     * 현재 중심 좌표 기준으로 주소정보를 얻어온다.
     *
     * @param {Object} opts
     *
     * @returns promise {Object}
     * {
     *   geometry : {
     *     lng, lat
     *   },
     *   addr : { // 주소 (좌표=>주소 GIS API를 이용해 받는다 )
     *     road_addr, // 도로명
     *     num_addr   // 지번
     *   }
     * }
     */
    getAddrFromCenterCoord(centerMarker) {
      if (this.$refs.dkGisMap && this.$refs.dkGisMap.getAddrFromCenterCoord) return this.$refs.dkGisMap.getAddrFromCenterCoord(centerMarker);
    },

    /**
     * gis좌표로 주소를 얻어온다.
     * @param {Object} latlng
     *
     * @returns promise {Object}
     */
    getAddr(latlng, gis) {
      return this.$refs.dkGisMap.getAddr(latlng, gis);
    },

    /**
     * GIS상에서 시설물 관리를 활성화 시킨다
     */
    setFcltManageOnGis() {
      this.$refs.dkGisMap.setFcltManageOnGis(this.isFcltManageOnGis);
      this.isFcltManageOnGis = !this.isFcltManageOnGis;
    },

    /**
     * 교통 정보 레이어를 표출한다. (kakao)
     */
    setTrafficLayer(flag) {
      return this.$refs.dkGisMap.setTrafficLayer(flag);
    },
    setTrafficSelectMode(flag) {
      return this.$refs.dkGisMap.setTrafficSelectMode(flag);
    },
    setTrafficLayerSource(name) {
      return this.$refs.dkGisMap.setTrafficLayerSource(name);
    },
    focusTrafficLayer(key, param, layer) {
      return this.$refs.dkGisMap.focusTrafficLayer(key, param, layer);
    },
    /**
     * 지도 표출 크기를 갱신한 후 호출
     */
    refresh() {
      this.$refs.dkGisMap.refresh();
    },

    /**
     * 로드뷰 보기 (카카오맵, 구글 정도만 지원)
     */
    setRoadview(coord) {
      this.$refs.dkGisMap.setRoadview(coord);
    },
    hideRoadview() {
      this.$refs.dkGisMap.hideRoadview();
    },

    /**
     * 지도상에서 사고/돌발 정보 추가
     */
    setIncidentOnGis() {
      this.$refs.dkGisMap.setIncidentOnGis(this.isIncidentOnGis);
      this.isIncidentOnGis = !this.isIncidentOnGis;
    },

    trafficLayerRefresh() {
      this.$refs.dkGisMap.trafficLayerRefresh();
    },

    getFeaturesAtCoord(coord) {
      return this.$refs.dkGisMap.getFeaturesAtCoord(coord);
    },

    /**
     * 시설물 커버리지를 표출한다
     */
    getFcltCover(aa) {
      return this.$refs.dkGisMap.getFcltCover(aa);
    },

    /**
     * 현재 지도에서 보는 영역에 대한 좌표 값을 받는다.
     *
     */
    getBounds() {
      return this.$refs.dkGisMap.getBounds();
    },

    // moveEnd() {
    //   this.$emit('moveEnd', {
    //     a: 1,
    //   });
    // },

    // zoomEnd() {
    //   this.$emit('zoomEnd', {
    //     a: 1,
    //   });
    // },

    getClusterMarker(args) {
      return this.$refs.dkGisMap.getClusterMarker(args);
    },

    /**
     *  geolocation 사용시의 지도 동작을 설정한다.
     *  geolocation으로 얻어온 사용자 위치로 이동하며,
     *  마커로 현 위치를, 폴리곤으로 주변 반경을 표시한다.
     *
     * @param {Object} coord
     *
     * @returns promise {Object}
     * {
     *   geometry : {
     *     lng, lat
     *   },
     *   addr : { // 주소 (좌표=>주소 GIS API를 이용해 받는다 )
     *     road_addr, // 도로명
     *     num_addr   // 지번
     *   }
     * }
     */
    actGeolocation(coord) {
      return this.$refs.dkGisMap.actGeolocation(coord);
    },

    /**
     * 로드뷰가 떠있는지를 확인한다.
     *
     * @returns boolean
     */
    isShowRoadView() {
      return this.$refs.dkGisMap.isShowRoadView();
    },

    /**
     * 교통 지도에 parameter를 넣어서 업데이트 한다
     */
    trafficLayerUpdate(param) {
      return this.$refs.dkGisMap.trafficLayerUpdate(param);
    },

    /**
     * 클릭 이벤트 함수 등록
     * @param {Function} callback
     */
    setClickEvent(callback) {
      return this.$refs.dkGisMap.setClickEvent(callback);
    },

    /**
     * 클릭 이벤트 함수 해제
     * @param {Function} callback
     */
    freeClickEvent(callback) {
      return this.$refs.dkGisMap.freeClickEvent(callback);
    },

    /**
     * 임시 마커 생성
     * @param {*} latLng
     */
    createMarker(latLng, gis) {
      return this.$refs.dkGisMap.createMarker(latLng, gis);
    },

    /**
     * 임시 마커 해제
     * @param {*} latLng
     */
    freeMarker(marker) {
      return this.$refs.dkGisMap.freeMarker(marker);
    },

    /**
     * 거리를 측정 라인 그리기 시작
     */
    startLineDraw(args) {
      return this.$refs.dkGisMap.startLineDraw(args);
    },

    /**
     * 거리를 측정 라인 그리기 종료
     */
    endLineDraw() {
      return this.$refs.dkGisMap.endLineDraw();
    },

    /**
     * 거리를 측정
     */
    getDistance(pointA, pointB) {
      return this.$refs.dkGisMap.getDistance(pointA, pointB);
    },

    /**
     * 마커 레이어 생성
     */
    createMarkerLayer() {
      return this.$refs.dkGisMap.createMarkerLayer();
    },

    /**
     * 원 레이어 생성
     */
    createCircleLayer() {
      return this.$refs.dkGisMap.createCircleLayer();
    },
    /**
     * 현재 보이는 지도 영역에 해당되는 배경 맵의 정적 캔버스 객체를 얻어온다
     */
    getStaticMapImageCanvas(args) {
      return this.$refs.dkGisMap.getStaticMapImageCanvas(args);
    },
  },
};
