import { Doughnut, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;
import ChartPluginLabels from 'chartjs-plugin-labels';

export default {
  extends: Doughnut,
  mixins: [reactiveProp],

  props: {
    chartData: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [ChartPluginLabels],
    },
  },
  // props: ['chartData', 'options'],
  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, this.options);
  },
  methods: {
    update() {
      this.$data._chart.update();
    },
  },
};
