var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mobile-download-page" }, [
    _c("div", { staticClass: "download-btn" }, [
      _c("button", { on: { click: _vm.onAppDownload } }, [
        _vm._v("APK 다운로드")
      ])
    ]),
    _c("span", { staticClass: "down-title" }, [_vm._v("사용설명서")]),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img-place" }, [
      _c("img", {
        attrs: { src: "static/images/manual/mobile/samsung-0.jpg" }
      }),
      _c("p", [
        _vm._v(
          '1. 모바일에서 인터넷 브라우저로 서버에 접속해서 "모바일 다운로드" 버튼을 눌러 다운로드 페이지로 이동합니다.'
        )
      ]),
      _c("img", {
        attrs: { src: "static/images/manual/mobile/samsung-1.jpg" }
      }),
      _c("p", [_vm._v('2. 상단의 "APK 다운로드" 버튼을 누릅니다.')]),
      _c("img", {
        attrs: { src: "static/images/manual/mobile/samsung-2.jpg" }
      }),
      _c("p", [_vm._v("3. 확인버튼을 선택해 설치파일을 다운로드 합니다.")]),
      _c("img", {
        attrs: { src: "static/images/manual/mobile/samsung-3.jpg" }
      }),
      _c("p", [
        _vm._v(
          '4. 하단의 "열기"를 누르거나 다운받은 설치파일을 실행하여 설치를 시작합니다.'
        )
      ]),
      _c("img", {
        attrs: { src: "static/images/manual/mobile/samsung-4.jpg" }
      }),
      _c("p", [
        _vm._v('\n      5. "설치"를 선택하여 설치를 진행합니다.\n      '),
        _c("span", { staticClass: "important" }, [
          _vm._v("※ App 이름은 설치되는 기관에 따라서 차이가 있을 수 있습니다.")
        ])
      ]),
      _c("img", {
        attrs: { src: "static/images/manual/mobile/samsung-5.jpg" }
      }),
      _c("p", [_vm._v("6. 설치 진행중인 화면입니다.")]),
      _c("img", {
        attrs: { src: "static/images/manual/mobile/samsung-6.jpg" }
      }),
      _c("p", [
        _vm._v(
          '7. 설치 진행 중에 다음과 같은 화면이 뜨면 "무시하고 설치"를 선택하여 설치를 계속 진행합니다.'
        )
      ]),
      _c("p", [_vm._v("8. 설치가 완료되었습니다")]),
      _c("p", [
        _c("b", [
          _vm._v(
            '+ 설치가 완료된 후 설치된 어플을 실행하면 아래와 같이 나오는 팝업에 전부 "허용" 을 선택합니다.'
          )
        ])
      ]),
      _c("div", { staticClass: "img-place-small" }, [
        _c("img", {
          attrs: { src: "static/images/manual/mobile/samsung-7.jpg" }
        }),
        _c("img", {
          attrs: { src: "static/images/manual/mobile/samsung-8.jpg" }
        }),
        _c("img", {
          attrs: { src: "static/images/manual/mobile/samsung-9.jpg" }
        }),
        _c("img", {
          attrs: { src: "static/images/manual/mobile/samsung-10.jpg" }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }