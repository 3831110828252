import Vue from 'vue';
import Vuex from 'vuex';

import DkConfig from '../_config/DkConfig';
import auth from './auth';
import configure from './configure';
import excel from './excel';
import fcltType from './fcltType';
import fcltStatus from './fcltStatus';
import license from './license';
import login from './login';
import gis from './gis';
import routerHistory from './routerHistory';
// import vmsDownloadedSchedule from './vmsDownloadedSchedule';
import dkStraightRoad from './dkStraightRoad.js';
import formTemplate from './formTemplate.js';

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    dkConfig: new DkConfig(),
  },
  modules: {
    auth,
    configure,
    excel,
    fcltType,
    fcltStatus,
    gis,
    license,
    login,
    routerHistory,
    // vmsDownloadedSchedule,
    dkStraightRoad,
    formTemplate,
  },
});
