import { render, staticRenderFns } from "./pageDashboard.vue?vue&type=template&id=c14e1924&scoped=true&"
import script from "./pageDashboard.vue?vue&type=script&lang=js&"
export * from "./pageDashboard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c14e1924",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/dk-platform-frontend/dk-platform-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c14e1924')) {
      api.createRecord('c14e1924', component.options)
    } else {
      api.reload('c14e1924', component.options)
    }
    module.hot.accept("./pageDashboard.vue?vue&type=template&id=c14e1924&scoped=true&", function () {
      api.rerender('c14e1924', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/core/pageDashboard.vue"
export default component.exports