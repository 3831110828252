<template>
  <el-dialog
    :title="formTitle"
    :visible.sync="formDialog"
    @close="hideDialog"
    :close-on-click-modal="false"
  >
    <div>
      <el-form :model="formData" label-width="160px" size="medium" :rules="rules" ref="form0">
        <el-row>
          <el-col :span="18">
            <el-form-item label="사용자 ID" prop="userid">
              <el-input v-model="formData.userid" placeholder="사용자 ID" v-if="!updateFlag"></el-input>
              <span v-else>{{ formData.userid }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-form-item label="이름" prop="name">
              <el-input v-model="formData.name" placeholder="이름"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="18" v-if="!updateFlag">
            <el-form-item label="비밀번호" prop="password">
              <el-input
                type="password"
                v-model="formData.password"
                placeholder="비밀번호"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="18" v-if="!updateFlag">
            <el-form-item label="비밀번호 확인" prop="checkPassword">
              <el-input
                type="password"
                v-model="formData.checkPassword"
                placeholder="비밀번호 확인"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-form-item label="그룹" prop="group">
              <!-- <div v-if="selectGroupData"><span style="color:#3266b4; font-weight:bold; margin-left:15px;">{{selectGroupData.label}}</span>
                    </div>
                    <el-button @click="groupDialog = true">
                      그룹 선택
              </el-button>-->
              <el-select
                v-model="formData.group"
                @change="selectGroupCombobox"
                placeholder="사용자 그룹 설정"
              >
                <el-option
                  v-for="(item, index) in groupList"
                  :key="index"
                  :label="item.name"
                  :value="item.menuId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-form-item label="전화번호" prop="phone">
              <el-input v-model="formData.phone" placeholder="전화번호"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-form-item label="이메일" prop="email">
              <el-input v-model="formData.email" placeholder="이메일"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-form-item label="사진">
              <dk-el-file-upload
                ref="uploadImage"
                uploaderType="image"
                listType="picture"
                path="user"
                :fileList="formData.images"
              >
                <div>
                  <el-button size="small" type="primary">관련사진 업로드</el-button>
                  <div slot="tip" class="el-upload__tip">이미지 파일만 업로드할 수 있습니다</div>
                </div>
              </dk-el-file-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="form-bottom">
        <el-button @click="hideDialog" style="float: right;">취소</el-button>
        <el-button type="primary" @click="save" style="float: right; margin-right: 5px;">저장</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import dkElFileUpload from '@/components/dkElFileUpload.vue';

export default {
  components: {
    'dk-el-file-upload': dkElFileUpload,
  },
  data() {
    var autoHypenPhone = function (str) {
      str = str.replace(/[^0-9]/g, '');
      var tmp = '';
      if (str.length < 4) {
        return str;
      } else if (str.length < 7) {
        tmp += str.substr(0, 3);
        tmp += '-';
        tmp += str.substr(3);
        return tmp;
      } else if (str.length < 11) {
        tmp += str.substr(0, 3);
        tmp += '-';
        tmp += str.substr(3, 3);
        tmp += '-';
        tmp += str.substr(6);
        return tmp;
      } else {
        tmp += str.substr(0, 3);
        tmp += '-';
        tmp += str.substr(3, 4);
        tmp += '-';
        tmp += str.substr(7);
        return tmp;
      }
      return str;
    };
    var validatePass = (rule, value, callback) => {
      var passwordRules = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,16}$/;
      if (!passwordRules.test(value)) {
        callback(new Error('비밀번호는 8자 이상의 숫자와 영문자, 특수문자(!@#$%^&*=-) 의 조합으로 만들어야 합니다.'));
      } else {
        if (this.formData.checkPassword !== '') {
          this.$refs.form0.validateField('checkPassword');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('비밀번호를 확인하여 주십시오'));
      } else if (value !== this.formData.password) {
        callback(new Error('비밀번호가 일치하지 않습니다'));
      } else {
        callback();
      }
    };
    var validatePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('연락처를 입력해 주세요'));
      } else {
        var _val = value.trim();
        this.formData.phone = autoHypenPhone(_val);
        callback();
      }
    };
    var test = ['admin', 'bongyo10'];
    var checkUserId = async (value) => {
      try {
        let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/user/checkUserId`, { userid: value });
        return response.data.msg;
      } catch (error) {
        console.log(error);
        return error;
      }

      // let check = test.find((a)=> {
      //   return a === value
      // })
      // return resolve(check)

      // }, 500)
      // let func = function() {
      //     me.loading = true;
      //     me.$store.dispatch("material/getMaterial", {
      //         'keyword': query
      //     })
      //     .then(() => {
      //         me.materialList = me.$store.getters['material/getMaterial'];
      //         me.loading = false;
      //     })
      // }
      // if (query !== '') {
      //     //me.$lodash.debounce(func, 500);
      //     setTimeout(func, 500);
      // }
      // else {
      //     this.materialList = [];
      // }
    };
    var validateUser = async (rule, value, callback) => {
      if (!this.updateFlag) {
        if (value === '') {
          callback(new Error('사용자 ID를 입력해 주세요'));
        } else if (value.length <= 3) {
          callback(new Error('사용자 ID는 4글자 이상 입력해 주세요'));
        } else {
          let chk = await checkUserId(value);
          if (chk) {
            callback(new Error('이미 등록된 사용자 ID입니다'));
          } else {
            callback();
          }
        }
      }
    };

    return {
      systemLogInfo : {},
      activeUser: null,
      formDialog: false,
      formTitle: '',
      groupDialog: false,
      groupList: [],
      selectGroupData: null,
      formData: {
        name: '',
        userid: '',
        phone: '',
        email: '',
        group: '',
        groupName: '',
        authority: '',
        password: '',
        checkPassword: '',
        images: [],
        salt: '',
      },
      updateFlag: false,
      rules: {
        userid: [
          // { required: true, message: '사용자 아이디를 입력하여 주십시오', trigger: ['blur', 'change'] }
          {
            required: true,
            validator: validateUser,
            trigger: ['blur', 'change'],
          },
        ],
        name: [
          {
            required: true,
            message: '이름을 입력하여 주십시오',
            trigger: ['blur', 'change'],
          },
        ],
        group: [
          {
            required: true,
            message: '사용자 그룹을 선택하여 주십시오',
            trigger: ['blur', 'change'],
          },
        ],
        phone: [
          // { required: true, message: '연락처를 입력하여 주십시오', trigger: ['blur', 'change'] }
          {
            required: true,
            validator: validatePhone,
            trigger: ['blur', 'change'],
          },
        ],
        email: [
          {
            type: 'email',
            message: '이메일 주소형태가 유효하지 않습니다.',
            trigger: ['blur', 'change'],
          },
          {
            required: true,
            message: '이메일 주소를 입력하여 주십시오',
            trigger: 'blur',
          },
        ],
        password: [{ required: true, trigger: ['blur', 'change'] }],
        checkPassword: [
          {
            required: true,
            validator: validatePass2,
            trigger: ['blur', 'change'],
          },
        ],
      },
    };
  },
  created() {
    this.activeUser = this.$dkRouter.getUserInfo();
    // 임시로 메뉴 목록 얻어오는걸로 처리함.
    // 자기보다 상위 권한의 메뉴를 얻어오지 못하도록 수정함
    this.getMenus();
  },
  methods: {
    async getMenus() {
      let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/menus`, {
        params: this.activeUser,
      });
      this.groupList = response.data;
    },
    askClose() {
      if (this.isEdit) {
        if (confirm('수정사항이 있습니다. 임시 저장 하시겠습니까?')) {
          this.$emit('update:savedIndex', 1);
          this.$emit('update:propdata', this.propdata);
        } else {
          this.init();
        }
      }
      this.close();
    },
    next() {
      if (this.stepcounter < 4) {
        this.stepcounter++;
      } else {
        if (this.editedIndex == -1) {
          this.createUser();
        } else {
          this.updateUser();
        }
      }
    },
    save() {
      try {
        let me = this;
        this.$refs.form0.validate(async (valid) => {
          if (valid) {
            let saveFunc = 'user/add';
            if (this.updateFlag) {
              saveFunc = 'user/update';
            }
            this.systemLogInfo.page = `${this.$route.name} ${this.formData.name}`
            // this.formData.group = this.selectGroupData;
            this.formData.images = this.$refs.uploadImage.getFileData();
            let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/${saveFunc}`, { data: this.formData });
            me.$parent.$emit('procDataComplete', this.formData);
            me.hideDialog();
            me.$message({
              type: 'success',
              message: `사용자 정보 등록 완료`,
            });
            this.systemLogInfo.saveType = saveFunc
            await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/systemLog/add`, { data: this.systemLogInfo });
          } else {
            return false;
          }
        });
      } catch (error) {
        this.hideDialog();
        this.$message.error(`사용자 정보 등록 중 에러 발생`);
      }
    },
    selectGroup(data, node) {
      this.selectGroupData = data;
    },
    createUser() {
      userAxios
        .requestServer(`${this.$config.getServerConfig().core.api}/core/api/userMaker`, this.binddata)
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    },
    updateUser() {
      userAxios
        .requestServer(`${this.$config.getServerConfig().core.api}/core/api/userUpdater`, this.binddata)
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    },
    showDialog(row) {
      if (row) {
        this.updateFlag = true;
        this.formTitle = '사용자 정보 수정';
        this.formData = this.$lodash.cloneDeep(row);
      } else {
        this.updateFlag = false;
        this.formTitle = '사용자 정보 추가';
      }
      this.formDialog = true;
      this.$forceUpdate();
      this.$nextTick(() => {
        if (row) {
          this.$refs.uploadImage.setFileData(row.images);
        }
      });
    },
    hideDialog() {
      this.resetData();
      this.formDialog = false;
    },
    resetData() {
      this.formData = {
        name: '',
        userid: '',
        phone: '',
        email: '',
        group: '',
        groupName: '',
        authority: '',
        password: '',
        checkPassword: '',
        images: [],
      };
      this.selectGroupData = null;
      if (this.$refs.form0) {
        this.$refs.form0.resetFields();
      }
      if (this.$refs.uploadImage) this.$refs.uploadImage.setFileData(null);
    },
    selectGroupCombobox(itemVal) {
      let selItem = this.groupList.find((data) => {
        return data.menuId == itemVal;
      });
      this.formData.groupName = selItem.name;
    },
  },
};
</script>
