<template>
  <div class="tab-box">
    <!-- 리스트 -->
    <ul class="status-list">
      <li :class="[selectDataId === 'all' ? `all-status ${colorClass}` : 'all-status']" @click="clickList({ id: 'all' })">
        <h4>전체 {{ tabLabel.main }} 시설물</h4>
        <div class="count-container-all">
          <div>
            <span class="title">시설물 개소</span>
            <span class="text-red">{{ sumStatusData.pole }}</span>
          </div>
          <div>
            <span class="title">시설물 대수</span>
            <span class="text-blue">{{ sumStatusData.fclt }}</span>
          </div>
        </div>
      </li>
      <li v-for="data in statusData" :key="data.fcltTypeId" :class="[selectDataId === data.id ? `${colorClass}` : '']" @click="clickList(data)">
        <h4>{{ data.name }}</h4>
        <div class="count-container">
          <span class="title">시설물 개소</span>
          <span class="text-red">{{ data.allPoleCnt }}</span>
        </div>
        <div class="count-container">
          <span class="title">시설물 대수</span>
          <span class="text-blue">{{ data.allFcltCnt }}</span>
        </div>
      </li>
    </ul>
    <!-- 차트 view -->
    <div class="detail-status-view">
      <div :class="[`back-color ${backColorClass}`]"></div>
      <!-- 전체 -->
      <div v-if="!isSelectDetail" class="content-box">
        <h2>전체 {{ tabLabel.main }} 시설물</h2>
        <div class="chart-box bar-chart">
          <div class="chart-header">
            <h4>전체 {{ tabLabel.main }} 시설물 개소</h4>
            <div class="legend-box">
              <div>
                <span class="rect back-red"></span>
                <span>개소</span>
              </div>
            </div>
          </div>
          <div class="chart-content">
            <ColumnChart :cols="chartOption.poleBar.cols" :data="chartDate.poleBar" :maxCount="chartOption.poleBar.max" />
          </div>
        </div>
        <div class="chart-box bar-chart">
          <div class="chart-header">
            <h4>전체 {{ tabLabel.main }} 시설물 대수</h4>
            <div class="legend-box">
              <div>
                <span class="rect back-blue"></span>
                <span>대수</span>
              </div>
            </div>
          </div>
          <div class="chart-content">
            <ColumnChart :cols="chartOption.fcltBar.cols" :data="chartDate.fcltBar" :maxCount="chartOption.fcltBar.max" :colors="['#409eff']" />
          </div>
        </div>
      </div>
      <!-- 선택시 -->
      <div v-else class="content-box">
        <h2>{{ selcetedDataInfo.name }}</h2>
        <div class="chart-box bar-chart">
          <div class="chart-header">
            <h4>{{ tabLabel.side }} 시설물 개소&amp;대수</h4>
            <div class="legend-box">
              <div>
                <span class="rect back-red"></span>
                <span>개소</span>
              </div>
              <div>
                <span class="rect back-blue"></span>
                <span>대수</span>
              </div>
            </div>
          </div>

          <div class="chart-content">
            <ColumnChart :cols="chartOption.allBar.cols" :data="chartDate.allBar" :maxCount="chartOption.allBar.max" />
          </div>
        </div>
        <div style="display: flex;">
          <div class="chart-box doughnut-chart">
            <div class="chart-header">
              <h4>{{ tabLabel.main }} 시설물 개소</h4>
            </div>
            <div class="chart-content">
              <DoughnutChart
                :centerText="chartOption.poleDoughnut.center"
                :cols="chartOption.poleDoughnut.cols"
                :data="chartDate.poleDoughnut"
                :colors="['#f56c6c', '#4D4D4D']"
              />
            </div>
            <div class="ratio">
              <span class="text-red">{{ selcetedDataInfo.allPoleCnt }}</span> /
              <span>{{ sumStatusData.pole }}</span>
            </div>
          </div>
          <div class="chart-box doughnut-chart">
            <div class="chart-header">
              <h4>{{ tabLabel.main }} 시설물 대수</h4>
            </div>
            <div class="chart-content">
              <DoughnutChart
                :centerText="chartOption.fcltDoughnut.center"
                :cols="chartOption.fcltDoughnut.cols"
                :data="chartDate.fcltDoughnut"
                :colors="['#409eff', '#4D4D4D']"
              />
            </div>
            <div class="ratio">
              <span class="text-blue">{{ selcetedDataInfo.allFcltCnt }}</span> /
              <span>{{ sumStatusData.fclt }}</span>
            </div>
          </div>
          <ul class="chart-box text-chart">
            <li v-for="item in selcetedDataInfo.cnt" :key="item.classifiedId">
              <span class="label">{{ item.classifiedName }}</span>
              <div class="count">
                <span class="text-red">{{ item.fcltPoleCnt }} 개소</span>
                <span class="text-blue">{{ item.fcltCnt }} 대수</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DoughnutChart from '@/components/chart/doughnutChart.vue';
import ColumnChart from '@/components/chart/columnChart.vue';

export default {
  components: {
    ColumnChart,
    DoughnutChart,
  },
  props: {
    dataType: {
      type: String,
      default: 'fcltTypeTab',
    },
    statusData: {
      type: Array,
      default() {
        return [];
      },
    },
    sumStatusData: {
      type: Object,
      default() {
        return {
          pole: 0,
          fclt: 0,
        };
      },
    },
  },
  data() {
    return {
      isSelectDetail: false,
      selectDataId: 'all',
      colorClass: 'selceted-blue',
      backColorClass: 'back-blue',
      tabLabel: {},
      selcetedDataInfo: [],
      chartOption: {
        poleDoughnut: {
          cols: [
            { label: '시설물 개소', type: 'string' },
            { label: '개소', type: 'number' },
          ],
          center: 0,
        },
        fcltDoughnut: {
          cols: [
            { label: '시설물 대수', type: 'string' },
            { label: '대수', type: 'number' },
          ],
          center: 0,
        },
        allBar: {
          cols: [
            { label: '시설물 유형', type: 'string' },
            { label: '개소', type: 'number' },
            { label: '대수', type: 'number' },
          ],
          max: 0,
        },
        poleBar: {
          cols: [
            { label: '시설물 유형', type: 'string' },
            { label: '개소', type: 'number' },
          ],
          max: 0,
        },
        fcltBar: {
          cols: [
            { label: '시설물 유형', type: 'string' },
            { label: '대수', type: 'number' },
          ],
          max: 0,
        },
      },
      chartDate: {
        poleDoughnut: [],
        fcltDoughnut: [],
        allBar: [],
        poleBar: [],
        fcltBar: [],
      },
    };
  },
  methods: {
    clickList(data) {
      this.selectDataId = data.id;
      if (data.id === 'all') {
        this.isSelectDetail = false;
      } else {
        this.setEachChartData(data);
        this.selcetedDataInfo = this.statusData.find((item) => item.id === data.id);
        this.isSelectDetail = true;
      }
    },
    // 전체 차트 세팅
    setAllChartData(data) {
      let maxPoleBar = 0;
      let maxFcltBar = 0;
      this.chartDate.poleBar = data.map((item) => {
        if (maxPoleBar < item.allPoleCnt) {
          maxPoleBar = item.allPoleCnt;
        }
        return { xaxisLabel: item.name, value: [item.allPoleCnt] };
      });
      this.chartDate.fcltBar = data.map((item) => {
        if (maxFcltBar < item.allFcltCnt) {
          maxFcltBar = item.allFcltCnt;
        }
        return { xaxisLabel: item.name, value: [item.allFcltCnt] };
      });
      this.chartOption.poleBar.max = maxPoleBar;
      this.chartOption.fcltBar.max = maxFcltBar;
    },
    // 디테일 차트 세팅
    setEachChartData(data) {
      // 시설물 개소/대수
      let maxBar = 0;
      this.chartDate.allBar = data.cnt.map((item) => {
        if (item.fcltPoleCnt > maxBar) {
          maxBar = item.fcltPoleCnt;
        }
        if (item.fcltCnt > maxBar) {
          maxBar = item.fcltCnt;
        }
        return { xaxisLabel: item.classifiedName, value: [item.fcltPoleCnt, item.fcltCnt] };
      });
      this.chartOption.allBar.max = maxBar;

      // 개소 도넛 차트
      this.chartDate.poleDoughnut = [
        { xaxisLabel: data.name, value: data.allPoleCnt },
        { xaxisLabel: '나머지 개소 수', value: this.sumStatusData.pole - data.allPoleCnt },
      ];
      this.chartOption.poleDoughnut.center = this.setCenterText(this.sumStatusData.pole, data.allPoleCnt);

      // 대수 도넛 차트
      this.chartDate.fcltDoughnut = [
        { xaxisLabel: data.name, value: data.allFcltCnt },
        { xaxisLabel: '나머지 대수', value: this.sumStatusData.fclt - data.allFcltCnt },
      ];
      this.chartOption.fcltDoughnut.center = this.setCenterText(this.sumStatusData.fclt, data.allFcltCnt);
    },
    // 도넛 차트 중앙 % 계산
    setCenterText(allCount, selectCount) {
      const percent = ((selectCount / allCount) * 100).toFixed(1) + '%';
      return percent;
    },
  },
  watch: {
    dataType: {
      immediate: true,
      handler(n) {
        if (n === 'fcltTypeTab') {
          this.tabLabel = { main: '유형별', side: '지역별' };
          this.colorClass = 'selceted-blue';
          this.backColorClass = 'back-blue';
        } else if (n === 'fcltAreaTab') {
          this.tabLabel = { main: '지역별', side: '유형별' };
          this.colorClass = 'selceted-red';
          this.backColorClass = 'back-red';
        }
      },
    },
    statusData: {
      immediate: true,
      handler(n) {
        if (Array.isArray(n) && n.length === 0) return;
        this.setAllChartData(n);
      },
    },
  },
};
</script>

<style></style>
