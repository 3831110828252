var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-view" },
    [
      _c("div", { staticClass: "left-box" }, [
        _c("div", { staticClass: "left-text" }, [
          _c("span", { staticClass: "web-name" }, [
            _c("strong", [_vm._v(_vm._s(_vm.$config.getSiteConfig().title))])
          ])
        ])
      ]),
      _c("div", { staticClass: "right-box hidden-xs-only" }),
      _c(
        "div",
        { staticClass: "size-change login-box-wrap" },
        [
          _c(
            "el-row",
            { staticClass: "mb-bg", attrs: { justify: "center" } },
            [
              _c(
                "el-col",
                { staticClass: "login-intro", attrs: { span: 24 } },
                [
                  _c("h2", [_vm._v("LOG IN")]),
                  _c("span"),
                  _c("p", [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$config.getSiteConfig().title))
                    ])
                  ])
                ]
              ),
              _c(
                "el-col",
                {
                  staticClass: "login-fill-box",
                  attrs: { span: 18, offset: 3 }
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form",
                            {
                              attrs: {
                                model: _vm.data,
                                "label-position": "top"
                              }
                            },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c("el-input", {
                                    staticClass: "login-input",
                                    attrs: {
                                      placeholder: "아이디를 입력하세요"
                                    },
                                    nativeOn: {
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.submit($event)
                                      }
                                    },
                                    model: {
                                      value: _vm.data.userid,
                                      callback: function($$v) {
                                        _vm.$set(_vm.data, "userid", $$v)
                                      },
                                      expression: "data.userid"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                [
                                  _c("el-input", {
                                    staticClass: "login-input",
                                    attrs: {
                                      placeholder: "비밀번호를 입력하세요",
                                      type: "password"
                                    },
                                    nativeOn: {
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.submit($event)
                                      }
                                    },
                                    model: {
                                      value: _vm.data.password,
                                      callback: function($$v) {
                                        _vm.$set(_vm.data, "password", $$v)
                                      },
                                      expression: "data.password"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticClass: "auto-login", attrs: { span: 24 } },
                        [
                          _c(
                            "el-checkbox",
                            {
                              model: {
                                value: _vm.data.autoLogin,
                                callback: function($$v) {
                                  _vm.$set(_vm.data, "autoLogin", $$v)
                                },
                                expression: "data.autoLogin"
                              }
                            },
                            [_vm._v("자동 로그인")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "text-align": "right" },
                          attrs: { span: 24 }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "button-login",
                              on: { click: _vm.submit }
                            },
                            [_vm._v("LOGIN")]
                          )
                        ],
                        1
                      ),
                      _vm.checkMobile()
                        ? _c(
                            "el-col",
                            {
                              staticStyle: { "text-align": "right" },
                              attrs: { span: 24 }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "download-btn",
                                  on: { click: _vm.downloadPage }
                                },
                                [_vm._v("모바일 다운로드")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c("span", { staticClass: "login-ci-wrap-mb" })
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "모바일 기기 인증",
            visible: _vm.formMobileApproveDialog,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.formMobileApproveDialog = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "email-approve-body" },
            [
              _c("table", { staticClass: "dk-table" }, [
                _c("colgroup", [
                  _c("col", { staticStyle: { width: "45%" } }),
                  _c("col", { staticStyle: { width: "auto" } })
                ]),
                _c("tbody", [
                  _c("tr", [
                    _c("th", { attrs: { scople: "row" } }, [
                      _vm._v("사용자 ID")
                    ]),
                    _c("td", [_vm._v(_vm._s(_vm.mobileApproveParam.userid))])
                  ]),
                  _c("tr", [
                    _c("th", { attrs: { scople: "row" } }, [
                      _vm._v("기기 모델")
                    ]),
                    _c("td", [_vm._v(_vm._s(_vm.mobileApproveParam.model))])
                  ]),
                  _c("tr", [
                    _c("th", { attrs: { scople: "row" } }, [
                      _vm._v("기기 제조사")
                    ]),
                    _c("td", [_vm._v(_vm._s(_vm.mobileApproveParam.vendor))])
                  ]),
                  _c("tr", [
                    _c("th", { attrs: { scople: "row" } }, [_vm._v("기기 OS")]),
                    _c("td", [_vm._v(_vm._s(_vm.mobileApproveParam.os))])
                  ])
                ])
              ]),
              _c("p", [
                _c("span", [_vm._v(_vm._s(_vm.$config.getSiteConfig().title))]),
                _vm._v(
                  "위 계정에 현재 접속한 기기로 인증을 하실 경우, 다른 모바일 기기에서는 해당 계정으로 접속하실 수 없습니다. "
                ),
                _c("br"),
                _vm._v(
                  "인증을 진행하시려면 확인을\n        눌러주세요.\n      "
                )
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: { click: _vm.mobileApprove }
                },
                [_vm._v("기기 인증")]
              ),
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      _vm.formMobileApproveDialog = false
                    }
                  }
                },
                [_vm._v("취소")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }