<template>
  <el-dialog :visible.sync="formDialog" @close="hideDialog" :close-on-click-modal="false" id="formLedMessageDialog" top="2vh" title="LED 메시지 관리">
    <div class="led-message-list-wrap">
      <div class="led-message-list">
        <draggable tag="ul" :list="ledMessageData" class="list-group" handle=".handle" :move="handleMove" @end="handleDragEnd">
          <li class="list-group-item" v-for="(item, idx) in ledMessageData" :key="idx" @click="selectLedMessage(item, idx)">
            <i class="fa fa-align-justify handle"></i>
            <led-preview-viewer :ledMessageData="item" :ref="`preview`"></led-preview-viewer>
            <i class="fa fa-times close" @click="removeLedMessage(idx, $event)"></i>
          </li>
        </draggable>
        <div class="add-message-wrap">
          <el-button @click="addNewLedMessage()">
            새 LED 메시지 추가
          </el-button>
        </div>
      </div>
      <div class="led-message-info" v-if="ledMessageData[ledMessageDataIdx]">
        <div class="form-row">
          <div class="form-label">
            <label>메시지 명칭</label>
          </div>
          <div class="form-data">
            <input class="dk-form-text small" v-model="ledMessageData[ledMessageDataIdx].ledMessageName" placeholder="" type="text" />
          </div>
        </div>
        <div class="form-row">
          <div class="form-label">
            <label>재생방식</label>
          </div>
          <div class="form-data">
            <label for="LoopTime">
              <input
                type="radio"
                name="playMode"
                id="LoopTime"
                value="LoopTime"
                :checked="ledMessageData[ledMessageDataIdx].playMode === 'LoopTime' ? true : false"
                @click="checkPlayMode('LoopTime')"
              />
              재생횟수
            </label>
            <label for="FixedTime">
              <input
                type="radio"
                name="playMode"
                id="FixedTime"
                value="FixedTime"
                :checked="ledMessageData[ledMessageDataIdx].playMode === 'FixedTime' ? true : false"
                @click="checkPlayMode('FixedTime')"
              />
              고정시간
            </label>
            <div v-if="ledMessageData[ledMessageDataIdx].playMode === 'LoopTime'">
              <input type="number" class="dk-form-text small" v-model="ledMessageData[ledMessageDataIdx].playTimes" />
            </div>
            <div v-else>
              <input type="number" class="dk-form-text small" v-model="ledMessageData[ledMessageDataIdx].playeTime" />
            </div>
          </div>
        </div>
        <div class="form-row">
          <el-button @click="saveLedMessageLibrary">
            LED 메시지 라이브러리에 저장
          </el-button>
        </div>
      </div>
    </div>
    <div class="led-message-editor-wrap">
      <div class="editor-form">
        <el-form :model="formData" label-width="120px" size="medium" :rules="rules" ref="form0" label-position="left" style="position: relative;">
          <el-row>
            <el-col :span="12">
              <el-form-item label="스케줄 명칭" prop="ledScheduleName">
                <el-input v-model="formData.ledScheduleName" placeholder="LED 스케줄 명칭"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <div class="led-info">{{ ledType === 'ctype' ? '풀컬러' : '3컬러' }} / {{ rows }}단 {{ cols }}열</div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="editor-toolbar-wrap" v-if="ledType === 'ctype'">
        <el-tooltip class="item" effect="dark" content="텍스트 오브젝트를 추가합니다" placement="top">
          <el-button size="small" @click="addTextObject"> <i class="fas fa-font"></i> 텍스트 추가 </el-button>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="JPG/GIF/PNG등의 이미지 오브젝트를 추가합니다" placement="top">
          <el-button size="small" @click="addImageObject"> <i class="fas fa-image"></i> 이미지 추가 </el-button>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="실시간 환경정보를 자동으로 연동하는 오브젝트를 추가 합니다" placement="top">
          <el-button size="small" @click="addImageObject" disabled> <i class="fas fa-image"></i> 환경정보 추가 </el-button>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="실시간 재난정보를 자동으로 연동하는 오브젝트를 추가 합니다" placement="top">
          <el-button size="small" @click="addImageObject" disabled> <i class="fas fa-image"></i> 재난정보 추가 </el-button>
        </el-tooltip>
      </div>
      <div class="editor-body">
        <led-message-editor
          ref="ledMessageEditor"
          @selectObject="ledMessageSelectObject"
          @selectObjectPosInfo="selectObjectPosInfo"
          @updateObject="updateObject"
          @importedData="importedData"
          :ratio="editorRatio"
          :ledType="ledType"
        ></led-message-editor>
      </div>
      <div class="editor-panel-wrap">
        <div class="editor-panel-object-info">
          <div class="object-list">
            <ul>
              <li v-for="(item, idx) in objectList" :key="idx" :class="selectedObjectClass(item)" @click="selectObjectAtList(item)">
                <i class="fas fa-font" v-if="item.getObjectType() === 'text'"></i>
                <i class="fas fa-image" v-else-if="item.getObjectType() === 'image'"></i>
                {{ item.getObjectId() }}
              </li>
            </ul>
          </div>
          <div class="object-info" v-if="selectedObject">
            <table class="object-info-table">
              <tr>
                <td style="color: #8a8a8a; font-size: 18px; width: 30px;" rowspan="2"><i class="fas fa-arrows-alt"></i></td>
                <td style="width: 40px;">X:</td>
                <td style="padding-left: 10px; text-align: left; width: 60px;">{{ selectedObjectPosInfo.x }}</td>
                <td style="color: #8a8a8a; font-size: 18px; width: 30px;" rowspan="2"><i class="fas fa-external-link-square-alt"></i></td>
                <td style="width: 40px;">W:</td>
                <td style="padding-left: 10px; text-align: left; width: 60px;">{{ selectedObjectPosInfo.width }}</td>
              </tr>
              <tr>
                <td>Y:</td>
                <td style="padding-left: 10px; text-align: left; width: 40px;">{{ selectedObjectPosInfo.y }}</td>
                <td>H:</td>
                <td style="padding-left: 10px; text-align: left; width: 40px;">{{ selectedObjectPosInfo.height }}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="editor-panel-body">
          <div class="text-editor" v-if="selectedObjectType === 'text' && ledType === 'ctype'">
            <div class="editor-panel-toolbar">
              <div class="toolbar-panel">
                <!-- <el-tooltip class="item" effect="dark" content="글꼴" placement="top">
                  <el-select size="mini" v-model="textObjectStyleData.fontFamily" @change="changeFontFamily" style="margin-right: 5px; width: 120px;">
                    <el-option label="나눔고딕" value="NanumGothic"></el-option>
                    <el-option label="돋움" value="Dotum"></el-option>
                    <el-option label="고딕" value="Gothic"></el-option>
                    <el-option label="바탕" value="Batang"></el-option>
                    <el-option label="궁서" value="Gungsuh"></el-option>
                    <el-option label="명조" value="Myeongjo"></el-option>
                  </el-select>
                </el-tooltip> -->
                <el-select size="mini" v-model="textObjectStyleData.fontSize" @change="changeFontSize" style="margin-right: 5px; width: 80px;">
                  <el-option label="6" :value="6 * this.editorRatio"></el-option>
                  <el-option label="8" :value="8 * this.editorRatio"></el-option>
                  <el-option label="9" :value="9 * this.editorRatio"></el-option>
                  <el-option label="10" :value="10 * this.editorRatio"></el-option>
                  <el-option label="11" :value="11 * this.editorRatio"></el-option>
                  <el-option label="12" :value="12 * this.editorRatio"></el-option>
                  <el-option label="13" :value="13 * this.editorRatio"></el-option>
                  <el-option label="14" :value="14 * this.editorRatio"></el-option>
                  <el-option label="15" :value="15 * this.editorRatio"></el-option>
                  <el-option label="16" :value="16 * this.editorRatio"></el-option>
                  <el-option label="17" :value="17 * this.editorRatio"></el-option>
                  <el-option label="18" :value="18 * this.editorRatio"></el-option>
                  <el-option label="19" :value="19 * this.editorRatio"></el-option>
                  <el-option label="20" :value="20 * this.editorRatio"></el-option>
                </el-select>
                <el-tooltip class="item" effect="dark" content="폰트 굵기" placement="top">
                  <el-button
                    size="mini"
                    icon="fas fa-bold"
                    class="toolbar-button"
                    :style="textObjectStyleData.fontWeight == 'bold' ? 'color:#8a8a8a' : 'color:#bfbfbf'"
                    @click="setFontWeight"
                  ></el-button>
                </el-tooltip>
              </div>
              <div class="toolbar-panel">
                <!-- <span v-for="(item, seq) in colors" :key="seq" :style="colorPickerStyle(item)" @click="changeTextColor(item)"></span> -->
                <button
                  v-for="(item, seq) in colors"
                  :key="seq"
                  @click="changeTextColor(item)"
                  class="button-color-picker"
                  :style="`background-color:${item}`"
                ></button>
              </div>
            </div>
            <div
              class="text-input-area"
              ref="textEditor"
              contenteditable="true"
              @keyup="textEditorKeyup"
              :style="[textObjectStyle]"
              v-html="textObjectDom"
            ></div>
          </div>

          <div v-else-if="selectedObjectType === 'image' && ledType === 'ctype'">
            <!-- <div class="editor-panel-toolbar"></div> -->
            <ul class="led-image-library-wrap">
              <li v-for="(item, idx) in ledImageLibraries" :key="idx">
                <span :style="[getLedImageLibStyle(item)]" @click="setLedImageObjectImage(item)"></span>
              </li>
            </ul>
          </div>
          <div v-else-if="ledType === '3color'" class="text-editor">
            <div class="editor-panel-toolbar">
              <div class="toolbar-panel">
                <button
                  v-for="(item, seq) in colors3"
                  :key="seq"
                  @click="changeTextColor(item)"
                  class="button-color-picker"
                  :style="`background-color:${item}`"
                ></button>
              </div>
            </div>
            <div
              class="text-input-area"
              ref="textEditor"
              contenteditable="true"
              @keyup="textEditorKeyup"
              :style="[textObjectStyle]"
              v-html="textObjectDom"
            ></div>
          </div>
        </div>
        <div class="editor-panel-object-options" v-if="ledType === 'ctype'">
          <el-row>
            <el-col :span="24">
              표출 효과
            </el-col>

            <el-col :span="24">
              <el-select size="small" @change="changeObjectDispEffect" v-model="selectedObjectDispEffectCode">
                <el-option v-for="(item, idx) in effectCode" :key="idx" :label="item.label" :value="item.key"></el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              효과 동작 시간
            </el-col>

            <el-col :span="24">
              <el-select size="small" @change="changeObjectDispTime" v-model="selectedObjectDispEffectTime">
                <el-option label="1 매우빠름" :value="1"></el-option>
                <el-option label="2" :value="2"></el-option>
                <el-option label="3 빠름" :value="3"></el-option>
                <el-option label="4" :value="4"></el-option>
                <el-option label="5 보통" :value="5"></el-option>
                <el-option label="6" :value="6"></el-option>
                <el-option label="7 느림" :value="7"></el-option>
                <el-option label="8" :value="8"></el-option>
                <el-option label="9 매우느림" :value="9"></el-option>
              </el-select>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="led-utility-wrap">
      <div class="title-wrap">
        <div class="title-text">LED 메시지 라이브러리</div>
        <div class="title-button">
          <i class="fas fa-times" @click="hideDialog"></i>
        </div>
      </div>
      <ul>
        <li v-for="(item, idx) in ledMessages" :key="idx">
          <!-- <led-preview-viewer :ledmessage="item" :scale="3"></led-preview-viewer> -->
          <div class="led-message-title">
            <led-preview-viewer :ledMessageData="item" :selectable="true"></led-preview-viewer>
            <div class="led-message-desc">
              <span class="led-message-name">
                {{ item.ledMessageName }}
              </span>
              <span>
                <el-button size="small" @click="importLedMessage(item)">불러오기</el-button>
              </span>
            </div>
          </div>
        </li>
      </ul>
      <div class="form-bottom" style="padding: 0 8px 8px 8px;">
        <el-button type="primary" @click="save" style="float: right;">
          저장
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
const effectCode = [
  { label: '왼쪽연속이동', key: 26 },
  { label: '오른쪽연속이동', key: 27 },
  { label: '효과없음', key: 0 },
  { label: '왼쪽이동', key: 1 },
  { label: '오른쪽이동', key: 2 },
  { label: '위쪽이동', key: 3 },
  { label: '아래쪽이동 ', key: 4 },
  { label: '왼쪽커버 ', key: 5 },
  { label: '오른쪽커버', key: 6 },
  { label: '위쪽커버 ', key: 7 },
  { label: '아래쪽커버', key: 8 },
  { label: '좌상각덮음 ', key: 9 },
  { label: '좌하각덮음 ', key: 10 },
  { label: '우상각덮음 ', key: 11 },
  { label: '우하각덮음 ', key: 12 },
  { label: '중간부터열림 ', key: 13 },
  { label: '위아래로열림 ', key: 14 },
  { label: '좌우로닫음 ', key: 15 },
  { label: '위아래로닫음', key: 16 },
  { label: '페이드 ', key: 17 },
  { label: '수평블라인드', key: 18 },
  { label: '수직블라인드', key: 19 },
  { label: '무작위', key: 25 },
];

import ledMessageEditor from './ledMessageEditor.vue';
import ledPreviewViewer from './ledPreviewViewer.vue';
import _ from 'lodash';
import draggable from 'vuedraggable';
import uuid from 'uuid/v1';

export default {
  components: {
    'led-message-editor': ledMessageEditor,
    'led-preview-viewer': ledPreviewViewer,
    draggable: draggable,
  },
  data() {
    return {
      formData: {
        ledScheduleName: '',
        ledMessages: [],
      },
      rules: {
        ledScheduleName: [
          {
            required: true,
            message: 'LED 스케줄 이름을 입력해 주세요',
            trigger: 'blur',
          },
        ],
      },
      colors: [
        '#ff0000',
        '#ff5e00',
        '#ffbb00',
        '#ffe400',
        '#abf200',
        '#1fda11',
        '#00d8ff',
        '#0055ff',
        '#0900ff',
        '#6600ff',
        '#ff00dd',
        '#ff007f',
        '#ffffff',
        '#eaeaea',
        '#bdbdbd',
      ],
      colors3: ['#ff0000', '#00ff00', '#ffff00'],
      ledFcltTypes: [],
      ledMessages: [],
      formDialog: false,
      ledMessageDataIdx: -1,
      ledMessageData: [],
      selectedObject: null,
      selectedObjectPosInfo: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      },
      selectedObjectDispEffectCode: null,
      selectedObjectDispEffectTime: null,
      editorRatio: 5,
      objectList: [],
      textObjectDom: '',
      textObjectStyleData: {
        fontSize: 14 * this.editorRatio,
        fontWeight: 'normal',
        fontColor: '#00ff00',
        fontFamily: 'NanumGothic',
      },
      ledImageLibraries: [],
      formTitle: '',
      updateFlag: false,
      cols: 6,
      rows: 1,
      ledType: 'ctype',
      movingItem: 0,
      futureItem: 0,
      // select 이벤트 중에는 에디터에서 updated나 imported로 인한 이벤트 전파를 막기위한 flag
      isSelectProc: false,
      effectCode: effectCode,
    };
  },
  computed: {
    selectedObjectType() {
      if (this.selectedObject && this.selectedObject.getObjectType) return this.selectedObject.getObjectType();
      else return null;
    },
    textObjectStyle() {
      return {
        fontSize: (this.textObjectStyleData.fontSize / this.editorRatio) * 2 + 'px',
        fontWeight: this.textObjectStyleData.fontWeight,
        color: this.textObjectStyleData.fontColor,
        fontFamily: this.textObjectStyleData.fontFamily,
        lineHeight: '110%',
        textAlign: 'left',
      };
    },
  },
  mounted() {
    // this.getLedMessage();
    this.getLedLibraries();
    this.getLedFcltTypes();
  },
  methods: {
    async getLedMessage() {
      try {
        const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/led/api/ledMessage`, {
          params: {
            ledMessageSize: `${this.rows}x${this.cols}`,
            ledType: this.ledType,
          },
        });
        this.ledMessages = response.data.docs;
      } catch (err) {
        this.ledMessages = [];
      }
    },
    async getLedLibraries() {
      const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/led/api/ledLibrary`);
      this.ledImageLibraries = response.data.docs;
    },
    async getLedFcltTypes() {
      // const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/led/api/ledFcltTypes`);
      // this.ledFcltTypes = response.data.docs;
    },
    addTextObject() {
      this.$refs.ledMessageEditor.addTextObject();
    },
    addImageObject() {
      this.$refs.ledMessageEditor.addImageObject();
    },
    importedData() {
      this.updateObject();
      this.$refs.ledMessageEditor.$emit('selectLedMessage', {});
    },
    updateObject() {
      if (this.isSelectProc) {
        this.objectList = this.$refs.ledMessageEditor.getObjectList();
        return;
      }
      // 이 타이밍에 preview에 commit 이 일어나는게 맞음
      const data = this.$refs.ledMessageEditor.exportData();
      this.ledMessageData[this.ledMessageDataIdx] = _.merge(this.ledMessageData[this.ledMessageDataIdx], _.cloneDeep(data));
      if (this.$refs.preview && this.$refs.preview.length > 0 && this.ledMessageDataIdx > -1) {
        this.$refs.preview[this.ledMessageDataIdx].refresh();
      }
      this.$nextTick(() => {
        this.objectList = this.$refs.ledMessageEditor.getObjectList();

        // if (this.$refs['preview-' + this.ledMessageDataIdx]) {
        // this.$refs['preview-' + this.ledMessageDataIdx].refresh();
        // }
      });
    },
    ledMessageSelectObject(arg) {
      this.selectedObject = arg.obj;
      if (this.selectedObject.getObjectType() === 'text') {
        this.textObjectDom = this.selectedObject.getEditDom();
        this.textObjectStyleData = {
          fontSize: this.selectedObject.getFontSize(),
          fontWeight: this.selectedObject.getFontWeight(),
          fontColor: this.selectedObject.getFontColor(),
          fontFamily: this.selectedObject.getFontFamily(),
        };
      }

      this.selectedObjectDispEffectCode = this.selectedObject.getDispEffectCode();
      this.selectedObjectDispEffectTime = this.selectedObject.getDispEffectTime();
      this.$forceUpdate();
    },
    selectObjectPosInfo(object) {
      if (object) {
        this.selectedObjectPosInfo.x = Math.round(object.getX() / this.editorRatio);
        this.selectedObjectPosInfo.y = Math.round(object.getY() / this.editorRatio);
        this.selectedObjectPosInfo.width = Math.round(object.getWidth() / this.editorRatio);
        this.selectedObjectPosInfo.height = Math.round(object.getHeight() / this.editorRatio);
      }
    },
    selectObjectAtList(item) {
      item.selectObjectKey();
    },
    textEditorKeyup(event) {
      if (this.selectedObject.getObjectType() === 'text') {
        this.selectedObject.setEditDom(event.target.innerHTML);
      }
    },
    changeFontSize(val) {
      if (this.selectedObject.getObjectType() === 'text') {
        this.selectedObject.setFontSize(val);
      }
    },
    changeFontFamily(val) {
      if (this.selectedObject.getObjectType() === 'text') {
        this.selectedObject.setFontFamily(val);
      }
    },
    setFontWeight() {
      if (this.selectedObject.getObjectType() === 'text') {
        this.textObjectStyleData.fontWeight = this.textObjectStyleData.fontWeight == 'bold' ? 'normal' : 'bold';
        this.selectedObject.setFontWeight(this.textObjectStyleData.fontWeight);
      }
    },
    getLedImageLibStyle(item) {
      const imageUrl = item.images.length > 0 ? item.images[0].serverFilePath : 'static/images/no-image.jpg';
      return {
        background: `url(${imageUrl}) no-repeat 50% 50% /contain #000`,
      };
    },
    setLedImageObjectImage(item) {
      if (this.selectedObject.getObjectType() === 'image' && item.images.length > 0) {
        this.selectedObject.setImageUrl(item.images[0].serverFilePath);
      }
    },
    changeTextColor(color) {
      document.execCommand('foreColor', false, color);

      this.$forceUpdate();
      window.getSelection().removeAllRanges();
      this.selectedObject.setEditDom(this.$refs.textEditor.innerHTML);
    },
    showDialog(row, options) {
      this.formDialog = true;
      this.updateFlag = false;
      this.formTitle = 'LED 스케줄 추가';
      this.resetData();
      if (options) {
        this.ledType = options.ledType || 'ctype';
        if (options.cols && options.rows) {
          this.cols = parseInt(options.cols);
          this.rows = parseInt(options.rows);
        }
      }

      if (row) {
        this.updateFlag = true;
        this.formTitle = 'LED 스케줄 수정';
        this.formData = this.$lodash.cloneDeep(row);
        this.ledMessageData = this.formData.ledMessages;
      }
      this.$nextTick(() => {
        if (options && options.cols && options.rows) {
          if (options.cols > 10) {
            this.$refs.ledMessageEditor.setRatio(4);
          }
          if (options.rows > 10) {
            this.$refs.ledMessageEditor.setRatio(1);
          }
          this.$refs.ledMessageEditor.setEditorSize({
            cols: options.cols,
            rows: options.rows,
          });
        }
        if (!this.updateFlag) {
          this.addNewLedMessage();
        }
      });
      this.getLedMessage();
      setTimeout(() => {
        if (this.formData.ledMessages.length > 0) {
          this.$refs.ledMessageEditor.importData(this.ledMessageData[0]);
        }
        this.$forceUpdate();
      }, 100);
    },
    async hideDialog() {
      this.formDialog = false;
      this.resetData();
      //날리면 아까우니 암시 스케줄 DB에 저장함
      // this.formData.ledMessages = this.ledMessageData;
      // await this.$http.post(`${this.$config.getServerConfig().core.api}/led/api/ledSchedule/saveTempData`, { data: this.formData });
    },
    resetData() {
      this.ledMessageDataIdx = -1;
      this.ledMessageData = [];
      (this.selectedObject = null),
        (this.selectedObjectPosInfo = {
          x: 0,
          y: 0,
          width: 0,
          height: 0,
        });
      this.editorRatio = 5;
      this.objectList = [];
      this.formData = {
        ledScheduleName: '',
        ledMessages: [],
      };
      if (this.$refs.ledMessageEditor) this.$refs.ledMessageEditor.resetEditor();
      this.selectedObjectDispEffectCode = null;
      this.selectedObjectDispEffectTime = null;
    },
    addNewLedMessage() {
      //const data = this.$refs.ledMessageEditor.exportData();
      //// console.log(_.merge(this.ledMessageData[this.ledMessageDataIdx], _.cloneDeep(data)));
      //this.ledMessageData[this.ledMessageDataIdx] = _.merge(this.ledMessageData[this.ledMessageDataIdx], _.cloneDeep(data));
      // const data = this.$refs.ledMessageEditor.exportData();
      // this.ledMessageData[this.ledMessageDataIdx] = {
      //   ...this.ledMessageData[this.ledMessageDataIdx],
      //   ..._.cloneDeep(data),
      // };

      if (this.ledMessageDataIdx > -1) {
        this.updateObject();
      }
      this.ledMessageDataIdx = this.ledMessageData.length;
      this.ledMessageData[this.ledMessageDataIdx] = {};
      this.ledMessageData[this.ledMessageDataIdx] = {
        cols: this.cols,
        rows: this.rows,
        moduleCount: 16,
        ledMessageId: uuid(),
        ledMessageName: `메시지 ${this.ledMessageDataIdx + 1}`,
        playMode: 'LoopTime',
        playTimes: '1',
        playeTime: '30',
        textData: [],
        imageData: [],
        ledType: this.ledType,
      };
      this.$refs.ledMessageEditor.resetEditor();
      this.objectList = [];
      if (this.ledType === '3color') {
        this.$refs.ledMessageEditor.addTextObject({
          x: 0,
          y: 0,
          width: '100%',
          height: '100%',
        });
      }
    },
    selectLedMessage(item, idx) {
      if (this.ledMessageDataIdx === idx) return;
      this.isSelectProc = true;
      //this.updateObject();
      const data = this.$refs.ledMessageEditor.exportData();
      this.ledMessageData[this.ledMessageDataIdx] = _.merge(this.ledMessageData[this.ledMessageDataIdx], _.cloneDeep(data));
      if (this.$refs.preview && this.$refs.preview.length > 0 && this.ledMessageDataIdx > -1) {
        this.$refs.preview[this.ledMessageDataIdx].refresh();
      }
      // delete this.ledMessageData[this.ledMessageDataIdx].textData;
      // delete this.ledMessageData[this.ledMessageDataIdx].imageData;
      // this.ledMessageData[this.ledMessageDataIdx] = {
      //   ...this.ledMessageData[this.ledMessageDataIdx],
      //   ..._.cloneDeep(data),
      // };
      this.objectList = [];
      this.$refs.ledMessageEditor.resetEditor();
      this.selectedObject = null;
      this.$refs.ledMessageEditor.importData(item);
      this.$forceUpdate();
      this.$nextTick(() => {
        this.ledMessageDataIdx = idx;
        this.isSelectProc = false;
        this.$refs.ledMessageEditor.$emit('selectLedMessage', {});
      });

      // const data = this.$refs.ledMessageEditor.exportData();
      // console.log(item);
      // this.ledMessageData[this.ledMessageDataIdx] = _.cloneDeep(data);
      // if (this.$refs[`preview-${this.ledMessageDataIdx}`] && this.$refs[`preview-${this.ledMessageDataIdx}`].length > 0) {
      //   this.$refs[`preview-${this.ledMessageDataIdx}`][0].refresh();
      // }
      // this.ledMessageDataIdx = idx;
      // this.$refs.ledMessageEditor.resetEditor();
      // this.objectList = [];
      // this.$refs.ledMessageEditor.importData(item);
      // this.$forceUpdate();
    },
    save() {
      this.$refs.form0.validate(async (valid) => {
        if (valid) {
          let saveFunc = '/led/api/ledSchedule/add';
          if (this.updateFlag) {
            saveFunc = '/led/api/ledSchedule/update';
          }
          const data = this.$refs.ledMessageEditor.exportData();
          if (this.ledMessageDataIdx == -1) this.ledMessageDataIdx = 0;
          this.ledMessageData[this.ledMessageDataIdx] = _.cloneDeep(data);
          this.formData.ledMessages = this.ledMessageData;
          this.formData.ledMessageSize = `${this.rows}x${this.cols}`;
          this.formData.ledType = this.ledType;
          try {
            await this.$http.post(`${this.$config.getServerConfig().core.api}${saveFunc}`, {
              data: this.formData,
            });
            this.$emit('procDataComplete', null);
            this.resetData();
            this.formDialog = false;
            this.$message({
              type: 'success',
              message: `LED 스케줄 등록 완료`,
            });
          } catch (err) {
            this.resetData();
            this.formDialog = false;
            this.$message.error(`LED 스케줄 등록 중 에러 발생`);
          }
        }
      });
    },
    selectedObjectClass(item) {
      if (this.selectedObject) {
        return this.selectedObject.getObjectId() === item.getObjectId() ? 'active' : '';
      } else {
        return '';
      }
    },
    handleDragEnd() {
      this.isSelectProc = true;
      const futureItem = this.ledMessageData[this.futureIndex];
      const movingItem = this.ledMessageData[this.movingIndex];
      const _items = Object.assign([], this.ledMessageData);
      _items[this.futureIndex] = movingItem;
      _items[this.movingIndex] = futureItem;
      this.ledMessageDataIdx = this.futureIndex;
      this.ledMessageData = _items;
      this.$refs.ledMessageEditor.resetEditor();
      this.$refs.ledMessageEditor.importData(this.ledMessageData[this.ledMessageDataIdx]);
      this.$forceUpdate();
      this.$nextTick(() => {
        this.isSelectProc = false;
      });
    },
    handleMove(e) {
      const { index, futureIndex } = e.draggedContext;
      this.movingIndex = index;
      this.futureIndex = futureIndex;
      return false; // disable sort
    },
    removeLedMessage(idx, e) {
      e.stopPropagation();
      if (idx > 0) {
        this.ledMessageData.splice(idx, 1);
        this.isSelectProc = true;
        this.$refs.ledMessageEditor.resetEditor();
        this.ledMessageDataIdx = -1;
        this.$nextTick(() => {
          this.selectLedMessage(this.ledMessageData[0], 0);
          this.isSelectProc = false;
        });
      } else {
        this.$message({
          type: 'danger',
          message: `마지막 LED메시지는 삭제할 수 없습니다`,
        });
      }
    },
    checkPlayMode(val) {
      this.ledMessageData[this.ledMessageDataIdx].playMode = val;
      this.$forceUpdate();
    },
    async saveLedMessageLibrary() {
      if (this.ledMessageData[this.ledMessageDataIdx].ledMessageName === '') {
        this.$message.error(`메시지 라이브러리에 등록하려면 메시지 명칭을 입력해야 합니다.`);
        return false;
      }
      if (this.ledMessageData[this.ledMessageDataIdx].ledMessageId === '') {
        this.$message.error(`메시지 ID 오류가 발생 했습니다.`);
        return false;
      }
      // message id 검사 해서 서버에 저장되어 있는지 확인한다.
      const param = {
        ledMessageId: this.ledMessageData[this.ledMessageDataIdx].ledMessageId,
      };
      const existLedMessage = await this.$http.get(`${this.$config.getServerConfig().core.api}/led/api/ledMessage/existLedMessage`, {
        params: param,
      });

      let saveFunc = '/led/api/ledMessage/add';
      if (existLedMessage.data) {
        saveFunc = '/led/api/ledMessage/update';
      }
      let messageData = this.$refs.ledMessageEditor.exportData();
      let tempData = _.cloneDeep(this.ledMessageData[this.ledMessageDataIdx]);
      delete tempData.textData;
      delete tempData.imageData;

      let data = {
        ...messageData,
        ...tempData,
      };

      //let data = this.ledMessageData[this.ledMessageDataIdx];
      data.ledMessageSize = `${data.rows}x${data.cols}`;
      data.ledType = this.ledType;
      try {
        await this.$http.post(`${this.$config.getServerConfig().core.api}${saveFunc}`, {
          data: data,
        });
        this.$emit('procDataComplete', null);
        this.getLedMessage();
        this.$message({
          type: 'success',
          message: `LED 스케줄 등록 완료`,
        });
      } catch (err) {
        this.$message.error(`LED 스케줄 등록 중 에러 발생`);
      }
    },
    changeObjectDispEffect(val) {
      if (this.selectedObject) this.selectedObject.setDispEffectCode(val);
    },
    changeObjectDispTime(val) {
      if (this.selectedObject) this.selectedObject.setDispEffectTime(val);
    },
    importLedMessage(ledMessage) {
      this.isSelectProc = true;
      this.$refs.ledMessageEditor.resetEditor();
      this.$refs.ledMessageEditor.importData(ledMessage);
      //this.ledMessageData[this.ledMessageDataIdx] = _.cloneDeep(ledMessage);
      this.$forceUpdate();
      this.$nextTick(() => {
        this.isSelectProc = false;
        this.$refs.ledMessageEditor.$emit('selectLedMessage', {});
        setTimeout(() => {
          if (this.ledMessageDataIdx > -1 && this.ledMessageData[this.ledMessageDataIdx]) {
            this.ledMessageData[this.ledMessageDataIdx] = null;
          }
          this.updateObject();
        }, 100);
      });
    },
  },
};
</script>

<style scoped></style>
