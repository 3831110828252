<template>
	<div class="fclt-mobile-dashboard">
		<el-tabs
			type="border-card"
			v-model="currentTab"
			:stretch="true"
			style="width: 100%;"
			class="mobile-maintnce-tab"
		>
			<el-tab-pane label="현재 유지관리 작업" name="tab1" style="padding: 0;">
				<div>
					<div v-for="(steps, index) in maintnceWorkerSteps" :key="index" class="maintnce-step">
						<div class="label">{{ steps.label }}</div>
						<div v-for="(item, index2) in maintnceBoardItem" :key="index2">
							<div class="item" v-if="steps.status === item.status">
								<p>{{ item.fcltName }}</p>
								<p>{{ item.maintnceSummary }}</p>
								{{ item }}
							</div>
						</div>
					</div>
				</div>
			</el-tab-pane>
			<el-tab-pane label="이전 유지관리 작업" name="tab2">
				<div></div>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import _ from 'lodash';
export default {
	data() {
		return {
			currentTab: 'tab1',
			maintnceSteps: this.$config.getSiteConfig().maintnceStatus,
			// maintnceWorkerSteps: _.reverse(this.$config.getSiteConfig().maintnceStatus),
			maintnceWorkerSteps: _.reverse(
				_.filter(this.$config.getSiteConfig().maintnceStatus, {
					isWorkerJob: true,
				})
			),
			maintnceBoardItem: [],
		};
	},
	created() {
		this.getMaintnceBoardItem();
	},
	methods: {
		async getMaintnceBoardItem() {
			let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/maintnceLog`, {});
			this.maintnceBoardItem = response.data.docs;
		},
	},
};
</script>

<style scoped></style>
