var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("GChart", {
    ref: "gChart",
    attrs: {
      type: "ColumnChart",
      settings: {
        packages: ["corechart"],
        language: "ko",
        callback: function() {
          this$1.drawChart()
        }
      },
      data: _vm.chartData,
      options: _vm.options
    },
    on: { ready: _vm.onChartReady }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }