var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: "abs-full " + (_vm.navOnOff ? "navOn" : "navOff"),
      staticStyle: { "z-index": "99 !important" }
    },
    [
      _c("div", {
        class: "side-menu-container " + (_vm.navOnOff ? "d-block" : "d-none"),
        on: { click: _vm.navOff }
      }),
      _c(
        "div",
        { class: "side-menu-box " + (_vm.navOnOff ? "navOn" : "navOff") },
        [
          _c("div", { staticClass: "login-area mb-both-pd" }, [
            _vm._m(0),
            _c("div", { staticClass: "login-text-box flex-box" }, [
              _c("div", { staticClass: "login-user" }, [
                _c("span", [_vm._v(_vm._s(_vm.userName))]),
                _c("i", {
                  staticClass: "icon-pencle",
                  on: { click: _vm.UserInfoModify }
                }),
                _c("div", [
                  _vm._v(_vm._s(_vm.userId + "(" + _vm.groupName + ")"))
                ])
              ]),
              _c(
                "div",
                { staticClass: "logout-mb", on: { click: _vm.logout } },
                [_c("i", { staticClass: "icon-exit" })]
              )
            ])
          ]),
          _c("div", { staticClass: "event-one-btn-container" }, [
            _c(
              "div",
              {
                staticClass: "one-btn flex-center btn-style",
                on: { click: _vm.onClickHome }
              },
              [
                _c("i", { staticClass: "icon-empty-home" }),
                _c("span", [_vm._v("홈")])
              ]
            ),
            _c(
              "div",
              {
                staticClass: "one-btn flex-center btn-style",
                on: { click: _vm.Setting }
              },
              [
                _c("i", { staticClass: "icon-empty-setting" }),
                _c("span", [_vm._v("환경설정")])
              ]
            )
          ]),
          _c("div", { staticClass: "menu-list" }, [
            _c(
              "ul",
              _vm._l(_vm.navList, function(depth1) {
                return _c(
                  "li",
                  { key: depth1.key, staticClass: "menu-depth1-container" },
                  [
                    depth1.navUlList
                      ? _c(
                          "div",
                          {
                            staticClass: "menu-depth1 btn-style",
                            on: {
                              click: function($event) {
                                return _vm.depth1Click($event, depth1)
                              }
                            }
                          },
                          [
                            _c("i", { class: depth1.icon }),
                            _c("span", [_vm._v(_vm._s(depth1.label))]),
                            _c("div", { staticClass: "float-right" }, [
                              _c("i", { class: depth1.arrow })
                            ])
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass: "menu-depth1 btn-style",
                            on: {
                              click: function($event) {
                                return _vm.depth1ClickMove($event, depth1)
                              }
                            }
                          },
                          [
                            _c("i", { class: depth1.icon }),
                            _c("span", [_vm._v(_vm._s(depth1.label))]),
                            _c("div", { staticClass: "float-right" }, [
                              _c("i", { class: depth1.arrow })
                            ])
                          ]
                        ),
                    _c(
                      "ul",
                      { staticClass: "depth2-ul" },
                      _vm._l(depth1.navUlList, function(depth2) {
                        return _c(
                          "li",
                          {
                            key: depth2.key,
                            on: {
                              click: function($event) {
                                return _vm.onClickMenu(depth2)
                              }
                            }
                          },
                          [_vm._v(_vm._s(depth2.label))]
                        )
                      }),
                      0
                    )
                  ]
                )
              }),
              0
            )
          ])
        ]
      ),
      _c("form-User-info-modify", { ref: "formUserInfoModify" }),
      _c("form-setting", { ref: "formSetting" }),
      _vm.formSpreadSituationFlag
        ? _c("form-spread-situation", { ref: "formSpreadSituation" })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "profile-img" }, [
      _c("img", { attrs: { src: "static/images/unknown.png" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }