var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vue-dkgis-wrap" },
    [
      _c(_vm.dkGisMap, {
        ref: "dkGisMap",
        tag: "component",
        attrs: {
          gisApiInfo: _vm.currentGisApiInfo,
          trafficLayerData: _vm.trafficLayerData,
          selectTrafficLayer: _vm.selectTrafficLayer,
          clusterData: _vm.clusterData,
          selectBoxData: _vm.selectBoxData
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }