<template>
  <el-dialog
    :title="formTitle"
    :visible.sync="formDialog"
    @close="hideDialog"
    :close-on-click-modal="false"
    top="5vh"
    class="el-dialog-aside"
    append-to-body
    width="1250px"
    id="formMaintnce"
    @opened="opened"
  >
    <div class="form-gis-wrap" v-if="!$isMobile">
      <el-row>
        <el-col :span="10" class="gis-search-wrap">
          <el-row>
            <el-col :span="24" style="padding-left: 11px; padding-right: 11px;">
              <el-select size="mini" v-model="searchFcltTypeId" placeholder="시설물 유형" :multiple="true" style="width: 100%;">
                <el-option v-for="item in fcltType" :key="item.fcltTypeId" :label="item.fcltTypeName" :value="item.fcltTypeId"></el-option>
              </el-select>
            </el-col>
            <el-col :span="16" style="margin-left: 11px; margin-top: 5px;">
              <el-input size="mini" v-model="keyword" @keyup.enter.native="searchFcltList" placeholder="시설물 검색"></el-input>
            </el-col>
            <el-col :span="2" style="margin-top: 5px; padding-left: 4px;" v-show="markerData.length > 0">
              <el-button type="primary" size="mini" style="padding-left: 0; padding-right: 0; width: 100%;" @click="resetFclt">
                <i class="fas fa-times"></i>
              </el-button>
            </el-col>
            <el-col :span="5" style="margin-top: 5px; padding-left: 3px;">
              <el-button type="primary" @click="searchFcltList" size="mini" style="width: 100%;">
                <i class="fas fa-search"></i>
              </el-button>
            </el-col>
            <el-col :span="24"> </el-col>
          </el-row>
          <ul v-if="markerData.length > 0" class="maintnce-gis-search-result">
            <li v-for="(item, idx) in markerData" :key="idx" :class="formData.fcltId === item.properties.fcltId ? 'selected' : ''">
              <div class="search-list-text" @click="selectFclt(item)">
                <div>
                  <span class="fclt-name">{{ item.properties.fcltName }}</span>
                  <span class="fclt-type-name">{{ item.properties.fcltTypeName }}</span>
                </div>
                <div>
                  {{ item.properties.setNumberAddr }}
                </div>
              </div>
            </li>
          </ul>
        </el-col>
        <el-col :span="14">
          <!-- <dk-gis ref="dkGis" style="height: 350px;" :gisApiInfo="gisApiInfo" :markerData="markerData" :level="level"></dk-gis> -->
          <dk-gis
            ref="dkGis"
            style="height: 380px;"
            :gisApiInfo="gisApiInfo"
            :markerData="markerData"
            :level="level"
            :clickMarker="selectFclt"
            :apiLoadComplete="apiLoadComplete"
            :clusterData="clusterData"
            @moveEnd="getGisFcltMarker"
            @zoomEnd="zoomEnd"
          ></dk-gis>
        </el-col>
      </el-row>
    </div>
    <el-container class="dialog-container maintnce-container">
      <div class="form-container">
        <el-form class="form-maintnce" ref="form0" size="medium" :label-position="$isMobile ? 'top' : 'left'" label-width="150px">
          <el-row>
            <el-col :span="16">
              <el-form-item label="유지관리 대상 시설물" prop="fcltName" v-if="!$isMobile">
                <div v-if="formData.fcltName" class="form-fclt-name">
                  {{ formData.fcltName }}
                  <span>{{ formData.fcltTypeName }}</span>
                </div>
                <div v-else class="form-fclt-name no-data">선택한 시설물이 없습니다.</div>
              </el-form-item>
              <h3 v-else>{{ formData.fcltName }}</h3>
            </el-col>
            <el-col :span="16" v-if="templateList.length > 0">
              <el-form-item label="유지관리 템플릿 선택">
                <el-select @change="setTemplate" v-model="selectTemplateId">
                  <el-option v-for="(item, idx) in templateList" :key="idx" :label="item.templateName" :value="item.templateId"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <dk-form ref="dkTemplateForm" formType="input" size="medium"></dk-form>
        </el-form>
        <el-form
          class="form-maintnce"
          :rules="rules"
          ref="form1"
          size="medium"
          :model="formData"
          :label-position="$isMobile ? 'top' : 'left'"
          label-width="150px"
        >
          <el-row>
            <!-- <el-col :span="24">
              <el-form-item label="유지관리 대상 시설물" prop="fcltName" v-if="!$isMobile">
                <div v-if="formData.fcltName" class="form-fclt-name">{{ formData.fcltName }}</div>
                <div v-else class="form-fclt-name no-data">선택한 시설물이 없습니다.</div>
              </el-form-item>
              <h3 v-else>{{ formData.fcltName }}</h3>
            </el-col> -->
            <!-- <el-col :span="12">
              <el-form-item label="작업 유형" prop="workType">
                <el-select v-model="formData.workType" placeholder="작업 유형" style="width: 90%;">
                  <el-option v-for="(work, index) in workTypes" :key="index" :label="work.label" :value="work.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col> -->
            <el-col :span="12">
              <el-form-item label="중요도" prop="importance">
                <el-select v-model="formData.importance" placeholder="중요도" style="width: 100%;">
                  <el-option v-for="(imp, index) in importances" :key="index" :label="imp.label" :value="imp.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="요약" prop="maintnceSummary">
                <el-input v-model="formData.maintnceSummary" placeholder="유지보수 내용 요약"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="설명" prop="maintnceDesc">
                <el-input v-model="formData.maintnceDesc" placeholder="내용 설명" type="textarea" :rows="5"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <div style="color: #606266; font-size: 14px; line-height: 40px;">유지관리 관련 사진</div>
              <dk-el-file-upload
                ref="uploadImage"
                uploaderType="image"
                listType="picture-card"
                path="maintnce"
                :fileList="formData.files"
                :onPreview="handlePictureCardPreview"
              >
                <div>
                  <i class="el-icon-plus"></i>
                </div>
              </dk-el-file-upload>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <el-aside width="350px">
        <div class="info-panel">
          <el-form class="form-maintnce" ref="form2" size="small" :model="formData" label-position="top">
            <el-row>
              <el-col :span="24">
                <el-form-item label="진행상황" prop="status">
                  <el-select v-model="formData.status" placeholder="작업 진행상황">
                    <el-option v-for="(status, index) in maintnceSteps" :key="index" :label="status.label" :value="status.status"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="담당자" prop="managerId">
                  <el-select v-model="formData.managerId" placeholder="담당자를 선택하세요">
                    <el-option v-for="(user, index) in userList" :key="index" :label="user.name" :value="user.userid"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="작업자" prop="workerId">
                  <el-select v-model="formData.workerId" placeholder="작업자를 지정할 경우 선택">
                    <el-option v-for="(user, index) in userList" :key="index" :label="user.name" :value="user.userid"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="작업완료 예정일" prop="completePlanDate">
                  <el-date-picker type="date" v-model="formData.completePlanDate" placeholder="완료일을 지정 할 경우 선택"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="종료일" prop="endDate">
                  <el-date-picker type="date" v-model="formData.endDate" placeholder="종료일을 수정 할 경우 선택"></el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-aside>
    </el-container>
    <span slot="footer" class="dialog-footer">
      <el-button v-if="updateFlag" icon="fas fa-trash-alt" @click="remove" style="float: left;"></el-button>
      <el-button type="primary" @click="confirm" v-if="checkConfirmUser()">작업 완료 확인</el-button>
      <el-button type="primary" @click="save">저장</el-button>
      <el-button @click="hideDialog">취소</el-button>
    </span>
    <el-dialog append-to-body :visible.sync="imagePreviewDialog">
      <img width="100%" :src="imagePreviewUrl" alt />
    </el-dialog>

    <!-- <el-dialog 
            title="유지관리 처리 사진"
            :visible.sync="maintnceImageDialog"
            append-to-body
        >
            <el-carousel 
                v-if="maintnceImage && maintnceImage.length > 0"                
                height="400px"
                ref="maintnceImageCarousel"
                :initial-index="activeMaintnceImageIndex"
                >
                <el-carousel-item
                    v-for="(file, index) in maintnceImage"
                    :key="index"                    
                >
                    <div class="dk-carousel-item">
                        <img :src="file.image.serverFilePath">
                        <div class="dk-carousel-item-label">
                            {{file.msg}}
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>         
    </el-dialog>-->
  </el-dialog>
</template>

<script>
import dkgismain from '@/components/dkGis/dkGisMain.vue';
// import DkFileUtil from '@/components/dkFileUtil.js'
import dkElFileUpload from '@/components/dkElFileUpload.vue';
import moment from 'moment';
import _ from 'lodash';
import dkTemplateForm from '@/components/dkTemplate/dkTemplateForm';

export default {
  components: {
    'dk-form': dkTemplateForm,
    'dk-gis': dkgismain,
    'dk-el-file-upload': dkElFileUpload,
  },
  data() {
    return {
      systemLogInfo: {},
      updateFlag: false,
      formDialog: false,
      formTitle: '유지관리 등록',
      formData: {
        id: '',
        fcltId: '',
        fcltName: '',
        fcltTypeId: '',
        fcltTypeName: '',
        maintnceSummary: '',
        maintnceDesc: '',
        status: 'step1',
        startDate: new Date(),
        endDate: null,
        completePlanDate: null,
        managerId: '',
        setNumberAddr: '',
        managerName: '',
        workerId: '',
        workerName: '',
        workComment: [],
        files: [],
      },
      maintnceImageDialog: false,
      activeMaintnceImageIndex: 0,
      rules: {
        fcltName: [
          {
            required: true,
            message: '유지관리 대상 시설물은 선택되어야 합니다.',
            trigger: ['change', 'blur'],
          },
        ],
        maintnceSummary: [
          {
            required: true,
            message: '유지관리 요약한 내용을 입력해주세요',
            trigger: ['change', 'blur'],
          },
        ],
        // workType: [
        //   {
        //     required: true,
        //     message: '작업 유형을 선택해주세요',
        //     trigger: ['change', 'blur'],
        //   },
        // ],
        importance: [
          {
            required: true,
            message: '유지관리 작업 중요도를 선택해주세요',
            trigger: ['change', 'blur'],
          },
        ],
      },
      rules2: {
        status: [{ required: true, message: '', trigger: 'blur' }],
      },
      maintnceSteps: this.$config.getSiteConfig().maintnceStatus,
      workTypes: [
        { value: '1', label: '점검' },
        { value: '2', label: '수리' },
        { value: '3', label: '교체' },
        { value: '4', label: '기타', default: true },
      ],
      importances: [
        { value: '1', label: '매우높음' },
        { value: '2', label: '높음' },
        { value: '3', label: '보통', default: true },
        { value: '4', label: '낮음' },
        { value: '5', label: '매우낮음' },
      ],
      imagePreviewDialog: false,
      imagePreviewUrl: '',
      userList: [],
      activeUser: this.$dkRouter.getUserInfo(),
      markerData: [],
      clusterData: [],
      gisApiInfo: null,
      currentGisApi: {},
      // searchData: [],
      timeout: null,
      fcltType: [],
      searchFcltTypeId: [],
      keyword: '',
      level: 5,
      beforeZoomLevel: 0,
      isSearch: false,
      gisParam: {
        clusterLv0: 0,
        clusterLv1: 6,
        clusterLv2: 8,
        clusterEnable: true,
      },
      templateList: [],
      selectTemplateId: '',
      selectTemplateData: null,
    };
  },
  created() {
    this.gisApiInfo = this.$store.getters['gis/getGisApi'];
    let ll = this.$config.getSiteConfig().maintnceStatus;
    this.maintnceSteps = _.filter(ll, (item) => {
      return item.status !== 'finish';
    });
  },
  mounted() {
    this.resetData();
    this.getUserList();
    // this.activeUser = this.$dkRouter.getUserInfo()
  },
  computed: {
    // getMaintnceStepImg() {
    //     return this.maintnceStepImg[this.maintnceStep]
    // },
    // maintnceImage() {
    //     let imageArr = [];
    //     for (let i=0; i<this.maintnceStep; i++) {
    //         let stepMsg = "";
    //         if (i==0) {
    //             stepMsg = "접수/발생 단계 사진";
    //         }
    //         else if (i==1) {
    //             stepMsg = "처리 단계 사진";
    //         }
    //         else if (i==2) {
    //             stepMsg = "확인 단계 사진";
    //         }
    //         if (this.formData.steps[i].images) {
    //             for(let j=0; j<this.formData.steps[i].images.length; j++) {
    //                 console.log(this.formData.steps[i].images[j])
    //                 let imgObj = {
    //                     image: this.formData.steps[i].images[j],
    //                     msg: stepMsg
    //                 }
    //                 imageArr.push(imgObj);
    //             }
    //         }
    //     }
    //     return imageArr;
    // }
  },
  methods: {
    // resetFcltList() {
    //     this.formData.fcltId=""
    //     this.fcltList = [];
    //     this.filterFcltList = this.fcltList
    // },
    async getFcltTypeTemplate() {
      let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/user`, {});
      this.templateList = response.data;
    },
    async getUserList() {
      let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/user`, {});
      this.userList = response.data.docs;
    },
    getUserName(userid) {
      let aa = this.userList.find((user) => {
        return user.userid === userid;
      });
      return aa.name;
    },
    save() {
      let me = this;
      this.$refs.form1.validate(async (valid) => {
        try {
          this.formData.files = this.$refs.uploadImage.getFileData();
          if (this.formData.managerId) {
            this.formData.managerName = this.getUserName(this.formData.managerId);
          }
          if (this.formData.workerId) {
            this.formData.workerName = this.getUserName(this.formData.workerId);
          }
          if (this.selectTemplateId) {
            this.formData.templateId = this.selectTemplateId;
          }
          this.formData.templateData = this.$refs.dkTemplateForm.exportTemplateData();

          let saveFunc = 'maintnce/add';
          if (this.updateFlag) {
            saveFunc = 'maintnce/update';
          }
          me.systemLogInfo.page = `${me.$route.name} ${this.formData.fcltName}`;
          if (valid) {
            let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/${saveFunc}`, { data: this.formData });
            me.$emit('procDataComplete', this.formData);
            me.hideDialog();
            me.$message({
              type: 'success',
              message: `유지관리 등록 완료`,
            });

            me.systemLogInfo.saveType = saveFunc;
            await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/systemLog/add`, { data: me.systemLogInfo });
          } else {
            return false;
          }
        } catch (error) {
          this.hideDialog();
          console.log(error);
          this.$message.error(`유지관리 등록 중 에러 발생`);
        }
      });
    },
    confirm() {
      let me = this;
      this.$confirm('유지관리 작업을 최종 완료합니까? 완료하시면 유지관리 보드에서 더 이상 표출되지 않습니다.', 'Warning', {
        confirmButtonText: '네',
        cancelButtonText: '아니오',
        type: 'error',
      }).then(() => {
        this.$refs.form1
          .validate(async (valid) => {
            try {
              this.formData.files = this.$refs.uploadImage.getFileData();
              if (this.formData.managerId) {
                this.formData.managerName = this.getUserName(this.formData.managerId);
              }
              if (this.formData.workerId) {
                this.formData.workerName = this.getUserName(this.formData.workerId);
              }
              this.formData.status = 'finish';
              if (!this.formData.endDate) this.formData.endDate = new Date();

              if (valid) {
                let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/maintnce/update`, { data: this.formData });
                me.$emit('procDataComplete', this.formData);
                me.hideDialog();
                me.$message({
                  type: 'success',
                  message: `${response.data.result.fcltName} 유지관리 작업 확인 완료`,
                });
              } else {
                return false;
              }
            } catch (error) {
              this.hideDialog();
              this.$message.error(`유지관리 작업 확인 중 에러 발생`);
            }
          })
          .catch(() => {
            me.$message.info(`취소합니다`);
          });
      });
    },
    remove() {
      let me = this;
      this.$confirm('현재 유지관리 작업을 삭제합니까? 삭제한 작업은 복구할 수 없습니다.', '경고', {
        confirmButtonText: '네',
        cancelButtonText: '아니오',
        type: 'danger',
      })
        .then(async () => {
          try {
            me.systemLogInfo.page = `${me.$route.name} ${me.formData.fcltName}`;
            await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/maintnce/delete`, { data: this.formData });
            me.$emit('procDataComplete', this.formData);
            me.hideDialog();
            me.$message({
              type: 'success',
              message: `유지관리 작업 삭제 완료`,
            });
            me.systemLogInfo.saveType = 'delete';
            await me.$http.post(`${this.$config.getServerConfig().core.api}/core/api/systemLog/add`, { data: me.systemLogInfo });
          } catch (error) {
            this.hideDialog();
            this.$message.error(`유지관리 작업 삭제 중 에러 발생`);
          }
        })
        .catch(() => {
          me.$message.info(`취소합니다`);
        });
    },
    showDialog(maintnceLog) {
      if (maintnceLog) {
        this.formData = this.$lodash.cloneDeep(maintnceLog);
        this.selectTemplateId = this.formData.templateId || '';
        this.updateFlag = true;
        // if (this.$refs.dkGis) {
        //   if (this.formData.gisLat && this.formData.gisLng) {
        //     this.$refs.dkGis.setCenter({
        //       lat: this.formData.gisLat,
        //       lng: this.formData.gisLng,
        //     });
        //     this.$refs.dkGis.setLevel(4);
        //   } else {
        //     this.$refs.dkGis.setCenter(this.gisApiInfo[0].geometry);
        //     this.$refs.dkGis.setLevel(this.gisApiInfo[0].level);
        //   }
        // }
        if (this.formData.status == 'step3') {
          this.formTitle = '유지관리 내역 확인';
        } else {
          this.formTitle = '유지관리 등록';
        }
        this.formData.managerId = this.formData.managerId ? this.formData.managerId : this.activeUser.userid;
        this.formData.files = this.formData.files ? this.formData.files : [];
        this.formDialog = true;
        this.applySearchData(this.formData);
        this.$forceUpdate();
        // }
        // else {
        //     setTimeout(()=> {
        //         me.hideDialog();
        //         me.$message.error(`${fcltData.fcltName} 유지관리 등록 중 에러 발생`);
        //     }, 500)
        // }
        // })
        //this.formData = this.$lodash.cloneDeep(maintnceLog);
      } else {
        this.formTitle = '유지관리 등록';
        this.formDialog = true;
        this.formData.status = 'step1';
        this.formData.managerId = this.activeUser.userid;
        this.$forceUpdate();
        setTimeout(() => {
          //this.searchFcltMarkerData();
        }, 100);
      }
      // });
    },
    showDialogOnGis(gisFcltInfo) {
      this.formData.fcltId = gisFcltInfo.fcltId;
      this.formTitle = '유지관리 등록';
      this.formDialog = true;
      this.formData.status = 'step1';
      this.formData.managerId = this.activeUser.userid;
      this.applySearchData(this.formData);
      this.$forceUpdate();
    },
    hideDialog() {
      this.resetData();
      this.formDialog = false;
    },
    isOpenDialog() {
      return this.formDialog;
    },
    resetData() {
      this.formData = {
        id: '',
        fcltId: '',
        fcltName: '',
        fcltTypeId: '',
        fcltTypeName: '',
        maintnceSummary: '',
        maintnceDesc: '',
        status: 'step1',
        // workType: '4',
        importance: '3',
        setNumberAddr: '',
        startDate: new Date(),
        endDate: null,
        completePlanDate: null,
        managerId: '',
        workerId: '',
        workComment: [],
        files: [],
      };
      this.keyword = '';
      this.updateFlag = false;
      this.formTitle = '유지관리 등록';
      if (this.$refs.form1) this.$refs.form1.resetFields();

      if (this.$refs.form2) this.$refs.form2.resetFields();

      if (this.$refs.uploadImage) this.$refs.uploadImage.setFileData([]);

      this.markerData = [];
      this.templateList = [];
      (this.selectTemplateId = ''), (this.selectTemplateData = null);
    },
    // showMaintnceImage(index) {
    //     this.maintnceImageDialog = true;
    //     this.activeFcltImageIndex = index;
    //     if (this.$refs.maintnceImageCarousel)
    //         this.$refs.maintnceImageCarousel.setActiveItem(index);
    // },

    selectFclt(args) {
      this.formData.fcltId = args.properties.fcltId;
      this.formData.fcltName = args.properties.fcltName;
      this.formData.fcltTypeName = args.properties.fcltTypeName;
      this.formData.fcltTypeId = args.properties.fcltTypeId;
      this.formData.gisLat = args.properties.gisLat;
      this.formData.gisLng = args.properties.gisLng;
      this.formData.setNumberAddr = args.properties.setNumberAddr;
      this.$refs.dkGis.setCenter({
        lat: args.properties.gisLat,
        lng: args.properties.gisLng,
      });
      this.getTemplateList();
      setTimeout(() => {
        this.$forceUpdate();
        // this.$refs.dkGis.setLevel(3);
        if (args.$markerClickEvent) {
          args.$markerClickEvent();
          return;
        }
      }, 100);
    },
    resetFclt() {
      if (this.formData.fcltId) {
        this.$confirm(`선택한 시설물을 초기화 합니다.`, '경고', {
          confirmButtonText: '확인',
          cancelButtonText: '취소',
          type: 'warning',
        }).then(() => {
          this.formData.fcltId = '';
          this.formData.fcltName = '';
          this.keyword = '';
          this.markerData = [];
        });
      } else {
        this.keyword = '';
        this.markerData = [];
      }
    },
    handlePictureCardPreview(file) {
      this.imagePreviewUrl = file.url;
      this.imagePreviewDialog = true;
    },
    checkConfirmUser() {
      if (this.activeUser.userid == this.formData.managerId) {
        return true;
      } else if (this.activeUser.group == 'manager') {
        return true;
      } else {
        return false;
      }
    },

    // searchFcltData(queryString, cb) {
    //   clearTimeout(this.timeout);
    //   if (queryString.length > 0) {
    //     this.timeout = setTimeout(async () => {
    //       let args = {
    //         keyword: queryString,
    //       };
    //       const response = await this.$http.get('/coreapi/core/api/fclt', { params: args });
    //       this.searchData = response.data.docs;
    //       return cb(this.searchData);
    //     }, 500);
    //   } else {
    //     this.searchData = [];
    //     cb(this.searchData);
    //   }
    // },
    async applySearchData(queryString) {
      let args = {};
      if (queryString.fcltId) args.fcltId = queryString.fcltId;

      let response = await this.$store.dispatch('gis/actGetFcltMarker', args);
      this.markerData = response.data.markerData;
      if (this.markerData.length > 0) {
        this.selectFclt(this.markerData[0]);
        this.$refs.dkGis.setCenter({
          lat: this.formData.gisLat,
          lng: this.formData.gisLng,
        });
        this.$refs.dkGis.setLevel(4);
      }
    },
    async searchFcltList() {
      let param = {};
      this.markerData = [];
      //this.searchPlaceData = [];

      param.keyword = this.keyword;

      // 장소 데이터는 검색하고 적용 했을 시, 그 부근으로 지도를 이동시키는 기능으로 실행한다.
      //this.searchPlaceData = await this.$refs.dkGis.getCoordFromAddr(param.keyword);
      // 검색된 시설물 데이터는 임시 목록에 저장한 후, 적용하였을 시, markerData에 엎어씌워서
      // 시설물 마커를 rendering하고 선택한다.
      param.clusterLv0 = 0;
      param.clusterLv1 = 999;
      param.clusterLv2 = 999;
      let tmpData = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/gis/getFcltMarker`, { params: param });
      this.markerData = tmpData.data.markerData;
    },
    async opened() {
      if (this.$refs.dkGis) {
        if (this.formData.gisLat && this.formData.gisLng) {
          this.$refs.dkGis.setCenter({
            lat: this.formData.gisLat,
            lng: this.formData.gisLng,
          });
          this.$refs.dkGis.setLevel(4);
        } else {
          this.$refs.dkGis.setCenter(this.gisApiInfo[0].geometry);
          this.$refs.dkGis.setLevel(this.gisApiInfo[0].level);
        }
      }
      await this.getGisFcltMarker();
      this.applySearchData(this.formData);
    },
    async getGisFcltMarker() {
      let args = {};
      let zoomLevel = this.$refs.dkGis.getLevel();
      if (this.beforeZoomLevel !== this.$refs.dkGis.getLevel()) {
        this.markerData = [];
        this.clusterData = [];
      }
      if (args && args.fcltTypeId) {
        if (!this.$lodash.isEqual(this.beforeSearchFcltTypeId, args.fcltTypeId)) {
          this.markerData = [];
          this.clusterData = [];
        }
        this.beforeSearchFcltTypeId = this.$lodash.cloneDeep(args.fcltTypeId);
      }
      this.beforeZoomLevel = zoomLevel;

      let activeUser = this.$store.getters[''];
      if (activeUser && activeUser.userid) {
        args.userid = activeUser.userid;
        args.group = activeUser.group;
      }

      args.bounds = this.$refs.dkGis.getBounds();
      args.zoomLevel = zoomLevel;
      args.centerCoords = this.$refs.dkGis.getCenter();
      args.clusterLv0 = this.gisParam.clusterLv0 || 0;
      args.clusterLv1 = this.gisParam.clusterLv1 || 4;
      args.clusterLv2 = this.gisParam.clusterLv2 || 8;
      args.clusterEnable = this.gisParam.clusterEnable;
      args.fcltTypeId = this.searchFcltTypeId;
      //마커가 최대한으로 표출될 줌레벨 (렉이 넘걸려.. )
      args.markerLimitLv = 6;

      let result = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/gis/getFcltMarker`, {
        params: args,
      });
      if (result.data.clusterData && result.data.clusterData.length > 0) {
        this.markerData = [];
        this.clusterData = result.data.clusterData;
      } else {
        this.clusterData = [];
      }
      if (result.data.markerData && result.data.markerData.length > 0) {
        this.markerData = [];

        let newMarkerData = this.$lodash.map(result.data.markerData, (item) => {
          let chk = _.find(this.markerData, function (o) {
            if (o && o.properties) {
              return item.properties.fcltId === o.properties.fcltId;
            } else {
              return false;
            }
          });
          if (!chk) {
            return item;
          } else {
            return null;
          }
        });
        this.markerData = this.markerData.concat(this.$lodash.compact(newMarkerData));
      } else {
        this.markerData = [];
      }
    },

    zoomEnd(val) {
      if (val.level <= 3) {
        this.$store.dispatch('gis/actIsOpenInfoWindow', { value: true });
      } else {
        if (this.isOpenMarkerPopup) {
          this.$store.dispatch('gis/actIsOpenInfoWindow', { value: true });
        } else {
          this.$store.dispatch('gis/actIsOpenInfoWindow', { value: false });
        }
      }
    },
    async apiLoadComplete(currentGisApi) {
      this.currentGisApi = currentGisApi;
      try {
        let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/fcltType`, {});
        this.fcltType = response.data.docs;

        this.searchFcltTypeId = [];
        this.fcltType.forEach((ft) => {
          if (ft.initDisplay) this.searchFcltTypeId.push(ft.fcltTypeId);
        });
        this.getGisFcltMarker();
      } catch (error) {
        this.$message({
          type: 'info',
          message: `시설물 타입 로드 에러`,
        });
      }
    },
    async getTemplateList() {
      let param = {
        fcltTypeId: this.formData.fcltTypeId,
      };
      const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/fcltTypeTemplate`, {
        params: param,
      });
      this.templateList = response.data;
      if (this.selectTemplateId) {
        this.setTemplate();
      }
    },
    setTemplate() {
      const template = _.find(this.templateList, (val) => {
        return val.templateId === this.selectTemplateId;
      });
      if (template) {
        this.selectTemplateData = template.templateData || [];
        this.createTemplateForm();
      }
    },
    createTemplateForm() {
      if (this.$refs.dkTemplateForm) {
        this.$refs.dkTemplateForm.createTemplateForm(this.selectTemplateData);
        setTimeout(() => {
          if (this.formData.templateData && this.formData.templateData.length > 0)
            this.$refs.dkTemplateForm.importTemplateFormValue(this.formData.templateData);
        }, 50);
      }
    },
  },
};
</script>
