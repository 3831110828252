var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dk-pagination-wrap" },
    [
      _c("el-pagination", {
        attrs: {
          background: "",
          layout: "prev, pager, next",
          total: _vm.totalDocs,
          "page-size": _vm.limit,
          "current-page": _vm.page
        },
        on: {
          "current-change": _vm.pagingProc,
          "update:currentPage": function($event) {
            _vm.page = $event
          },
          "update:current-page": function($event) {
            _vm.page = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }