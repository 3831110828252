import _ from 'lodash';

export default {
  namespaced: true,
  state: {
    activatedLinkData: [],
    dataKey: '',
  },
  getters: {
    getActivatedLinkData: (state) => {
      return state.activatedLinkData;
    },
  },
  mutations: {
    setDataKey(state, payload) {
      state.dataKey = payload;
    },
    setActivatedLinkData(state, payload) {
      let tmpData = state.activatedLinkData.find((dt) => {
        return dt[state.dataKey] === payload[state.dataKey];
      });
      let param = {};
      param[state.dataKey] = payload[state.dataKey];
      if (tmpData) {
        _.remove(state.activatedLinkData, param);
      } else {
        state.activatedLinkData.push(payload);
        state.activatedLinkData = _.sortBy(state.activatedLinkData, 'seq');
      }
    },
    setActivatedLinkDataArray(state, payload) {
      state.activatedLinkData = payload;
    },
    removeActivatedLinkData(state, payload) {
      let param = {};
      param[state.dataKey] = payload[state.dataKey];
      _.remove(state.activatedLinkData, param);
    },
    clearActivatedLinkData(state) {
      state.activatedLinkData = [];
    },
  },
  actions: {
    resetActivatedLinkData({ commit }) {
      commit('clearActivatedLinkData');
    },
  },
};
