var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.formTitle,
        visible: _vm.formDialog,
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.formDialog = $event
        },
        close: _vm.hideDialog
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form0",
              attrs: {
                model: _vm.formData,
                "label-width": "160px",
                size: "medium",
                rules: _vm.rules
              }
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "사용자 ID", prop: "userid" } },
                        [
                          !_vm.updateFlag
                            ? _c("el-input", {
                                attrs: { placeholder: "사용자 ID" },
                                model: {
                                  value: _vm.formData.userid,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "userid", $$v)
                                  },
                                  expression: "formData.userid"
                                }
                              })
                            : _c("span", [_vm._v(_vm._s(_vm.formData.userid))])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "이름", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "이름" },
                            model: {
                              value: _vm.formData.name,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "name", $$v)
                              },
                              expression: "formData.name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  !_vm.updateFlag
                    ? _c(
                        "el-col",
                        { attrs: { span: 18 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "비밀번호", prop: "password" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "password",
                                  placeholder: "비밀번호",
                                  autocomplete: "off"
                                },
                                model: {
                                  value: _vm.formData.password,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "password", $$v)
                                  },
                                  expression: "formData.password"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.updateFlag
                    ? _c(
                        "el-col",
                        { attrs: { span: 18 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "비밀번호 확인",
                                prop: "checkPassword"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "password",
                                  placeholder: "비밀번호 확인",
                                  autocomplete: "off"
                                },
                                model: {
                                  value: _vm.formData.checkPassword,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "checkPassword", $$v)
                                  },
                                  expression: "formData.checkPassword"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "그룹", prop: "group" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "사용자 그룹 설정" },
                              on: { change: _vm.selectGroupCombobox },
                              model: {
                                value: _vm.formData.group,
                                callback: function($$v) {
                                  _vm.$set(_vm.formData, "group", $$v)
                                },
                                expression: "formData.group"
                              }
                            },
                            _vm._l(_vm.groupList, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.menuId }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "전화번호", prop: "phone" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "전화번호" },
                            model: {
                              value: _vm.formData.phone,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "phone", $$v)
                              },
                              expression: "formData.phone"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "이메일", prop: "email" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "이메일" },
                            model: {
                              value: _vm.formData.email,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "email", $$v)
                              },
                              expression: "formData.email"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "사진" } },
                        [
                          _c(
                            "dk-el-file-upload",
                            {
                              ref: "uploadImage",
                              attrs: {
                                uploaderType: "image",
                                listType: "picture",
                                path: "user",
                                fileList: _vm.formData.images
                              }
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "small", type: "primary" }
                                    },
                                    [_vm._v("관련사진 업로드")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "el-upload__tip",
                                      attrs: { slot: "tip" },
                                      slot: "tip"
                                    },
                                    [
                                      _vm._v(
                                        "이미지 파일만 업로드할 수 있습니다"
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-bottom" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  on: { click: _vm.hideDialog }
                },
                [_vm._v("취소")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right", "margin-right": "5px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.save }
                },
                [_vm._v("저장")]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }