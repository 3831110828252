var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "fclt-status-wrap" },
      [
        _c("doughnut-chart", {
          ref: "chart",
          attrs: {
            "chart-data": _vm.statusData,
            height: _vm.height - 20,
            options: {
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                display: false,
                position: "bottom",
                align: "center",
                labels: {
                  padding: 10,
                  boxWidth: 15
                }
              }
            }
          }
        }),
        _c("div", { staticClass: "center-label" }, [
          _c("span", { staticStyle: { color: "#FB6A7B" } }, [
            _vm._v(_vm._s(_vm.fcltFailureCount))
          ]),
          _vm._v("\n      /" + _vm._s(_vm.fcltCount) + "\n    ")
        ])
      ],
      1
    ),
    _c("div", { staticClass: "fclt-status-label" }, [_vm._v(_vm._s(_vm.label))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }