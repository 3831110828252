<template>
  <div class="fill-width-height el-container" id="pageLedDisplay">
    <el-container class="page-body">
      <div class="led-fclt-wrap">
        <el-tabs class="led-fclt-list" v-model="activeTab" @tab-click="goToLedFcltList">
          <el-tab-pane label="LED 목록" name="tab0" class="gis-search-result">
            <el-row>
              <el-col :span="16" style="margin-left: 11px; margin-top: 12px;">
                <el-input size="medium" v-model="keyword" @keyup.enter.native="searchLedFclt" placeholder="LED 검색"></el-input>
              </el-col>
              <el-col :span="2" style="margin-top: 12px; padding-left: 4px;" v-show="isSearch">
                <el-button type="primary" @click="resetSearch" size="medium" style="padding: 10px 0; width: 100%;">
                  <i class="fas fa-times"></i>
                </el-button>
              </el-col>
              <el-col :span="5" style="margin-top: 12px; padding-left: 3px;">
                <el-button type="primary" @click="searchLedFclt" size="medium" style="width: 100%;">
                  <i class="fas fa-search"></i>
                </el-button>
              </el-col>
            </el-row>
            <!-- <el-row>
              <el-col :span="24" style="padding: 5px 5px 5px 11px;">
                <el-button size="mini" :type="!selectedLedSize ? 'primary' : ''" @click="setLedFcltBySize(null)">모든크기</el-button>
                <el-button size="mini" :type="selectedLedSize === '1x6' ? 'primary' : ''" @click="setLedFcltBySize('1x6')">1x6</el-button>
                <el-button size="mini" :type="selectedLedSize === '2x8' ? 'primary' : ''" @click="setLedFcltBySize('2x8')">2x8</el-button>
                <el-button size="mini" :type="selectedLedSize === '20x20' ? 'primary' : ''" @click="setLedFcltBySize('20x20')">20x20</el-button>
              </el-col>
            </el-row> -->
            <el-row :class="['list-body', selectedLedSize !== null ? 'is-batch-transfer' : '']">
              <ul v-if="ledFcltList.length > 0">
                <li v-for="(item, idx) in ledFcltList" :key="idx">
                  <div class="led-fclt-checkbox" v-if="selectedLedSize">
                    <i :class="[getLedFcltCheckedClass(item), 'fa-checkbox']" @click="checkLedFcltItem(item)"></i>
                  </div>
                  <div class="search-list-text">
                    <div>
                      <span class="title">{{ item.properties.fcltName }}</span>
                    </div>
                    <div>{{ item.properties.setNumberAddr }}</div>
                  </div>
                  <div class="search-list-button">
                    <i class="fas fa-map-marker-alt" @click="selectLedFclt(item)"></i>
                  </div>
                </li>
              </ul>
            </el-row>
            <el-row v-show="selectedLedSize" class="batch-transfer-wrap">
              <el-button type="primary" :disabled="checkedLedFclt.length === 0" @click="showBatchTransferDialog()"
                >LED 스케줄 일괄 표출 ({{ checkedLedFclt.length }})</el-button
              >
            </el-row>
          </el-tab-pane>
          <el-tab-pane v-if="selectedLedFclt" :label="selectedLedFclt.fcltName" name="tab1">
            <div>
              <div class="led-info">
                <div class="led-info-title">
                  {{ selectedLedFclt.fcltName }}
                </div>
                {{ selectedLedFclt.setNumberAddr }}
              </div>

              <div class="schedule-info">
                <div class="tab-subject">현재 표출 중인 LED 스케줄</div>
                <div class="schedule-info-body">
                  <div
                    class="current-schedule-view"
                    v-if="selectedLedFcltCurrentSchedule && selectedLedFcltCurrentSchedule.currentLedSchedule.length > 0"
                  >
                    <led-preview-viewer
                      v-for="(item, idx) in selectedLedFcltCurrentSchedule.currentLedSchedule[0].ledMessages"
                      :key="idx"
                      :ledMessageData="item"
                      :ref="`preview-${idx}`"
                    ></led-preview-viewer>
                  </div>
                  <div class="no-led-schedule" v-else>
                    등록된 LED 표출 스케줄이 없습니다.
                  </div>
                </div>
              </div>
              <div class="led-info-button-wrap">
                <el-button size="mini" type="primary" @click="showOneTransferDialog">스케줄 표출</el-button>
                <el-button size="mini" type="info" @click="showOneBrightnessDialog">밝기 조절</el-button>
              </div>
            </div>
            <!-- <el-row>
              <el-col :span="24" style="padding: 5px 5px 5px 11px;">
                <el-button size="mini" :type="!selectedLedSize ? 'primary' : ''" @click="setLedFcltBySize(null)">모든크기</el-button>
                <el-button size="mini" :type="selectedLedSize === '1x6' ? 'primary' : ''" @click="setLedFcltBySize('1x6')">1x6</el-button>
                <el-button size="mini" :type="selectedLedSize === '2x8' ? 'primary' : ''" @click="setLedFcltBySize('2x8')">2x8</el-button>
                <el-button size="mini" :type="selectedLedSize === '20x20' ? 'primary' : ''" @click="setLedFcltBySize('20x20')">20x20</el-button>
              </el-col>
            </el-row> -->
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="led-gis-wrap">
        <div class="led-gis-body">
          <dk-gis
            fill-height
            :gisApiInfo="gisApiInfo"
            :markerData="ledFcltList"
            :clickMarker="clickMarker"
            :apiLoadComplete="apiLoadComplete"
            :fcltManagerOnGis="fcltManagerOnGis"
            ref="dkGis"
            id="dkGis"
          ></dk-gis>
          <div class="gis-button-wrap">
            <div class="gis-ledfclt-select-wrap"></div>
            <div class="gis-ledfclt-regist-wrap">
              <el-button size="small" class="fclt-btn" @click="setFcltManageOnGis">
                <i class="fas fa-map-marker-alt" style="margin-right: 10px;"></i>LED 지점추가
              </el-button>
              <el-button size="small" class="fclt-btn" @click="ipSearchLedFclt">
                <i class="fas fa-search" style="margin-right: 10px;"></i>LED IP검색
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="led-schedule-wrap">
        <div class="schedule-button-wrap">
          <span class="schedule-button-text" v-if="selectedLedFclt"> {{ getLedTypeText(selectedLedFclt) }} / {{ getLedSize(selectedLedFclt) }} </span>
          <span v-else>
            전체 스케줄
          </span>
          <el-button size="small" type="primary" @click="checkLedTypeForm = true">스케줄 추가</el-button>
        </div>
        <el-table :data="ledSchedules" empty-text="등록된 LED 스케줄 정보가 없습니다." size="mini" stripe>
          <el-table-column type="expand">
            <template slot-scope="props">
              <led-preview-viewer
                v-for="(item, idx) in props.row.ledMessages"
                :key="idx"
                :ledMessageData="item"
                :ref="`preview-${idx}`"
              ></led-preview-viewer>
              <div style="text-align: right;">
                <el-button @click="updateLedSchedule(props.row)" size="mini"><i class="fas fa-edit" style="margin-right: 5px;"></i>수정</el-button>
                <el-button @click="removeLedSchedule(props.row)" size="mini" type="danger"
                  ><i class="fas fa-trash-alt" style="margin-right: 5px;"></i>삭제</el-button
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column label="스케줄 명칭" header-align="left" align="left" prop="ledScheduleName"></el-table-column>
          <el-table-column label="크기" header-align="left" align="left" width="60px">
            <template slot-scope="scope">{{ getLedMessageSize(scope.row.ledMessageSize) }}</template>
          </el-table-column>
        </el-table>
      </div>
    </el-container>
    <el-dialog :visible.sync="checkLedTypeForm" title="LED 메시지 유형 선택" width="360px">
      <div class="wrap">
        <el-form class="led-form-data" :model="checkLedTypeValue" label-width="120px" size="mini" :rules="rules" ref="form0">
          <el-form-item label="LED 유형" prop="ledType">
            <el-select v-model="checkLedTypeValue.ledType">
              <el-option value="ctype" label="풀컬러"></el-option>
              <el-option value="3color" label="3컬러"></el-option>
            </el-select>
          </el-form-item>
          <el-row>
            <el-form-item label="LED 열 (너비)" prop="cols">
              <el-col :span="12">
                <el-input-number v-model="checkLedTypeValue.cols" :min="1" :max="30"></el-input-number>
              </el-col>
            </el-form-item>

            <el-form-item label="LED 단 (높이)" prop="rows">
              <el-col :span="12">
                <el-input-number v-model="checkLedTypeValue.rows" :min="1" :max="30"></el-input-number>
              </el-col>
            </el-form-item>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addLedSchedule">LED스케줄 추가</el-button>
        <el-button @click="hideCheckLedType">취소</el-button>
      </span>
    </el-dialog>
    <form-led-message ref="formLedMessage" @procDataComplete="getLedSchedules"></form-led-message>
    <form-led-batch-transfer ref="formLedBatchTransfer" @completeBatchTransfer="getCurentDisplayLedSchedule"></form-led-batch-transfer>
    <form-led-brightness-control ref="formLedBrightnessControl"></form-led-brightness-control>
    <form-led-fclt ref="formLedFclt" :gisApiInfo="gisApiInfo" @completeLedFcltRegisterd="searchLedFclt"></form-led-fclt>
  </div>
</template>

<script>
import dkgismain from '@/components/dkGis/dkGisMain.vue';
import ledPreviewViewer from './form/ledPreviewViewer.vue';
import formLedMessage from './form/formLedMessage';
import formLedBatchTransfer from './form/formLedBatchTransfer';
import formLedBrightnessControl from './form/formLedBrightnessControl';
import _ from 'lodash';
// import dkHiveWebRtcPlayer from '@/components/webrtc/dkHiveWebRtcPlayer.vue';
// import { vplayer } from 'vue-hls-player';
import formLedFclt from './form/formLedFclt';
import DKLedUtils from './ledUtils';

export default {
  components: {
    'dk-gis': dkgismain,
    'form-led-message': formLedMessage,
    'led-preview-viewer': ledPreviewViewer,
    'form-led-batch-transfer': formLedBatchTransfer,
    'form-led-brightness-control': formLedBrightnessControl,
    // 'dk-hive-webrtc-player': dkHiveWebRtcPlayer,
    'form-led-fclt': formLedFclt,
    // 'vue-hls-player': vplayer,
  },
  created() {
    this.getGisApi();
  },
  mounted() {
    this.$nextTick(() => {
      this.searchLedFclt();
      this.getLedSchedules();
    });
  },
  data() {
    return {
      gisApiInfo: [],
      keyword: '',
      isSearch: false,
      ledMessageDialog: false,
      activeTab: 'tab0',
      ledFcltList: [],
      ledFcltTypes: [],
      tempLedFcltList: [],
      selectedLedFclt: null,
      selectedLedFcltCurrentSchedule: null,
      selectedLedSize: null,
      selectedSchedule: null,
      checkedLedFclt: [],
      ledSchedules: [],
      webRtcInfo: {
        port: 28080,
        ip: 'dkant2.iptime.org',
        videoName: '3ABA6B6C-C8BB-46C5-873F-233B0801ADD9',
      },
      vOption: {
        type: 'application/x-mpegURL',
        src: 'http://222.107.186.131:1935/live/truen.stream/playlist.m3u8',
        preload: false,
        autoplay: true,
        isLoop: false,
        // poster: 'https://oimdztrab.qnssl.com/Frp4SyVe5PosdkUKRaE-krjK7B5z',
        controls: 'progress,current,durration',
        crossOrigin: false,
      },
      checkLedTypeForm: false,
      checkLedTypeValue: {
        cols: 6,
        rows: 1,
        ledType: 'ctype',
      },
      rules: {
        ledType: [
          {
            required: true,
            message: 'LED 유형을 선택해 주세요',
            trigger: ['change', 'blur'],
          },
        ],
      },
    };
  },
  methods: {
    getGisApi() {
      this.gisApiInfo = this.$store.getters['gis/getGisApi'];
      this.platformType = this.gisApiInfo[0].platformType || 'fclt';
      this.gisApiInfo[0].gisName = 'openStreetMap';
      delete this.gisApiInfo[0].gisOption;
    },
    async searchLedFclt() {
      this.ledFcltList = [];
      let param = {
        fcltTypeId: ['FCLTTYPE000021', 'FCLTTYPE000022'],
        clusterLv0: 0,
        clusterLv1: 999,
        clusterLv2: 999,
      };
      if (this.keyword) {
        param.keyword = this.keyword;
        this.isSearch = true;
      }
      let result = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/gis/getFcltMarker`, {
        params: param,
      });
      result.data.markerData.forEach((item) => {
        let ledSize = '';
        if (
          item.properties.fcltTypeData.ledWidth &&
          item.properties.fcltTypeData.ledWidth.value &&
          item.properties.fcltTypeData.ledHeight &&
          item.properties.fcltTypeData.ledHeight.value
        )
          ledSize = `${item.properties.fcltTypeData.ledHeight.value}x${item.properties.fcltTypeData.ledWidth.value}`;

        item.ledSize = ledSize;
      });
      this.tempLedFcltList = result.data.markerData;
      this.setLedFcltBySize(null);
    },
    async getLedSchedules() {
      this.ledSchedules = [];
      let param = {};
      //      if (this.selectedLedSize) param.ledMessageSize = this.selectedLedSize;
      if (this.selectedLedFclt) {
        param.ledMessageSize = DKLedUtils.getLedSize(this.selectedLedFclt);
        param.ledType = this.selectedLedFclt.fcltTypeData.ledType.value;
      }
      const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/led/api/ledSchedule`, {
        params: param,
      });
      this.ledSchedules = response.data.docs;
    },
    async getCurentDisplayLedSchedule() {
      if (!this.selectedLedFclt || !this.selectedLedFclt.fcltId) {
        return false;
      }
      let param = {
        fcltId: this.selectedLedFclt.fcltId,
      };
      const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/led/api/ledCurrentDisplaySchedule`, {
        params: param,
      });
      this.selectedLedFcltCurrentSchedule = response.data.result;
    },
    clickMarker(obj) {
      this.selectLedFclt(obj);
      this.selectedSchedule = null;
    },
    resetSearch() {
      this.isSearch = false;
      this.keyword = '';
      this.searchLedFclt();
    },
    apiLoadComplete() {},
    selectLedFclt(args) {
      this.activeTab = 'tab1';
      if (!args) {
        return false;
      }
      this.$refs.dkGis.setCenter({
        lat: args.properties.gisLat,
        lng: args.properties.gisLng,
      });
      setTimeout(() => {
        // this.$refs.dkGis.setLevel(3);
        this.selectedLedFclt = args.properties;
        //this.setLedFcltBySize(DKLedUtils.getLedSize(this.selectedLedFclt));
        this.getCurentDisplayLedSchedule();
        this.getLedSchedules();
        if (args.$markerClickEvent) {
          args.$markerClickEvent();
          return;
        }
      }, 100);
    },
    checkLedFcltItem(item) {
      const idx = _.findIndex(this.checkedLedFclt, (ledItem) => {
        return ledItem.fcltId == item.properties.fcltId;
      });
      //let idx = this.checkedLedFclt.indexOf(item.properties.fcltId);
      if (item.checked) {
        item.checked = false;
        if (idx !== -1) {
          this.checkedLedFclt.splice(idx, 1);
        }
      } else {
        item.checked = true;
        if (idx === -1) {
          this.checkedLedFclt.push(item.properties);
        }
      }
      this.$forceUpdate();
    },
    resetCheckedLedFcltItem() {
      this.ledFcltList.forEach((item) => {
        item.checked = false;
      });
      this.checkedLedFclt = [];
    },
    getLedFcltCheckedClass(item) {
      let result = 'far fa-square';
      if (item.checked) result = 'fas fa-check-square';

      return result;
    },
    setLedFcltBySize(ledSize) {
      this.resetCheckedLedFcltItem();
      this.selectedLedSize = ledSize;
      if (!ledSize) {
        this.ledFcltList = this.tempLedFcltList;
      } else {
        this.ledFcltList = _.filter(this.tempLedFcltList, function (o) {
          return o.ledSize == ledSize;
        });
      }
      this.getLedSchedules();
    },
    showBatchTransferDialog() {
      this.$refs.formLedBatchTransfer.showDialog(this.checkedLedFclt);
    },
    showOneTransferDialog() {
      this.$refs.formLedBatchTransfer.showDialog([this.selectedLedFclt]);
    },
    showOneBrightnessDialog() {
      this.$refs.formLedBrightnessControl.showDialog(this.selectedLedFclt);
    },
    getLedMessageSize(val) {
      return DKLedUtils.getLedMessageSizeKorean(val);
    },
    hideCheckLedType() {
      this.checkLedTypeForm = false;
      this.checkLedTypeValue = {
        ledType: 'ctype',
        cols: 6,
        rows: 1,
      };
    },
    addLedSchedule() {
      this.$refs.formLedMessage.showDialog(null, {
        cols: this.checkLedTypeValue.cols,
        rows: this.checkLedTypeValue.rows,
        ledType: this.checkLedTypeValue.ledType,
      });
      this.hideCheckLedType();
    },
    updateLedSchedule(row) {
      if (row && row.ledMessageSize) {
        const v = row.ledMessageSize.split('x');
        this.$refs.formLedMessage.showDialog(row, {
          cols: v[1],
          rows: v[0],
          ledType: row.ledType ? row.ledType : 'ctype',
        });
      } else {
        this.$refs.formLedMessage.showDialog(row);
      }
    },
    removeLedSchedule(args) {
      this.$confirm(`${args.ledScheduleName} LED 문자식 스케줄를 삭제 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(async () => {
          try {
            await this.$http.post(`${this.$config.getServerConfig().core.api}/led/api/ledSchedule/delete`, {
              data: args,
            });
            this.getLedSchedules();
            this.$message({
              type: 'success',
              message: `${args.ledScheduleName} 삭제 완료`,
            });
          } catch (err) {
            this.$message({
              type: 'info',
              message: `${args.ledScheduleName} LED 문자식 스케줄 삭제 중 에러 발생`,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `${args.ledScheduleName} LED 문자식 스케줄 삭제 취소`,
          });
        });
    },

    fcltManagerOnGis(args) {
      if (!args.geometry) {
        return;
      }
      let licenseInfo = this.$store.getters['license/getLicense'];
      if (licenseInfo.currentFcltCount >= licenseInfo.limitFcltCount) {
        this.$alert(
          `등록된 ${licenseInfo.currentFcltCount}건의 시설물 정보가 있고, 현재 라이선스는 총 ${licenseInfo.limitFcltCount}건의 시설물이 등록 가능하므로, 유효한 시설물 등록 수를 초과합니다.`,
          '라이선스 등록 초과',
          {
            confirmButtonText: '확인',
            type: 'warning',
          }
        );
        this.$store.dispatch('license/setDialog', true);
        return;
      }
      const geometry = this.$lodash.cloneDeep(args.geometry);
      this.$refs.formLedFclt.showDialog(false, {
        geometry: geometry,
      });
    },
    ipSearchLedFclt() {
      this.$refs.formLedFclt.showDialog(true, {});
    },
    setFcltManageOnGis() {
      this.$refs.dkGis.setFcltManageOnGis();
    },
    getLedTypeText(fcltData) {
      return DKLedUtils.getLedTypeText(fcltData);
    },
    getLedSize(fcltData) {
      return DKLedUtils.getLedSizeKorean(fcltData);
    },
    goToLedFcltList() {
      if (this.activeTab == 'tab0') {
        this.selectedLedFclt = null;
        this.getLedSchedules();
      }
    },
  },
};
</script>
