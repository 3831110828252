var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "fill-width-height el-container",
      attrs: { id: "pageFcltStatus" }
    },
    [
      _c(
        "el-container",
        { staticClass: "page-body" },
        [
          _c(
            "el-main",
            [
              _c(
                "el-row",
                { staticClass: "content-wrap" },
                [
                  _c(
                    "el-col",
                    { staticClass: "page-content", attrs: { span: 24 } },
                    [
                      _c(
                        "el-tabs",
                        {
                          staticClass: "tab-container",
                          on: { "tab-click": _vm.tabClick },
                          model: {
                            value: _vm.activeTabName,
                            callback: function($$v) {
                              _vm.activeTabName = $$v
                            },
                            expression: "activeTabName"
                          }
                        },
                        [
                          _c(
                            "el-tab-pane",
                            {
                              attrs: {
                                label: "유형별 시설물 현황",
                                name: "fcltTypeTab"
                              }
                            },
                            [
                              _c("FcltStatusTabLayout", {
                                attrs: {
                                  dataType: _vm.activeTabName,
                                  statusData: _vm.statusData,
                                  sumStatusData: _vm.sumStatusData
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-tab-pane",
                            {
                              attrs: {
                                label: "지역별 시설물 현황",
                                name: "fcltAreaTab"
                              }
                            },
                            [
                              _c("FcltStatusTabLayout", {
                                attrs: {
                                  dataType: _vm.activeTabName,
                                  statusData: _vm.statusData,
                                  sumStatusData: _vm.sumStatusData
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }