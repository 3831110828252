<template>
  <div id="app">
    <!-- <div id="nav">
      <div class="box-base s-between">
        <div id="logo-box">
          <img alt="logo image" src="static/images/logo-blue-white.png" />
        </div>
        <div id="fixed-btn">
          <span></span>
        </div>
      </div>
      <span class="divider"></span>
      <div class="box-base"></div>
      <div id="proflie"></div>
      <ul>
        <li></li>
      </ul>
    </div>-->
    <div :class="['sidebar-box', isOpen]">
      <div class="logo-box">
        <div class="img-box">
          <img alt="logo image" :src="this.$config.getServerConfig().image.url + logoData.serverFilePath" @click="goHome" />
        </div>
        <div class="hamberger-box" @click="menuClick">
          <div :class="['hamberger', menuChange]">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <!-- <span class="divider"></span> -->
      <div class="sidebar-content">
        <div class="user-info-box">
          <div
            class="user-img"
            v-if="this.activeUser.images && this.activeUser.images.length > 0"
            :style="'background-image:url(' + $config.getServerConfig().image.url + this.activeUser.images[0].serverFilePath + ')'"
          ></div>
          <div v-else class="user-img" :style="'background-image: url(' + 'static/images/unknown.png);'"></div>
          <div class="user-info" @click="userClick">
            <div>
              <span>{{ this.activeUser.name }}</span>
              <i class="icon-arrow"></i>
            </div>
            <span>{{ this.activeUser.userid }} ({{ this.activeUser.groupName }})</span>
          </div>
          <ul :class="['user-modal', dpOn]">
            <li @click="showLicenseModal">라이선스 정보</li>
            <li @click="updateUserInfo">로그인 정보</li>
            <li @click="selectLogout">로그아웃</li>
          </ul>
        </div>
        <ul class="nav-list-box">
          <li v-for="depth1 in navData" :key="depth1.featureId" @click="depth1Click($event, depth1)" :id="depth1.featureId">
            <div :class="[isDepth1selected(depth1.featureId), 'depth1-div ']">
              <span><i :class="[depth1.icon, 'list-icon list-align']"></i></span>
              <span class="list-title">{{ depth1.label }}</span>
              <i class="icon-arrow list-icon"></i>
            </div>
            <ul>
              <li v-for="(child, key) in depth1.children" :key="key" @click="selectItem(child, $event)">{{ child.label }}</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <el-dialog
      :visible.sync="licenseDialog"
      v-if="licenseInfo.licenseFullName"
      @open="openedLicenseDialog"
      @close="closeLicenseDialog"
      title="라이선스 정보"
      class="license-dialog"
      width="940px"
    >
      <hr />
      <div class="license-dialog-wrap">
        <h3 class="license-title">
          <span :class="licenseInfo.licenseCode.toLowerCase()">{{ licenseInfo.licenseFullName.toUpperCase() }}</span>
          을 사용 중입니다.
        </h3>
        <p class="license-fclt-count">
          현재 등록된 시설물 개소 :
          <span :class="licenseInfo.currentFcltCount >= licenseInfo.limitFcltCount ? 'excess' : 'normal'">{{ licenseInfo.currentFcltCount }}</span>
          / {{ licenseInfo.limitFcltCount }}
        </p>
        <!-- <hr> -->
        <el-row :gutter="20">
          <el-col :span="6">
            <el-card :body-style="{ padding: '0px' }" shadow="never" style="border-top: 2px solid #4abb14;">
              <div>
                <img :src="licenseImage['ce']" class="image" />
              </div>
              <div style="font-size: 12px; padding: 10px; text-align: center;">시설물 등록대수 최대 1000개소</div>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card :body-style="{ padding: '0px' }" shadow="never" style="border-top: 2px solid #27a5dc;">
              <div>
                <img :src="licenseImage['se']" class="image" />
              </div>
              <div style="font-size: 12px; padding: 10px; text-align: center;">시설물 등록대수 최대 1500개소</div>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card :body-style="{ padding: '0px' }" shadow="never" style="border-top: 2px solid #b94b5b;">
              <div>
                <img :src="licenseImage['pe']" class="image" />
              </div>
              <div style="font-size: 12px; padding: 10px; text-align: center;">시설물 등록대수 최대 3000개소</div>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card :body-style="{ padding: '0px' }" shadow="never" style="border-top: 2px solid #6d5abe;">
              <div>
                <img :src="licenseImage['ee']" class="image" />
              </div>
              <div style="font-size: 12px; padding: 10px; text-align: center;">시설물 등록대수 최대 5000개소</div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState, mapGetters } from 'vuex';
export default {
  data() {
    return {
      defaultCheck: false,
      defaultCheck2: false,
      // licenseInfo: {
      //   licenseFullName: '',
      //   licenseCode: '',
      // },
      licenseImage: {
        ce: require('../assets/img/edition-contributed.png'),
        se: require('../assets/img/edition-standard.png'),
        pe: require('../assets/img/edition-premium.png'),
        ee: require('../assets/img/edition-enterprise.png'),
      },
      //vuex로 현재 활성화된 메뉴 상태 커밋을 해주길 바람
      selectedMenu: {},
      activeUser: { images: [] },
    };
  },

  mounted() {
    //주석
    this.activeUser = this.$dkRouter.getUserInfo();
  },
  props: {
    navData: {
      type: Array,
    },
    selectItem: {
      type: Function,
      default: function (item) {},
    },
    selectLogout: {
      type: Function,
      default: function (item) {},
    },
    updateUserInfo: {
      type: Function,
      default: function (item) {},
    },
    logoData: {
      type: Object,
      default: () => {
        return {
          name: 'logo-blue-white.png',
          serverFileName: '',
          serverFilePath: 'static/images/logo-blue-white.png',
          size: 0,
          url: '',
        };
      },
    },
  },
  computed: {
    ...mapState({
      licenseDialog: (state) => state.license.dialog,
    }),
    ...mapGetters({
      licenseInfo: 'license/getLicense',
    }),
    isOpen() {
      if (this.defaultCheck) {
        return 'on';
      } else {
        return '';
      }
    },
    menuChange() {
      if (this.defaultCheck) {
        return 'active';
      } else {
        return '';
      }
    },
    dpOn() {
      if (this.defaultCheck2) {
        return 'dp-on';
      } else {
        return '';
      }
    },
  },
  created() {
    this.getNavData();
  },

  methods: {
    async getNavData() {},
    menuClick() {
      this.defaultCheck = !this.defaultCheck;
      this.$emit('onHamberger', this.defaultCheck);
    },
    userClick() {
      this.defaultCheck2 = !this.defaultCheck2;
    },
    isDepth1selected(featureId) {
      if (this.selectedMenu.featureId === featureId) {
        return 'depth1selected';
      } else {
        return '';
      }
    },
    depth1Click(event, depth1Data, featureId) {
      // // 기존꺼 닫는 기능
      // if (this.selectedMenu.featureId && this.selectedMenu.featureId !== '') {
      //   // (this.selectedMenu.featureId:선택된 li의 아이디명)이 빈 칸이 아니라면(=li가 선택되어 있는거임)
      //   const ele = document.querySelector(`#${this.selectedMenu.featureId}`);

      //   if (ele) {
      //     ele.children[1].style.height = 0;
      //     // 페이지에서 선택한 메뉴의 featureId에서 3번째에 해당하는(ul) 것의 높이를 0으로 한다
      //   }
      // }

      // if (
      //   this.selectedMenu.featureId &&
      //   this.selectedMenu.featureId === depth1Data.featureId
      //   // 클릭한 featureId가 해당 li의 depth1Data.featureId랑 동일하다면
      // ) {
      //   //닫아야지
      //   event.currentTarget.children[1].style.height = 0;
      //   this.selectedMenu = {};
      //   // 그 아이디의 자식(=ul)의 높이를 0으로 한다
      // } else {
      //   event.currentTarget.children[1].style.height =
      //     event.currentTarget.children[1].scrollHeight + 'px';
      //   //그렇지 않다면, 선택한 아이디의 ul의 높이를 ul 내용의 전체의 높이px로 한다.
      //   this.selectedMenu = {
      //     featureId: depth1Data.featureId,
      //   };
      // }
      if (
        !event.currentTarget.children[1].style.height ||
        event.currentTarget.children[1].style.height === 0 ||
        event.currentTarget.children[1].style.height === '0' ||
        event.currentTarget.children[1].style.height === '0px'
      ) {
        if (this.selectedMenu.featureId && this.selectedMenu.featureId !== '') {
          // (this.selectedMenu.featureId:선택된 li의 아이디명)이 빈 칸이 아니라면(=li가 선택되어 있는거임)
          const ele = document.querySelector(`#${this.selectedMenu.featureId}`);

          if (ele) {
            ele.children[1].style.height = '0px';
            // 페이지에서 선택한 메뉴의 featureId에서 3번째에 해당하는(ul) 것의 높이를 0으로 한다
          }
        }

        event.currentTarget.children[1].style.height = event.currentTarget.children[1].scrollHeight + 'px';

        this.selectedMenu = {
          featureId: depth1Data.featureId,
        };
      } else {
        event.currentTarget.children[1].style.height = '0px';
        this.selectedMenu = {};
      }
    },
    userModalOff() {
      this.defaultCheck2 = false;
    },
    goHome() {
      // this.$router.push(`${this.$config.getServerConfig().core.api}/`);
      this.$router.push(`/`);
      this.isMenuOpen = false;
    },
    closeLicenseDialog() {
      this.$store.dispatch('license/setDialog', false);
    },
    openLicenseDialog() {
      this.$store.dispatch('license/setDialog', true);
    },
    openedLicenseDialog() {},

    showLicenseModal() {
      this.openLicenseDialog();
    },
  },
};
</script>

<style scoped></style>
