import axios from 'axios';
import { store } from '../store';
import router from '../router';

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const fromRequest = error.config;
    // console.log(error.config.url);
    // if (error.response) {
    //   if (error.response.status === 401 && !fromRequest._retry) {
    //     fromRequest._retry = true;
    //     store.dispatch(`auth/actAccessToken`, {access_payload: ''}, {root: true});
    //     new Promise((resolve, reject) => {
    //       axios.post(`${store.getters['api/getCoreApi']}/auth/getAccess`)
    //         .then((response) => {
    //           store.dispatch('auth/actAccessToken', {access_payload: response.data.result.accessToken}, {root: true});
    //           resolve();
    //         }).catch((err) => {
    //         localStorage.removeItem('refreshToken');
    //         store.dispatch('auth/actRefreshToken', {refresh_payload: ""}, {root: true});
    //         reject(err);
    //       })
    //     }).then(() => {
    //       return axios(fromRequest).then((response) => {
    //         router.push(store.getters.getTodo);
    //         store.dispatch('actTodo', '');
    //       })
    //     }).catch((err) => {
    //       return Promise.reject(err)
    //     })
    //   }
    // }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(
  (config) => {
    // if (!store.getters['auth/getRefreshToken'] && localStorage.getItem('refreshToken')) {
    //   store.dispatch('auth/actRefreshToken', {refresh_payload: localStorage.getItem('refreshToken')}, {root: true});
    // }
    // console.log(`${config.url} ${!!store.getters['auth/getRefreshToken']}`);

    // config.headers.authorization = (store.getters['auth/getAccessToken']) ? 'bearer ' + store.getters['auth/getAccessToken'] : 'bearer ' + store.getters['auth/getRefreshToken'];
    config.headers.authorization = 'bearer ' + store.getters['auth/getRefreshToken'];
    return config;
  },
  (error) => Promise.reject(error)
);
export default axios;
