<template>
  <div class="main-view">
    <div class="left-box">
      <div class="left-text">
        <span class="web-name">
          <strong>{{ $config.getSiteConfig().title }}</strong>
        </span>
        <!-- <span class="login-ci-wrap">
          <img :src="logoData.serverFilePath" />
        </span> -->
      </div>
    </div>
    <div class="right-box hidden-xs-only"></div>
    <div class="size-change login-box-wrap">
      <el-row justify="center" class="mb-bg">
        <el-col :span="24" class="login-intro">
          <h2>LOG IN</h2>
          <span></span>
          <p>
            <strong>{{ $config.getSiteConfig().title }}</strong>
          </p>
        </el-col>
        <el-col :span="18" :offset="3" class="login-fill-box">
          <el-row>
            <el-col :span="24">
              <el-form :model="data" label-position="top">
                <el-form-item>
                  <el-input class="login-input" placeholder="아이디를 입력하세요" v-model="data.userid" @keyup.enter.native="submit"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-input
                    class="login-input"
                    placeholder="비밀번호를 입력하세요"
                    type="password"
                    v-model="data.password"
                    @keyup.enter.native="submit"
                  ></el-input>
                </el-form-item>
              </el-form>
            </el-col>
            <el-col :span="24" class="auto-login">
              <el-checkbox v-model="data.autoLogin">자동 로그인</el-checkbox>
            </el-col>
            <el-col :span="24" style="text-align: right;">
              <el-button class="button-login" @click="submit">LOGIN</el-button>
            </el-col>
            <el-col :span="24" style="text-align: right;" v-if="checkMobile()">
              <el-button class="download-btn" @click="downloadPage">모바일 다운로드</el-button>
            </el-col>
            <el-col :span="24">
              <span class="login-ci-wrap-mb">
                <!-- <img :src="logoData.serverFilePath" /> -->
                <!-- <img src="static/images/ulsan_north_main_ci.png" /> -->
              </span>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>

    <!-- <el-row type="flex" class="row-bg login-wrap" justify="center">
        <el-col :md="10" :sm="16" :xs="24" class="login-box">
          
        </el-col>
    </el-row>-->
    <el-dialog title="모바일 기기 인증" :visible.sync="formMobileApproveDialog" :close-on-click-modal="false">
      <div class="email-approve-body">
        <table class="dk-table">
          <colgroup>
            <col style="width: 45%;" />
            <col style="width: auto;" />
          </colgroup>

          <tbody>
            <tr>
              <th scople="row">사용자 ID</th>
              <td>{{ mobileApproveParam.userid }}</td>
            </tr>
            <tr>
              <th scople="row">기기 모델</th>
              <td>{{ mobileApproveParam.model }}</td>
            </tr>
            <tr>
              <th scople="row">기기 제조사</th>
              <td>{{ mobileApproveParam.vendor }}</td>
            </tr>
            <tr>
              <th scople="row">기기 OS</th>
              <td>{{ mobileApproveParam.os }}</td>
            </tr>
          </tbody>
        </table>
        <p>
          <span>{{ $config.getSiteConfig().title }}</span
          >위 계정에 현재 접속한 기기로 인증을 하실 경우, 다른 모바일 기기에서는 해당 계정으로 접속하실 수 없습니다. <br />인증을 진행하시려면 확인을
          눌러주세요.
        </p>
        <el-button type="primary" round @click="mobileApprove">기기 인증</el-button>
        <el-button round @click="formMobileApproveDialog = false">취소</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Fingerprint2 from 'fingerprintjs2';
import UAParser from 'ua-parser-js';

export default {
  data() {
    return {
      data: {
        userid: '',
        password: '',
        autoLogin: true,
      },
      locale: 'ko',
      logoData: null,
      formMobileApproveDialog: false,
      mobileApproveParam: {
        userid: '',
        model: '',
        vendor: '',
        os: '',
        accessid: '',
      },
    };
  },
  created() {
    if (this.$config.getSiteConfig().logo.length > 0) {
      this.logoData = this.$config.getSiteConfig().logo[0];
      document.title = this.$config.getSiteConfig().title;
    }
    this.backAction();
  },
  computed: {
    passwordRules() {
      let rules = '';
      rules = 'required|min:' + this.$system_conf.setting.id.minlength;
      return rules;
    },
    idRules() {
      let rules = '';
      rules = 'required|min:' + this.$system_conf.setting.password.minlength;
      return rules;
    },
  },
  methods: {
    submit() {
      if (this.data.userid.length == 0) {
        this.$message({
          type: 'error',
          message: `아이디를 입력하세요.`,
        });
        return false;
      }
      if (this.data.password.length == 0) {
        this.$message({
          type: 'error',
          message: `비밀번호를 입력하세요.`,
        });
        return false;
      }
      if (window.modbileDeviceId) this.data.appId = window.modbileDeviceId;
      if (window.mobileToken) this.data.appToken = window.mobileToken;
      let refreshToken = undefined;
      this.$store
        .dispatch('login/actLogin', { data: this.data }, { root: true })
        .then(async (data) => {
          refreshToken = data.result.refreshToken;
          if (this.data.autoLogin) {
            // localStorage.setItem('refreshToken', refreshToken);
          } else {
            // localStorage.removeItem('refreshToken');
          }

          // 기기인증 로그인
          if (this.$config.getSiteConfig().isLoginApprovedEquip) {
            if (data.result.approveEquipStatus && data.result.approveEquipStatus === 'success') {
              // await this.$dkRouter.getActiveUser(localStorage.getItem('refreshToken'));
              await this.$dkRouter.getActiveUser(refreshToken);
              await this.$dkPlugin.initPlugin();
              return this.$router.replace('/');
            } else if (data.result.approveEquipStatus && data.result.approveEquipStatus === 'invalid') {
              throw '입력한 사용자정보와 일치하지 않은 장비입니다.';
            }
            // 모바일 접속
            if (this.$isMobile) {
              if (data.result.approveEquipStatus && data.result.approveEquipStatus === 'mobile-auth') {
                var parser = new UAParser();
                Fingerprint2.get({}, (components) => {
                  var values = components.map(function (component) {
                    return component.value;
                  });
                  var accessid = Fingerprint2.x64hash128(values.join(''), 31);

                  this.mobileApproveParam = {
                    userid: data.result.userInfo.userid,
                    model: parser.getDevice().model,
                    vendor: parser.getDevice().vendor,
                    os: parser.getOS().name,
                    accessid: accessid,
                  };
                  if (window.modbileDeviceId) this.mobileApproveParam.appId = window.modbileDeviceId;

                  if (window.mobileToken) this.mobileApproveParam.appToken = window.mobileToken;

                  this.formMobileApproveDialog = true;
                });
                return;
              }
            }
            // PC 접속
            else {
              if (data.result.pc_macaddress) {
                if (
                  confirm(
                    `PC 맥어드레스${data.result.pc_macaddress} 로 최초 접속하셨습니다. 확인 버튼을 선택하실 경우, 해당 아이디와 디바이스가 귀속됩니다.`
                  )
                ) {
                  let approveResult = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/auth/approveEquip`, { ...data.result });
                  throw {
                    messageType: 'success',
                    message: `PC 맥어드레스${data.result.pc_macaddress} 로 인증되셨습니다. 다시 로그인 해주세요.`,
                  };
                  // return this.$dkRouter.getActiveUser(localStorage.getItem('refreshToken'));
                } else {
                  throw '취소하셨습니다.';
                }
              } else if (!data.result.approveEquipStatus) {
                throw '사용자 인증 접속 모드에서는 접속이 불가능한 상태입니다.';
              }
            }
          } else {
            // 일반 로그인
            // await this.$dkRouter.getActiveUser(localStorage.getItem('refreshToken'));
            await this.$dkRouter.getActiveUser(refreshToken);
            await this.$dkPlugin.initPlugin();
            // incidentEvent.websocketOpen();
            return this.$router.replace('/');
          }
        })

        .catch((err) => {
          if (typeof err === 'string') {
            this.$message({
              type: 'error',
              message: err,
            });
          } else if (typeof err === 'object') {
            console.error(err);
            if (err.messageType && err.messageType == 'success') {
              this.$message({
                type: 'success',
                message: err.message,
              });
            } else if (err.response && err.response.data && err.response.data.message) {
              this.$message({
                type: 'error',
                message: err.response.data.message,
              });
            } else {
              this.$message({
                type: 'error',
                message: '로그인 중 에러 발생',
              });
            }
          }
        });
    },
    async mobileApprove() {
      try {
        let approveResult = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/auth/mobileverify`, {
          data: this.mobileApproveParam,
        });
        this.$message({
          type: 'success',
          message: '모바일 기기 인증 되었습니다.',
        });
        this.formMobileApproveDialog = false;
      } catch (err) {
        this.$message({
          type: 'error',
          message: '모바일 기기 인증 실패하였습니다.',
        });
        this.formMobileApproveDialog = false;
      }
    },
    downloadPage() {
      // this.$router.push({
      //   path: '/download',
      // });
      this.$router.push('/download');
    },

    checkMobile() {
      // return window.modbileDeviceId && window.mobileToken;
      return this.$isMobile && !window.modbileDeviceId;
    },
    backAction() {
      if (!this.$isMobile || !window.modbileDeviceId) {
        return;
      }
      const me = this;
      window.backAction = () => {
        try {
          if (window.JSBridge && window.JSBridge.wvStatus) {
            window.JSBridge.wvStatus('idle');
          }
        } catch (err) {
          // this.$message('뒤로가기 에러');
        }
      };
    },
  },
};
</script>

<style scoped>
.email-approve-body {
  padding: 20px 0;
  text-align: center;
}

.email-approve-body p {
  line-height: 150%;
  margin-bottom: 40px;
}
.email-approve-body p span {
  color: #409eff;
  font-weight: bold;
}
.login-ci {
}
.download-btn {
  background-color: #fff;
  border: 1px solid #2c3e50;
  color: #2c3e50;
  font-weight: 600;
  height: 50px;
  margin-top: 10px;
  width: 100%;
}
</style>
