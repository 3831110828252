import axios from 'axios';
import dkTemplateUtil from '@/components/dkTemplate/dkTemplateUtil';

export default {
  namespaced: true,
  state: {
    template: false,
  },
  getters: {
    getTemplate: (state) => {
      return state.template;
    },
  },
  mutations: {
    setTemplate(state, payload) {
      state.template = payload;
    },
  },
  actions: {
    setFormTemplate({ commit }, payload) {
      commit('setTemplate', payload);
    },
    // getLicense({ commit }, payload) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get(`${this.state.dkConfig.getServerConfig().core.api}/core/api/license`, { params: payload })
    //       .then((response) => {
    //         resolve(response);
    //       })
    //       .catch((error) => {
    //         reject(error);
    //       });
    //   })
    //     .then((response) => {
    //       commit('setLicense', response.data);
    //       ret2urn response.data;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    setFcltTypeDataTemplate({ commit }, payload) {
      commit('setTemplate', payload);
    },
  },
};
