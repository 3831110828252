<template>
  <el-dialog :title="formTitle" :visible.sync="formDialog" width="70%" top="5vh" @close="hideDialog" @open="openDialog" :close-on-click-modal="false">
    <div>
      <el-row class="form-gis-wrap" :gutter="20">
        <el-col :span="13">
          <el-form :model="formData" :rules="rules" label-width="90px" size="small">
            <el-row>
              <el-col :span="24">
                <table class="dk-table">
                  <colgroup>
                    <col style="width: 30%;" />
                    <col style="width: 70%;" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th scople="row">노드링크 정보</th>
                      <td>
                        {{ formData.linkData.roadName }}<span>({{ formData.linkData.linkId }})</span>
                      </td>
                    </tr>
                    <tr>
                      <th scople="row">구간 정보</th>
                      <td>{{ formData.linkData.fNodeName }} ~ {{ formData.linkData.tNodeName }} ({{ formData.linkData.length }}m)</td>
                    </tr>
                  </tbody>
                </table>
              </el-col>
              <el-col :span="24" style="margin-top: 20px;">
                <el-form-item label="진행 상태" prop="incidentStatus">
                  <el-radio-group v-model="formData.incidentStatus">
                    <el-radio-button :label="1">진행 중</el-radio-button>
                    <el-radio-button :label="2">종료</el-radio-button>
                  </el-radio-group>
                </el-form-item>

                <el-form-item label="돌발 유형" prop="incidentType">
                  <el-select v-model="formData.incidentType" placeholder="돌발 유형">
                    <el-option v-for="item in incidentType" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="통제 여부" prop="incidentRoadCode">
                  <el-select v-model="formData.incidentRoadCode" placeholder="통제 여부">
                    <el-option v-for="item in incidentRoadCode" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="발생 일시" prop="occurDateTime">
                  <el-date-picker type="date" v-model="formData.occurDateTime" placeholder="발생 일시"></el-date-picker>
                </el-form-item>

                <el-form-item label="완료 예정" prop="completeDateTime">
                  <el-date-picker type="date" v-model="formData.completeDateTime" placeholder="완료 예정"></el-date-picker>
                </el-form-item>

                <el-form-item label="내용" prop="incidentInfo">
                  <el-input type="textarea" v-model="formData.incidentInfo"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
        <el-col :span="11" class="map-wrap">
          <dk-gis
            fill-height
            :gisApiInfo="gisApiInfo"
            :trafficLayerData="trafficLayerData"
            :selectTrafficLayer="selectTrafficLayer"
            :markerData="markerData"
            ref="dkGis"
          >
          </dk-gis>
        </el-col>
      </el-row>

      <div class="form-bottom">
        <el-button @click="hideDialog" style="float: right;">
          취소
        </el-button>
        <el-button type="primary" @click="save" style="float: right; margin-right: 5px;">
          저장
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import dkgismain from '@/components/dkGis/dkGisMain.vue';
// import incidentConst from '@/traffic/incidentConst.js'
import dkStraightRoad from './dkStraightRoad.vue';

export const incidentType = [
  { value: '1', label: '공사' },
  { value: '2', label: '사고' },
  { value: '3', label: '행사' },
  { value: '4', label: '집회' },
  { value: '5', label: '자연재해' },
];

export const incidentVehicle = [
  { value: '0', label: '추가정보 필요없음' },
  { value: '1', label: '추가정보 필요' },
  { value: '2', label: '자전거/오토바이' },
  { value: '3', label: '승용차' },
  { value: '4', label: '버스' },
  { value: '5', label: '트럭' },
  { value: '6', label: '트레일러' },
];

export const incidentStatus = [
  { value: '0', label: '추가정보 필요없음' },
  { value: '1', label: '추가정보 필요' },
  { value: '2', label: '사고 감지' },
  { value: '3', label: '확인 및 대응조치' },
  { value: '4', label: '처리 및 복구' },
  { value: '5', label: '완료' },
  { value: '6', label: '기타' },
];

export const incidentRoadCode = [
  { value: '0', label: '없음' },
  { value: '1', label: '부분통제' },
  { value: '2', label: '전체통제' },
  { value: '3', label: '도로폐쇄' },
];

export const plannedRoadClosure = [
  { value: '0', label: '없음' },
  { value: '1', label: '기타' },
  { value: '2', label: '장기공사' },
  { value: '3', label: '단기공사' },
  { value: '4', label: '유지보수' },
  { value: '5', label: '긴급유지보수' },
];

export default {
  components: {
    'dk-gis': dkgismain,
  },
  data() {
    return {
      incidentType: incidentType,
      incidentRoadCode: incidentRoadCode,
      incidentVehicle: incidentVehicle,
      incidentStatus: incidentStatus,
      plannedRoadClosure: plannedRoadClosure,
      incidentFormStatus: 'event',
      formDialog: false,
      formTitle: '',
      formData: {
        incidentStatus: '1',
        incidentType: '1',
        incidentRoadCode: '0',
        occurDateTime: new Date(),
        completeDateTime: new Date(),
        incidentInfo: '',
        linkData: {
          linkId: '',
          roadName: '',
          tNodeName: '',
          fNodeName: '',
          length: 0,
        },
      },
      roadLinkData: [],
      rules: {},
      markerData: [
        {
          seq: 0,
          draggable: false,
          geometry: {
            lng: 127.10056150323506,
            lat: 37.489008855218195,
          },
          properties: {},
        },
      ],
      trafficLayerData: {
        base: [
          {
            name: 'link_traf',
            url: `${this.$config.getServerConfig().traffic.gisServer}/geoserver/wms`,
            //params: {'LAYERS': 'cite:gimpo_ifsc_traf', 'TILED': true,  'STYLES': 'cite:gimpo_utis_deselect_style' },
            params: { LAYERS: 'cite:gimpo_link_offset', TILED: true },
            default: true,
            idKey: 'link_id',
          },
        ],
        select: {
          source: {
            url: `${this.$config.getServerConfig().traffic.gisServer}/geoserver/wms`,
          },
          mode: 'Single',
        },
      },
      isGisInit: false,
      currentGisApi: null,
      trafficLayers: [
        { level: 8, layer: 'gimpo_link_level_16' },
        { level: 7, layer: 'gimpo_link_level_15' },
        { level: 6, layer: 'gimpo_link_level_14' },
        { level: 5, layer: 'gimpo_link_level_13' },
        { level: 4, layer: 'gimpo_link_level_12' },
        { level: 3, layer: 'gimpo_link_level_12' },
      ],
    };
  },
  created() {},
  props: {
    gisApiInfo: {
      type: Array,
      // gis 기본 설정값을 띄우는 요부분은 나중에 vuex에서 받아서 처리할것
      default() {
        return [];
      },
    },
    geometry: {
      type: Object,
      default: null,
    },
  },
  methods: {
    async save() {
      try {
        this.formData.gisLat = this.formData.linkData.centerPoint.coordinates[1];
        this.formData.gisLng = this.formData.linkData.centerPoint.coordinates[0];

        await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/trafficIncident/register`, { data: this.formData });
        this.$message({
          type: 'success',
          message: `유지관리 등록 완료`,
        });
        this.hideDialog();
        this.$emit('onSaveComplete', this.formData);
      } catch (err) {
        this.$message.error(`유지관리 등록 중 에러 발생`);
        this.hideDialog();
        this.$emit('onSaveComplete', this.formData);
      }
    },
    showDialog(incidentData) {
      console.log(incidentData);
      if (incidentData.occurDateTime) {
        this.updateFlag = true;
        this.formTitle = '돌발/행사 수정';
        this.formData = this.$lodash.cloneDeep(incidentData);
      } else {
        this.updateFlag = false;
        this.formTitle = '돌발/행사 추가';
        this.resetData();
        this.getMarkerPoint(incidentData.linkData);
      }
      this.formDialog = true;
      setTimeout(() => {
        this.setCenter({ lat: this.formData.linkData.centerPoint.coordinates[1], lng: this.formData.linkData.centerPoint.coordinates[0] });
      }, 300);
      this.$forceUpdate();
    },
    async getMarkerPoint(link) {
      const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/trafficIncident/linkCenterPoint`, { params: { linkId: link.link_id } });
      this.formData.linkData = response.data;
      // this.setCenter({ lat: this.formData.linkData.centerPoint.coordinates[1], lng: this.formData.linkData.centerPoint.coordinates[0] });
    },
    getIncidentType(val) {
      const result = this.incidentType.find((d) => {
        return d.value == val;
      });
      return result.label;
    },
    getIncidentRoadCode(val) {
      const result = this.incidentRoadCode.find((d) => {
        return d.value == val;
      });
      return result.label;
    },
    apiLoadComplete(currentGisApi) {
      this.isGisInit = true;
      this.currentGisApi = currentGisApi;
      this.$refs.dkGis.setTrafficLayer(true);
    },
    getLinkData() {},
    openDialog(data) {
      // this.fcltManageDialogGisInit()
    },
    hideDialog() {
      this.resetData();
      this.formDialog = false;
    },
    resetData() {
      this.isGisInit = false;
      this.formData = {
        incidentStatus: '1',
        incidentType: '1',
        incidentRoadCode: '0',
        occurDateTime: new Date(),
        completeDateTime: new Date(),
        incidentInfo: '',
        linkData: {
          linkId: '',
          roadName: '',
          tNodeName: '',
          fNodeName: '',
          length: 0,
        },
      };
    },

    changeIsPopup(val) {
      if (val) {
        //if (!this.formData.endDate) {
        //  this.formData.endDate = new Date();
        //}
      }
    },

    setCenter(geometry) {
      this.$refs.dkGis.setCenter(geometry);
      this.markerData[0].geometry = geometry;
    },

    async getLinksByRoad(linkData) {
      const size = 5;
      let rowData = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/linksByRoad`, {
        params: linkData,
      });
      if (!rowData.data || rowData.data.length == 0) {
        return false;
      }
      // let centerLinkData = rowData.data.find((d) => { return d.link_id == linkData.link_id })
      // let currentLinkData = this.$lodash.cloneDeep(centerLinkData)
      // let prevLinkData = []
      // let roadLinkData = []
      //  prevLinkData.push(centerLinkData)
      // for (var i=0; i<size; i++) {
      //   let tmpLinkData = rowData.data.find((d) => {
      //     return d.t_node === currentLinkData.f_node && d.f_node !== currentLinkData.t_node
      //   })
      //   if (tmpLinkData) {
      //     currentLinkData = tmpLinkData
      //     prevLinkData.push(tmpLinkData)
      //   }
      // }
      // roadLinkData = this.$lodash.reverse(prevLinkData)
      // currentLinkData = centerLinkData
      // for (var i=0; i<size; i++) {
      //   let tmpLinkData = rowData.data.find((d) => {
      //     return d.f_node === currentLinkData.t_node && d.t_node !== currentLinkData.f_node
      //   })
      //   if (tmpLinkData) {
      //     currentLinkData = tmpLinkData
      //     roadLinkData.push(tmpLinkData)
      //   }
      // }
      this.roadLinkData = rowData.data;
      // console.log(roadLinkData)
    },
    selectTrafficLayer(feature) {
      console.log(feature);
    },
  },
};
</script>

<style scoped>
.form-gis-wrap {
  height: 400px;
}

.form-gis-wrap .map-wrap {
  height: 400px;
  position: relative;
}

.form-gis-wrap .map-wrap .vue-dkgis-wrap {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
</style>
