var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-upload",
    {
      attrs: {
        action: _vm.uploadPath,
        name: "uploadFile",
        "list-type": _vm.listType,
        data: this.uploadParam,
        "before-upload": _vm.checkUpload,
        "on-change": _vm.procUploadFile,
        "on-remove": _vm.procRemoveFile,
        "file-list": _vm.fileList,
        drag: _vm.drag,
        multiple: _vm.multiple,
        limit: _vm.limit,
        "on-preview": _vm.onPreview,
        disabled: _vm.disabled
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }